import {
	getmybizAxiosConfig
} from '../../apisSetup/myBizConfig';
const mybizAxiosApiCaller = getmybizAxiosConfig(true);
import {
	CHARTERED_FLIGHT_STORE
} from '../actionConstants';
import {
	PATHS, APPLICATION_CONSTANTS
} from '../../AppConstants';

export const submitQuery = (data) => async (dispatch) => {
	const emailType = 'CHARTER_FLIGHT_TEMPLATE';
	let response = null;
	const { date, email, from_city, name, number, to_city, travellers } = data;
	let mailerParams = {
		emailType: emailType,
		metaData: {
			date,
			email,
			from_city,
			name,
			number,
			to_city,
			travellers: Number(travellers)
		},
		toEmailId: APPLICATION_CONSTANTS.CHARTERED_FLIGHT_SENDER_EMAIL,
		mediumsApplicable: ['EMAIL']
	};
	dispatch({
		type: CHARTERED_FLIGHT_STORE.CHARTERED_API_CALLED
	});
	try {
		response = await mybizAxiosApiCaller.post(PATHS.SEND_MAIL, mailerParams);
		dispatch({
			type: CHARTERED_FLIGHT_STORE.CHARTERED_API_SUCCESS,
			payload: response.data
		});
	} catch (e) {
		dispatch({
			type: CHARTERED_FLIGHT_STORE.CHARTERED_API_FAILURE
		});
		return;
	}
};
