import { MANAGE_REQUEST_BOOKINGS } from 'reduxStore/actionConstants';
import { successFor, failedFor } from 'reduxStore/sagaUtil';
import { ORG_ADMIN, filters, pageApiConfigs } from '../../manageRequestBookings/config';
import { formatFiltersOptions, getFilters, getInitialQuery } from 'UtilityComponents/manageRequestBookingUtils';

const initialBookingsList = { data: [], error: false, loading: true, hasMore: true };

export const INITIAL_STATE = {
	detailsList: { ...initialBookingsList, loading: true },
	selectedRecords: {},
	list: [],
	filters: [],
	query: null,
	searchQuery: '',
	requestType: '',
	approverLoading: false,
	...pageApiConfigs
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}
	const { type, payload } = action;
	switch (type) {
		case MANAGE_REQUEST_BOOKINGS.SET_REQUEST_TYPE:
			const { value = '', requestType = '' } = payload || {};
			const filtersConfig = getFilters(filters, requestType);
			const initialQuery = getInitialQuery(requestType, value);
			return {
				...state,
				filters: filtersConfig,
				isReset: true,
				detailsList: { ...initialBookingsList },
				query: { ...initialQuery, requestType },
				...pageApiConfigs

			};
		case MANAGE_REQUEST_BOOKINGS.GET_BOOKING_LIST:
			return {
				...state,
				detailsList: {
					...state.detailsList,
					error: false,
					loading: true
				}
			};
		case successFor(MANAGE_REQUEST_BOOKINGS.GET_BOOKING_LIST):
		case failedFor(MANAGE_REQUEST_BOOKINGS.GET_BOOKING_LIST):
			return {
				...state,
				...payload
			};
		case MANAGE_REQUEST_BOOKINGS.UPDATE_PAGE_NO: {
			return {
				...state,
				page: state.page + 1,
				isReset: false
			};
		}
		case MANAGE_REQUEST_BOOKINGS.FILTER_QUERY: {
			const { updatedQuery, updatedFilter = state.filters } = payload || {};

			return {
				...state,
				query: updatedQuery,
				filters: updatedFilter,
				isReset: true,
				detailsList: { ...initialBookingsList },
				...pageApiConfigs
			};
		}
		case MANAGE_REQUEST_BOOKINGS.FILTER_CLEAR: {
			const { updatedQuery, updatedFilter } = payload || {};
			return {
				...state,
				query: updatedQuery,
				filters: updatedFilter,
				isReset: true,
				detailsList: { ...initialBookingsList },
				...pageApiConfigs
			};
		}
		case MANAGE_REQUEST_BOOKINGS.GET_APPROVERS_LIST: {
			return {
				...state,
				approverLoading: true
			};
		}
		case successFor(MANAGE_REQUEST_BOOKINGS.GET_APPROVERS_LIST): {
			const { managersData = [], index } = payload;
			managersData.unshift(ORG_ADMIN);

			let filters = state.filters;
			filters = formatFiltersOptions(
				state.filters,
				index,
				managersData
			);
			return {
				...state,
				filters: filters,
				approverLoading: false
			};
		}
		case MANAGE_REQUEST_BOOKINGS.GET_FILTER_COUNTS: {
			return {
				...state,
				tabsCount: {}
			};
		}
		case successFor(MANAGE_REQUEST_BOOKINGS.GET_FILTER_COUNTS): {
			const { filters, tabsCount } = payload;
			return {
				...state,
				filters,
				tabsCount
			};
		}
		case MANAGE_REQUEST_BOOKINGS.CLEAR_SEARCH_VALUE: {
			const { ...tempQuery } = state.query;
			delete (tempQuery.searchQuery);
			return {
				...state,
				query: tempQuery
			};
		}
		case MANAGE_REQUEST_BOOKINGS.SET_SEARCH_QUERY: {
			const { searchQuery } = payload;
			return {
				...state,
				searchQuery,
				tabsCount: {}
			};
		}
		case MANAGE_REQUEST_BOOKINGS.UPDATE_TAB_COUNT: {
			return {
				...state,
				...payload
			};
		}
		default: {
			return state;
		}
	}
};
