import { ORG_DETAILS_CONSTANT } from 'reduxStore/actionConstants';
import { actionCreator, failedFor, successFor } from 'reduxStore/sagaUtil';

export const getOrgsData = actionCreator(ORG_DETAILS_CONSTANT.GET_ORGANIZATION_DETAILS);
export const getOrgsDataSuccess = actionCreator(successFor(ORG_DETAILS_CONSTANT.GET_ORGANIZATION_DETAILS));
export const getOrgsDataFailure = actionCreator(failedFor(ORG_DETAILS_CONSTANT.GET_ORGANIZATION_DETAILS));

export const setModal = actionCreator(ORG_DETAILS_CONSTANT.SET_MODAL);
export const postFormData = actionCreator(ORG_DETAILS_CONSTANT.POST_FORM_DATA);
export const postFormDataFailed = actionCreator(failedFor(ORG_DETAILS_CONSTANT.POST_FORM_DATA));

export const getCurrencies = actionCreator(ORG_DETAILS_CONSTANT.GET_CURRENCIES_API);
export const getCurrenciesSuccess = actionCreator(successFor(ORG_DETAILS_CONSTANT.GET_CURRENCIES_API));
export const getCurrenciesFailed = actionCreator(failedFor(ORG_DETAILS_CONSTANT.GET_CURRENCIES_API));

export const getApiKeys = actionCreator(ORG_DETAILS_CONSTANT.GET_API_KEYS);
export const getApiKeysSuccess = actionCreator(successFor(ORG_DETAILS_CONSTANT.GET_API_KEYS));
export const getApiKeysFailed = actionCreator(failedFor(ORG_DETAILS_CONSTANT.GET_API_KEYS));

export const updateApiKeys = actionCreator(ORG_DETAILS_CONSTANT.UPDATE_API_KEYS);
export const updateApiKeysSuccess = actionCreator(successFor(ORG_DETAILS_CONSTANT.UPDATE_API_KEYS));
export const updateApiKeysFailed = actionCreator(failedFor(ORG_DETAILS_CONSTANT.UPDATE_API_KEYS));

export const getOrgsAddressData = actionCreator(ORG_DETAILS_CONSTANT.GET_ORGANIZATION_ADDRESS_DETAILS);
export const getOrgsAddresDataSuccess = actionCreator(successFor(ORG_DETAILS_CONSTANT.GET_ORGANIZATION_ADDRESS_DETAILS));
export const getOrgsAddresDataFailure = actionCreator(failedFor(ORG_DETAILS_CONSTANT.GET_ORGANIZATION_ADDRESS_DETAILS));

export const addUpdateOrgsAddress = actionCreator(ORG_DETAILS_CONSTANT.ADD_UPDATE_ADDRESS);
export const addUpdateOrgsAddressSuccess = actionCreator(successFor(ORG_DETAILS_CONSTANT.ADD_UPDATE_ADDRESS));
export const addUpdateOrgsAddressFailure = actionCreator(failedFor(ORG_DETAILS_CONSTANT.ADD_UPDATE_ADDRESS));

export const addUpdateOrgsAddressOrId = actionCreator(ORG_DETAILS_CONSTANT.ADD_UPDATE_ORG_ID_ADDRESS);
