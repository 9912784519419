import * as ActionConstants from '../actionConstants';
import 'es6-promise';
import axios from 'axios';
import {
	PATHS,
	LOGIN_CONSTANTS,
	APPLICATION_CONSTANTS
} from 'AppConstants';
import {
	isDeviceMobile,
	setCookie,
	setProfileData,
	setMmtAuthForCorpLogin,
	clearLoginState,
	saveServerLogs
} from 'UtilityComponents/Util';
import { trackClickEventSimple } from '../../logging/omnitureService';

export const validateEmail = (email) => {
	if (!email) {
		return false;
	}
	email = email.trim();
	return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
};
export const loginByAuth = (fromLogin) => function(dispatch) {
	if (fromLogin && (urlParam(LOGIN_CONSTANTS.USER_INACTIVITY) || urlParam(LOGIN_CONSTANTS.SESSION_EXPIRY))) {
		resetLoggingState();
		return logoutOnExpire(dispatch);
	}
	dispatch({
		type: ActionConstants.LOGIN_PROGRESS,
		payload: {
			loginState: ActionConstants.LOGIN_PROGRESS,
			errorType: null,
			errorMsg: null
		}
	});
	let res = axios({
		method: 'GET',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		url: '/loginByAuth'
	}).then((res) => handleLoginResult(res, dispatch, '', fromLogin))
		.catch((err) => {
			if (fromLogin) {
				disapatchValidationFailed(dispatch);
			} else {
				disapatchLoginFailed(dispatch, ActionConstants.LOGIN_FAILED_NW_ERROR);
			}
			saveServerLogs(err.stack, 'error', '/loginByAuth');
		});
};

export const logInMyUser = (email, password) => function(dispatch) {
	if (!validateEmail(email)) {
		dispatch({
			type: ActionConstants.INVALID_EMAIL_FORMAT,
			payload: {
				loginState: ActionConstants.STATES.FAILED,
				errorType: ActionConstants.INVALID_EMAIL_FORMAT,
				errorMsg: ActionConstants.ERR_MSGS[ActionConstants.INVALID_EMAIL_FORMAT]
			}
		});
		return;
	}
	if (!password || password.length < 6) {
		dispatch({
			type: ActionConstants.PASSWORD_LENGTH_IS_INSUFFICIENT,
			payload: {
				loginState: ActionConstants.STATES.FAILED,
				errorType: ActionConstants.PASSWORD_LENGTH_IS_INSUFFICIENT,
				errorMsg: ActionConstants.ERR_MSGS[ActionConstants.PASSWORD_LENGTH_IS_INSUFFICIENT]
			}
		});
		return;
	}
	dispatch({
		type: ActionConstants.LOGIN_PROGRESS,
		payload: {
			loginState: ActionConstants.LOGIN_PROGRESS,
			errorType: null,
			errorMsg: null
		}
	});
	let request = {
		'userEmail': email,
		'password': password,
		'type': 'EMAIL',
		'query': [
			[{
				'name': 'extendedUser',
				'keys': ['primaryEmailId', 'profileId', 'accountId', 'profileType', 'personalDetails', 'corporateData', 'status', 'contactDetails', 'uuid']
			}]
		]
	};
	try {
		let res = axios({
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			data: request,
			url: '/pwa/mmtLogin/new'
		}).then((res) => {
			handleLoginResult(res, dispatch, email, true);
		})
			.catch((err) => {
				disapatchLoginFailed(dispatch, LOGIN_CONSTANTS.API_ERROR);
			});
	} catch (err) {

	}
};
export const handleLoginResult = (res, dispatch, email, fromLogin) => {
	dispatch({
		type: ActionConstants.OFF_LOADING,
		payload: {}
	});
	if (!res || !res.data) {
		return disapatchLoginFailed(dispatch, LOGIN_CONSTANTS.LOGIN_ERROR);
	}
	let data = res.data;
	let expireTime = 0.5; // 12 hours to be send to setcookie api;
	let response = res.data;
	if (!data.success) {
		document.cookie = 'couchKey' + '=' + ';path=/;';
		let nextPageUrl = PATHS.SSO_PAGE_URL;
		if (data.errorCode && data.errorCode === APPLICATION_CONSTANTS.HCS_SSO_LOGIN_API_ERROR_CODE) {
			return dispatch({
				type: ActionConstants.LOGIN_SSO,
				payload: {
					loginState: ActionConstants.STATES.SSO_USER,
					nextPageUrl: nextPageUrl,
					errorType: ActionConstants.SSO_USER_LOGIN_AT_NORMAL_FORM,
					email: email,
					popupError: (response.message ? response.message : APPLICATION_CONSTANTS.SSO_SIGN_ON_ERROR_MESSAGE)
				}
			});
		}
		let resetPasswordUrl = PATHS.FORGOT_PWD;
		if (data.errorCode && data.errorCode === APPLICATION_CONSTANTS.HCS_PASSWORD_EXPIRE_ERROR_CODE) {
			return dispatch({
				type: ActionConstants.LOGIN_PASS_EXPIRE,
				payload: {
					loginState: ActionConstants.STATES.PASSWORD_EXPIRED,
					nextPageUrl: resetPasswordUrl,
					errorType: ActionConstants.PASSWORD_EXPIRED_USER,
					email: email,
					popupError: (response.message ? response.message : APPLICATION_CONSTANTS.RESET_PASSWORD_ERROR_MESSAGE)
				}
			});
		}
		const popupError = response.message ? response.message : LOGIN_CONSTANTS.LOGIN_ERROR;
		return disapatchLoginFailed(dispatch, popupError);
	}
	let profiles = response.data.profileLoginResponse;
	let businessProfile = null;
	for (let index in profiles) {
		if (profiles[index].profileType === 'BUSINESS') {
			businessProfile = profiles[index];
		}
	}
	if (!businessProfile || !businessProfile.userDetails || !businessProfile.userDetails.extendedUser ||
        !businessProfile.userDetails.extendedUser.corporateData) {
		document.cookie = 'couchKey' + '=' + ';path=/;';
		return disapatchLoginFailed(dispatch, LOGIN_CONSTANTS.B2C_USER_ERROR);
	}

	let corpData = JSON.parse(businessProfile.userDetails.extendedUser.corporateData);
	if (!corpData || JSON.stringify(corpData) === '{}' || corpData.errorCode) {
		document.cookie = 'couchKey' + '=' + 'expires=' + expireTime + ';path=/;';
		return disapatchLoginFailed(dispatch, LOGIN_CONSTANTS.B2C_USER_ERROR);
	}
	if (corpData.employee.employeeStatus === 'PENDING_VERIFICATION') {
		document.cookie = 'couchKey' + '=' + 'expires=' + expireTime + ';path=/;';
		return disapatchLoginFailed(dispatch, LOGIN_CONSTANTS.VERIFY_USER, ActionConstants.SCREEN_VERIFY_EMAIL.GO_TO_VERIFY);
	}
	if (corpData.employee.employeeStatus === 'DISABLED') {
		document.cookie = 'couchKey' + '=' + 'expires=' + expireTime + ';path=/;';
		return disapatchLoginFailed(dispatch, LOGIN_CONSTANTS.DISABLED_ERROR);
	}
	let employee = corpData.employee;
	// now the employee is proper and verified;

	let windowObj = {};
	let isAdmin = false;
	let role = '';
	if (employee && employee.roles) {
		setProfileData(employee);
		// set defaults and cookies
		isAdmin = checkRole(employee.roles);
		role = isAdmin ? 'admin' : '';
		const couchKey = isAdmin ? response.couchBasekey : '';
		// setMmtAuthForCorpLogin(businessProfile.authToken, expireTime, role, couchKey, fromLogin);
		setMmtAuthForCorpLogin(businessProfile.authToken, expireTime, role, couchKey);
		if (isAdmin && employee.mmtUserId) {
			setCookie('mmtAuth', businessProfile.authToken, expireTime);
			let domain = null;
			if (employee.businessEmailId) {
				let email = employee.businessEmailId.split('@');
				domain = email[1];
			}
			let encryptedEmpId = btoa(parseInt(Math.random() * 1000000).toString() + '|' + employee.id);
			windowObj = {
				'userName': employee.name,
				'userEmail': employee.businessEmailId,
				'x_CSRF_TOKEN': response.x_CSRF_TOKEN,
				'currentLoggedInEmpId': encryptedEmpId,
				'domain': domain
			};
		} else if (employee.provisionAdmin) {
			setCookie('provisionalAdminCookie', response.provisionalAdminKey, expireTime);
			setCookie('mmtAuth', businessProfile.authToken, expireTime);
		}
		if (employee.organizationId) {
			windowObj.orgId = employee.organizationId;
		}
	}
	// if(fromLogin === 'noRedirect') {
	//     return false
	// }
	const isProvisionalAdmin = !isAdmin && employee.provisionAdmin;
	trackClickEventSimple('login_sucess');
	if (fromLogin && !urlParam('nextPage')) {
		return dispatch({
			type: ActionConstants.LOGIN_PROCEED_TO_URL,
			payload: {
				loginState: ActionConstants.LOGIN_PROCEED_TO_URL,
				nextPageUrl: getNextPage(PATHS.MMT_MYBIZ)
			}
		});
	}

	// handle cases on loggedIn
	return onLogInRedirect(employee, isAdmin, windowObj, dispatch);
};
const onLogInRedirect = (employee, isAdmin, windowObj, dispatch) => {
	const nextPageFromUrl = urlParam('nextPage');
	if (employee && employee.roles && isAdmin) {
		windowObj.nextPageParam = (LOGIN_CONSTANTS.DASHBOARD_URL + employee.organizationId);
		return dispatch({
			type: ActionConstants.LOGIN_PROCEED_TO_DASHBOARD,
			payload: {
				loginState: ActionConstants.LOGIN_PROCEED_TO_DASHBOARD,
				windowObj
			}
		});
	} else if (nextPageFromUrl && nextPageFromUrl === 'main_booking_site') {
		if (!isDeviceMobile()) {
			return dispatch({
				type: ActionConstants.LOGIN_PROCEED_TO_URL,
				payload: {
					loginState: ActionConstants.LOGIN_PROCEED_TO_URL,
					nextPageUrl: getNextPage(PATHS.MMT_MYBIZ)
				}
			});
		}
		windowObj.nextPageParam = (LOGIN_CONSTANTS.DASHBOARD_URL + employee.organizationId);
		return dispatch({
			type: ActionConstants.LOGIN_PROCEED_TO_DASHBOARD,
			payload: {
				loginState: ActionConstants.LOGIN_PROCEED_TO_DASHBOARD,
				windowObj
			}
		});
	} else if (employee && employee.roles && !isAdmin && employee.provisionAdmin) {
		return dispatch({
			type: ActionConstants.PROCEED_TO_PROVISIONAL_DASHBOARD,
			payload: {
				loginState: ActionConstants.PROCEED_TO_PROVISIONAL_DASHBOARD,
				nextPageUrl: getNextPage(PATHS.PROVISIONAL_ADMIN_PAGE)
			}
		});
	} else {
		let nextPageUrl = getNextPage(PATHS.MMT_MYBIZ);
		let nextPageMessage = LOGIN_CONSTANTS.EMPLOYEE_LOGIN_MESSAGE;
		if (isDeviceMobile()) {
			nextPageUrl = LOGIN_CONSTANTS.MOBILE_EMPLOYEE_URL;
			nextPageMessage = LOGIN_CONSTANTS.MOBILE_EMPLOYEE_LOGIN_MESSAGE;
		}
		return dispatch({
			type: ActionConstants.LOGIN_B2C,
			payload: {
				loginState: ActionConstants.LOGIN_B2C,
				errorMsg: null,
				nextPageUrl: nextPageUrl,
				popupError: nextPageMessage
			}
		});
	}
};

const logoutOnExpire = (dispatch) => {
	clearLoginState();
	disapatchValidationFailed(dispatch);
};
export const getNextPage = (defaultNextPage, orgId) => {
	let nextPage = urlParam('nextPage');
	let defaultUrlVal = defaultNextPage || '';
	let redirectUrl = urlParam('redirectUrl');
	redirectUrl = decodeURIComponent(redirectUrl);
	switch (nextPage) {
		case 'C_approval':
			return '/approval/corporate';
		case 'C_bulk_invoice':
			return '/bulkDownloadPage?orgId=' + orgId;
		case 'main_booking_site':
			return 'https://www.makemytrip.com';
		case 'payment_page':
			return `${PATHS.WALLET_PAGE}?orgId=${orgId}`;
		case 'payment_page_campaign':
			return `${PATHS.WALLET_PAGE}?orgId=${orgId}&isMail=true`;
		case 'employee_page':
			return 'corporate?orgId=' + orgId + '&tab=employee';
		case 'report_page':
			return '/report/corporate?orgId=' + orgId;
		case 'groups_page':
			return '/corporate?orgId=' + orgId + '&tab=group';
		case 'gst_page':
			return '/gst?orgId=' + orgId;
		case 'redirect':
			return redirectUrl;
		default:
			return defaultUrlVal;
	}
	// todo: if user is coming from nonloggedin page of mybiz route him to same page
	// return (document.referrer !== document.location.href && document.referrer.split('/').length >= 3 && document.referrer.split('/')[2] === 'mybiz.makemytrip.com') ? document.referrer :
};
export const urlParam = (name) => {
	if (typeof window === 'undefined') {
		return null;
	}
	if (window.location && window.location.href) {
		let results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
		if (results == null) {
			return null;
		} else {
			return decodeURI(results[1]) || 0;
		}
	}
};
const checkRole = (role) => {
	for (let key in role) {
		if (role[key] === 'ADMIN') {
			return true;
		}
	}
	return false;
};

export const resetLoggingState = () => (dispatch) => {
	dispatch({
		type: ActionConstants.RESET_LOGGING,
		payload: {}
	});
};
const disapatchValidationFailed = (dispatch) => {
	dispatch({
		type: ActionConstants.LOGIN_FAILED,
		payload: {
			loginState: ActionConstants.STATES.NO_ACTION
		}
	});
};
const disapatchLoginFailed = (dispatch, error, state) => {
	dispatch({
		type: ActionConstants.LOGIN_FAILED,
		payload: {
			loginState: ActionConstants.STATES.FAILED || state,
			popupError: error,
			errorMsg: null
		}
	});
};
