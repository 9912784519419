import { takeLatest, put, select, call } from 'redux-saga/effects';

import { EMPLOYEE_STORE_CONST } from '../actionConstants';
import { empListSuccess, setApiLoading, setApiError, setApiSuccess,
	bulkUpdateSuccsess, dwlEmpDataSuccsess, getUpdateStatusSuccess, setEmpModal,
	getAbEmpValsSuccess, fetchInvitationChannelsSuccess, fetchInvitationChannels,
	fetchHrmsStatusSuccess } from '../actions/employeeActions';
import { resetApiState as resetGrpApiState } from '../actions/groupsAction';
import { employeeSelectors } from '../selectors';
import { APPLICATION_CONSTANTS, EMPLOYEE_CONSTANTS, PATHS, GROUP_CONSTANTS } from 'AppConstants';
import { getEmpList, updateBulkData, downloadEmpData, getUpdateStatusApi,
	downloadDataFromurl, getInviteChannel, updateInviteChannel,
	getHrmsStatusApi } from '../../apis/empApis';
import { getAbExperiment } from '../../apis/commonApis';
import { ERROR_SUCCES_MSG, updateInviteChannelSuccess } from '../../Employee/config';
import {
	formatEmployeeData,
	formatChannelRequest
} from '../../Employee/utils';
import { getDate, saveServerLogs } from 'UtilityComponents/Util';
import { createGrpApi } from '../../apis/groupapis';

function* fetchEmployeeList({ payload }) {
	let isError = false;
	const isAllSelect = yield select(employeeSelectors.isAllSelected);
	const empList = yield select(employeeSelectors.employeeList);
	const selectedEmployees = yield select(employeeSelectors.selectedEmployees);
	const { filterQuery = {}, isSearch = false, grpId = false } = payload;
	const resetPage = filterQuery.page === 1;

	const retData = {
		employeeList: {
			hasMore: false,
			error: true,
			data: resetPage ? [] : empList.data,
			loading: false
		},
		selectedEmployees: resetPage ? {} : selectedEmployees
	};
	try {
		const response = yield call(getEmpList, filterQuery, isSearch);
		const { data } = response || {};

		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const { searchResponses = [], limit } = data;
			const responseLength = searchResponses.length;
			const hasMore = responseLength > 0 && limit <= responseLength;
			const { list, selectedList } = formatEmployeeData(searchResponses, isAllSelect);
			retData.employeeList = {
				data: resetPage ? list : [...empList.data, ...list],
				error: false,
				loading: false,
				hasMore
			};
			retData.selectedEmployees = resetPage ? selectedList : { ...selectedEmployees, ...selectedList };
			retData.selectedGrpId = grpId;
			yield put(empListSuccess(retData));
		} else {
			isError = true;
		}
	} catch (err) {
		isError = true;
	} finally {
		if (isError) {
			yield put(empListSuccess(retData));
		}
	}
}

function* bulkUpdateEmp({ payload }) {
	let error = false;
	const { apiData, label } = payload;
	const { type } = apiData;
	const selectedEmployees = yield select(employeeSelectors.selectedEmployees);
	const selectedVals = yield select(employeeSelectors.selectedVals);
	const isSingle = yield select(employeeSelectors.isSingleSelect);
	const isAllSelecter = yield select(employeeSelectors.isAllSelected) || false;
	const isAllSelect = !isSingle && isAllSelecter;
	const query = yield select(employeeSelectors.query);
	const empSearchStr = yield select(employeeSelectors.empSearchStr);
	const selectedGrpId = yield select(employeeSelectors.selectedGrpId);
	const selectedEmployeesList = selectedVals ? selectedVals : Object.keys(selectedEmployees);
	let response = null;

	let { error: errMsg, success: successMsg } = ERROR_SUCCES_MSG[type] || {};
	if (type === EMPLOYEE_CONSTANTS.GROUPS_KEY) {
		successMsg = `Employee(s) moved to ${label} successfully!`;
	}

	try {
		let reqData = {
			...apiData,
			type: type,
			isAllSelected: isAllSelect
		};
		if (isAllSelect) {
			reqData.employeeFilterRequest = empSearchStr ? { searchKey: empSearchStr } : { ...query };
			if (selectedGrpId) {
				reqData.employeeFilterRequest.groups = [selectedGrpId];
			}
		} else if (selectedEmployeesList.length) {
			reqData.emailCommIds = selectedEmployeesList;
		}
		delete (reqData.isSingle);
		yield put(setApiLoading(true, false, false, EMPLOYEE_STORE_CONST.BULK_UPDATE_EMP ));
		yield put(resetGrpApiState());
		response = yield call(updateBulkData, reqData);
		const { data } = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			if (data.sendEmail) {
				yield put(getUpdateStatusSuccess({
					totalCount: data.totalCount,
					remainingCount: data.totalCount,
					jobId: data.jobId,
					status: APPLICATION_CONSTANTS.PENDING
				}));
				yield put(setEmpModal(EMPLOYEE_CONSTANTS.BULK_STATUS_POP));
			} else {
				yield put(bulkUpdateSuccsess({}));
				if (type === EMPLOYEE_CONSTANTS.RESTORE_INVITE_KEY) {
					yield put({
						type: EMPLOYEE_STORE_CONST.EMPLOYEE_INVITE_SUCCESS,
						payload: true
					});
				} else {
					yield put(setApiSuccess({
						data: {
							msg: successMsg || ''
						},
						apiType: EMPLOYEE_STORE_CONST.BULK_UPDATE_EMP,
						showNotify: true
					}));
				}
			}
		} else if (data.status === 'VIPLE') {
			yield put({
				type: EMPLOYEE_STORE_CONST.VIP_EXCEED
			});
		} else {
			error = true;
		}
	} catch (e) {
		if (e && e.response && e.response.data && e.response.data.status === 'VIPLE') {
			yield put({
				type: EMPLOYEE_STORE_CONST.VIP_EXCEED
			});
		} else {
			error = true;
			saveServerLogs(e.stack, 'error', PATHS.BULK_UPDATE_EMP);
		}
	} finally {
		if (error) {
			yield put(setApiError({
				data: {
					msg: errMsg || ''
				},
				apiType: EMPLOYEE_STORE_CONST.BULK_UPDATE_EMP,
				showNotify: true
			}));
		}
	}
}

function* createNewGroup({ payload }) {
	let error = false;
	let response = null;
	const { requestBody, type } = payload;
	const { error: errMsg, success: successMsg } = ERROR_SUCCES_MSG[type];

	const employeeList = yield select(employeeSelectors.employeeList);
	const isAllSelected = yield select(employeeSelectors.isAllSelected) || false;
	const selectedEmployees = yield select(employeeSelectors.selectedEmployees);
	const query = yield select(employeeSelectors.query);
	const empSearchStr = yield select(employeeSelectors.empSearchStr);
	const empData = employeeList?.data;
	requestBody.isAllSelected = isAllSelected;
	if (isAllSelected) {
		requestBody.employeeFilterRequest = empSearchStr ? { searchKey: empSearchStr } : { ...query };
	} else {
		requestBody.validUsers = empData ?
			empData.filter((emp) => selectedEmployees[emp.employeeEmailCommId]).map((emp) => emp.employeeEmailId) :
			[];
	}

	try {
		yield put(setApiLoading(true, false, false, EMPLOYEE_STORE_CONST.CREATE_NEW_GROUP));
		response = yield call(createGrpApi, requestBody);
		const { data } = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			if (data.sendEmail) {
				yield put(getUpdateStatusSuccess({
					totalCount: data.totalCount,
					remainingCount: data.totalCount,
					jobId: data.jobId,
					status: APPLICATION_CONSTANTS.PENDING
				}));
				yield put(setEmpModal(EMPLOYEE_CONSTANTS.BULK_STATUS_POP));
			} else {
				yield put(bulkUpdateSuccsess({ orgGrps: [], modal: EMPLOYEE_CONSTANTS.CREATEGRP_SUCCESS, groupData: { ...data.groupDetails } }));
				yield put(setApiSuccess({
					data: {
						msg: successMsg || ''
					},
					apiType: EMPLOYEE_STORE_CONST.CREATE_NEW_GROUP,
					showNotify: false,
					openModal: true
				}));
			}
		} else {
			error = true;
		}
	} catch (e) {
		error = true;
		saveServerLogs(e.stack, 'error', PATHS.CREATE_GROUP);
	} finally {
		if (error) {
			yield put(setApiError({
				data: {
					msg: errMsg || ''
				},
				apiType: EMPLOYEE_STORE_CONST.CREATE_NEW_GROUP,
				showNotify: true
			}));
		}
	}
}

function* dwlEmpDataCsv() {
	let error = false;
	const { error: errMsg, success: successMsg, zeroEmpMsg } = ERROR_SUCCES_MSG['dwlemp'];
	const selectedEmployees = yield select(employeeSelectors.selectedEmployees);
	const isAllSelect = yield select(employeeSelectors.isAllSelected);
	const filters = yield select(employeeSelectors.query);
	const empSearchStr = yield select(employeeSelectors.empSearchStr);
	const selectedGrpId = yield select(employeeSelectors.selectedGrpId);
	try {
		yield put(setApiLoading(true, false, false, EMPLOYEE_STORE_CONST.EMP_DWL_DATA ));
		yield put(resetGrpApiState());
		let reqData = {
			emailCommIds: Object.keys(selectedEmployees)
		};
		if (isAllSelect) {
			reqData = empSearchStr ? { searchKey: empSearchStr } : { ...filters };
			if (selectedGrpId) {
				reqData.groups = [selectedGrpId];
			}
		}
		const response = yield call(downloadEmpData, reqData);
		const { data } = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const { csvOnEmail, csvProcessId, isZeroEmployee } = data;
			if (csvOnEmail) {
				yield put(dwlEmpDataSuccsess());
			} else if (csvProcessId) {
				window.open(`${PATHS.CCB_URL}${PATHS.DWL_EMP_JOB}?downloadCsvId=${csvProcessId}`);
				yield put(setApiSuccess({
					data: {
						msg: successMsg || ''
					},
					apiType: EMPLOYEE_STORE_CONST.EMP_DWL_DATA,
					showNotify: true
				}));
				return;
			} else {
				error = true;
				if (isZeroEmployee) {
					errMsg = zeroEmpMsg;
				}
			}
		}
	} catch (e) {
		error = true;
		saveServerLogs(e.stack, 'error', PATHS.EMP_DWL_DATA);
	} finally {
		if (error) {
			yield put(setApiError({
				data: {
					msg: errMsg || ''
				},
				apiType: EMPLOYEE_STORE_CONST.EMP_DWL_DATA,
				showNotify: true
			}));
		}
	}
}

function* getUploadStatus({ payload = {} }) {
	let response = null;
	const { jobId } = payload;
	let reqData = {
		jobId
	};
	if (!jobId) {
		reqData = {
			pageName: `${EMPLOYEE_CONSTANTS.EMP_UPDATE},${GROUP_CONSTANTS.GRP_UPDATE}`
		};
	}
	try {
		response = yield call(getUpdateStatusApi, reqData);
		const { data } = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			if (!jobId && data.jobId) {
				yield put(setEmpModal(EMPLOYEE_CONSTANTS.BULK_STATUS_POP));
			}
			yield put(getUpdateStatusSuccess(data));
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.BULK_UPDATE_EMP);
		yield put(getUpdateStatusSuccess({
			status: APPLICATION_CONSTANTS.FAILED
		}));
	}
}

function* downloadfromUrl({ payload = {} }) {
	let response = null;
	let error = false;
	const { error: errMsg, expired } = ERROR_SUCCES_MSG['dwlempurl'];
	try {
		response = yield call(downloadDataFromurl, payload);
		const { data } = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			if (data.isLinkExpired) {
				yield put(setApiError({
					data: {
						msg: expired
					},
					apiType: EMPLOYEE_STORE_CONST.BULK_UPDATE_EMP,
					showNotify: true
				}));
			} else if (data.employeeCsvS3url) {
				window.open(data.employeeCsvS3url);
			} else {
				error = true;
			}
		} else {
			error = true;
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.BULK_UPDATE_EMP);
		error = true;
	} finally {
		if (error) {
			yield put(setApiError({
				data: {
					msg: errMsg
				},
				apiType: EMPLOYEE_STORE_CONST.BULK_UPDATE_EMP,
				showNotify: true
			}));
		}
	}
}

function* getAbValues({ payload = {} }) {
	let response = null;
	try {
		response = yield call(getAbExperiment, APPLICATION_CONSTANTS.MYBIZ_CORE);
		if (response) {
			const typeEnabledOrg = response && response.vip_enabled_org;
			yield put(getAbEmpValsSuccess(typeEnabledOrg));
			return {
				typeEnabledOrg
			};
		}
	} catch (e) {
		yield put(getAbEmpValsSuccess(false));
		saveServerLogs(e.stack, 'error', 'Abtest failed');
	} finally {

	}
}

function* fetchInviteChannels({ payload = {} }) {
	let response;
	let error = false;
	let errorMsg = APPLICATION_CONSTANTS.API_ERROR;
	try {
		response = yield call(getInviteChannel, payload);
		const resp = response && response.data;
		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const channels = resp.invitationChannels;
			yield put(fetchInvitationChannelsSuccess(channels));
		} else {
			error = true;
			errorMsg = resp.message;
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.INVITE_CHANNELS);
		error = true;
	} finally {
		if (error) {
			yield put(setApiError({
				data: {
					msg: errorMsg || ''
				},
				apiType: EMPLOYEE_STORE_CONST.FETCH_INVITATION_CHNL,
				showNotify: true
			}));
		}
	}
}

function* updateInviteChannels({ payload }) {
	let response;
	let error = false;
	let errorMsg = APPLICATION_CONSTANTS.API_ERROR;
	try {
		const request = formatChannelRequest(payload);
		response = yield call(updateInviteChannel, request);
		const resp = response && response.data;
		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			yield put(fetchInvitationChannels());
			yield put(setApiSuccess(updateInviteChannelSuccess));
		} else {
			error = true;
			errorMsg = resp.message;
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.INVITE_CHANNELS);
		error = true;
	} finally {
		if (error) {
			yield put(setApiError({
				data: {
					msg: errorMsg || ''
				},
				apiType: EMPLOYEE_STORE_CONST.UPDATE_INVITATION_CHNL,
				showNotify: true
			}));
		}
	}
}

function* getHrmsStatus() {
	let response;
	try {
		response = yield call(getHrmsStatusApi);
		const resp = response && response.data;
		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const updateData = {
				status: resp.data.hrmsStatus
			};
			if (resp.data.lastSyncTime) {
				updateData.lastSyncTime = getDate(resp.data.lastSyncTime, 'hh:mm A, DD MMM yyyy');
				updateData.status = EMPLOYEE_CONSTANTS.HRMS_STATUS.SYNCED;
			}
			yield put(fetchHrmsStatusSuccess(updateData));
		} else {
			throw ({ response });
		}
	} catch (e) {
		yield put(fetchHrmsStatusSuccess({ status: EMPLOYEE_CONSTANTS.HRMS_STATUS.API_FAILED }));
		saveServerLogs(e.response?.data?.message || e.stack, 'error', PATHS.GET_HRMS_STATUS);
	}
}

export default [
	takeLatest(EMPLOYEE_STORE_CONST.EMPLOYEE_LIST, fetchEmployeeList),
	takeLatest(EMPLOYEE_STORE_CONST.BULK_UPDATE_EMP, bulkUpdateEmp),
	takeLatest(EMPLOYEE_STORE_CONST.EMP_DWL_DATA, dwlEmpDataCsv),
	takeLatest(EMPLOYEE_STORE_CONST.GET_UPDATE_STATUS, getUploadStatus),
	takeLatest(EMPLOYEE_STORE_CONST.DOWNLOAD_BULK_URL, downloadfromUrl),
	takeLatest(EMPLOYEE_STORE_CONST.GET_AB_VALS, getAbValues),
	takeLatest(EMPLOYEE_STORE_CONST.CREATE_GRP_EMP, createNewGroup),
	takeLatest(EMPLOYEE_STORE_CONST.FETCH_INVITATION_CHNL, fetchInviteChannels),
	takeLatest(EMPLOYEE_STORE_CONST.UPDATE_INVITATION_CHNL, updateInviteChannels),
	takeLatest(EMPLOYEE_STORE_CONST.GET_HRMS_STATUS, getHrmsStatus)
];
