import { call, takeLatest, put, select } from 'redux-saga/effects';

import { getBookingsData, getFilterCountData, modifiyBookingApi } from 'apis/manageReqBookingApis';
import { MANAGE_REQUEST_BOOKINGS } from 'reduxStore/actionConstants';
import { APPLICATION_CONSTANTS, PATHS } from 'AppConstants';
import { saveServerLogs } from 'UtilityComponents/Util';
import { getFilterCountSuccess, getBookingsDetailsSuccess, getBookingsDetailsFailure, updateTabCount } from 'reduxStore/actions/manageRequestBookingActions';
import { formatFilterCounts, formatPendingData } from 'UtilityComponents/manageRequestBookingUtils';
import { getDetailsList, getResetList } from 'reduxStore/selectors/manageBookingSelector';
import { updatePopup } from '../actions/popupActions';

function* fetchBookingList({ payload }) {
	const apiPayload = { ...payload };
	let errorMsg = false;
	const isReset = yield select(getResetList);
	const currentList = (isReset ? [] : yield select(getDetailsList) || []);
	try {
		const response = yield call(getBookingsData, apiPayload);
		const resp = response && response.data;
		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			errorMsg = false;
			const { textBoxSearchCount, bookingsDetails } = resp;
			const { searchQuery, requestType } = apiPayload;
			if (searchQuery && bookingsDetails?.length > 0) {
				yield put(updateTabCount({ tabsCount: { [requestType]: textBoxSearchCount } }));
			}
			const listingData = formatPendingData(bookingsDetails, currentList, requestType);
			yield put(getBookingsDetailsSuccess({ detailsList: listingData, isReset: false }));
		} else {
			errorMsg = resp.message || APPLICATION_CONSTANTS.GENERIC_API_ERROR;
		}
	} catch (error) {
		errorMsg = APPLICATION_CONSTANTS.GENERIC_API_ERROR;
		saveServerLogs(error.stack, 'error', PATHS.GET_BOOKING_LIST);
	} finally {
		if (errorMsg) {
			yield put(getBookingsDetailsFailure({ detailsList: { data: currentList, error: true, loading: false, hasMore: true } }));
		}
	}
}

function* fetchFilterCount({ payload }) {
	const { filterQuery = {} } = payload;
	let isError = false;
	const filters = yield select((state) => state.manageRequestBookings.filters);
	try {
		const response = yield call(getFilterCountData, filterQuery);
		const resp = response && response.data;
		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const { filterCount = {} } = resp;
			const formattedFilters = formatFilterCounts(filterCount, filters);
			yield put(getFilterCountSuccess({
				filters: formattedFilters,
				tabsCount: filterCount?.requestType
			}));
		} else {
			isError = true;
		}
	} catch (error) {
		isError = true;
		saveServerLogs(error.stack, 'error', PATHS.GET_BOOKINGS_FILTERS_COUNT);
	} finally {
		if (isError) {
			const newFilters = formatFilterCounts(null, filters);
			yield put(getFilterCountSuccess({
				filters: newFilters,
				tabsCount: {}
			}));
		}
	}
}

function* accessModifyBooking({ payload }) {
	let errorMsg = APPLICATION_CONSTANTS.GENERIC_API_ERROR;
	yield put(updatePopup({
		showPop: true,
		popData: {
			heading: '',
			content: 'Redirecting to booking details',
			isLoading: true,
			apiStatus: '',
			isFooter: false
		}
	}));
	try {
		const resp = yield call(modifiyBookingApi, { bookingId: payload });
		const { responseCode, actionUrl } = resp?.data || {};
		if (responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			if (actionUrl) {
				errorMsg = false;
				window.open(actionUrl);
			}
		} else {
			errorMsg = resp.message || APPLICATION_CONSTANTS.GENERIC_API_ERROR;
		}
	} catch (e) {
		errorMsg = e.message || APPLICATION_CONSTANTS.GENERIC_API_ERROR;
		saveServerLogs(e.stack, 'error', PATHS.MODIFY_BOOKING);
	} finally {
		if (errorMsg) {
			yield put(updatePopup({
				showPop: true,
				popData: {
					heading: APPLICATION_CONSTANTS.GENERIC_ERROR_HEADING,
					content: errorMsg,
					apiStatus: 'ERR_DEFAULT',
					isFooter: false
				}
			}));
		} else {
			yield put(updatePopup({
				showPop: false
			}));
		}
	}
}

export default [
	takeLatest(MANAGE_REQUEST_BOOKINGS.GET_BOOKING_LIST, fetchBookingList),
	takeLatest(MANAGE_REQUEST_BOOKINGS.GET_FILTER_COUNTS, fetchFilterCount),
	takeLatest(MANAGE_REQUEST_BOOKINGS.MODIFY_BOOKING, accessModifyBooking)
];
