import {
    CHARTERED_FLIGHT_STORE
} from "../actionConstants";

const INITIAL_STATE = {
    apiLoader: false,
    apiSuccess: false,
    apiError: false
};

export default (state = INITIAL_STATE, action) => {
    if (!action) {
        return state;
    }
    switch (action.type) {
        case CHARTERED_FLIGHT_STORE.CHARTERED_API_CALLED: {
            return {
                ...state,
                ...action.payload,
                apiLoader: true,
                apiError: false,
                apiSuccess: false,
            }
        }
        case CHARTERED_FLIGHT_STORE.CHARTERED_API_FAILURE: {
            return {
                ...state,
                apiLoader: false,
                apiError: true,
                apiSuccess: false,
            }
        }
        case CHARTERED_FLIGHT_STORE.CHARTERED_API_SUCCESS: {
            return {
                ...state,
                apiLoader: false,
                apiSuccess: true,
                apiError: false
            }
        }
        default:
            return state
    }
}