import { PATHS, APPLICATION_CONSTANTS } from 'AppConstants';
import { INTEGRATION_CONSTANTS } from '../actionConstants';

export const fetchIntegrationData = (key, dataKey) => async (dispatch, _, axiosInstance) => {
	let error = false;
	try {
		const url = `${PATHS.GET_CONFIG}${key}`;
		const response = await axiosInstance.get(url);
		const resp = response.data;

		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			dispatch({
				type: INTEGRATION_CONSTANTS.API_SUCESS,
				payload: { [dataKey]: resp?.config }
			});
			return;
		}
		error = true;
	} catch (e) {
		error = true;
	} finally {
		if (error) {
			dispatch({
				type: INTEGRATION_CONSTANTS.API_FAILED,
				payload: dataKey
			});
		}
	}
};
