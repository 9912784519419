import axios from 'axios';

import { EMPLOYEE_STORE_CONST } from '../actionConstants';
import { getAxiosConfig } from '../../apisSetup/apiConfig';
import { APPLICATION_CONSTANTS, PATHS, EMPLOYEE_CONSTANTS } from 'AppConstants';

import { actionCreator, successFor } from '../sagaUtil';
import { saveServerLogs, getUploadRemainingTime } from 'UtilityComponents/Util';
import { ERROR_SUCCES_MSG } from '../../Employee/config';
import { getEmployeeList } from 'apis/commonApis';

const ccbAxiosApiCaller = getAxiosConfig(PATHS.CCB_URL, true, {
}, false);

const CancelToken = axios.CancelToken;
let source;

export const setSelected = (payload) => ({
	type: EMPLOYEE_STORE_CONST.UPDATE_SELECTED,
	payload
});

export const fetchEmployeeList = actionCreator(EMPLOYEE_STORE_CONST.EMPLOYEE_LIST);

export const empListSuccess = actionCreator(successFor(EMPLOYEE_STORE_CONST.EMPLOYEE_LIST));

export const fetchFilterCount = ({ query }) => async (dispatch) => {
	try {
		const response = await ccbAxiosApiCaller.post(PATHS.EMPLOYEE_COUNT, query);
		const { data } = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			dispatch({
				type: EMPLOYEE_STORE_CONST.SET_FILTER_COUNT,
				payload: {
					employeeCounts: data.employeeCounts
				}
			});
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.EMPLOYEE_COUNT);
	}
};

export const setBulkUplStatus = (state) => ({
	type: EMPLOYEE_STORE_CONST.SET_BULK_UP_STATUS,
	payload: state
});

export const setEmpModal = (modal) => ({
	type: EMPLOYEE_STORE_CONST.SET_MODAL,
	payload: modal
});

export const setEmpModalData = (modalData) => ({
	type: EMPLOYEE_STORE_CONST.SET_MODAL_DATA,
	payload: modalData
});

export const setApiLoading = (state, isPopup, data, apiType ) => ({
	type: EMPLOYEE_STORE_CONST.API_LOADING,
	payload: {
		loading: state,
		isPopup,
		data,
		apiType
	}
});

export const setApiError = (data) => ({
	type: EMPLOYEE_STORE_CONST.API_ERROR,
	payload: data
});

export const setApiSuccess = (data) => ({
	type: EMPLOYEE_STORE_CONST.API_SUCCESS,
	payload: data
});

export const resetApiState = () => ({
	type: EMPLOYEE_STORE_CONST.RESET_API_STATE
});

export const setInviteData = (data) => ({
	type: EMPLOYEE_STORE_CONST.SET_INVITE_DATA,
	payload: data
});

export const inviteEmployee = (data) => async (dispatch) => {
	let error = false;
	let resp = false;
	const sendData = data;
	try {
		dispatch(setApiLoading(true, false, false, EMPLOYEE_STORE_CONST.INVITE_EMPLOYEE ));
		resp = await ccbAxiosApiCaller.post(PATHS.INVITE_EMPLOYEE, sendData);
		const { data } = resp;
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			dispatch({
				type: EMPLOYEE_STORE_CONST.EMPLOYEE_INVITE_SUCCESS,
				payload: sendData.sendInvite
			});
		} else {
			error = data && data.message ? data.message : APPLICATION_CONSTANTS.API_ERROR;
		}
	} catch (e) {
		error = APPLICATION_CONSTANTS.API_ERROR;
		saveServerLogs(e.stack, 'error', PATHS.INVITE_EMPLOYEE);
	} finally {
		if (error) {
			dispatch(setApiError({
				data: {
					msg: error
				},
				apiType: EMPLOYEE_STORE_CONST.BULK_UPDATE_EMP
			}));
		} else {
			dispatch(setApiLoading(false));
		}
	}
};

export const getManagersList = ({ searchString, key, isFilterSearch }) => async (dispatch) => {
	try {
		dispatch({
			type: EMPLOYEE_STORE_CONST.GET_MANAGERS_LIST,
			payload: {
				isLoading: true
			}
		});
		const response = await getEmployeeList(searchString, 500);
		if (response) {
			dispatch({
				type: EMPLOYEE_STORE_CONST.GET_MANAGERS_LIST,
				payload: {
					managersList: response,
					key,
					isFilterSearch
				}
			});
		}
	} catch (e) {
		dispatch({
			type: EMPLOYEE_STORE_CONST.GET_MANAGERS_LIST,
			payload: {
				isLoading: false
			}
		});
		saveServerLogs(e.stack, 'error', PATHS.EMPLOYEE_AUTOSUGGEST);
	}
};

export const fetchGroupsData = (grpData) => (dispatch) => {
	try {
		const { key, groupList } = grpData;
		dispatch({
			type: EMPLOYEE_STORE_CONST.GET_GROUP_LIST,
			payload: {
				groupList,
				key
			}
		});
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.GET_GROUP);
	}
};

export const fetchDesignationData = ({ key }) => async (dispatch) => {
	try {
		const response = await ccbAxiosApiCaller.get(PATHS.GET_DESIGNATION);
		const { data } = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			dispatch({
				type: EMPLOYEE_STORE_CONST.GET_DESIGNATION_LIST,
				payload: {
					designationList: data.designations,
					key
				}
			});
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.GET_DESIGNATION);
	}
};

export const setFilterQuery = (payload) => ({
	type: EMPLOYEE_STORE_CONST.EMP_FILTER_QUERY,
	payload
});

export const clearFilterUpdate = (payload) => ({
	type: EMPLOYEE_STORE_CONST.EMP_CLEAR_FILTER,
	payload
});

export const updateSortQuery = (payload) => ({
	type: EMPLOYEE_STORE_CONST.EMP_UPDATE_SORT,
	payload
});

export const updateQueryPage = (payload) => ({
	type: EMPLOYEE_STORE_CONST.EMP_UPDATE_PAGE,
	payload
});

export const openChangePop = (data) => ({
	type: EMPLOYEE_STORE_CONST.OPEN_CHANGE_EMP_PROP,
	payload: data
});

export const bulkUpdateSuccsess = actionCreator(successFor(EMPLOYEE_STORE_CONST.BULK_UPDATE_EMP));

export const bulkUpdateData = actionCreator(EMPLOYEE_STORE_CONST.BULK_UPDATE_EMP);

export const createNewGroup = actionCreator(EMPLOYEE_STORE_CONST.CREATE_GRP_EMP);

export const setEmpSearchStr = ({ empSearchStr }) => ({
	type: EMPLOYEE_STORE_CONST.EMP_SEARCH_STR,
	payload: {
		empSearchStr
	}
});

export const modifyEmpDetails = (data) => ({
	type: EMPLOYEE_STORE_CONST.MODIFY_EMP_DET,
	payload: data
});

export const updatemodifyEmpDetails = (data) => ({
	type: EMPLOYEE_STORE_CONST.UPDATE_MODIFY_EMP_DET,
	payload: data
});

export const resetEmpPopups = () => ({
	type: EMPLOYEE_STORE_CONST.RESET_POPUPS_DATA
});

export const modifyEmployeeData = (data) => async (dispatch) => {
	let error = false;
	let resp = false;
	const { error: errMsg, success: successMsg } = ERROR_SUCCES_MSG['modify'];
	const sendData = data;
	try {
		dispatch(setApiLoading(true, false, false, EMPLOYEE_STORE_CONST.BULK_UPDATE_EMP ));
		resp = await ccbAxiosApiCaller.put(PATHS.MODIFY_EMP_DET, sendData);
		const { data } = resp;
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			dispatch({
				type: EMPLOYEE_STORE_CONST.EMPLOYEE_MODIFY_SUCCESS
			});
			dispatch(setApiSuccess({
				data: {
					msg: successMsg || ''
				},
				apiType: EMPLOYEE_STORE_CONST.BULK_UPDATE_EMP,
				showNotify: true
			}));
		} else {
			error = true;
		}
	} catch (e) {
		error = true;
		saveServerLogs(e.stack, 'error', PATHS.INVITE_EMPLOYEE);
	} finally {
		if (error) {
			dispatch(setApiError({
				data: {
					msg: errMsg
				},
				apiType: EMPLOYEE_STORE_CONST.BULK_UPDATE_EMP,
				showNotify: true
			}));
		}
	}
};

export const getReminderSettings = () => async (dispatch) => {
	let response = null;
	try {
		response = await ccbAxiosApiCaller.get(PATHS.GET_REMINDER_SETTING + APPLICATION_CONSTANTS.EMPLOYEE_TYPE);
		const { data = {} } = response || {};
		const { responseCode } = data;
		if (responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE || responseCode === 'GRDF') {
			dispatch({
				type: EMPLOYEE_STORE_CONST.GET_REMINDER_SUCCESS,
				payload: data
			});
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.GET_REMINDER_SETTING);
	}
};

export const setReminder = (apiRequest) => async (dispatch) => {
	const apiParams = {
		'is_enable': apiRequest.is_enable,
		'type': APPLICATION_CONSTANTS.EMPLOYEE_TYPE,
		'frequency': apiRequest.frequency,
		'is_granular': true,
		'granular_data': {
			'every_period': apiRequest.every_period,
			'on_day': apiRequest.on_day
		}
	};
	dispatch({
		type: EMPLOYEE_STORE_CONST.REMINDER_API_CALLED
	});
	const url = apiRequest.is_enable ? PATHS.SET_REMINDER + '?type=' + APPLICATION_CONSTANTS.EMPLOYEE_TYPE : PATHS.REMOVE_REMINDER;
	try {
		await ccbAxiosApiCaller.post(url, apiParams);
		dispatch({
			type: EMPLOYEE_STORE_CONST.REMINDER_SUCCESS,
			payload: {
				apiParams
			}
		});
		getReminderSettings()(dispatch);
	} catch (e) {
		dispatch({
			type: EMPLOYEE_STORE_CONST.REMINDER_FAILED
		});
		saveServerLogs(e.stack, 'error', url);
		return;
	}
};

export const uploadCSv = (apiRequest, url, successStatus = APPLICATION_CONSTANTS.SUCCESS) => async (dispatch) => {
	source = CancelToken.source();
	let response = null;
	let status;
	const completeStatus = {
		percentage: 0,
		status: APPLICATION_CONSTANTS.PENDING
	};
	dispatch({
		type: EMPLOYEE_STORE_CONST.EMP_UPLOAD_STATUS,
		payload: { ...completeStatus }
	});

	const config = {
		uploadedBytes: 0,
		totalLength: 0
	};

	const timeController = getUploadRemainingTime(config, (remainingTime) => {
		dispatch({
			type: EMPLOYEE_STORE_CONST.EMP_UPLOAD_TIME,
			payload: {
				remainingTime: remainingTime
			}
		});
	});

	dispatch(setApiLoading(true, false, false, EMPLOYEE_CONSTANTS.EMP_CSV_UPLOAD));
	let error = false;
	let errMsg = false;
	try {
		response = await ccbAxiosApiCaller.post(url, apiRequest,
			{ cancelToken: source.token,
				headers: {
					'Content-Type': 'multipart/form-data'
				},
				onUploadProgress: (progressEvent) => {
					const { total, loaded, target } = progressEvent;
					config.totalLength = progressEvent.lengthComputable ?
						total :
						target.getResponseHeader('content-length');
					config.uploadedBytes = loaded;
					const progressData = Math.round( (loaded * 100) / config.totalLength );
					const percentage = progressData < 80 ? progressData : 80;
					dispatch({
						type: EMPLOYEE_STORE_CONST.EMP_UPLOAD_STATUS,
						payload: { ...completeStatus, percentage: percentage }
					});
				} });
		if (response.data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			status = successStatus;
		} else {
			error = true;
			status = APPLICATION_CONSTANTS.FAILED;
			errMsg = response.data.message;
		}
	} catch (e) {
		status = APPLICATION_CONSTANTS.FAILED;
		error = e.message !== 'Operation canceled by the user.';
		saveServerLogs(e.stack, 'error', PATHS.EMP_BULK_UPLOAD);
	} finally {
		if (error) {
			dispatch(setApiError());
		} else {
			dispatch(setApiLoading(false));
		}
		dispatch({
			type: EMPLOYEE_STORE_CONST.EMP_UPLOAD_STATUS,
			payload: {
				status,
				errMsg
			}
		});
		clearInterval(timeController);
	}
};

export const cancelUpload = () => (dispatch) => {
	source && source.cancel('Operation canceled by the user.');
	dispatch({
		type: EMPLOYEE_STORE_CONST.EMP_UPLOAD_CANCEL
	});
};

export const resetUpload = () => ({
	type: EMPLOYEE_STORE_CONST.EMP_UPLOAD_RESET
});

export const newEmpFeatures = (feature, close) => ({
	type: EMPLOYEE_STORE_CONST.EMP_FEATURE_DATA,
	payload: { feature: close ? 0 : feature }
});

export const updateDataCount = actionCreator(EMPLOYEE_STORE_CONST.DATA_UPDATE_COUNT);

export const dwlEmpDataSuccsess = actionCreator(successFor(EMPLOYEE_STORE_CONST.EMP_DWL_DATA));

export const dwlEmpData = actionCreator(EMPLOYEE_STORE_CONST.EMP_DWL_DATA);

export const getUpdateStatus = actionCreator(EMPLOYEE_STORE_CONST.GET_UPDATE_STATUS);

export const getUpdateStatusSuccess = actionCreator(successFor(EMPLOYEE_STORE_CONST.GET_UPDATE_STATUS));

export const downloadfromUrl = actionCreator(EMPLOYEE_STORE_CONST.DOWNLOAD_BULK_URL);

export const resetJobCounts = actionCreator(EMPLOYEE_STORE_CONST.RESET_JOB_COUNT);

export const getAbEmpVals = actionCreator(EMPLOYEE_STORE_CONST.GET_AB_VALS);

export const getAbEmpValsSuccess = actionCreator(successFor(EMPLOYEE_STORE_CONST.GET_AB_VALS));

export const fetchInvitationChannels = actionCreator(EMPLOYEE_STORE_CONST.FETCH_INVITATION_CHNL);

export const fetchInvitationChannelsSuccess = actionCreator(successFor(EMPLOYEE_STORE_CONST.FETCH_INVITATION_CHNL));

export const updateInvitationChannels = actionCreator(EMPLOYEE_STORE_CONST.UPDATE_INVITATION_CHNL);

export const fetchHrmsStatus = actionCreator(EMPLOYEE_STORE_CONST.GET_HRMS_STATUS);

export const fetchHrmsStatusSuccess = actionCreator(successFor(EMPLOYEE_STORE_CONST.GET_HRMS_STATUS));
