import moment from 'moment';
import bowser from 'bowser';
import cloneDeep from 'lodash/cloneDeep';

import { REQUISITION_STORE_CONSTANT } from '../actionConstants';
import { failedFor, successFor } from '../sagaUtil';
import { APPLICATION_CONSTANTS, REQUISITION_CONSTS } from 'AppConstants';
import { getCookie } from 'UtilityComponents/Util';
import { ONE_WAY, CAB_TYPES, ECONOMY, GENERIC_DATA_ERROR, PICKUP_TYPE_OPTIONS, defaultCabTravelDetails } from '../../travRequisition/pages/ReqForm/config';
import { isErrorInFlightData, generateKey, travDispVal, isErrorInHotelData, isErrorInBusData, isDataMissingInFlights } from '../../travRequisition/utils';

const { platform, os } = typeof window === 'undefined' ? {} : bowser.getParser(window.navigator.userAgent).parsedResult;
const dvid = typeof window === 'undefined' ? false : getCookie('dvid');

const defaultFlightTravelDetails = [
	{
		from: null,
		to: null,
		depDate: null,
		arrDate: null,
		errorState: GENERIC_DATA_ERROR
	}
];

const defaultBusSearchParams = {
	from: null,
	to: null,
	fromDate: null,
	paxCount: 1,
	errorState: GENERIC_DATA_ERROR
};

const defaultHotelSearchParams = {
	destination: null,
	checkin: null,
	checkout: null,
	roomDetails: [{ id: generateKey(0), value: 1 }],
	errorState: GENERIC_DATA_ERROR
};

const INITIAL_STATE = {
	'flightSearchList': [],
	'hotelSearchList': [],
	'busSearchList': [],
	'isPopularSearch': false,
	'searchData': {
		flightData: false
	},
	'flightSearchParams': {
		tripType: ONE_WAY,
		travelClass: ECONOMY,
		paxCount: 1,
		flightTravelDetails: cloneDeep(defaultFlightTravelDetails)
	},
	'hotelSearchParams': {
		destination: null,
		checkin: null,
		checkout: null,
		roomDetails: [{ id: generateKey(0), value: 1 }],
		errorState: GENERIC_DATA_ERROR
	},
	'busSearchParams': {
		from: null,
		to: null,
		fromDate: null,
		paxCount: 1,
		errorState: GENERIC_DATA_ERROR
	},
	'cabSearchParams': [{
		cabType: CAB_TYPES.ONE_WAY,
		pickupDetails: cloneDeep(defaultCabTravelDetails),
		dropDetails: cloneDeep(defaultCabTravelDetails),
		pickupType: PICKUP_TYPE_OPTIONS[0].id,
		listingPageUrl: ''
	}],
	'modal': APPLICATION_CONSTANTS.NO_POP,
	'travellerDetails': {
		bookingFor: REQUISITION_CONSTS.BOOKFOR.MYSELF,
		paxDetails: []
	},
	'tripTags': { apiData: [] },
	'travelReasons': { apiData: [], userData: { error: true } },
	'deviceDetails': {
		'version': os?.version,
		'deviceId': dvid,
		'osType': platform?.type
	}
};

const getLastSettedTo = (data = []) => {
	const dataLength = data.length;
	if (dataLength) {
		for (let i = dataLength - 1; i >= 0; i--) {
			if (data[i].to) {
				return cloneDeep(data[i].to);
			}
		}
	}
	return null;
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}

	const { type, payload = {} } = action;

	switch (type) {
		case successFor(REQUISITION_STORE_CONSTANT.GET_POPULAR_FLIGHT): {
			const { flightSearchList = [], isPopular } = payload;
			return {
				...state,
				flightSearchList,
				isPopularSearch: isPopular
			};
		}
		case REQUISITION_STORE_CONSTANT.SET_FLIGHT_TRAVEL_DETAILS: {
			const { index = 0, dataKey, dataValue } = payload;
			let flightTravelDetails = state.flightSearchParams.flightTravelDetails;
			if (dataKey) {
				flightTravelDetails = [...state.flightSearchParams.flightTravelDetails];
				flightTravelDetails[index] = {
					...flightTravelDetails[index],
					[dataKey]: dataValue
				};
			}
			flightTravelDetails[index].errorState = isErrorInFlightData(flightTravelDetails[index], state.flightSearchParams.tripType);
			return {
				...state,
				flightSearchParams: {
					...state.flightSearchParams,
					flightTravelDetails
				}
			};
		}
		case REQUISITION_STORE_CONSTANT.SET_FLIGHT_SEARCH: {
			const { dataKey, dataValue, resetTravelDetails } = payload;
			let flightSearchParams = { ...state.flightSearchParams };
			if (dataKey) {
				flightSearchParams[dataKey] = dataValue;
			}
			let flightTravelDetails = state.flightSearchParams.flightTravelDetails;
			if (resetTravelDetails) {
				flightTravelDetails = cloneDeep(defaultFlightTravelDetails);
				flightTravelDetails[0].errorState = isErrorInFlightData(flightTravelDetails[0], dataValue);
			}
			flightSearchParams.flightTravelDetails = flightTravelDetails;
			return {
				...state,
				flightSearchParams
			};
		}
		case REQUISITION_STORE_CONSTANT.ADD_REMOVE_FLIGHT_TRAVEL: {
			const { isRemove } = payload;
			let flightTravelDetails = state.flightSearchParams.flightTravelDetails;
			const travelLastIdx = flightTravelDetails.length - 1;
			if (isRemove) {
				flightTravelDetails = flightTravelDetails.filter((_, index) => index !== (travelLastIdx));
			} else {
				flightTravelDetails = [...state.flightSearchParams.flightTravelDetails];
				flightTravelDetails[travelLastIdx + 1] = cloneDeep(defaultFlightTravelDetails[0]);
				const lastTravelDetail = flightTravelDetails[travelLastIdx + 1];
				let lastDepDate = flightTravelDetails[travelLastIdx].depDate;
				lastTravelDetail.from = getLastSettedTo(state.flightSearchParams.flightTravelDetails);
				lastTravelDetail.to = null;
				lastTravelDetail.depDate = lastDepDate ? moment(lastDepDate).add(1, 'days').toDate() : null;
				lastTravelDetail.errorState = isErrorInFlightData(lastTravelDetail, state.flightSearchParams.tripType);
			}

			return {
				...state,
				flightSearchParams: {
					...state.flightSearchParams,
					flightTravelDetails
				}
			};
		}
		case REQUISITION_STORE_CONSTANT.UPDATE_TRAVELLERS: {
			const displayVal = travDispVal(payload);
			let primaryPax = state.travellerDetails.primaryPax;
			if (state.travellerDetails.bookingFor === REQUISITION_CONSTS.BOOKFOR.GROUP && state.travellerDetails?.paxDetails?.length === 0 && !state.travellerDetails?.primaryPax?.name) {
				primaryPax = payload[0];
			}
			return {
				...state,
				travDispVal: displayVal,
				travellerDetails: { ...state.travellerDetails, primaryPax: primaryPax,
					paxDetails: cloneDeep(payload) }
			};
		}

		case REQUISITION_STORE_CONSTANT.UPDATE_BOOK_FOR: {
			const displayVal = travDispVal(payload.paxDetails);
			return {
				...state,
				travDispVal: displayVal,
				travellerDetails: cloneDeep(payload)
			};
		}

		case REQUISITION_STORE_CONSTANT.UPDATE_REQUISITION_MODAL: {
			return {
				...state,
				modal: payload
			};
		}
		case successFor(REQUISITION_STORE_CONSTANT.GET_HOTEL_SEARCH): {
			const { hotelSearchList = [] } = payload;
			return {
				...state,
				hotelSearchList
			};
		}
		case successFor(REQUISITION_STORE_CONSTANT.GET_PLACE_DETAILS): {
			const { place_id } = payload;
			let hotelSearchParams = state.hotelSearchParams;
			if (state.hotelSearchParams.destination && (state.hotelSearchParams.destination.place_id === place_id)) {
				hotelSearchParams = {
					...state.hotelSearchParams,
					destination: {
						...state.hotelSearchParams.destination,
						...payload
					}
				};
			}
			return {
				...state,
				hotelSearchParams
			};
		}
		case REQUISITION_STORE_CONSTANT.UPDATE_HOTEL_DATA: {
			const { dataKey, dataValue } = payload;
			let hotelSearchParams = state.hotelSearchParams;
			if (dataKey) {
				hotelSearchParams = { ...state.hotelSearchParams };
				hotelSearchParams[dataKey] = dataValue;
				hotelSearchParams.errorState = isErrorInHotelData(hotelSearchParams);
			}
			return {
				...state,
				hotelSearchParams
			};
		}
		case REQUISITION_STORE_CONSTANT.UPDATE_BUS_DATA: {
			const { dataKey, dataValue } = payload;
			let busSearchParams = state.busSearchParams;
			if (dataKey) {
				busSearchParams = { ...state.busSearchParams };
				busSearchParams[dataKey] = dataValue;
				busSearchParams.errorState = isErrorInBusData(busSearchParams);
			}
			return {
				...state,
				busSearchParams
			};
		}

		case REQUISITION_STORE_CONSTANT.RESET_BUS_DATA: {
			return {
				...state,
				busSearchParams: cloneDeep(defaultBusSearchParams)
			};
		}

		case REQUISITION_STORE_CONSTANT.RESET_HOTEL_DATA: {
			return {
				...state,
				hotelSearchParams: cloneDeep(defaultHotelSearchParams)
			};
		}
		case REQUISITION_STORE_CONSTANT.SET_REASONS_TRIP_TAGS: {
			const { tripTags, approvalDetails, travelReasons } = payload;
			return {
				...state,
				tripTags: {
					...state.tripTags,
					apiData: tripTags
				},
				travelReasons: {
					...state.travelReasons,
					apiData: travelReasons
				},
				approvalDetails
			};
		}
		case REQUISITION_STORE_CONSTANT.UPDATE_PRIMARY_PAX: {
			return {
				...state,
				travellerDetails: {
					...state.travellerDetails,
					primaryPax: payload
				}

			};
		}
		case REQUISITION_STORE_CONSTANT.UPDATE_TRIP_TAGS: {
			return {
				...state,
				tripTags: {
					...state.tripTags,
					userData: payload
				}

			};
		}
		case REQUISITION_STORE_CONSTANT.SET_TRAV_ERROR: {
			return {
				...state,
				travReqError: payload

			};
		}
		case REQUISITION_STORE_CONSTANT.SET_TRAV_REASONS: {
			return {
				...state,
				travelReasons: {
					...state.travelReasons,
					userData: payload
				}

			};
		}

		case REQUISITION_STORE_CONSTANT.RESET_FLIGHT: {
			return {
				...state,
				'searchData': {
					...state.searchData,
					flightData: {}
				},
				'flightSearchParams': {
					...state.flightSearchParams,
					'flightTravelDetails': cloneDeep(defaultFlightTravelDetails)
				}

			};
		}

		case REQUISITION_STORE_CONSTANT.SET_CAB_PARAMS: {
			return {
				...state,
				'cabSearchParams': cloneDeep(payload)

			};
		}
		case REQUISITION_STORE_CONSTANT.SET_SEARCH_DATA: {
			return {
				...state,
				'searchData': {
					...state.searchData,
					...payload
				}

			};
		}
		case successFor(REQUISITION_STORE_CONSTANT.GET_BUS_SEARCH): {
			const { busSearchList = [] } = payload;
			return {
				...state,
				busSearchList
			};
		}
		case successFor(REQUISITION_STORE_CONSTANT.PROCEED_TO_SUBMIT):
		case failedFor(REQUISITION_STORE_CONSTANT.PROCEED_TO_SUBMIT): {
			const { data } = payload;
			return {
				...state,
				modal: true,
				modalData: data
			};
		}

		case REQUISITION_STORE_CONSTANT.SET_APILOADING: {
			return {
				...state,
				apiLoading: payload
			};
		}

		case REQUISITION_STORE_CONSTANT.SET_MODAL: {
			const { name, data } = payload;
			return {
				...state,
				modal: name,
				modalData: data,
				apiLoading: false
			};
		}

		default:
			return state;
	}
};
