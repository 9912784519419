import {
	INVOICE_STORE_CONSTANTS
} from '../actionConstants';
import { INVOICE_CONSTANTS, APPLICATION_CONSTANTS } from '../../AppConstants';
import {
	filters
} from '../../invoice/config';
import InvoiceUtils from '../../UtilityComponents/InvoiceUtils';
import cloneDeep from 'lodash/cloneDeep';
const filtersObject = InvoiceUtils.presetFilters(filters, []);
const INITIAL_STATE = {
	filters: filtersObject.filters,
	list: [],
	appliedFilters: filtersObject.appliedFilters,
	pageDataApi: true,
	selectedList: {},
	isAllCount: true,
	LQTD: true,
	mmtCount: 0,
	mainLastIndex: 0,
	listScrolling: true
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}
	switch (action.type) {
		case INVOICE_STORE_CONSTANTS.NEXT_FEATURE: {
			const status = action.payload === 1 ? 'next' : 'previous';
			InvoiceUtils.trackInvoiceEvents(`Invoice_onboarding_${state.feature}_${INVOICE_CONSTANTS.TOTAL_FEATURES}_${status}`);
			return {
				...state,
				feature: state.feature ? parseInt(state.feature) + action.payload : 1

			};
		}
		case INVOICE_STORE_CONSTANTS.SKIP_FEATURE: {
			InvoiceUtils.trackInvoiceEvents(`Invoice_onboarding_${INVOICE_CONSTANTS.TOTAL_FEATURES}_${INVOICE_CONSTANTS.TOTAL_FEATURES}_next`);
			return {
				...state,
				feature: false

			};
		}
		case INVOICE_STORE_CONSTANTS.MODAL_TYPE: {
			return {
				...state,
				modalType: action.payload

			};
		}
		case INVOICE_STORE_CONSTANTS.SET_LIST: {
			const listData = InvoiceUtils.formatListData(action.payload, state.isAllSelected, state.mmtCount);
			return {
				...state,
				list: [...state.list, ...listData.list],
				listApiCalled: true,
				lastIndex: listData.lastIndex,
				pageDataApi: listData.list.length === 0,
				selectedList: { ...state.selectedList, ...listData.selectedList },
				mmtCount: listData.mmtCount,
				listScrolling: false

			};
		}
		case INVOICE_STORE_CONSTANTS.SET_COUNT_BUNDLE: {
			const appFltLen = state.appliedFilters.length;
			const lastFlt = appFltLen > 0 ? state.appliedFilters[appFltLen - 1] : {};
			const formattedData = InvoiceUtils.formatCount(action.payload, cloneDeep(state.filters), state.filterQuery, lastFlt);
			return {
				...state,
				bundleData: formattedData.bundleData,
				filters: formattedData.filters,
				bunldeApiCalled: true,
				isAllCount: formattedData.isAllCount,
				LQTD: formattedData.isAllCount
			};
		}
		case INVOICE_STORE_CONSTANTS.SET_FILTER_OBJECT: {
			return {
				...state,
				filters: action.payload

			};
		}
		case INVOICE_STORE_CONSTANTS.UPDATE_APPLY_FILTER_OBJECT: {
			return {
				...state,
				...action.payload

			};
		}
		case INVOICE_STORE_CONSTANTS.SET_QUERY: {
			return {
				...state,
				filterQuery: action.payload,
				list: [],
				bundleData: false,
				listApiCalled: false,
				bunldeApiCalled: false

			};
		}
		case INVOICE_STORE_CONSTANTS.RESET_LIST: {
			return {
				...state,
				filterQuery: action.payload,
				list: [],
				listApiCalled: false

			};
		}
		case INVOICE_STORE_CONSTANTS.UPDATE_SELECT_LIST: {
			return {
				...state,
				...action.payload

			};
		}
		case INVOICE_STORE_CONSTANTS.SET_INIT_QUERY: {
			return {
				...state,
				initQuery: action.payload,
				filterQuery: {
					...action.payload
				}

			};
		}
		case INVOICE_STORE_CONSTANTS.SET_FILTER_OPTIONS: {
			const filterOptions = InvoiceUtils.fetchFilterOptions(action.payload, cloneDeep(state.filters), cloneDeep(state.appliedFilters));
			return {
				...state,
				filters: filterOptions.filters,
				appliedFilters: filterOptions.appliedFilters

			};
		}
		case INVOICE_STORE_CONSTANTS.SEARCH_RESULTS_START: {
			let searchList = [];
			if (action.payload === state.id) {
				searchList = state.searchList;
			}
			return {
				...state,
				searchById: true,
				searchList: searchList,
				id: action.payload,
				listApiCalled: false,
				isAllSelected: false,
				mainLastIndex: state.lastIndex,
				isSearched: false

			};
		}
		case INVOICE_STORE_CONSTANTS.SEARCH_RESULTS_CANCEL: {
			return {
				...state,
				searchById: false,
				listApiCalled: true,
				lastIndex: state.mainLastIndex,
				selectedList: {},
				isAllSelected: false,
				mmtCount: 0,
				isSearched: false

			};
		}
		case INVOICE_STORE_CONSTANTS.SET_SEARCH_RESULTS: {
			const searchList = InvoiceUtils.formatListData(action.payload);
			return {
				...state,
				selectedList: {},
				mmtCount: 0,
				searchList: searchList.list,
				listApiCalled: true,
				lastIndex: searchList.lastIndex,
				isAllSelected: false,
				isSearched: true

			};
		}
		case INVOICE_STORE_CONSTANTS.LIST_API_CALLED: {
			return {
				...state,
				listApiCalled: true,
				isSearched: action.payload

			};
		}
		case INVOICE_STORE_CONSTANTS.BUNDLE_API_CALLED: {
			return {
				...state,
				bunldeApiCalled: true

			};
		}
		case INVOICE_STORE_CONSTANTS.DOWNLOAD_INVOICE_START: {
			return {
				...state,
				downloadFailed: false,
				downloadProgress: true
			};
		}
		case INVOICE_STORE_CONSTANTS.DOWNLOAD_INVOICE_FAILED: {
			return {
				...state,
				downloadProgress: false,
				downloadFailed: true,
				modalType: INVOICE_CONSTANTS.DOWNLOAD_OVERLAY
			};
		}
		case INVOICE_STORE_CONSTANTS.DOWNLOAD_INVOICE_SUCCESS: {
			return {
				...state,
				downloadProgress: false,
				downloadFailed: false,
				modalType: false,
				selectedList: {},
				isAllSelected: false,
				mmtCount: 0
			};
		}
		case INVOICE_STORE_CONSTANTS.DOWNLOAD_RESET: {
			return {
				...state,
				downloadProgress: false,
				downloadFailed: false,
				modalType: false
			};
		}
		case INVOICE_STORE_CONSTANTS.SET_ALL_COUNT_API: {
			return {
				...state,
				isAllCount: true
			};
		}
		case INVOICE_STORE_CONSTANTS.SET_ALL_COUNT_BUNDLE: {
			const upatedFilter = InvoiceUtils.getFilterCount(action.payload, cloneDeep(state.filters), state.filterQuery);
			return {
				...state,
				isAllCount: true,
				filters: upatedFilter
			};
		}
		case INVOICE_STORE_CONSTANTS.REMINDER_API_CALLED: {
			return {
				...state,
				reminderLoader: true,
				reminderError: false,
				reminderSucces: false
			};
		}
		case INVOICE_STORE_CONSTANTS.REMINDER_SUCCESS: {
			const details = InvoiceUtils.getReminderSettings(action.payload.apiParams);
			return {
				...state,
				reminderSettings: {
					...state.reminderSettings,
					...details
				},
				reminderLoader: false,
				reminderError: false,
				reminderSucces: true
			};
		}
		case INVOICE_STORE_CONSTANTS.REMINDER_FAILED: {
			return {
				...state,
				reminderLoader: false,
				reminderError: APPLICATION_CONSTANTS.API_ERROR,
				reminderSucces: false
			};
		}
		case INVOICE_STORE_CONSTANTS.GET_REMINDER_SUCCESS: {
			return {
				...state,
				reminderSettings: action.payload
			};
		}
		case INVOICE_STORE_CONSTANTS.LIST_SCROLL: {
			return {
				...state,
				listScrolling: true

			};
		}
		case INVOICE_STORE_CONSTANTS.INVOICE_SUMMARY_CALLED: {
			return {
				...state,
				invoiceSummary: [],
				summaryCalled: true,
				summaryError: false
			};
		}
		case INVOICE_STORE_CONSTANTS.INVOICE_SUMMARY_SUCCESS: {
			return {
				...state,
				invoiceSummary: action.payload,
				summaryCalled: false
			};
		}
		case INVOICE_STORE_CONSTANTS.INVOICE_SUMMARY_FAILED: {
			return {
				...state,
				invoiceSummary: [],
				summaryCalled: false,
				summaryError: true
			};
		}
		default:
			return state;
	}
};
