import { INTEGRATION_CONSTANTS } from '../actionConstants';
import { INTEGRATION_AWARNESS_CONSTANTS } from 'AppConstants';

const INITIAL_STATE = {
	[INTEGRATION_AWARNESS_CONSTANTS.LANDING_REDUX_KEY]: {
		loader: false, error: false
	},
	[INTEGRATION_AWARNESS_CONSTANTS.DETAIL_REDUX_KEY]: {
		loader: false, error: false
	}
};

export default (state = INITIAL_STATE, action) => {
	if (!action) return state;

	switch (action.type) {
		case INTEGRATION_CONSTANTS.API_LOADER:
			return { ...state, [action.payload]: {
				loader: true, error: false
			} };
		case INTEGRATION_CONSTANTS.API_SUCESS:
			return { ...state, loader: false, ...action.payload };
		case INTEGRATION_CONSTANTS.API_FAILED:
			return { ...state, [action.payload]: {
				loader: false, error: true
			} };
	}
	return state;
};
