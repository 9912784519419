import cloneDeep from 'lodash/cloneDeep';

import { getccbAxiosConfig } from '../../apisSetup/ccbConfig';
import { saveServerLogs, urlParam } from 'UtilityComponents/Util';
import { PATHS, APPLICATION_CONSTANTS } from 'AppConstants';
import { TRIP_TAG_CONSTANTS } from '../actionConstants';
import {
	existingTripTagToFormDataConverter,
	getTripFieldObject,
	showRequisitionPopup,
	deleteField,
	updateInputType,
	tripValueChangeHandler,
	modifyTripData
} from '../../tripTag/Utils';
import { CONSTANTS,
	LIMIT,
	DummyTripTagAttributeList,
	TRIP_TAG_FIELDS,
	childForm,
	FIELD_TYPE,
	TRIP_LOBS } from '../../tripTag/config';

const ccbAxiosApiCaller = getccbAxiosConfig(true, {}, false);

export const fetchAllTripTags = () => async (dispatch) => {
	dispatch(updateModalMetaData({ modalType: '', error: '', tripMasterData: [], showPopup: false, isDummy: false, genericError: true }));
	let response = null;
	let groupId = urlParam(CONSTANTS.GROUP_ID_URL_PARAM);
	try {
		let url = `${PATHS.FETCH_ALL_API_URL}?group_id=${groupId}`;
		response = await ccbAxiosApiCaller.get(url);
		let responseData = response && response.data;
		if (responseData && responseData.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE && responseData.attributeListV2) {
			if (responseData.attributeListV2.length > 0) {
				dispatch(allTripTagFetchSuccess(responseData.attributeListV2, false));
				return;
			}
			dispatch(allTripTagFetchSuccess(DummyTripTagAttributeList, true));
		} else {
			let errorMsg = responseData?.message || CONSTANTS.GENERIC_ERROR_MESSAGE;
			dispatch(updateModalMetaData({ modalType: CONSTANTS.ERROR_MODAL_TYPE, error: errorMsg, showPopup: true }));
		}
	} catch (e) {
		dispatch(updateModalMetaData({ modalType: CONSTANTS.ERROR_MODAL_TYPE, error: CONSTANTS.GENERIC_ERROR_MESSAGE, showPopup: true }));
	}
};

export const fetchTripTag = () => async (dispatch) => {
	dispatch(updateModalMetaData({ modalType: '', error: '', tripData: [], showPopup: false, errorData: null, genericError: true, redirectedTripTagId: '' }));
	let response = null;
	let data = null;
	try {
		let groupId = urlParam(CONSTANTS.GROUP_ID_URL_PARAM);
		let trip_tag_uuid = urlParam(CONSTANTS.TRIP_TAG_UUID_URL_PARAM);
		if (groupId && trip_tag_uuid) {
			let url = `${PATHS.FETCH_TRIP_API_URL}?group_id=${groupId}&trip_tag_uuid=${trip_tag_uuid}`;
			response = await ccbAxiosApiCaller.get(url);
			let responseData = response && response.data;
			if (responseData && responseData.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE && responseData.fetchTripTagResponseV2) {
				data = responseData.fetchTripTagResponseV2;
			} else {
				let errorMsg = responseData?.message || CONSTANTS.GENERIC_ERROR_MESSAGE;
				dispatch(updateModalMetaData({ modalType: CONSTANTS.ERROR_MODAL_TYPE, error: errorMsg, showPopup: true }));
				return;
			}
		}
		const formattedData = existingTripTagToFormDataConverter(data);
		dispatch(tripTagSuccess(formattedData));
	} catch (e) {
		saveServerLogs(e.stack, 'error', 'fetchtriptag');
		dispatch(updateModalMetaData({ modalType: CONSTANTS.ERROR_MODAL_TYPE, error: CONSTANTS.GENERIC_ERROR_MESSAGE, showPopup: true }));
	}
};

export const saveTag = (newTripMasterObj, redirect) => async (dispatch) => {
	let resp = null;
	let url = '';
	let tripData = {};
	let errorMsg = '';
	let genericError = true;
	try {
		let groupId = urlParam(CONSTANTS.GROUP_ID_URL_PARAM);
		let trip_tag_uuid = urlParam(CONSTANTS.TRIP_TAG_UUID_URL_PARAM);
		if (trip_tag_uuid) {
			url = `${PATHS.EDIT_API_URL}?group_id=${groupId}&trip_tag_uuid=${trip_tag_uuid}`;
			tripData = {
				'editV2TripTagRequest': newTripMasterObj
			};
		} else {
			url = `${PATHS.CREATE_AI_URL}?group_id=${groupId}`;
			tripData = {
				'createV2TripTagRequest': newTripMasterObj
			};
		}
		if (groupId) {
			resp = await ccbAxiosApiCaller.post(url, tripData);
			let res = resp && resp.data;
			if (res && res.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
				dispatch(saveTagSuccess({ modalType: CONSTANTS.INFO_MODAL_TYPE, showPopup: true, redirect: redirect, infoMsg: CONSTANTS.SUCCESS_MESSAGE, errorData: null }));
				return;
			}
			errorMsg = res.message ? res.message : '';
			genericError = false;
		}
		if (!errorMsg) {
			errorMsg = CONSTANTS.GENERIC_ERROR_MESSAGE;
		}
		dispatch(updateModalMetaData({ modalType: CONSTANTS.ERROR_MODAL_TYPE, error: errorMsg, showPopup: true, genericError: genericError }));
	} catch (e) {
		saveServerLogs(e.stack, 'error', 'savetag');
		dispatch(updateModalMetaData({ modalType: CONSTANTS.ERROR_MODAL_TYPE, error: CONSTANTS.GENERIC_ERROR_MESSAGE, showPopup: true, genericError: genericError }));
	}
};

export const allTripTagFetchSuccess = (tripMasterData, isDummy) =>({
	type: TRIP_TAG_CONSTANTS.FETCH_ALL_TRIPTAG_SUCCESS,
	payload: { tripMasterData, isDummy }
});

export const updateModalMetaData = (modalData) => ({
	type: TRIP_TAG_CONSTANTS.UPDATE_MODAL_DATA,
	payload: modalData
});

export const updateTripTag = (newTripObj) =>({
	type: TRIP_TAG_CONSTANTS.UPDATE_TRIPTAG,
	payload: newTripObj
});

export const updateTripTagWithErrorData = (newTripObj, errorData) =>({
	type: TRIP_TAG_CONSTANTS.UPDATE_TRIPTAG_WITH_ERROR_DATA,
	payload: { newTripObj, errorData }
});

export const saveTagSuccess = (update) =>({
	type: TRIP_TAG_CONSTANTS.SAVE_TRIPTAG_SUCCESS,
	payload: update
});

export const updateTripFormData = (newValue, fieldName, tripData, index) => (dispatch, getState) => {
	const storeData = getState();
	const isApprovalMatrix = storeData?.tripTag?.isApprovalMatrix;
	let newTripData = [...tripData];
	let tripField = null;
	if (fieldName != TRIP_TAG_FIELDS.ATTRIBUTE_POSSIBLE_VALUES) {
		tripField = getTripFieldObject(fieldName, newTripData);
	}
	if (tripField) {
		switch (fieldName) {
			case TRIP_TAG_FIELDS.ATTRIBUTE_NAME:
			case TRIP_TAG_FIELDS.ATTRIBUTE_DESCRIPTION:
			case TRIP_TAG_FIELDS.MANDATORY_CHECK:
				tripField.value = newValue;
				tripField.error = '';
				break;
			case TRIP_TAG_FIELDS.ENABLE_APPROVAL_MATRIX:
				tripField.value = newValue;
				tripField.error = '';
				updateInputType(newTripData, newValue);
				dispatch(updateApprovalMatrix(newValue));
				break;
			case TRIP_TAG_FIELDS.LOBS:
				const field = tripField?.options?.[index];
				const isRequisitionField = field?.name === TRIP_LOBS.REQUISITION;
				const prevValue = field?.checked;
				tripField.options[index].checked = newValue;
				const { popup, selectedLobCount, requistionSelected } = showRequisitionPopup(tripField.options);
				const showPopup = popup && isApprovalMatrix;
				if (!showPopup) {
					dispatch(handleRequisitionLob(newTripData, selectedLobCount, requistionSelected, isRequisitionField, isApprovalMatrix));
					return;
				} else if (showPopup) {
					tripField.options[index].checked = showPopup ? prevValue : newValue;
					dispatch(updateModalMetaData(
						{ modalType: CONSTANTS.REQUISITION_INFO_MODAL,
							showPopup: true,
							descMsg: CONSTANTS.ANOTHER_LOB_SELECTED_MSG,
							newValue,
							newTripData }
					));
					return;
				}
				break;
			case TRIP_TAG_FIELDS.ATTRIBUTE_TYPE:
				tripField.value = newValue;
				tripField.error = '';
				handleFieldType(tripField, newValue);
				break;
		}
	}
	dispatch(updateTripTag(newTripData));
};

export const handleRequisitionLob = (newTripData, selectedLobCount, requistionStatus, isRequisitionField, prevApprovalMatrix) => (dispatch) => {
	let approvalMatrixStatus = prevApprovalMatrix;
	if (!requistionStatus || (requistionStatus && selectedLobCount > 1)) {
		approvalMatrixStatus = false;
		newTripData = deleteField(TRIP_TAG_FIELDS.ENABLE_APPROVAL_MATRIX, newTripData);
		dispatch(updateApprovalMatrix(approvalMatrixStatus));
	} else if (requistionStatus && selectedLobCount === 1) {
		newTripData = modifyTripData(newTripData);
		approvalMatrixStatus = false;
		dispatch(updateApprovalMatrix(false));
	}
	updateInputType(newTripData, approvalMatrixStatus);
	dispatch(updateTripTag(newTripData));
	dispatch(updateModalMetaData({ modalType: '' }));
};

const handleFieldType = (newField, type) => {
	switch (type) {
		case FIELD_TYPE.FREETEXT:
			newField.childNode = [];
			newField.showChild = false;
			break;
		default:
			newField.showChild = true;
			if (newField.childNode.length === 0) {
				for (let i = 0; i < LIMIT.MIN_LIMIT; i++) {
					newField.childNode.push(cloneDeep(childForm));
				}
			}
	}
};

export const addValues = (tripData) => (dispatch) => {
	let newTripData = [...tripData];
	let tripField = getTripFieldObject(TRIP_TAG_FIELDS.ATTRIBUTE_TYPE, newTripData);
	let type = tripField.value;

	if (type != FIELD_TYPE.FREETEXT && validate(tripField)) {
		tripField.childNode.push(cloneDeep(childForm));
	}
	tripField.error = '';
	dispatch(updateTripTag(newTripData));
};

export const deleteValues = (tripData, index, matrixMap, tripValueManagerMap = {}) => (dispatch) => {
	const newTripData = [...tripData];
	const tripField = getTripFieldObject(TRIP_TAG_FIELDS.ATTRIBUTE_TYPE, newTripData);

	if (tripField.childNode.length > LIMIT.MIN_LIMIT) {
		const tripValue = tripField.childNode[index].value;
		tripField.childNode.splice(index, 1);
		if (matrixMap && matrixMap[index]) {
			matrixMap.splice(index, 1);
			dispatch(setMatrix(cloneDeep(matrixMap)));
			if (tripValueManagerMap[tripValue]) {
				delete tripValueManagerMap[tripValue];
				dispatch(setTripValueManagerMap(cloneDeep(tripValueManagerMap)));
			}
		}
	} else {
		tripField.error = CONSTANTS.MINIMUM_VALUES_MESSAGE;
	}
	dispatch(updateTripTag(newTripData));
};

export const handleChangePossibleValues = (tripData, index, newValue) => (dispatch, getState) => {
	const storeData = getState();
	const tripTagNewState = storeData?.tripTag;
	const { matrixMap, tripValueManagerMap, tripTagApprovalFields, isApprovalMatrix } = tripTagNewState || {};

	let newTripData = [...tripData];
	let tripField = getTripFieldObject(TRIP_TAG_FIELDS.ATTRIBUTE_TYPE, newTripData);
	let childArray = tripField.childNode;
	childArray[index].value = newValue;
	childArray[index].error = '';
	validate(tripField);
	dispatch(updateTripTag(cloneDeep(newTripData)));
	if (isApprovalMatrix) {
		tripValueChangeHandler(matrixMap, index, newValue, tripValueManagerMap, tripTagApprovalFields);
		dispatch(tripTagSuccess({ matrixMap: matrixMap }));
	}
};

const validate = (tripField) =>{
	let status = true;
	let l = tripField.childNode.length;
	let childNodes = tripField.childNode;
	for (let i = 0; i < l; i++) {
		if (!childNodes[i].value) {
			childNodes[i].error = CONSTANTS.REQUIRED_ERROR_MESSAGE;
			status = false;
		}
	}
	return status;
};

export const deleteTripTagApi = (tripObj, trip_tag_uuid) => async (dispatch) =>{
	let response = null;
	let errorMessage = '';
	try {
		let groupId = urlParam('group_id');
		if (groupId && trip_tag_uuid) {
			let url = `${PATHS.DELETE_TRIP_API_URL}?group_id=${groupId}&trip_tag_uuid=${trip_tag_uuid}`;
			response = await ccbAxiosApiCaller.delete(url);
			let responseData = response && response.data;
			if (responseData && responseData.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE ) {
				dispatch(fetchAllTripTags());
				return;
			}
			errorMessage = responseData.message ? responseData.message : CONSTANTS.GENERIC_ERROR_MESSAGE;
		}
		errorMessage = CONSTANTS.GENERIC_ERROR_MESSAGE;
		dispatch(updateModalMetaData({ modalType: CONSTANTS.ERROR_MODAL_TYPE, error: errorMessage, showPopup: true }));
	} catch (e) {
		errorMessage = CONSTANTS.GENERIC_ERROR_MESSAGE;
	} finally {
		if (errorMessage) {
			saveServerLogs(e.stack, 'error', 'deletetriptagapi');
			dispatch(updateModalMetaData({ modalType: CONSTANTS.ERROR_MODAL_TYPE, error: errorMessage, showPopup: true }));
		}
	}
};

export const setTripValueManagerMap = (tripValueManagerMap) =>({
	type: TRIP_TAG_CONSTANTS.SET_TRIP_VALUE_MANAGER_MAP,
	payload: tripValueManagerMap
});

export const tripTagSuccess = (payload) => ({
	type: TRIP_TAG_CONSTANTS.FETCH_TRIPTAG_SUCCESS,
	payload: payload
});

export const updateApprovalMatrix = (update) =>({
	type: TRIP_TAG_CONSTANTS.UPDATE_APPROVAL_MATRIX,
	payload: update
});

export const updateRequisition = (update) =>({
	type: TRIP_TAG_CONSTANTS.UPDATE_REQUISITION,
	payload: update
});

export const setMatrix = (fieldMap) =>({
	type: TRIP_TAG_CONSTANTS.SET_MATRIX,
	payload: fieldMap
});

export const updateEnableAprovalFieldsData = (fields) => ({
	type: TRIP_TAG_CONSTANTS.SET_ENABLE_APPROVAL_FIELDS,
	payload: fields
});

export const updateErrorState = (errorState = []) => ({
	type: TRIP_TAG_CONSTANTS.UPDATE_ERROR_STATE,
	payload: errorState
});
