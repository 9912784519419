import { takeLatest, put, call } from 'redux-saga/effects';

import { TRAVEL_REQUEST_DETAILS_CONSTANT } from 'reduxStore/actionConstants';
import { formatTRFData, getNotificationToastData, isReqId } from 'UtilityComponents/TravelRequestDetailsUtils';
import { getTRFDetails, putApproveReject, putMarkBooked, cancelRequestApi } from '../../apis/TRFApis';
import {
	getTRFDataFailure, getTRFDataSuccess, putApproveRejectRequestFailure,
	putApproveRejectRequestSuccess, showNotification
} from 'reduxStore/actions/TravelRequestDetailsActions';
import { APPLICATION_CONSTANTS, PATHS } from 'AppConstants';
import { CANCEL_MODAL_DATA } from '../../travRequisition/pages/reqDetails/config';
import { saveServerLogs } from 'UtilityComponents/Util';

function* fetchTRFDetails({ payload }) {
	const { requisitionId, authCode, action } = payload;
	let apiData = {
		requisitionId,
		authCode
	};
	let isError = false;
	try {
		const response = yield call(getTRFDetails, apiData);
		const resp = response && response.data;
		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const formattedData = formatTRFData(resp);
			const payloadData = {
				formattedData,
				uiConfig: resp.uiConfig?.rejectionReasons
			};
			if (action) {
				const { status, primaryPaxName, paxCount } = resp;
				const notificationData = getNotificationToastData(status, primaryPaxName, paxCount);
				yield put(showNotification({ notificationData }));
			}

			yield put(getTRFDataSuccess(payloadData));
		} else {
			isError = true;
		}
	} catch (error) {
		isError = true;
		const isRequisitionId = isReqId(payload);
		saveServerLogs(error.stack, 'error', isRequisitionId ? PATHS.TRAVEL_REQUEST_DETAILS_API : PATHS.TRAVEL_REQUEST_DETAILS_API_AUTHCODE);
	} finally {
		if (isError) {
			yield put(getTRFDataFailure({
				msg: APPLICATION_CONSTANTS.API_ERROR
			}));
		}
	}
}

function* putApprRejRequest({ payload }) {
	let errorMsg = false;
	try {
		let apiFn = putApproveReject;
		if (payload.action === 'recalled') {
			apiFn = cancelRequestApi;
		}
		const response = yield call(apiFn, payload);
		const resp = response && response.data;
		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			if (resp.services) {
				const formattedData = formatTRFData(resp);
				const payloadData = {
					formattedData,
					uiConfig: resp.uiConfig?.rejectionReasons
				};
				yield put(getTRFDataSuccess(payloadData));
				const { status, primaryPaxName, paxCount } = resp;
				const notificationData = getNotificationToastData(status, primaryPaxName, paxCount);
				yield put(showNotification({ notificationData }));
			} else {
				yield put(putApproveRejectRequestSuccess(CANCEL_MODAL_DATA));
			}
		} else {
			errorMsg = resp && resp.message ? resp.message : APPLICATION_CONSTANTS.API_ERROR;
		}
	} catch (error) {
		errorMsg = APPLICATION_CONSTANTS.API_ERROR;
		const isRequisitionId = isReqId(payload);
		saveServerLogs(error.stack, 'error', isRequisitionId ? PATHS.APPR_REJ_DETAILS_API : PATHS.APPR_REJ_DETAILS_API_AUTHCODE);
	} finally {
		if (errorMsg) {
			yield put(putApproveRejectRequestFailure({
				msg: errorMsg
			}));
		}
	}
}

function* markAsBooked({ payload }) {
	let isError = false;
	let { requisitionId, workflowId } = payload;
	let apiPayload = {
		requisitionId,
		workflowId,
		status: 'booked'
	};
	try {
		const response = yield call(putMarkBooked, apiPayload);
		const resp = response && response.data;
		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			window.location.reload();
		} else {
			isError = true;
		}
	} catch (error) {
		isError = true;
		saveServerLogs(error.stack, 'error', PATHS.MARK_AS_BOOKED_API);
	} finally {
		if (isError) {
			yield put(getTRFDataFailure({
				msg: APPLICATION_CONSTANTS.API_ERROR
			}));
		}
	}
}
export default [
	takeLatest(TRAVEL_REQUEST_DETAILS_CONSTANT.GET_TRF_DETAILS, fetchTRFDetails),
	takeLatest(TRAVEL_REQUEST_DETAILS_CONSTANT.PUT_APPR_REJ_REQUEST, putApprRejRequest),
	takeLatest(TRAVEL_REQUEST_DETAILS_CONSTANT.MARK_AS_BOOKED, markAsBooked)
];
