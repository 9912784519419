const SUCCESS_SUFFIX = '_SUCCESS';
const FAILED_SUFFIX = '_FAILED';

export const successFor = (action) => action + SUCCESS_SUFFIX;

export const failedFor = (action) => action + FAILED_SUFFIX;

export function actionCreator(type) {
	return (payload) => ({
		type,
		payload
	});
}
