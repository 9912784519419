import { TRIP_TAG_CONSTANTS } from '../actionConstants';
import { rankFieldConf, enableMatrixManagerField } from '../../tripTag/config';

const INITIAL_STATE = {
	tripMasterData: [],
	tripData: [],
	isApprovalMatrix: false,
	isRequistionEnabled: false,
	matrixMap: [],
	tripValueManagerMap: {},
	tripTagApprovalFields: {
		rankField: rankFieldConf,
		managerField: enableMatrixManagerField
	},
	isApprovalMatApi: false
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}
	switch (action.type) {
		case TRIP_TAG_CONSTANTS.FETCH_ALL_TRIPTAG_SUCCESS: {
			return { ...state, ...action.payload };
		}
		case TRIP_TAG_CONSTANTS.FETCH_TRIPTAG_SUCCESS: {
			return { ...state, ...action.payload };
		}
		case TRIP_TAG_CONSTANTS.SAVE_TRIPTAG_SUCCESS: {
			return { ...state, ...action.payload };
		}
		case TRIP_TAG_CONSTANTS.UPDATE_MODAL_DATA: {
			return { ...state, ...action.payload };
		}
		case TRIP_TAG_CONSTANTS.UPDATE_TRIPTAG: {
			return { ...state, tripData: action.payload };
		}
		case TRIP_TAG_CONSTANTS.UPDATE_TRIPTAG_WITH_ERROR_DATA: {
			return { ...state, tripData: action.payload.newTripObj, errorData: action.payload.errorData };
		}
		case TRIP_TAG_CONSTANTS.UPDATE_APPROVAL_MATRIX: {
			return { ...state, isApprovalMatrix: action.payload };
		}
		case TRIP_TAG_CONSTANTS.UPDATE_REQUISITION: {
			return { ...state, isRequistionEnabled: action.payload };
		}
		case TRIP_TAG_CONSTANTS.SET_MATRIX: {
			return { ...state, matrixMap: action.payload };
		}
		case TRIP_TAG_CONSTANTS.SET_TRIP_VALUE_MANAGER_MAP: {
			return { ...state, tripValueManagerMap: action.payload };
		}
		case TRIP_TAG_CONSTANTS.SET_ENABLE_APPROVAL_FIELDS: {
			return { ...state, tripTagApprovalFields: action.payload };
		}
		case TRIP_TAG_CONSTANTS.UPDATE_ERROR_STATE: {
			return { ...state, errorData: action.payload };
		}
	}
	return state;
};
