import { SHORTLIST_OPTIONS_CONST } from '../actionConstants';
import { setApiLoading, setApiError, setApiSuccess } from './shortlistNShareActions';
import { getAxiosConfig } from '../../apisSetup/apiConfig';
import { ERROR_CONTENT, ERROR_HEADING, STATUS, MORE_REQ_CART_ERROR, SHARED_CART_ERROR } from '../../shortListNShare/config';
import { appendWishId } from '../../UtilityComponents/ShortlistUtils';

const PAGE_LOAD = 'PAGE_LOAD';
import {
	saveServerLogs, getCookie
} from '../../UtilityComponents/Util';
import {
	PATHS
} from '../../AppConstants';

const axiosApiCaller = getAxiosConfig(PATHS.WISHLIST_FQDN, true, { 'Content-Type': 'application/json',
	'mmt-auth': getCookie('mmt-auth') }, false);

export const getShortlistedOptions = (wishId) => async (dispatch) => {
	let resp = null;
	try {
		dispatch(setApiLoading(PAGE_LOAD));
		resp = await axiosApiCaller.get(appendWishId(PATHS.SHORTLISTED_OPTION));

		const { data } = resp;
		dispatch(setApiLoading(false));
		if (data.responseCode === '600' && data.wishlist && data.wishlist.wishlistItems) {
			dispatch({
				type: SHORTLIST_OPTIONS_CONST.GET_OPTIONS_SUCCESS,
				payload: data.wishlist
			});
		} else {
			let heading = ERROR_HEADING;
			let content = ERROR_CONTENT;
			if (data && data.wishlist && data.wishlist.wishlistStatus && !data.wishlist.wishlistItems) {
				switch (data.wishlist.wishlistStatus) {
					case STATUS.SHARED:
						content = SHARED_CART_ERROR;
						break;
					case STATUS.MORE_OPTIONS_REQUESTED:
						content = MORE_REQ_CART_ERROR;
						break;

					default:
						break;
				}
			}

			dispatch(setApiError({
				name: PAGE_LOAD,
				data: {
					heading,
					content
				}
			}));
			dispatch({
				type: SHORTLIST_OPTIONS_CONST.PAGE_CALLED
			});
		}
	} catch (e) {
		dispatch(setApiError({
			name: PAGE_LOAD
		}));
		saveServerLogs(e.stack, 'error', '');
		dispatch({
			type: SHORTLIST_OPTIONS_CONST.PAGE_CALLED
		});
	}
};

export const removeWishlistProduct = (wishlistId, idData, grpId, lob)=> async (dispatch) => {
	let resp = null;
	try {
		dispatch(setApiLoading(PAGE_LOAD));
		const data = {
			productIdList: idData
		};
		resp = await axiosApiCaller.post(appendWishId(PATHS.REMOVE_PRODUCT, wishlistId), data);
		dispatch(setApiLoading(false));
		if (resp.data && resp.data.responseCode === '600') {
			dispatch({
				type: SHORTLIST_OPTIONS_CONST.UPDATE_GRPIDS_DATA,
				payload: { idData, grpId, lob }
			});
		} else {
			dispatch(setApiError({
				name: PAGE_LOAD,
				data: {
					heading: ERROR_HEADING,
					content: ERROR_CONTENT
				}
			}));
		}
	} catch (e) {
		dispatch(setApiError({
			name: PAGE_LOAD,
			data: {
				heading: ERROR_HEADING,
				content: ERROR_CONTENT
			}
		}));
		saveServerLogs(e.stack, 'error', '');
	}
};

export const sharetoEmployee = (apiRequest, wishlistId)=> async (dispatch) => {
	let resp = null;
	try {
		dispatch(setApiLoading(PAGE_LOAD));
		resp = await axiosApiCaller.post(appendWishId(PATHS.SHARE_WISHLIST, wishlistId), apiRequest);
		dispatch(setApiLoading(false));
		const { data } = resp;
		if (data && data.responseCode === '600') {
			const { popUp = {} } = data;
			dispatch(setApiSuccess({
				name: 'SHARED',
				isPopup: true,
				data: {
					heading: popUp.heading,
					content: popUp.text
				}
			}));
		} else {
			dispatch(setApiError({
				name: PAGE_LOAD,
				data: {
					heading: ERROR_HEADING,
					content: ERROR_CONTENT
				}
			}));
		}
	} catch (e) {
		dispatch(setApiError({
			name: PAGE_LOAD,
			data: {
				heading: ERROR_HEADING,
				content: ERROR_CONTENT
			}
		}));
		saveServerLogs(e.stack, 'error', '');
	}
};
