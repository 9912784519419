import { APPLICATION_CONSTANTS, TRF_DETAILS_CONSTANTS } from 'AppConstants';
import { TRAVEL_REQUEST_DETAILS_CONSTANT } from 'reduxStore/actionConstants';
import { successFor, failedFor } from '../sagaUtil';

const INITIAL_STATE = {
	apiLoader: false,
	apiSuccess: false,
	apiError: false,
	data: {},
	openModal: '',
	isPopup: false,
	uiConfig: [],
	showNotification: false
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	};
	const { type, payload = {} } = action;
	switch (type) {
		case TRAVEL_REQUEST_DETAILS_CONSTANT.GET_TRF_DETAILS: {
			return {
				...state,
				apiLoader: 'GET_TRF_DETAILS',
				apiError: false,
				apiSuccess: false
			};
		}
		case successFor(TRAVEL_REQUEST_DETAILS_CONSTANT.GET_TRF_DETAILS): {
			const { formattedData, uiConfig = [] } = payload;
			return {
				...state,
				apiLoader: false,
				apiSuccess: true,
				apiError: false,
				openModal: false,
				data: formattedData,
				uiConfig: [...uiConfig]
			};
		}
		case failedFor(TRAVEL_REQUEST_DETAILS_CONSTANT.GET_TRF_DETAILS): {
			const { msg } = payload;
			return {
				...state,
				apiLoader: false,
				apiError: true,
				apiSuccess: false,
				isPopup: true,
				msg: msg
			};
		}
		case TRAVEL_REQUEST_DETAILS_CONSTANT.SHOW_MODAL: {
			return {
				...state,
				openModal: payload.modalType,
				workflowId: payload.workflowId
			};
		}
		case TRAVEL_REQUEST_DETAILS_CONSTANT.PUT_APPR_REJ_REQUEST: {
			return {
				...state,
				apiLoader: payload.action,
				apiError: false,
				apiSuccess: false
			};
		}
		case successFor(TRAVEL_REQUEST_DETAILS_CONSTANT.PUT_APPR_REJ_REQUEST): {
			const { msg, heading } = payload;
			return {
				...state,
				apiLoader: false,
				apiSuccess: true,
				msg: msg,
				heading: heading,
				openModal: TRF_DETAILS_CONSTANTS.ERROR_MODAL
			};
		}
		case failedFor(TRAVEL_REQUEST_DETAILS_CONSTANT.PUT_APPR_REJ_REQUEST): {
			const { msg } = payload;
			return {
				...state,
				apiLoader: false,
				apiError: false,
				apiSuccess: true,
				msg: msg,
				openModal: TRF_DETAILS_CONSTANTS.ERROR_MODAL
			};
		}
		case TRAVEL_REQUEST_DETAILS_CONSTANT.MARK_AS_BOOKED: {
			return {
				...state,
				apiLoader: 'markbooked',
				apiError: false,
				apiSuccess: false
			};
		}
		case TRAVEL_REQUEST_DETAILS_CONSTANT.MARK_AS_BOOKED_FAILURE: {
			return {
				...state,
				apiLoader: false,
				apiError: true,
				apiSuccess: false,
				msg: APPLICATION_CONSTANTS.GENERIC_API_ERROR
			};
		}
		case TRAVEL_REQUEST_DETAILS_CONSTANT.SHOW_NOTIFICATION: {
			return {
				...state,
				showNotification: true,
				notificationData: payload.notificationData
			};
		}
		case TRAVEL_REQUEST_DETAILS_CONSTANT.CLOSE_NOTIFICATION: {
			return {
				...state,
				showNotification: false
			};
		}
		default: {
			return state;
		}
	}
};
