import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunk from 'redux-thunk';
// import AsyncStorage from '@react-native-community/async-storage';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import axiosConfigSSR from '../../apisSetup/axiosConfigSSR';

import rootReducer from '../reducers';
import hcsHeaderReducer from '../reducers/hcsHeaderReducer';
import dashboardReducer from '../reducers/dashboardReducer';

import tripTagReducer from '../reducers/TripTagReducer';
import invoiceReducer from '../reducers/invoiceReducer';
import adminReferReducer from '../reducers/adminReferreducer';
import charteredReducer from '../reducers/CharteredFlightReducer';
import walletReducer from '../reducers/WalletReducer';
import policyReducer from '../reducers/policyReducer';
import bizHeaderReducer from '../reducers/bizHeaderReducer';
import shortlistNShareReducer from '../reducers/shortlistNShareReducer';
import shortlistoptionsReducer from '../reducers/shortListedOptionsReducer';
import shortlistItineraryDetailReducer from '../reducers/shortlistItineraryDetailReducer';
import employeeReducer from '../reducers/employeeReducer';
import groupsReducer from '../reducers/groupsReducer';
import requisitionTravelReducer from '../reducers/requisitionTravelReducer';
import reportsReducer from '../reducers/reports';
import gstReducer from '../reducers/gstReducer';
import popupReducer from '../reducers/popupReducer';
import mrbReducer from '../reducers/manageRequestBookingReducer';
import integrationReducer from '../reducers/integrationReducer';
import organizationReducer from '../reducers/organizationReducer';

import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
import trainReducer from '../reducers/trainReducer';
import resourceReducer from '../reducers/resourceReducer';
import TravelRequestDetailsReducer from '../reducers/TravelRequestDetailsReducer';

const persistConfig = {
	key: 'root',
	// storage: AsyncStorage,
	storage,
	blacklist: [
		'tripTag',
		'invoice',
		'adminRefer',
		'chartered',
		'wallet',
		'policy',
		'bizHeader',
		'shortlist',
		'shortlistoptions',
		'shortlistItineraryDetail',
		'travelRequest',
		'apprReq',
		'expenseLanding',
		'expense',
		'expenseDetails',
		'expReimburse',
		'expenseAdmin',
		'expensePolicy',
		'employee',
		'groups',
		'train',
		'resource',
		'requisitionTravel',
		'travelRequestDetails',
		'login',
		'reports',
		'gst',
		'companyDtls',
		'popup',
		'manageRequestBookings',
		'integrationAwarness',
		'organization'
	]
};
const composeEnhancers = typeof window === 'object' &&
window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
	}) : compose;
const combinedReducer = persistReducer(persistConfig, combineReducers({
	login: rootReducer,
	hcs: hcsHeaderReducer,
	dashboard: dashboardReducer,
	tripTag: tripTagReducer,
	invoice: invoiceReducer,
	adminRefer: adminReferReducer,
	chartered: charteredReducer,
	wallet: walletReducer,
	policy: policyReducer,
	bizHeader: bizHeaderReducer,
	shortlist: shortlistNShareReducer,
	shortlistoptions: shortlistoptionsReducer,
	shortlistItineraryDetail: shortlistItineraryDetailReducer,
	employee: employeeReducer,
	groups: groupsReducer,
	train: trainReducer,
	resource: resourceReducer,
	requisitionTravel: requisitionTravelReducer,
	travelRequestDetails: TravelRequestDetailsReducer,
	reports: reportsReducer,
	gst: gstReducer,
	popup: popupReducer,
	manageRequestBookings: mrbReducer,
	integrationAwarness: integrationReducer,
	organization: organizationReducer
}));

export default (initialState = {}, config = {}) => {
	const ccbAxiosApiCaller = axiosConfigSSR(config);
	let store = createStore(combinedReducer, initialState, composeEnhancers(
		applyMiddleware(thunk.withExtraArgument(ccbAxiosApiCaller), sagaMiddleware)
	));
	sagaMiddleware.run(sagas);
	let persistor = persistStore(store);
	return { store, persistor };
};
