import { TRAVEL_WISHLIST_CONST } from '../actionConstants';
import { setApiLoading, setApiError, setApiSuccess } from './shortlistNShareActions';
import { getAxiosConfig } from '../../apisSetup/apiConfig';
import {
	formatWishlistData,
	formatWishlistConfirmData,
	getFlightFarePayload,
	formatFlightFareResp,
	flightFareLoading,
	getHotelFarePayload,
	hotelFareLoading
} from '../../shortListNShare/Components/CBTravelList/utils';
import {
	saveServerLogs, getCookie
} from '../../UtilityComponents/Util';
import {
	PATHS,
	PAGE_NAME_CONST,
	SHORTLIST_SHARE_CONSTANTS
} from '../../AppConstants';

const PAGE_LOAD = 'PAGE_LOAD';

const axiosApiCaller = getAxiosConfig(PATHS.WISHLIST_FQDN, true,
	{
		'Content-Type': 'application/json',
		'mmt-auth': getCookie('mmt-auth')
	}, false);

const ERROR_DFLT_HEADING = 'Please Note';
const ERROR_DFLT_CONTENT = 'Something went wrong. Please try again';

const getErrorActionObj = (pageName = PAGE_LOAD, heading = ERROR_DFLT_HEADING, content = ERROR_DFLT_CONTENT, updateModal) => ({
	name: pageName,
	isPopup: true,
	updateModal,
	data: {
		heading,
		content
	}
});

const setFlightFareLoading = (grpId, rKeyMap, loadingState) => ({
	type: TRAVEL_WISHLIST_CONST.LOADING_FLIGHT_FARE,
	payload: flightFareLoading(grpId, rKeyMap, loadingState)
});

const setHotelFareLoading = (grpId, lobProductIdMap, loadingState) => ({
	type: TRAVEL_WISHLIST_CONST.LOADING_HOTEL_FARE,
	payload: hotelFareLoading(grpId, lobProductIdMap, loadingState)
});

const getCommonParams = ({ wishId, authId }) => {
	const params = {};
	if (authId) {
		params.authCode = authId;
	} else if (wishId) {
		params.wishlistId = wishId;
	}
	return params;
};

export const getWishlist = (wishId, userType, authId, isMobile) => async (dispatch) => {
	try {
		dispatch(setApiLoading(PAGE_NAME_CONST.CB_WISHLIST));
		let resp = {};
		if (authId) {
			resp = await axiosApiCaller.get(PATHS.AUTH_CB_SINGLE_WISHLIST, {
				params: getCommonParams({ authId })
			});
		} else {
			resp = await axiosApiCaller.get(PATHS.CB_SINGLE_WISHLIST, {
				params: getCommonParams({ wishId })
			});
		}

		let { data } = resp;
		dispatch(setApiLoading(false));

		if (data && data.responseCode === '600') {
			const payload = formatWishlistData(data, userType, isMobile);
			dispatch({
				type: TRAVEL_WISHLIST_CONST.GET_WISHLIST,
				payload
			});
		} else {
			dispatch(setApiError(getErrorActionObj(PAGE_LOAD, ERROR_DFLT_HEADING, data.message)));
		}
	} catch (e) {
		dispatch(setApiLoading(false));
		dispatch(setApiError(getErrorActionObj()));
		saveServerLogs(e.stack, 'error', '');
	}
};

export const confirmMoreOptions = (bodyData, wishId, authId, updateModal) => async (dispatch) => {
	const body = formatWishlistConfirmData(bodyData);
	try {
		dispatch(setApiLoading(updateModal ? SHORTLIST_SHARE_CONSTANTS.CONFIRM_MODAL : PAGE_NAME_CONST.CB_WISHLIST));
		let resp = {};
		if (authId) {
			resp = await axiosApiCaller.put(PATHS.AUTH_WISHLIST_CONFIRM_STATUS, body, {
				params: getCommonParams({ authId })
			});
		} else {
			resp = await axiosApiCaller.put(PATHS.WISHLIST_CONFIRM_STATUS, body, {
				params: getCommonParams({ wishId })
			});
		}
		const { data } = resp;
		dispatch(setApiLoading(false));
		if (data && data.responseCode === '600') {
			dispatch(setApiSuccess({
				name: 'SHARED',
				isPopup: true,
				updateModal,
				data: {
					heading: 'Itinerary Shared with travel desk !',
					content: 'You will be notified when booking is confirmed by the travel desk.'
				}
			}));
		} else {
			dispatch(setApiError(getErrorActionObj(updateModal ? 'SHARED_ERROR' : PAGE_LOAD, ERROR_DFLT_HEADING, data.message, updateModal)));
		}
	} catch (e) {
		dispatch(setApiLoading(false));
		dispatch(setApiError(getErrorActionObj()));
		saveServerLogs(e.stack, 'error', '');
	}
};

export const moreOptionsRequest = (apiRequest, wishId, authId, updateModal) => async (dispatch) => {
	try {
		dispatch(setApiLoading(updateModal ? SHORTLIST_SHARE_CONSTANTS.REQ_MORE_MODAL : PAGE_NAME_CONST.CB_WISHLIST));
		let resp = {};
		if (authId) {
			resp = await axiosApiCaller.put(PATHS.AUTH_WISHLIST_CONFIRM_STATUS, apiRequest, {
				params: getCommonParams({ authId })
			});
		} else {
			resp = await axiosApiCaller.put(PATHS.WISHLIST_CONFIRM_STATUS, apiRequest, {
				params: getCommonParams({ wishId })
			});
		}
		const { data } = resp;
		dispatch(setApiLoading(false));
		if (data && data.responseCode === '600') {
			dispatch(setApiSuccess({
				name: updateModal ? 'REQUEST_MORE' : 'SHARED',
				isPopup: true,
				updateModal,
				data: {
					heading: 'Request Shared with travel desk !',
					content: 'You will be notified when more options are shared by the travel desk.'
				}
			}));
		} else {
			dispatch(setApiError(getErrorActionObj(PAGE_LOAD, ERROR_DFLT_HEADING, data.message)));
		}
	} catch (e) {
		dispatch(setApiLoading(false));
		dispatch(setApiError(getErrorActionObj()));
		saveServerLogs(e.stack, 'error', '');
	}
};

export const cancelItenary = (apiRequest, wishId, authId, updateModal) => async (dispatch) => {
	try {
		dispatch(setApiLoading(updateModal ? SHORTLIST_SHARE_CONSTANTS.CANCEL_REQ_MODAL : PAGE_NAME_CONST.CB_WISHLIST));
		let resp = {};
		if (authId) {
			resp = await axiosApiCaller.put(PATHS.AUTH_CANCEL_REQUEST, apiRequest, {
				params: getCommonParams({ authId })
			});
		} else {
			resp = await axiosApiCaller.put(PATHS.CANCEL_REQUEST, apiRequest, {
				params: getCommonParams({ wishId })
			});
		}
		const { data } = resp;
		dispatch(setApiLoading(false));
		if (data && data.responseCode === '600') {
			dispatch(setApiSuccess({
				name: 'REQ_CANCELLED',
				isPopup: true,
				updateModal,
				data: {
					heading: 'Request Cancelled',
					content: 'Travel desk will be notified about your decision.'
				}
			}));
		} else {
			dispatch(setApiError(getErrorActionObj(PAGE_LOAD, ERROR_DFLT_HEADING, data.message)));
		}
	} catch (e) {
		dispatch(setApiLoading(false));
		dispatch(setApiError(getErrorActionObj()));
		saveServerLogs(e.stack, 'error', '');
	}
};

export const flightFareDetails = (group, grpKey, grpDetail, statusToInclude, wishId, authId) => async (dispatch) => {
	if (grpDetail && grpDetail.status === statusToInclude) {
		const formatPayload = getFlightFarePayload(group, statusToInclude);
		const { lobProductIds, params, lob, rKeyMap } = formatPayload;
		if (lobProductIds && lobProductIds.length) {
			try {
				dispatch(setFlightFareLoading( grpKey, rKeyMap, true));
				const reqBody = {
					lobProductIds,
					params,
					lob
				};
				let resp;
				if (authId) {
					resp = await axiosApiCaller.post(PATHS.AUTH_LATEST_FLIGHT_FARE, reqBody, {
						params: getCommonParams({ authId })
					});
				} else {
					resp = await axiosApiCaller.post(PATHS.LATEST_FLIGHT_FARE, reqBody, {
						params: getCommonParams({ wishId })
					});
				}
				const { data } = resp;
				if (data && data.responseCode === '600') {
					const actionPayload = formatFlightFareResp(data, grpKey, rKeyMap);
					dispatch({
						type: TRAVEL_WISHLIST_CONST.GET_FLIGHT_FARE,
						payload: actionPayload
					});
				} else {
					dispatch(setFlightFareLoading( grpKey, rKeyMap, false));
				}
			} catch (e) {
				dispatch(setApiError(getErrorActionObj()));
				dispatch(setFlightFareLoading( grpKey, rKeyMap, false));
				saveServerLogs(e.stack, 'error', '');
			}
		}
	}
};

export const hotelFareDetails = (group, grpKey, grpDetail, statusToInclude, wishId, authId) => async (dispatch) => {
	if (grpDetail && grpDetail.status === statusToInclude) {
		const { postData = {}, params = {}, idMap } = getHotelFarePayload(group, statusToInclude, grpKey, wishId, authId);
		const { lobProductIds = [] } = postData;
		if (lobProductIds.length) {
			try {
				dispatch(setHotelFareLoading( grpKey, idMap, true));
				const resp = await axiosApiCaller.post(`${PATHS.LATEST_HOTEL_FARE}`, postData, { params });
				const { data } = resp;
				if (data && data.responseCode === '600') {
					const actionPayload = formatFlightFareResp(data, grpKey, idMap);
					dispatch({
						type: TRAVEL_WISHLIST_CONST.GET_HOTEL_FARE,
						payload: actionPayload
					});
				} else {
					dispatch(setHotelFareLoading( grpKey, idMap, false));
				}
			} catch (e) {
				dispatch(setHotelFareLoading( grpKey, idMap, false));
				dispatch(setApiError(getErrorActionObj()));
				saveServerLogs(e.stack, 'error', '');
			}
		}
	}
};

export const setSelectedOptions = (group, option, checked, lob) => ({
	type: TRAVEL_WISHLIST_CONST.SET_WISHLIST_OPTION,
	payload: {
		option,
		checked,
		group,
		lob
	}
});
