import { call, put, takeLatest } from 'redux-saga/effects';

import { APPLICATION_CONSTANTS, PATHS, POPUP_CONSTS } from 'AppConstants';
import { GST_ACTION_CONSTANTS } from 'reduxStore/actionConstants';
import { getGstDetailsSuccess, getGstDetailsFailed, updateGstFailed, updateGstSuccess, getGstDetails } from 'reduxStore/actions/gstActions';
import { getGstData, updateGstData } from 'apis/gstApis';
import { saveServerLogs } from 'UtilityComponents/Util';
import { formatGstData, formatGstRequest } from 'UtilityComponents/gstUtils';
import { updatePopup } from 'reduxStore/actions/popupActions';
import { gstSuccessPopupTxt, gstFailedText } from '../../gst/config';

function* fetchGstDetails() {
	try {
		const response = yield call(getGstData);
		const resp = response && response.data;
		if (resp &&
			resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE &&
			resp.gstDetails) {
			yield put(getGstDetailsSuccess(resp.gstDetails.map((gst)=>formatGstData(gst)) ));
		} else {
			yield put(getGstDetailsFailed(resp.message));
		}
	} catch (error) {
		yield put(getGstDetailsFailed(error.message));
		saveServerLogs(error.stack, 'error', PATHS.GET_GST);
	}
}

function* updateGst({ payload }) {
	const popData = {
		showPop: true,
		popData: false
	};
	try {
		const response = yield call(updateGstData, formatGstRequest(payload));
		const resp = response && response.data;
		if (resp &&
			resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			yield put(updateGstSuccess());
			yield put(getGstDetails());
			popData.popData = {
				heading: gstSuccessPopupTxt.heading,
				content: gstSuccessPopupTxt.content,
				apiStatus: POPUP_CONSTS.SUCCESS_POP,
				isFooter: false
			};
		} else {
			yield put(updateGstFailed());
			popData.popData = {
				heading: gstFailedText,
				content: resp.message,
				apiStatus: POPUP_CONSTS.ERR_POPUP,
				isFooter: false
			};
		}
	} catch (error) {
		yield put(updateGstFailed());
		popData.popData = {
			heading: gstFailedText,
			content: error.message || APPLICATION_CONSTANTS.GENERIC_API_ERROR,
			apiStatus: POPUP_CONSTS.ERR_POPUP,
			isFooter: false
		};
		saveServerLogs(error.stack, 'error', PATHS.UPDATE_GST);
	} finally {
		yield put(updatePopup(popData));
	}
}

export default [
	takeLatest(GST_ACTION_CONSTANTS.GET_GST_DATA, fetchGstDetails),
	takeLatest(GST_ACTION_CONSTANTS.UPDATE_GST_DATA, updateGst)
];
