import { TRAVEL_REQUEST_DETAILS_CONSTANT } from 'reduxStore/actionConstants';
import { actionCreator, failedFor, successFor } from 'reduxStore/sagaUtil';

export const getTravelRequestDetailsData = actionCreator(TRAVEL_REQUEST_DETAILS_CONSTANT.GET_TRF_DETAILS);
export const getTRFDataSuccess = actionCreator(successFor(TRAVEL_REQUEST_DETAILS_CONSTANT.GET_TRF_DETAILS));
export const getTRFDataFailure = actionCreator(failedFor(TRAVEL_REQUEST_DETAILS_CONSTANT.GET_TRF_DETAILS));
export const putApproveRejectRequest = actionCreator(TRAVEL_REQUEST_DETAILS_CONSTANT.PUT_APPR_REJ_REQUEST);
export const putApproveRejectRequestSuccess = actionCreator(successFor(TRAVEL_REQUEST_DETAILS_CONSTANT.PUT_APPR_REJ_REQUEST));
export const putApproveRejectRequestFailure = actionCreator(failedFor(TRAVEL_REQUEST_DETAILS_CONSTANT.PUT_APPR_REJ_REQUEST));
export const putMarkAsBooked = actionCreator(TRAVEL_REQUEST_DETAILS_CONSTANT.MARK_AS_BOOKED);
export const showModal = actionCreator(TRAVEL_REQUEST_DETAILS_CONSTANT.SHOW_MODAL);
export const showNotification = actionCreator(TRAVEL_REQUEST_DETAILS_CONSTANT.SHOW_NOTIFICATION);
export const closeNotification = actionCreator(TRAVEL_REQUEST_DETAILS_CONSTANT.CLOSE_NOTIFICATION);
