import * as ActionConstants from '../actionConstants';

const initialState = {
	banners: []
};
const dashboardReducer = (state = initialState, action) => {
	if (!action) {
		return state;
	}
	let dConst = ActionConstants.DASHBOARD_CONST;
	switch (action.type) {
		case dConst.CALL_FAILED:
		case dConst.CALL_IN_PROGRESS:
		case dConst.CALL_IN_SUCCESS:
		case dConst.CALL_NW_ERROR:
		case dConst.RESET:
			return { ...state, dashboardPayload: action.dashboard_payload };
			break;
		case dConst.CONFIG_CALL_IN_PROGRESS:
		case dConst.CONFIG_CALL_FAILED:
		case dConst.CONFIG_CALL_IN_SUCCESS:
		case dConst.CONFIG_CALL_NW_ERROR:
			return { ...state, configPayload: action.config_Payload };
			break;
		case dConst.SAVINGS_CALL_IN_SUCCESS:
		case dConst.SAVINGS_CALL_FAILED:
		case dConst.SAVINGS_CALL_NW_ERROR:
		case dConst.SAVINGS_CALL_IN_PROGRESS:
			return { ...state, savingsPayload: action.savings_payload };
			break;
		case dConst.WALLET_CALL_FAILED:
		case dConst.WALLET_CALL_IN_SUCCESS:
		case dConst.WALLET_CALL_NW_ERROR:
		case dConst.WALLET_CALL_IN_PROGRESS:
			return { ...state, walletPayload: action.wallet_payload };
			break;
		case dConst.ORG_CALL_NW_ERROR:
		case dConst.ORG_CALL_IN_SUCCESS:
		case dConst.ORG_CALL_FAILED:
		case dConst.ORG_CALL_IN_PROGRESS:
			return { ...state, orgPayload: action.org_payload };
			break;
		case dConst.BANNER_CALL_IN_PROGRESS:
		case dConst.BANNER_CALL_IN_SUCCESS:
		case dConst.BANNER_CALL_NW_ERROR:
		case dConst.BANNER_CALL_FAILED:
			return { ...state, bannerPayload: action.banner_payload };
			break;
		case dConst.DASHBOARD_BANNER: {
			const { payload } = action;
			const { referralBanners, supportBanners } = payload;
			return {
				...state,
				banners: supportBanners,
				referralBanners
			};
		}
		default:
			return state;
	}
};
export default dashboardReducer;
