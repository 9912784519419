import axios from 'axios';
import * as ActionConstants from '../actionConstants';
import { HCS_CONST } from '../actionConstants';
import { clearLoginState, getCookie } from '../../UtilityComponents/Util';
import { APPLICATION_CONSTANTS, PATHS } from 'AppConstants';

export const logoutFromHcs = (source) => (dispatch) => {
	const mmtAuth = getCookie('mmt-auth');
	const userIdentifier = {
		'ipAddress': 'ipAddress',
		'imie': 'imie',
		'appVersion': 'appVersion',
		'os': 'DESKTOP',
		'osVersion': 'osVersion',
		'timeZone': 'timeZone',
		'type': 'mmt-auth',
		'profileType': '',
		'Authorization': APPLICATION_CONSTANTS.MMT_USER_SERVICE_AUTHORIZATION,
		'deviceId': getCookie('dvid'),
		'cookie': mmtAuth,
		'value': mmtAuth
	};
	axios({
		method: 'DELETE',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'mmt-auth': mmtAuth,
			'authorization': APPLICATION_CONSTANTS.MMT_USER_SERVICE_AUTHORIZATION,
			'User-Identifier': JSON.stringify(userIdentifier)
		},
		url: PATHS.LOGOUT_USER
	}).then((res) => dispatchLogoutResult(res, dispatch, source))
		.catch((err) => dispatch({
			type: ActionConstants.HCS_CONST.LOGOUT_FAILED,
			payload: {
				state: ActionConstants.HCS_CONST.LOGOUT_FAILED,
				next_action: HCS_CONST.SHOW_SUCCESS_POPUP,
				popupMsg: HCS_CONST.MSG_LOGOUT_FAILED
			}
		}));
};

export const dispatchLogoutResult = (response, dispatch, source) => {
	if (response && response.data) {
		clearLoginState();
		window.location = PATHS.MYBIZ_URL;
	} else {
		dispatch({
			type: ActionConstants.HCS_CONST.LOGOUT_FAILED,
			payload: {
				state: ActionConstants.HCS_CONST.LOGOUT_FAILED,
				next_action: HCS_CONST.SHOW_SUCCESS_POPUP,
				popupMsg: HCS_CONST.MSG_LOGOUT_FAILED
			}
		});
	}
};
