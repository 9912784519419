import cloneDeep from 'lodash/cloneDeep';

import { EMPLOYEE_STORE_CONST } from '../actionConstants';
import { successFor } from '../sagaUtil';
import { EMPLOYEE_CONSTANTS, APPLICATION_CONSTANTS } from 'AppConstants';
import { EMP_FILTER_CONSTANTS } from '../../Employee/config';
import {
	handleSelected,
	formatFilterCounts,
	formatFiltersOptions,
	formatManagerData,
	formatGroupData,
	formatDesignationData,
	getReminderSettings
} from '../../Employee/utils';
import { filters, SORT_PROP, inviteSuccessModal, inviteLinkSuccessModal, vipLimitModal, MANAGER_NOT_SET, DESIGNATION_NOT_SET } from '../../Employee/config';
import { getProfileDataProps } from 'UtilityComponents/Util';

const initialEmployeeList = { data: [], error: false, loading: false, hasMore: false };

const INITIAL_STATE = {
	employeeList: { ...initialEmployeeList },
	bulkUpStatus: false,
	selectedEmployees: {},
	filters: filters,
	query: {
		statusList: [EMP_FILTER_CONSTANTS.INVITED.value]
	},
	grpQuery: {
		statusList: [EMP_FILTER_CONSTANTS.INVITED.value, EMP_FILTER_CONSTANTS.PENDING_VERIFICATION.value, EMP_FILTER_CONSTANTS.VERIFIED.value]
	},
	sortQuery: {
		limit: EMPLOYEE_CONSTANTS.PAGE_LIMIT * 2,
		sort: { ...SORT_PROP }
	},
	pageNo: 1,
	country: 'INDIA',
	countryCode: '91',
	changeEmpType: false,
	managersLoading: false,
	orgGrps: [],
	modal: false,
	isAllSelected: false,
	empSearchStr: '',
	managerData: [],
	reminderSettings: {},
	dataUpdateCount: 0,
	invitedCount: 0,
	totalEmployee: 0,
	downloadCsvStatus: {
		status: '',
		msg: ''
	},
	uploadRemainingTime: { min: 0, hr: 0, sec: 0 },
	uploadStatus: {
		status: '',
		percentage: 0
	},
	feature: 0,
	remainingCount: 0,
	totalTobeUpdated: 0,
	typeEnabledOrg: false
};

const initApiState = {
	apiLoading: false,
	msgData: false,
	apiStatus: '',
	openModal: '',
	apiType: false
};

const popupInitData = {
	inviteData: false,
	isSingleSelect: false,
	selectedVals: false,
	empChangeValue: false,
	isModify: false,
	modifyData: false,
	managerAdd: false,
	modal: false,
	...initApiState
};

const initUploadState = {
	totalTobeUpdated: 0,
	remainingCount: 0,
	jobId: false,
	updateStatus: {}
};

export default (state = INITIAL_STATE, action) => {
	if (!state.hydrated) {
		state = { ...INITIAL_STATE, ...state };
	}
	if (!action) {
		return state;
	}

	const { type, payload = {} } = action;

	switch (type) {
		case EMPLOYEE_STORE_CONST.EMPLOYEE_LIST: {
			const { filterQuery = { } } = payload;
			const resetPage = filterQuery.pageNo === 1;
			return {
				...state,
				employeeList: {
					data: resetPage ? [] : state.employeeList.data,
					error: false,
					loading: true,
					hasMore: state.employeeList.hasMore
				},
				selectedEmployees: resetPage ? {} : state.selectedEmployees
			};
		}

		case successFor(EMPLOYEE_STORE_CONST.EMPLOYEE_LIST): {
			return {
				...state,
				...payload
			};
		}

		case EMPLOYEE_STORE_CONST.API_LOADING: {
			const { loading, openModal, data, apiType = false } = payload;
			return {
				...state,
				msgData: data || false,
				apiType: apiType,
				apiStatus: loading ? EMPLOYEE_STORE_CONST.LOADING : '',
				openModal: openModal || false
			};
		}
		case EMPLOYEE_STORE_CONST.API_SUCCESS: {
			const { data = false, openModal, apiType = false, showNotify } = payload;
			return {
				...state,
				apiLoading: false,
				msgData: data || false,
				apiError: true,
				apiType: apiType,
				apiStatus: EMPLOYEE_STORE_CONST.SUCCESS,
				openModal: openModal || false,
				modal: showNotify ? false : state.modal,
				showNotify
			};
		}
		case EMPLOYEE_STORE_CONST.API_ERROR: {
			const { data = false, openModal, apiType = false, showNotify } = payload;
			return {
				...state,
				apiLoading: false,
				msgData: data,
				apiType: apiType,
				apiStatus: EMPLOYEE_STORE_CONST.ERROR,
				openModal: typeof openModal === 'undefined' ? openModal : false,
				modal: showNotify ? false : state.modal,
				showNotify: showNotify
			};
		}
		case EMPLOYEE_STORE_CONST.SET_MODAL: {
			return {
				...state,
				modal: payload
			};
		}
		case EMPLOYEE_STORE_CONST.SET_MODAL_DATA: {
			return {
				...state,
				modalData: payload
			};
		}
		case EMPLOYEE_STORE_CONST.RESET_API_STATE: {
			return {
				...state,
				...initApiState
			};
		}
		case EMPLOYEE_STORE_CONST.UPDATE_SELECTED: {
			const { selectedList, itemList } = handleSelected(state.selectedEmployees, payload);
			const { selectAll } = payload;
			let updateList = state.employeeList.data;
			if (typeof selectAll !== 'undefined') {
				updateList = cloneDeep(itemList);
			}
			return {
				...state,
				selectedEmployees: cloneDeep(selectedList),
				isAllSelected: selectAll,
				employeeList: {
					...state.employeeList,
					data: updateList
				}
			};
		}
		case EMPLOYEE_STORE_CONST.SET_BULK_UP_STATUS: {
			return {
				...state,
				bulkUpStatus: payload,
				dataUpdateCount: state.dataUpdateCount + 1
			};
		}
		case EMPLOYEE_STORE_CONST.SET_INVITE_DATA: {
			return {
				...state,
				inviteData: payload,
				country: payload.country,
				modal: EMPLOYEE_CONSTANTS.ADD_EMP_MANAGER,
				managerAdd: EMPLOYEE_CONSTANTS.INVITE_EMP_MGR,
				modifyData: state.isModify ? payload : state.modifyData,
				...initApiState
			};
		}
		case EMPLOYEE_STORE_CONST.SET_FILTER_COUNT: {
			const { employeeCounts = {} } = payload;
			const filters = formatFilterCounts(employeeCounts, state.filters);
			const { totalFiltered } = employeeCounts;
			return {
				...state,
				filters,
				totalEmployee: totalFiltered
			};
		}
		case EMPLOYEE_STORE_CONST.GET_MANAGERS_LIST: {
			const { managersList = [], key, isLoading = false, isFilterSearch = false } = payload;
			const managerData = managersList;
			if (isFilterSearch) {
				managerData.push({ ...MANAGER_NOT_SET });
			}
			let filters = state.filters;
			if (key) {
				filters = formatFiltersOptions(
					state.filters,
					key,
					managerData
				);
			}
			return {
				...state,
				filters,
				managersLoading: isLoading,
				managerData: managerData
			};
		}
		case EMPLOYEE_STORE_CONST.GET_GROUP_LIST: {
			const { groupList, key } = payload;
			const formattedGroupData = formatGroupData(groupList);
			const filters = key ? formatFiltersOptions(
				state.filters,
				key,
				formattedGroupData
			) : state.filters;
			return {
				...state,
				filters,
				orgGrps: formattedGroupData
			};
		}
		case EMPLOYEE_STORE_CONST.EMP_FILTER_QUERY: {
			const { updatedQuery, updatedFilter = {} } = payload;
			const { key } = updatedFilter;
			let { filters } = state;
			if (key) {
				filters = {
					...filters,
					[key]: updatedFilter
				};
			}
			return {
				...state,
				query: updatedQuery,
				filters,
				employeeList: { ...initialEmployeeList },
				pageNo: 1
			};
		}
		case EMPLOYEE_STORE_CONST.EMP_CLEAR_FILTER: {
			const { updatedFilters, updatedQuery } = payload;
			return {
				...state,
				query: updatedQuery,
				filters: updatedFilters,
				employeeList: { ...initialEmployeeList },
				pageNo: 1
			};
		}
		case EMPLOYEE_STORE_CONST.EMP_UPDATE_SORT: {
			const { updatedSortQuery } = payload;
			return {
				...state,
				sortQuery: updatedSortQuery,
				employeeList: { ...initialEmployeeList },
				pageNo: 1
			};
		}
		case EMPLOYEE_STORE_CONST.EMP_UPDATE_PAGE: {
			return {
				...state,
				pageNo: state.pageNo + 1
			};
		}
		case EMPLOYEE_STORE_CONST.GET_DESIGNATION_LIST: {
			const { designationList, key } = payload;
			const designationData = formatDesignationData(designationList);
			designationData.push({ ...DESIGNATION_NOT_SET });
			const filters = formatFiltersOptions(
				state.filters,
				key,
				designationData
			);
			return {
				...state,
				filters
			};
		}
		case EMPLOYEE_STORE_CONST.OPEN_CHANGE_EMP_PROP: {
			return {
				...state,
				managerAdd: false,
				inviteData: false,
				isModify: false,
				modifyData: false,
				...initApiState,
				...payload
			};
		}
		case successFor(EMPLOYEE_STORE_CONST.BULK_UPDATE_EMP): {
			return {
				...state,
				...popupInitData,
				...initUploadState,
				...payload,
				modal: payload.modal,
				isAllSelected: false,
				selectedEmployees: {},
				refreshEmplist: true,
				dataUpdateCount: state.dataUpdateCount + 1,
				pageNo: 1
			};
		}
		case EMPLOYEE_STORE_CONST.EMPLOYEE_INVITE_SUCCESS: {
			return {
				...state,
				...popupInitData,
				modal: EMPLOYEE_CONSTANTS.EMP_SUCCESS,
				successData: payload ? inviteLinkSuccessModal : inviteSuccessModal,
				successType: payload ? EMPLOYEE_CONSTANTS.INVITE_LINK_EMP : EMPLOYEE_CONSTANTS.INVITE_EMP,
				dataUpdateCount: state.dataUpdateCount + 1
			};
		}

		case EMPLOYEE_STORE_CONST.MODIFY_EMP_DET: {
			return {
				...state,
				modal: EMPLOYEE_CONSTANTS.INVITE_EMP,
				modifyData: payload,
				isModify: true,
				empChangeValue: payload.managers,
				...initApiState
			};
		}

		case EMPLOYEE_STORE_CONST.UPDATE_MODIFY_EMP_DET: {
			return {
				...state,
				modifyData: payload
			};
		}

		case EMPLOYEE_STORE_CONST.RESET_POPUPS_DATA: {
			return {
				...state,
				...popupInitData

			};
		}

		case EMPLOYEE_STORE_CONST.EMPLOYEE_MODIFY_SUCCESS: {
			return {
				...state,
				...popupInitData,
				refreshEmplist: true,
				dataUpdateCount: state.dataUpdateCount + 1,
				pageNo: 1

			};
		}
		case EMPLOYEE_STORE_CONST.EMP_SEARCH_STR: {
			const { empSearchStr = '' } = payload;
			return {
				...state,
				empSearchStr,
				employeeList: { ...initialEmployeeList },
				isAllSelected: false,
				selectedEmployees: {},
				pageNo: 1
			};
		}
		case EMPLOYEE_STORE_CONST.REMINDER_API_CALLED: {
			return {
				...state,
				reminderLoader: true,
				reminderError: false,
				reminderSuccess: false
			};
		}
		case EMPLOYEE_STORE_CONST.REMINDER_SUCCESS: {
			const details = getReminderSettings(action.payload.apiParams);
			return {
				...state,
				reminderSettings: {
					...state.reminderSettings,
					...details
				},
				reminderLoader: false,
				reminderError: false,
				reminderSuccess: true
			};
		}
		case EMPLOYEE_STORE_CONST.REMINDER_FAILED: {
			return {
				...state,
				reminderLoader: false,
				reminderError: APPLICATION_CONSTANTS.API_ERROR,
				reminderSuccess: false
			};
		}
		case EMPLOYEE_STORE_CONST.GET_REMINDER_SUCCESS: {
			const { response, invitedCount } = action.payload;
			return {
				...state,
				reminderSettings: { ...response },
				invitedCount
			};
		}
		case EMPLOYEE_STORE_CONST.VIP_EXCEED: {
			return {
				...state,
				...popupInitData,
				modal: EMPLOYEE_CONSTANTS.EMP_SUCCESS,
				successData: vipLimitModal,
				successType: EMPLOYEE_CONSTANTS.VIP_EXCEED
			};
		}
		case EMPLOYEE_STORE_CONST.DOWNLOAD_CSV_FAIL: {
			const { msg = '' } = payload;
			return {
				...state,
				downloadCsvStatus: {
					...state.downloadCsvStatus,
					msg,
					status: APPLICATION_CONSTANTS.FAILED
				}
			};
		}
		case EMPLOYEE_STORE_CONST.EMP_UPLOAD_STATUS: {
			const { status, percentage, errMsg = false } = payload;
			const isPending = status === APPLICATION_CONSTANTS.PENDING;
			return {
				...state,
				uploadRemainingTime: isPending ? state.uploadRemainingTime : {
					min: 0,
					hr: 0,
					sec: 0
				},
				uploadStatus: {
					...state.uploadStatus,
					status: status,
					errMsg: errMsg,
					percentage: isPending ? percentage : 100
				}
			};
		}
		case EMPLOYEE_STORE_CONST.EMP_UPLOAD_TIME: {
			const { remainingTime } = payload;
			return {
				...state,
				uploadRemainingTime: remainingTime
			};
		}
		case EMPLOYEE_STORE_CONST.EMP_UPLOAD_RESET: {
			return {
				...state,
				uploadRemainingTime: {
					min: 0,
					hr: 0,
					sec: 0
				},
				uploadStatus: {
					status: '',
					percentage: 0
				}
			};
		}
		case EMPLOYEE_STORE_CONST.EMP_FEATURE_DATA: {
			const { feature } = payload;
			return {
				...state,
				feature
			};
		}
		case EMPLOYEE_STORE_CONST.DATA_UPDATE_COUNT: {
			return {
				...state,
				dataUpdateCount: state.dataUpdateCount + 1
			};
		}

		case successFor(EMPLOYEE_STORE_CONST.EMP_DWL_DATA): {
			const email = getProfileDataProps('email') || '';
			return {
				...state,
				modal: EMPLOYEE_CONSTANTS.EMP_SUCCESS,
				successType: EMPLOYEE_CONSTANTS.EMAIL_DWL,
				apiType: '',
				apiStatus: '',
				successData: {
					contentText: `We have detected a bulk download request. Since this is a bulk request we will send you a download link on your email ${email} . After clicking on the link, you will be redirected to our portal where your download will start automatically.`,
					heading: `Download employee csv Will Be Emailed within 15 Minutes`,
					img: '/emailImg.png'
				}
			};
		}

		case successFor(EMPLOYEE_STORE_CONST.GET_UPDATE_STATUS): {
			const { totalCount = state.totalTobeUpdated, remainingCount = 0, jobId = state.jobId, jobStatus, csvLink, message } = payload;
			return {
				...state,
				totalTobeUpdated: totalCount,
				remainingCount: remainingCount,
				jobId,
				updateStatus: {
					csvLink,
					message,
					status: jobStatus
				}
			};
		}

		case EMPLOYEE_STORE_CONST.RESET_JOB_COUNT: {
			const { resetAll = false } = payload;
			let retData = {
				totalTobeUpdated: 0,
				remainingCount: 0
			};
			if (resetAll) {
				retData = initUploadState;
			}
			return {
				...state,
				...retData
			};
		}

		case successFor(EMPLOYEE_STORE_CONST.GET_AB_VALS): {
			return {
				...state,
				typeEnabledOrg: payload
			};
		}
		case successFor(EMPLOYEE_STORE_CONST.FETCH_INVITATION_CHNL): {
			return {
				...state,
				invitationChannels: payload
			};
		}
		case successFor(EMPLOYEE_STORE_CONST.GET_HRMS_STATUS): {
			return {
				...state,
				hrmsStatus: payload
			};
		}

		case (EMPLOYEE_STORE_CONST.GET_HRMS_STATUS): {
			return {
				...state,
				hrmsStatus: { status: APPLICATION_CONSTANTS.LOADING }
			};
		}

		default:
			return state;
	}
};
