// Constants and Config
import { BIZ_HEADER_STORE_CONSTANTS, CALLBACK_ACTION_TYPE } from '../actionConstants';
import {
	getccbAxiosConfig
} from '../../apisSetup/ccbConfig';
import {
	APPLICATION_CONSTANTS,
	PATHS
} from 'AppConstants';
import { claimsAuthTokenApiHeaders, CLAIMS_STATE_CONFIG, CLAIMS_SUCCESS_POPUP_DATA, optInRequest } from '../../bizHeader/config';
import { getAxiosConfig } from '../../apisSetup/apiConfig';

// Util
import {
	saveServerLogs
} from 'UtilityComponents/Util';

// Redux
import { updatePopup } from './popupActions';
import { actionCreator } from 'reduxStore/sagaUtil';

const ccbAxiosApiCaller = getccbAxiosConfig(true);

const axiosApiCaller = getAxiosConfig(PATHS.CCB_URL, true, {
}, false);

export const setServerHeaderData = (payload) => ({
	type: BIZ_HEADER_STORE_CONSTANTS.SET_SERVER_DATA,
	payload
});

export const nextFeatureDesc = (payload, isClose) => {
	if (isClose === true) {
		return skipFeatureDesc();
	}
	return {
		type: BIZ_HEADER_STORE_CONSTANTS.BIZ_HEADER_NEXT_FEATURE,
		payload: payload
	};
};

export const skipFeatureDesc = () => ({
	type: BIZ_HEADER_STORE_CONSTANTS.BIZ_HEADER_SKIP_FEATURE
});

export const updateHeaderOptions = (payload) => async (dispatch) => {
	dispatch({
		type: BIZ_HEADER_STORE_CONSTANTS.UPDATE_HEADER_OPTS,
		payload
	});
};

export const getHeaderOptions = () => async (dispatch) => {
	let response = null;
	dispatch({
		type: BIZ_HEADER_STORE_CONSTANTS.GET_HEADER_OPTS_CALLING
	});
	try {
		response = await ccbAxiosApiCaller.get(PATHS.GET_HEADER_OPTS);
		if (response.data && response.data.responseCode === '600') {
			dispatch({
				type: BIZ_HEADER_STORE_CONSTANTS.GET_HEADER_OPTS_SUCCESS,
				payload: response.data
			});
		}
	} catch (e) {
		dispatch({
			type: BIZ_HEADER_STORE_CONSTANTS.GET_HEADER_OPTS_FAILURE
		});
		saveServerLogs(e.stack, 'error', PATHS.GET_HEADER_OPTS);
		return;
	}
};

export const getProfile = () => async (dispatch) => {
	let response = null;
	/* dispatch({
		type: BIZ_HEADER_STORE_CONSTANTS.GET_PROFILE_CALLING
	}); */
	try {
		response = await ccbAxiosApiCaller.get(PATHS.GET_PROFILE);
		if (response.data && response.data.responseCode === '600') {
			dispatch({
				type: BIZ_HEADER_STORE_CONSTANTS.GET_PROFILE_SUCCESS,
				payload: response.data
			});
		}
	} catch (e) {
		dispatch({
			type: BIZ_HEADER_STORE_CONSTANTS.GET_PROFILE_FAILURE
		});
		saveServerLogs(e.stack, 'error', PATHS.GET_PROFILE_FAILURE);
		return;
	}
};

export const getCallbackSupport = (isHeader = false) => async (dispatch) => {
	let response = null;
	dispatch({
		type: CALLBACK_ACTION_TYPE.GET_CALLBACK_SUPPORT_CALLING
	});
	try {
		response = await ccbAxiosApiCaller.get(PATHS.GET_CALLBACK_SUPPORT);
		if (response.data && response.data.responseCode === '600') {
			if (!response.data.showPopUp && isHeader) {
				window.location = PATHS.CALL_SUPPORT_URL;
			} else {
				dispatch({
					type: CALLBACK_ACTION_TYPE.GET_CALLBACK_SUPPORT_SUCCESS,
					payload: { response: response.data, isHeader }
				});
			}
		}
	} catch (e) {
		dispatch({
			type: CALLBACK_ACTION_TYPE.GET_CALLBACK_SUPPORT_FAILURE
		});
		saveServerLogs(e.stack, 'error', PATHS.GET_CALLBACK_SUPPORT);
		return;
	}
};

export const getRequestCallback = (apiRequest, url) => async (dispatch) => {
	let response = null;
	const pathUrl = url ? url : PATHS.GET_REQUEST_CALLBACK;
	dispatch({
		type: CALLBACK_ACTION_TYPE.REQUEST_CALLBACK
	});
	try {
		response = await ccbAxiosApiCaller.post(pathUrl, apiRequest);
		dispatch({
			type: CALLBACK_ACTION_TYPE.REQUEST_CALLBACK_SUCCESS,
			payload: response.data
		});
	} catch (e) {
		dispatch({
			type: CALLBACK_ACTION_TYPE.REQUEST_CALLBACK_FAILURE
		});
		saveServerLogs(e.stack, 'error', pathUrl);
	}
};

export const handlingModalOpeningClosing = (payload) => (dispatch) => {
	dispatch({
		type: CALLBACK_ACTION_TYPE.CALLBACK_MODAL_DATA,
		payload
	});
};

export const headerUpdate = () => ({
	type: BIZ_HEADER_STORE_CONSTANTS.GET_HEADER_OPTS_SUCCESS
});

export const updateOrgSuccess = (payload) => ({
	type: BIZ_HEADER_STORE_CONSTANTS.UPDATE_ORG_SUCCESS,
	payload
});

export const claimsApiLoading = actionCreator(BIZ_HEADER_STORE_CONSTANTS.SET_LOADING);

export const claimsOptIn = (tneStatus) => async (dispatch) => {
	let response = null;
	let isError = false;
	const apiPath = tneStatus === CLAIMS_STATE_CONFIG.NOT_DONE ? PATHS.POST_CLAIMS_OPT_IN : PATHS.GET_CLAIMS_AUTH_TOKEN;
	try {
		dispatch(claimsApiLoading({ loader: true }));
		response = tneStatus === CLAIMS_STATE_CONFIG.NOT_DONE ? await axiosApiCaller.post(apiPath, optInRequest) :
			await axiosApiCaller.get(apiPath, {
				headers: claimsAuthTokenApiHeaders
			});
		const responseData = response && response.data;
		if (responseData?.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			if (tneStatus === CLAIMS_STATE_CONFIG.NOT_DONE) {
				dispatch(updatePopup({
					showPop: true,
					popData: {
						...CLAIMS_SUCCESS_POPUP_DATA,
						redirectionUrl: window.location.href
					}

				}));
			} else if (tneStatus === CLAIMS_STATE_CONFIG.DONE && responseData.redirectionUrl) {
				window.open(responseData.redirectionUrl);
			}
		} else {
			isError = true;
		}
	} catch (e) {
		isError = true;
		saveServerLogs(e.stack, 'error', apiPath);
	} finally {
		dispatch(claimsApiLoading({ loader: false }));
		if (isError) {
			dispatch(updatePopup({
				showPop: true,
				isFooter: false
			}));
		}
	}
};

/**
 * FetchMSRRedirectionUrl
 *
 * @author - MMT11933
 * @description - Asynchronously fetches the MSR (Member Service Request) redirection URL based on the provided payload.
 * This function is an action creator that returns a thunk function. The thunk function can be dispatched
 * to the Redux store and is capable of dispatching other actions based on the result of the asynchronous
 * operation (e.g., fetching the URL).
 * @param {Object} payload - The payload containing necessary data for fetching the MSR redirection URL.
 * @return {Function} A thunk function that takes the dispatch function as an argument and performs the asynchronous operation.
 */
export const fetchMSRRedirectionUrl = (payload) => async (dispatch) => {
	let response = null;
	let errorData = null;
	const apiUrl = PATHS.CCB_URL + PATHS.GET_MSR_DETAILS;

	dispatch({ type: BIZ_HEADER_STORE_CONSTANTS.FETCH_MSR_DETAILS_REQUEST });

	try {
		response = await axiosApiCaller.post(apiUrl, payload);
		const { responseCode, redirectionUrl, message } = response?.data;
		if (responseCode !== APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE && !redirectionUrl) {
			// eslint-disable-next-line no-throw-literal
			throw { message, responseCode };
		}
	} catch (error) {
		errorData = error;
	} finally {
		if (!errorData) {
			dispatch({
				type: BIZ_HEADER_STORE_CONSTANTS.FETCH_MSR_DETAILS_UPDATE,
				payload: { scenario: APPLICATION_CONSTANTS.SUCCESS }
			});
			window.location.href = response?.data?.redirectionUrl;
		} else {
			dispatch({
				type: BIZ_HEADER_STORE_CONSTANTS.FETCH_MSR_DETAILS_UPDATE,
				payload: { error: errorData, scenario: APPLICATION_CONSTANTS.FAILED }
			});
			saveServerLogs(errorData.stack, 'error', apiUrl);
		}
	}
};

/**
 * ResetMSRDetails
 *
 * @author - MMT11933
 * @description -Action creator for resetting the MSR (Member Service Request) details in the Redux store.
 * This function returns an action object that, when dispatched, triggers the reducer to reset
 * the state related to MSR details to its initial state.
 * @return {Object} An action object with a type property indicating the action to reset MSR details.
 */
export const resetMSRDetails = () => ({
	type: BIZ_HEADER_STORE_CONSTANTS.FETCH_MSR_DETAILS_RESET
});
