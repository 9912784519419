import { PATHS } from 'AppConstants';

export const LOGIN_B2C = 'LOGIN_B2C';
export const LOGIN_SSO = 'LOGIN_SSO';
export const LOGIN_PASS_EXPIRE = 'LOGIN_PASS_EXPIRE';
export const LOGIN_CONTINUE = 'LOGIN_CONTINUE';
export const RESET_LOGGING = 'RESET_LOGGING';
export const LOGIN_PROCEED_TO_URL = 'LOGIN_PROCEED_TO_URL';
export const PROCEED_TO_PROVISIONAL_DASHBOARD = 'PROCEED_TO_PROVISIONAL_DASHBOARD';
export const SHOW_LOGIN_POPUP = 'SHOW_LOGIN_POPUP';
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const LOGIN_PROGRESS = 'LOGIN_PROGRESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const INVALID_EMAIL_FORMAT = 'INVALID_EMAIL_FORMAT';
export const SSO_USER_LOGIN_AT_NORMAL_FORM = 'SSO_USER_LOGIN_AT_NORMAL_FORM';
export const PASSWORD_EXPIRED_USER = 'PASSWORD_EXPIRED_USER';
export const INVALID_EMAIL = 'INVALID_EMAIL';
export const PRE_VALIDATION_FAILED = 'PRE_VALIDATION_FAILED';
export const PRE_VALIDATION_SUCCESS = 'PRE_VALIDATION_SUCCESS';
export const PASSWORD_LENGTH_IS_INSUFFICIENT = 'PASSWORD_LENGTH_IS_INSUFFICIENT';
export const OFF_LOADING = 'OFF_LOADING';
export const ON_LOADING = 'ON_LOADING';
export const LOGIN_PROCEED_TO_DASHBOARD = 'LOGIN_PROCEED_TO_DASHBOARD';
export const LOGIN_FAILED_NW_ERROR = 'Login failed, please retry after sometime';
export const MONTH = 'month';
export const DAY = 'day';
export const YEAR = 'year';
export const WEEKLY = 'weekly';
export const WEEK = 'week';
export const MONTHLY = 'monthly';
export const QUARTERLY = 'quarterly';
export const YEARLY = 'yearly';
export const MORE_OPTIONS = 'More options';

const GENERIC_NW_CALL_STATES = {
	CALL_FAILED: 'CALL_FAILED',
	CALL_IN_PROGRESS: 'CALL_IN_PROGRESS',
	CALL_DONE: 'CALL_DONE',
	CALL_SUCCESS: 'CALL_SUCCESS',
	NW_ERROR: 'NW_ERROR',
	SERVER_ERROR: 'SERVER_ERROR',
	CLEAR_STATE: 'CLEAR_STATE'
};

export const SCREEN_LOGIN = {
	CALL_SUCCESS_NONADMIN: 'CALL_SUCCESS_NONADMIN'
};

// IMPLEMENT SCREEN WISE ACTIONS AND CORRESPONDING ERROR MESSAGES
// SO THAT IT IS EASY TO WRITE TEST CASES
export const SCREEN_FGT_PWD = {
	CALL_FAILED: 'FGT_PWD_CALL_FAILED',
	CALL_IN_PROGRESS: 'FGT_PWD_CALL_IN_PROGRESS',
	CALL_DONE: 'FGT_PWD_CALL_DONE',
	CALL_SUCCESS: 'FGT_PWD_CALL_SUCCESS',
	NW_ERROR: 'FGT_PWD_NW_ERROR',
	SERVER_ERROR: 'FGT_PWD_SERVER_ERROR',
	CLEAR_STATE: 'FGT_PWD_CLEAR_STATE',
	SSO_USER_FORGOT_PASSWORD: 'SSO_USER_FORGOT_PASSWORD',
	FORGOT_PWD_INVALID_EMAIL_FORMAT: 'FORGOT_PWD_INVALID_EMAIL_FORMAT',
	FORGOT_PWD_INVALID_EMAIL: 'FORGOT_PWD_INVALID_EMAIL',
	SSO_USER_RESET_PASSWORD_ERROR: 'SSO_USER_RESET_PASSWORD_ERROR',
	ERR_MSGS: {
		FORGOT_PWD_INVALID_EMAIL_FORMAT: 'Invalid email format',
		FORGOT_PWD_INVALID_EMAIL: 'Invalid email',
		SSO_USER_RESET_PASSWORD_ERROR_MSG: 'Please use the Company SSO portal to reset password.'
	}
};

export const SCREEN_VERIFY_EMAIL = {
	CALL_FAILED: 'VERIFY_CALL_FAILED',
	CALL_IN_PROGRESS: 'VERIFY_CALL_IN_PROGRESS',
	CALL_DONE: 'VERIFY_CALL_DONE',
	CALL_SUCCESS: 'VERIFY_CALL_SUCCESS',
	NW_ERROR: 'VERIFY_NW_ERROR',
	SERVER_ERROR: 'VERIFY_SERVER_ERROR',
	CLEAR_STATE: 'VERIFY_CLEAR_STATE',
	GO_TO_VERIFY: 'GO_TO_VERIFY',
	NEXT_CLICKED: 'NEXT_CLICKED',
	ADMIN_LOGGED_IN: 'ADMIN_LOGGED_IN',
	NON_ADMIN_LOGGED_IN: 'NON_ADMIN_LOGGED_IN',
	EMAIL_SENT: 'EMAIL_SENT',
	NOT_VERIFIED: 'NOT_VERIFIED'
};

export const STATES = {
	STARTED: 'STARTED',
	PROGRESS: 'PROGRESS',
	ERROR: 'ERROR',
	FAILED: 'FAILED',
	DONE: 'DONE',
	B2C: 'B2C',
	NO_ACTION: 'NO_ACTION',
	SSO_USER: 'SSO_USER',
	PASSWORD_EXPIRED: 'PASSWORD_EXPIRED'
};
export const ERR_MSGS = {
	INVALID_EMAIL_FORMAT: 'Invalid email format',
	PASSWORD_LENGTH_IS_INSUFFICIENT: 'Password length insufficient',
	LOGIN_FAILED: 'Login failed , please re-check email and password'
};
export const SSO_CONST = {
	ERROR_INVALID_MAIL: 'invalid_email',
	ERROR_MULTI_ORG: 'multi_org',
	ERROR_NO_SAML: 'no_saml',
	ERROR_EMP_DISABLED: 'emp_disabled',
	ERROR_NO_ORG: 'no_org',
	ERROR_NAME_EMPTY: 'emp_name_empty',
	ERROR_CREDENTIALS_FAILED: 'emp_cre_failed',
	ERROR_NO_USER_DATA: 'emp_no_user_data'
};

export const SSO_ERR_CONFIGS = {
	[SSO_CONST.ERROR_INVALID_MAIL]: {
		reasonString: 'Please enter a valid email id',
		errorLink: PATHS.SSO_LOGIN,
		errorLinkText: 'Try again'
	},
	[SSO_CONST.ERROR_MULTI_ORG]: {
		reasonString: 'Multiple organizations exists under this domain, please contact your admin to get an invite.',
		errorLink: PATHS.MYBIZ_API_URL,
		errorLinkText: ''
	},
	[SSO_CONST.ERROR_NO_SAML]: {
		reasonString: 'Single signon (SSO Company login) is not configured for your company.',
		errorLink: '//makemytrip.viewpage.co/SSO-registration-page',
		errorLinkText: ''
	},
	[SSO_CONST.ERROR_EMP_DISABLED]: {
		reasonString: 'Your email has been disabled, please contact your admin to enable it.',
		errorLink: PATHS.MYBIZ_API_URL,
		errorLinkText: ''
	},
	[SSO_CONST.ERROR_NO_ORG]: {
		reasonString: 'Your organisation is currently not registered with myBiz',
		errorLink: PATHS.MYBIZ_API_URL,
		errorLinkText: ''
	},
	[SSO_CONST.ERROR_NAME_EMPTY]: {
		reasonString: 'Employee name not found.',
		errorLink: PATHS.MYBIZ_API_URL,
		errorLinkText: ''
	},
	[SSO_CONST.ERROR_CREDENTIALS_FAILED]: {
		reasonString: 'Employee signup failed.',
		errorLink: PATHS.MYBIZ_API_URL,
		errorLinkText: ''
	},
	[SSO_CONST.ERROR_NO_USER_DATA]: {
		reasonString: 'Employee not configured properly',
		errorLink: PATHS.MYBIZ_API_URL,
		errorLinkText: ''
	}
};

export const HCS_CONST = {
	PAYLOAD: 'HCS_PAYLOAD',
	RESET: 'HCS_RESET',
	PERSONAL_CLICKED: 'HCS_PERSONAL_CLICKED',
	LOGOUT_CLICKED: 'HCS_LOGOUT_CLICKED',
	ASK_FOR_USER_CONFIRMATION: 'HCS_ASK_FOR_USER_CONFIRMATION',
	USERINFO_SUCCESS: 'HCS_USERINFO_SUCCESS',
	LOGIN_IN_PROGRESS: 'HCS_LOGIN_IN_PROGRESS',
	LOGIN_SUCCESS: 'HCS_LOGIN_IN_PROGRESS',
	LOGIN_FAILED: 'HCS_LOGIN_IN_PROGRESS',
	LOGOUT_IN_PROGRESS: 'HCS_LOGOUT_IN_PROGRESS',
	LOGOUT_SUCCESS: 'HCS_LOGOUT_SUCCESS',
	LOGOUT_FAILED: 'HCS_LOGOUT_FAILED',
	SWITCH_IN_PROGRESS: 'HCS_SWITCH_IN_PROGRESS',
	SWITCH_SUCCESS: 'HCS_SWITCH_SUCCESS',
	SWITCH_FAILED: 'HCS_SWITCH_FAILED',
	SHOW_SUCCESS_POPUP: 'HCS_SHOW_SUCCESS_POPUP',
	SHOW_FAILED_POPUP: 'HCS_SHOW_FAILED_POPUP',
	MSG_LOGOUT_SUCCESS: 'Corporate profile is logged out',
	MSG_TAKE_USER_CONFIRMATION: 'You will have to logout from your myBiz account and login into your personal account in order to switch.',
	MSG_LOGOUT_FAILED: 'Logout failed , please retry after sometime'
};
export const DASHBOARD_CONST = {
	PAYLOAD: 'DASHBOARD_PAYLOAD',
	RESET: 'DASHBOARD_RESET',
	CALL_IN_PROGRESS: 'DASHBOARD_CALL_IN_PROGRESS',
	CALL_IN_SUCCESS: 'DASHBOARD_CALL_SUCCESS',
	CALL_FAILED: 'DASHBOARD_CALL_FAILED',
	CALL_NW_ERROR: 'DASHBOARD_CALL_NW_ERROR',
	LAST_WEEK: 'DASHBOARD_LAST_WEEK',
	LAST_MONTH: 'DASHBOARD_LAST_MONTH',
	LAST_QUARTER: 'DASHBOARD_LAST_QUARTER',
	LAST_YEAR: 'DASHBOARD_LAST_YEAR',
	THIS_WEEK: 'DASHBOARD_THIS_WEEK',
	THIS_MONTH: 'DASHBOARD_THIS_MONTH',
	THIS_QUARTER: 'DASHBOARD_THIS_QUARTER',
	THIS_YEAR: 'DASHBOARD_THIS_YEAR',
	SAVINGS_CALL_IN_PROGRESS: 'DASHBOARD_ SAVINGS_CALL_IN_PROGRESS',
	SAVINGS_CALL_IN_SUCCESS: 'DASHBOARD_ SAVINGS_CALL_SUCCESS',
	SAVINGS_CALL_FAILED: 'DASHBOARD_ SAVINGS_CALL_FAILED',
	SAVINGS_CALL_NW_ERROR: 'DASHBOARD_ SAVINGS_CALL_NW_ERROR',
	BANNER_CALL_IN_PROGRESS: 'DASHBOARD_ BANNER_CALL_IN_PROGRESS',
	BANNER_CALL_IN_SUCCESS: 'DASHBOARD_ BANNER_CALL_SUCCESS',
	BANNER_CALL_FAILED: 'DASHBOARD_ BANNER_CALL_FAILED',
	BANNER_CALL_NW_ERROR: 'DASHBOARD_ BANNER_CALL_NW_ERROR',
	ORG_CALL_IN_PROGRESS: 'ORG_CALL_IN_PROGRESS',
	ORG_CALL_IN_SUCCESS: 'ORG_CALL_IN_SUCCESS',
	ORG_CALL_FAILED: 'ORG_CALL_FAILED',
	ORG_CALL_NW_ERROR: 'ORG_CALL_NW_ERROR',
	WALLET_CALL_IN_SUCCESS: 'WALLET_CALL_IN_SUCCESS',
	WALLET_CALL_IN_PROGRESS: 'WALLET_CALL_IN_PROGRESS',
	WALLET_CALL_FAILED: 'WALLET_CALL_FAILED',
	WALLET_CALL_NW_ERROR: 'WALLET_CALL_NW_ERROR',
	CONFIG_CALL_IN_PROGRESS: 'DASHBOARD_ CONFIG_CALL_IN_PROGRESS',
	CONFIG_CALL_IN_SUCCESS: 'DASHBOARD_ CONFIG_CALL_SUCCESS',
	CONFIG_CALL_FAILED: 'DASHBOARD_ CONFIG_CALL_FAILED',
	CONFIG_CALL_NW_ERROR: 'DASHBOARD_ CONFIG_CALL_NW_ERROR',
	CARD_TYPE_TOTAL_BOOKINGS: 'total_bookings',
	CARD_TYPE_TOTAL_SPENDS: 'total_spends',
	CARD_TYPE_TOTAL_AP_SPENDS: 'ap_stats',
	CARD_TYPE_TOP_HOTELS: 'top_hotels',
	CARD_TYPE_TOP_FLIGHTS: 'top_airlines',
	CARD_TYPE_TOP_TRAVELLERS: 'top_travellers',
	CARD_TYPE_WALLET_BOOKINGS: 'wallet_bookings',
	CARD_TYPE_TOTAL_SAVINGS: 'total_savings',
	CARD_TYPE_FREQUENT_TRAVELLER: 'frequent_traveler',
	CARD_TYPE_GROUP_NAME: 'group_name',
	DATEFORMAT: 'YYYY-MM-DD',
	DATEFORMAT_REPORT: 'DD-MM-YYYY',
	DASHBOARD_BANNER: 'DASHBOARD_BANNER',
	PAGE_NAME: 'dashboard:landing_new_v2'
};

export const LANDING_SIGNUP_PAGE_CONST = {
	UploadOfAllFilesCompleted: 'uploadFinished',
	UPLOAD_IN_PROGRESS: 'UploadProgress',
	UPLOAD_FILE_DONE: 'uploadDone',
	UPLOAD_FILE_FAILED: 'uploadFailed',
	EMAIL_CAMPAIGN_SAVE_API_FAILED: 'EMAIL_CAMPAIGN_SAVE_API_FAILED',
	DECISION_API_FAILED: 'DECISION_API_FAILED',
	DECISION_API_SUCCESS: 'DECISION_API_SUCCESS',
	ACCOUNT_UPGRADE_SIGN_UP_FLOW: 'ACCOUNT-UPGRADE',
	USER_CREATED_FROM_INVITE_SIGN_UP_FLOW: 'USER_CREATED_FROM_INVITE',
	USER_CREATED_VERIFICATION_MAIL_SENT: 'USER_CREATED_VERIFICATION_MAIL_SENT',
	USER_EXISTS_SIGN_UP_WORK: 'USER_ALREADY_EXISTS',
	NON_WORK_EMAIL_IN_WORK_API: 'NON_WORK_DOMAIN_USER',
	SSO_USER_IN_WORK_SIGNUP: 'SSO_USER_IN_WORK_SIGNUP',
	SELF_SIGNUP_NOT_ALLOWED_WORK: 'SELF_SIGNUP_NOT_ALLOWED',
	UAE_WORK: 'UAE_WORK',
	NWE_WORK: 'NWE_WORK',
	SSO_WORK: 'SSO_WORK',
	SIGN_UP_WORK_UNKNOWN_ERROR_CODE: 'BACKEND_UNKNOWN_CODE',
	NETWORK_FAILED_WORK: 'NETWORK_FAILED_WORK',
	BACKEND_UNKNOWN_FAILURE: 'BACKEND_UNKNOWN_FAILURE',
	BACKEND_FAILED_IN_SIGN_UP_REQUEST: 'BACKEND-FAILED',
	BACKEND_FAILED_NON_WORK: 'BACKEND_FAILED_NON_WORK',
	USER_ALREADY_EXISTS_NON_WORK: 'USER_ALREADY_EXISTS_NON_WORK',
	GSTN_OR_PAN_INVALID_NON_WORK: 'GSTN_OR_PAN_INVALID_NON_WORK',
	EMAIL_NOT_ALLOWED_WORK_NON_WORK: 'EMAIL_NOT_ALLOWED_WORK_NON_WORK',
	NON_WORK_SIGNUP_SUCCESS: 'NON_WORK_SIGNUP_SUCCESS',
	VERIFICATION_MAIL_SENT_SUCCESS: 'MAIL_SENT_SUCCESS',
	VERIFICATION_MAIL_SENT_FAILED: 'MAIL_SENT_FAILED',
	REVIEW_API_SUCCESS: 'REVIEW_API_SUCCESS',
	REVIEW_API_FAILURE: 'REVIEW_API_FAILURE',
	DOCUMENT_CHANGE: 'DOCUMENT_CHANGED',
	RESET_DOCUMENTS: 'RESET_DOCUMENTS',
	INVITE_FLOW: 'INVITE_FLOW',
	VERIFICATION_MAIL_FLOW: 'VERIFICATION_MAIL_FLOW',
	STATUS_VERIFIED: 'VERIFIED',
	STATUS_PENDING: 'PENDING_VERIFICATION',
	STATUS_DISABLED: 'DISABLED',
	NETWORK_ERROR_SIGNUP_WORK: 'NETWORK_ERROR_SIGNUP_WORK',
	ACCOUNT_UPGRADE_FLOW: 'ACCOUNT_UPGRADE_FLOW',
	NON_WORK_UAE: 'NON_WORK_UAE',
	NON_WORK_GST_PAN_INVALID: 'NON_WORK_GST_PAN_INVALID',
	EMAIL_NOT_ALLOWED: 'EMAIL_NOT_ALLOWED',
	NETWORK_ERROR_SIGNUP_NON_WORK: 'NETWORK_ERROR_SIGNUP_NON_WORK',
	BACKEND_FAILED_NW: 'BACKEND_FAILED_NW',
	NETWORK_FAILED_NON_WORK: 'NETWORK_FAILED_NON_WORK',
	NON_WORK_SUCCESS: 'NON_WORK_SUCCESS',
	REFER_ADMIN_SUCCESS: 'REFER_ADMIN_SUCCESS',
	REFER_ADMIN_FAILURE: 'REFER_ADMIN_FAILURE',
	UPLOAD_COMPANY_DATA_NETWORK_FAILED: 'UPLOAD_COMPANY_DATA_NETWORK_FAILED',
	UPLOAD_COMPANY_DATA_SUCCESS: 'UPLOAD_COMPANY_DATA_SUCCESS',
	UPLOAD_COMPANY_DATA_BACKEND_FAILURE: 'UPLOAD_COMPANY_DATA_BACKEND_FAILURE',
	REFER_ADMIN_NETWORK_FAILURE: 'REFER_ADMIN_NETWORK_FAILURE',
	REQUEST_ADMIN_NETWORK_FAILURE: 'REQUEST_ADMIN_NETWORK_FAILURE',
	REQUEST_ADMIN_SUCCESS: 'REQUEST_ADMIN_SUCCESS',
	REQUEST_ADMIN__FAILURE: 'REQUEST_ADMIN__FAILURE',
	UPLOAD_DOC_REQUEST_NETWORK_FAILURE: 'UPLOAD_DOC_REQUEST_NETWORK_FAILURE',
	UPLOAD_DOC_REQUEST_SUCCESS: 'UPLOAD_DOC_REQUEST_SUCCESS',
	UPLOAD_DOC_REQUEST_BACKEND_FAILURE: 'UPLOAD_DOC_REQUEST_BACKEND_FAILURE',
	UPLOAD_FILE_SKIPED: 'UPLOAD_FILE_SKIPED',
	HIDE_POPUP: 'HIDE_POPUP',
	EDIT_POPUP: 'EDIT_POPUP',
	SHOW_POPUP: 'SHOW_POPUP',
	SSO_USER: 'showSSODomainPopup',
	NON_WORK_DOMAIN: 'showNonWorkDomainPopUp',
	MULTI_DOMAIN_ORG: 'signUpNotAllowedPopUp',
	NON_VERIFIED_USER: 'verificationMailPage',
	EXISTING_USER: 'login',
	ALREADY_EXISTS: 'ACCOUNT_ALREADY_EXISTS',
	SELF_SIGNUP_NOT_ALLOWED: 'SELF_SIGNUP_NOT_ALLOWED',
	NON_WORK_EMAIL: 'NON_WORK_EMAIL',
	SSO_EMAIL: 'SSO_EMAIL',
	NEXT_SIGNUP_PAGE: 'signUpPage',
	DISABLED_USER_SIGNUP: 'DISABLED_USER_SIGNUP',
	COMPANY_DETAILS_NORMAL: 'companyDetailsFormNormal',
	COMPANY_DETAILS_EXISTING: 'companyDetailsPageWithOrgData',
	REFER_ADMIN: 'referAdminPage',
	THANK_YOU: 'thankYouPage',
	DOC_TYPE_NORMAL: 'documentTypePage',
	DOC_TYPE_REQUEST_ADMIN: 'documentTypePageWithRequestAdminFeature',
	LANDING_PAGE: 'landing',
	UPLOAD_COMPANY_DATA_FAILED: 'orgMetaApiFailed',
	OUT_OF_FLOW_EXCEPTION: 'OUT_OF_FLOW_EXCEPTION',
	REFER_ADMIN_API_FAILED: 'referAdminApiFailed',
	UPLOAD_DOC_REQUEST_API_FAILED: 'saveDocumentTypeApiFailed',
	UPLOAD_DOC_API_FAILED: 'saveDocumentTypeApiFailed',
	SHOW_LOADER: 'SHOW_LOADER',
	HIDE_LOADER: 'HIDE_LOADER',
	RESET_COMPANY_DETAIL_API_STATUS: 'RESET_COMPANY_DETAIL_API_STATUS',
	RESET_DOC_REQ_API_STATUS: 'RESET_DOC_REQ_API_STATUS',
	RESET_DOC_UPLOAD_API_STATUS: 'RESET_DOC_UPLOAD_API_STATUS',
	RESET_REFER_ADMIN_API_STATUS: 'RESET_REFER_ADMIN_API_STATUS',
	VERIFICATION_MAIL_SENT_FAILED_NE: 'VERIFICATION_MAIL_SENT_FAILED_NE',
	ASYNC_NON_WORK_SIGNUP_FAILED: 'ASYNC_NON_WORK_SIGNUP_FAILED',
	B2C_LOGIN_FAILED: 'B2C_LOGIN_FAILED',
	B2C_LOGIN_SUCCESS: 'B2C_LOGIN_SUCCESS',
	INCORRECT_PASSWORD: 'incorrect_pwd',
	CHECK_STATUS_FAILED: 'CHECK_STATUS_FAILED',
	CHECK_STATUS_SUCCESS: 'CHECK_STATUS_SUCCESS',
	RESET_CHECK_STATUS: 'RESET_CHECK_STATUS',
	EMPLOYEE_STATUS_POPUP: 'EMPLOYEE_STATUS_POPUP',
	DISABLED_USER: 'DISABLED_USER',
	REQUEST_DEMO_SUCCESS: 'REQUEST_DEMO_SUCCESS',
	REQUEST_DEMO_API_FAILED: 'REQUEST_DEMO_API_FAILED',
	RESET_REQUEST_DEMO_API_STATE: 'RESET_REQUEST_DEMO_API_STATE',
	FILE_SIZE_ERROR: 'FILE_SIZE_ERROR',
	RESET_REQUEST_ADMIN_API_STATUS: 'RESET_REQUEST_ADMIN_API_STATUS',
	RESET_SIGNUP_API_RESPONSE: 'RESET_SIGNUP_API_RESPONSE',
	UNSET_GST_PAN_RESET: 'UNSET_GST_PAN_RESET',
	SET_GST_PAN_RESET: 'SET_GST_PAN_RESET',
	RESET_VERIFICATION_MAIL_API_STATUS: 'RESET_VERIFICATION_MAIL_API_STATUS',
	INVALID_OTP: 'INVALID_OTP',
	SET_OTP: 'SET_OTP',
	UPDATE_POPUP: 'UPDATE_POPUP',
	UPDATE_OTP: 'UPDATE_OTP',
	UPDATE_PASSWORD: 'UPDATE_PASSWORD',
	CHANGE_EMAIL: 'changeEmail',
	OTP: 'otp',
	RESET_PASSWORD: 'resetPassword',
	LOGIN_WITH_EXISTING: 'loginWithExisting',
	SET_ERROR_STATE: 'SET_ERROR_STATE',
	ERROR_MESSAGE: 'We are not able to process your request.',
	ERROR_COLOR_CODE: '#eb2026',
	FORBIDDEN_STATUS_CODE: 403,
	UNAUTHORIZED_STATUS_CODE: 401,
	SAME_PASSWORD_ERROR_CODE: 5407,
	PASSWORD_FAILED_ERROR_CODE: 5401,
	PASSWORD_EXPIRED_ERROR_CODE: 5408,
	MMT_AUTH_TIME: 1800,
	OTP_INCORRECT: 'otp_incorrect',
	OTP_CORRECT: 'otp_correct',
	PASSWORD_RESETTED: 'password_resetted',
	SAME_PASSWORD_RESET: 'same_pwd_reset',
	GENERIC_ERROR: 'generic_error',
	POPUP_CLOSED: 'popup_closed',
	RESEND_OTP: 'resend_otp',
	PASSWORD_RESET_CLICKED: 'password_reset_clicked',
	LOGIN_CLICKED: 'login_clicked',
	SHOW_LOGIN_WITH_EXISTING: 'SHOW_LOGIN_WITH_EXISTING',
	PROCESSING: 'PROCESSING',
	ACCOUNT_LOCK: 'ACCOUNT_LOCK',
	RESET_PASSWORD_LOGIN_FAIL: 'RESET_PASSWORD_LOGIN_FAIL',
	POPUP_LOGIN_CLICKED: 'popup_login_clicked',
	GENERIC_MODAL: 'genericModal',
	SAVE_FORM_DETAILS: 'SAVE_FORM_DETAILS',
	REQUEST_DEMO_PAGE: 'REQUEST_DEMO_PAGE',
	REQUIRED_VALIDATION: 'REQUIRED_VALIDATION',
	GST_ADDED: 'GST_ADDED',
	GST_DATA_BACKEND_FAILURE: 'GST_DATA_BACKEND_FAILURE',
	SET_DATA_NETWORK_FAILED: 'SET_DATA_NETWORK_FAILED',
	SET_DATA_BACKEND_FAILURE: 'SET_DATA_BACKEND_FAILURE',
	SET_COMPANY_DATA_SUCCESS: 'SET_COMPANY_DATA_SUCCESS',
	SET_V3_AB: 'SET_V3_AB',
	RESET_DECISION_DATA: 'RESET_DECISION_DATA',
	UPDATE_B2C_USER_DETAILS: 'UPDATE_B2C_USER_DETAILS',
	EXISTING_SSO_USER: 'EXISTING_SSO_USER',
	ALREADY_EXISTS_SSO: 'ACCOUNT_ALREADY_EXISTS_SSO',
	AB_META_VALUES: 'AB_META_VALUES'
};

export const PROVISIONAL_ADMIN_CONSTANTS = {
	DOC_STATUS_API_FAILURE: 'DOC_STATUS_API_FAILURE',
	DOC_STATUS_API_SUCCESS: 'DOC_STATUS_API_SUCCESS',
	DOC_STATUS_API_NET_FAILURE: 'DOC_STATUS_API_NET_FAILURE',
	CHECK_AUTH_FOR_PROVISIONAL_ADMIN_API_FAILED: 'CHECK_AUTH_FOR_PROVISIONAL_ADMIN_API_FAILED',
	CHECK_AUTH_FOR_PROVISIONAL_ADMIN_API_SUCCESS: 'CHECK_AUTH_FOR_PROVISIONAL_ADMIN_API_SUCCESS',
	HIDE_POPUP: 'HIDE_POPUP',
	SHOW_POPUP: 'SHOW_POPUP',
	SHOW_LOADER: 'SHOW_LOADER',
	HIDE_LOADER: 'HIDE_LOADER',
	DOCUMENT_VERIFICATION_PENDING: 'DOCUMENT_VERIFICATION_PENDING',
	DOCUMENT_NOT_SUBMITTED: 'DOCUMENT_NOT_SUBMITTED',
	DOCUMENT_VERIFIED: 'DOCUMENT_VERIFIED',
	FLUSH_STATE: 'FLUSH_STATE',
	AUTH_FAILURE: 'AUTH_FAILURE',
	DOC_UPLOAD_SUCCESS: 'DOC_UPLOAD_SUCCESS',
	REQUEST_SENT_TO_OTHER_ADMINS: 'REQUEST_SENT_TO_OTHER_ADMINS',
	REQUEST_TO_OTHER_ADMIN_SUCCESS: 'REQUEST_TO_OTHER_ADMIN_SUCCESS',
	REQUEST_TO_OTHER_ADMIN_FAILURE: 'REQUEST_TO_OTHER_ADMIN_FAILURE',
	REQUEST_REJECTED_BY_OTHER_ADMINS: 'REQUEST_REJECTED_BY_OTHER_ADMINS',
	REQUEST_GIVEN_BY_OTHER_ADMINS: 'REQUEST_GIVEN_BY_OTHER_ADMINS',
	SET_UPDATE_REQUIRED: 'SET_UPDATE_REQUIRED',
	RESET_UPDATE_REQUIRED: 'RESET_UPDATE_REQUIRED',
	COMPANY_DETAILS_NOT_PROVIDED: 'COMPANY_DETAILS_NOT_PROVIDED',
	COMPANY_DETAILS_SUBMITTED_SUCCESS: 'COMPANY_DETAILS_SUBMITTED_SUCCESS'

};

export const REPORT_DOWNLOAD_CONSTANTS = {

	CHECK_AUTH_FOR_REPORT_DOWNLOAD_API_FAILED: 'CHECK_AUTH_FOR_REPORT_DOWNLOAD_API_FAILED',
	CHECK_AUTH_FOR_REPORT_DOWNLOAD_API_SUCCESS: 'CHECK_AUTH_FOR_REPORT_DOWNLOAD_API_SUCCESS',
	REPORT_HIDE_POPUP: 'REPORT_HIDE_POPUP',
	REPORT_SHOW_POPUP: 'REPORT_SHOW_POPUP',
	REPORT_SHOW_LOADER: 'REPORT_SHOW_LOADER',
	REPORT_HIDE_LOADER: 'REPORT_HIDE_LOADER',
	REPORT_AUTH_FAILURE: 'REPORT_AUTH_FAILURE',
	REPORT_DOWNLOAD_API_FAILURE: 'REPORT_DOWNLOAD_API_FAILURE',
	RESET_REPORT_DOWNLOAD_STATE: 'RESET_REPORT_DOWNLOAD_STATE',
	CHECK_EMPLOYEE_SESSION_DATA_API_FAILURE: 'CHECK_EMPLOYEE_SESSION_DATA_API_FAILURE',
	CHECK_EMPLOYEE_SESSION_DATA_API_SUCCESS: 'CHECK_EMPLOYEE_SESSION_DATA_API_SUCCESS',
	REPORT_FLUSH_STATE: 'REPORT_FLUSH_STATE',
	REPORT_INVALID_DATE_SELECTED: 'REPORT_INVALID_DATE_SELECTED',
	REPORT_FIELDS_UPDATE: 'REPORT_FIELDS_UPDATE'

};

export const INVOICE_STORE_CONSTANTS = {
	NEXT_FEATURE: 'NEXT_FEATURE',
	SKIP_FEATURE: 'SKIP_FEATURE',
	MODAL_TYPE: 'MODAL_TYPE',
	SET_LIST: 'SET_LIST',
	SET_COUNT_BUNDLE: 'SET_COUNT_BUNDLE',
	SET_FILTER_OBJECT: 'SET_FILTER_OBJECT',
	UPDATE_SELECT_LIST: 'UPDATE_SELECT_LIST',
	DOWNLOAD_INVOICE_START: 'DOWNLOAD_INVOICE_START',
	DOWNLOAD_INVOICE_SUCCESS: 'DOWNLOAD_INVOICE_SUCCESS',
	DOWNLOAD_INVOICE_FAILED: 'DOWNLOAD_INVOICE_FAILED',
	DOWNLOAD_RESET: 'DOWNLOAD_RESET',
	SET_QUERY: 'SET_QUERY',
	SET_INIT_QUERY: 'SET_INIT_QUERY',
	SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
	SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
	SEARCH_RESULTS_START: 'SEARCH_RESULTS_START',
	SEARCH_RESULTS_CANCEL: 'SEARCH_RESULTS_CANCEL',
	LIST_API_CALLED: 'LIST_API_CALLED',
	BUNDLE_API_CALLED: 'BUNDLE_API_CALLED',
	SET_NO_RESULTS: 'SET_NO_RESULTS',
	UPDATE_APPLY_FILTER_OBJECT: 'UPDATE_APPLY_FILTER_OBJECT',
	TOGGLE_SELECT: 'TOGGLE_SELECT',
	SET_ALL_COUNT_API: 'SET_ALL_COUNT_API',
	SET_ALL_COUNT_BUNDLE: 'SET_ALL_COUNT_BUNDLE',
	RESET_LIST: 'RESET_LIST',
	REMINDER_API_CALLED: 'INVOICE_REMINDER_API_CALLED',
	REMINDER_SUCCESS: 'INVOICE_REMINDER_SUCCESS',
	REMINDER_FAILED: 'INVOICE_REMINDER_FAILED',
	GET_REMINDER_API_CALLED: 'GET_REMINDER_API_CALLED',
	GET_REMINDER_SUCCESS: 'GET_REMINDER_SUCCESS',
	GET_REMINDER_FAILED: 'GET_REMINDER_FAILED',
	LIST_SCROLL: 'LIST_SCROLL',
	INVOICE_SUMMARY_CALLED: 'INVOICE_SUMMARY_CALLED',
	INVOICE_SUMMARY_SUCCESS: 'INVOICE_SUMMARY_SUCCESS',
	INVOICE_SUMMARY_FAILED: 'INVOICE_SUMMARY_FAILED'
};

export const CHARTERED_FLIGHT_STORE = {
	CHARTERED_API_CALLED: 'CHARTERED_API_CALLED',
	CHARTERED_API_SUCCESS: 'CHARTERED_API_SUCCESS',
	CHARTERED_API_FAILURE: 'CHARTERED_API_FAILURE'
};

export const WALLET_STORE_CONSTANTS = {
	NEXT_FEATURE: 'NEXT_FEATURE_WALLET',
	SKIP_FEATURE: 'SKIP_FEATURE_WALLET',
	MODAL_TYPE: 'MODAL_TYPE_WALLET',
	RESET_LIST: 'RESET_LIST_WALLET',
	SET_LIST: 'SET_LIST_WALLET',
	LIST_API_CALLED: 'LIST_API_CALLED_WALLET',
	LIST_API_FAILED: 'LIST_API_FAILED',
	SET_COUNT: 'SET_COUNT',
	COUNT_API_CALLED: 'COUNT_API_CALLED_WALLET',
	RESET_WALLET_DATA: 'RESET_WALLET_DATA',
	SET_QUERY: 'SET_QUERY_WALLET',
	SET_FILTER_OBJECT: 'SET_FILTER_OBJECT_WALLET',
	UPDATE_APPLY_FILTER_OBJECT: 'UPDATE_APPLY_FILTER_OBJECT_WALLET',
	UPDATE_SELECT_LIST: 'UPDATE_SELECT_LIST_WALLET',
	SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS_WALLET',
	SET_ALL_COUNT_API: 'SET_ALL_COUNT_API_WALLET',
	SET_ALL_WALLET_COUNT: 'SET_ALL_WALLET_COUNT',
	WALLET_ACC_DTL_CALLED: 'WALLET_ACC_DTL_CALLED',
	SET_WALLET_ACC_DTL: 'SET_WALLET_ACC_DTL',
	WALLET_ACC_DTL_FAILED: 'WALLET_ACC_DTL_FAILED',
	RECHARGE_SUCCESS: 'RECHARGE_SUCCESS',
	RECHARGE_FAILED: 'RECHARGE_FAILED',
	RECHARGE_API_CALLED: 'RECHARGE_API_CALLED',
	REMINDER_API_CALLED: 'REMINDER_API_CALLED',
	REMINDER_SUCCESS: 'REMINDER_SUCCESS',
	REMINDER_FAILED: 'REMINDER_FAILED',
	DOWNLOAD_API_CALLED: 'DOWNLOAD_API_CALLED',
	DOWNLOAD_API_SUCCESS: 'DOWNLOAD_API_SUCCESS',
	DOWNLOAD_API_FAILED: 'DOWNLOAD_API_FAILED',
	RECHARGE_STATUS: 'RECHARGE_STATUS',
	LIST_API_CALLING: 'LIST_API_CALLING',
	SELF_MAIL_SUCCESS: 'SELF_MAIL_SUCCESS',
	SELF_MAIL_FAILURE: 'SELF_MAIL_FAILURE',
	CLEAR_MODAL_STATE: 'CLEAR_MODAL_STATE',
	SET_EXP_DATA: 'SET_EXP_DATA',
	WALLET_OVERLAY: 'WALLET_OVERLAY',
	RESET_REWARDS_LIST: 'RESET_REWARDS_LIST',
	SET_REWARDS_LIST: 'SET_REWARDS_LIST',
	REWARDS_LIST_API_CALLING: 'REWARDS_LIST_API_CALLING',
	REWARDS_LIST_API_CALLED: 'REWARDS_LIST_API_CALLED',
	REWARDS_LIST_API_FAILED: 'REWARDS_LIST_API_FAILED'
};

export const BIZ_HEADER_STORE_CONSTANTS = {
	GET_HEADER_OPTS_CALLING: 'GET_HEADER_OPTS_CALLING',
	GET_HEADER_OPTS_SUCCESS: 'GET_HEADER_OPTS_SUCCESS',
	GET_HEADER_OPTS_FAILURE: 'GET_HEADER_OPTS_FAILURE',
	UPDATE_HEADER_OPTS: 'UPDATE_HEADER_OPTS',
	GET_PROFILE_CALLING: 'GET_PROFILE_CALLING',
	GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
	GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',
	ASK_FOR_USER_CONFIRMATION: 'ASK_FOR_USER_CONFIRMATION',
	BIZ_LOGOUT_SUCESS: 'BIZ_LOGOUT_SUCESS',
	BIZ_HEADER_NEXT_FEATURE: 'BIZ_HEADER_NEXT_FEATURE',
	BIZ_HEADER_SKIP_FEATURE: 'BIZ_HEADER_SKIP_FEATURE',
	SET_SERVER_DATA: 'SET_SERVER_DATA',
	SET_LOADING: 'SET_LOADING',
	UPDATE_ORG_SUCCESS: 'UPDATE_ORG_SUCCESS',
	FETCH_MSR_DETAILS_REQUEST: 'FETCH_MSR_DETAILS_REQUEST',
	FETCH_MSR_DETAILS_UPDATE: 'FETCH_MSR_DETAILS_UPDATE',
	FETCH_MSR_DETAILS_RESET: 'FETCH_MSR_DETAILS_RESET'
};

export const REPORT_LANDING_STORE_CONSTANTS = {
	INIT: 'INIT',
	GET_SUMMARY: 'GET_SUMMARY',
	UPDATE_FILTER_DATA: 'UPDATE_FILTER_DATA',
	UPDATE_FILTER_QUERY: 'UPDATE_FILTER_QUERY',
	UPDATE_FILTER_QUERY_EXP: 'UPDATE_FILTER_QUERY_EXP',
	GET_GSTR_STATUS: 'GET_GSTR_STATUS',
	GET_CUSTOM_REPORTS: 'GET_CUSTOM_REPORTS',
	FETCH_REPORT: 'FETCH_REPORT',
	GET_REPORT_DATA: 'GET_REPORT_DATA',
	SAVE_REPORT_DATA: 'SAVE_REPORT_DATA',
	GET_ADDITIONAL_DATA: 'GET_ADDITIONAL_DATA',
	DWNLD_REPORT_DATA: 'DWNLD_REPORT_DATA',
	SCHEDULE_SETTINGS: 'SCHEDULE_SETTINGS',
	SET_MODAL: 'SET_MODAL',
	UPDATE_FREQUENCY: 'UPDATE_FREQUENCY',
	UPDATE_ADD_DATA: 'UPDATE_ADD_DATA',
	INIT_REPORT: 'INIT_REPORT',
	DELETE_REPORT: 'DELETE_REPORT',
	HIGHLIGHT_FEATURE: 'HIGHLIGHT_FEATURE',
	GET_TYPE_SUMMARY: 'GET_TYPE_SUMMARY',
	DOWNLOAD_TYPE_REPORT: 'DOWNLOAD_TYPE_REPORT',
	REPORT_API_LOADING: 'REPORT_API_LOADING',
	REPORT_API: 'REPORT_API',
	RESET_REPORT_LANDING_API: 'RESET_REPORT_LANDING_API',
	UPDATE_REPORT_FIELD: 'UPDATE_REPORT_FIELD',
	DOWNLOAD_GSTR_REPORT: 'DOWNLOAD_GSTR_REPORT',
	SET_INIT_GST_STATE: 'SET_INIT_GST_STATE',
	DOWNLOAD_LEDGER_REPORT: 'DOWNLOAD_LEDGER_REPORT'
};

export const REPORT_DETAILS_STORE_CONSTANTS = {
	GET_DETAILS_LISTING: 'GET_DETAILS_LISTING',
	SET_DETAILS_LISTING: 'SET_DETAILS_LISTING',
	DETAILS_API_LOADING: 'DETAILS_API_LOADING',
	DETAILS_API: 'DETAILS_API',
	SET_REPORT_TYPE: 'SET_REPORT_TYPE',
	DETAIL_FILTER_QUERY: 'DETAIL_FILTER_QUERY',
	DETAIL_FILTER_CLEAR: 'DETAIL_FILTER_CLEAR',
	DETAIL_UPDATE_SORT: 'DETAIL_UPDATE_SORT',
	DETAIL_UPDATE_PAGE: 'DETAIL_UPDATE_PAGE',
	UPDATE_DETAILS_SELECTED: 'UPDATE_DETAILS_SELECTED',
	RESET_REPORT_DETAIL_API: 'RESET_REPORT_DETAIL_API',
	RPRT_DETAILS_FILTER_COUNT: 'RPRT_DETAILS_FILTER_COUNT',
	REPORT_DETAILS_SEARCH: 'REPORT_DETAILS_SEARCH',
	UPDATE_DETAILS_SEARCH: 'UPDATE_DETAILS_SEARCH',
	UPDATE_REPORTS_LEVEL: 'UPDATE_REPORTS_LEVEL',
	GET_AB_VALS_DETAILS: 'GET_AB_VALS_DETAILS',
	SET_REPORT_LEVEL: 'SET_REPORT_LEVEL'
};

export const CALLBACK_ACTION_TYPE = {
	GET_CALLBACK_SUPPORT_CALLING: 'GET_CALLBACK_SUPPORT_CALLING',
	GET_CALLBACK_SUPPORT_SUCCESS: 'GET_CALLBACK_SUPPORT_SUCCESS',
	GET_CALLBACK_SUPPORT_FAILURE: 'GET_CALLBACK_SUPPORT_FAILURE',
	REQUEST_CALLBACK: 'REQUEST_CALLBACK',
	REQUEST_CALLBACK_SUCCESS: 'REQUEST_CALLBACK_SUCCESS',
	REQUEST_CALLBACK_FAILURE: 'REQUEST_CALLBACK_FAILURE',
	CALLBACK_MODAL_DATA: 'CALLBACK_MODAL_DATA'
};

export const AIRLINE_MAPPING = {
	'AI': 'Air India',
	'I5': 'AirAsia',
	'KA': 'Cathay Dragon',
	'9W': 'Jet Airways',
	'SG': 'Spicejet',
	'SGH': 'Spicejet HBag Only',
	'2T': 'Trujet',
	'JP': 'Adria Airways',
	'A3': 'Aegean Airlines',
	'EI': 'Aer Lingus',
	'SU': 'Aeroflot',
	'AR': 'Aerolineas Argentinas',
	'AM': 'Aeromexico',
	'8U': 'Afriqiyah Airways',
	'ZI': 'Aigle Azur',
	'AH': 'Air Algerie',
	'G9': 'Air Arabia',
	'AK': 'Air Asia',
	'D7': 'Air Asia X',
	'KC': 'Air Astana',
	'UU': 'Air Austral',
	'BT': 'Air Baltic',
	'BP': 'Air Botswana',
	'2J': 'Air Burkina',
	'AC': 'Air Canada',
	'TX': 'Air Caraibes',
	'CA': 'Air China',
	'QD': 'Air Class Lineas',
	'XK': 'Air Corsica',
	'HF': 'Air Cote Dlvoire',
	'UX': 'Air Europa',
	'AF': 'Air France',
	'3S': 'Air Guyane S.p.',
	'IX': 'Air India Express',
	'NX': 'Air Macau',
	'MD': 'Air Madagascar',
	'KM': 'Air Malta',
	'ZM': 'Air Manas',
	'MK': 'Air Mauritius',
	'9U': 'Air Moldova',
	'SW': 'Air Namibia',
	'NZ': 'Air New Zealand',
	'FJ': 'Air Pacific Ltd',
	'JU': 'AIR SERBIA',
	'HM': 'Air Seychelles',
	'4D': 'Air Sinai',
	'AS': 'Alaska Airlines',
	'AZ': 'Alitalia',
	'NH': 'All Nippon Airways ANA',
	'AA': 'American Airlines',
	'IZ': 'Arkia Israel Airlines',
	'OZ': 'Asiana Airlines',
	'KP': 'ASKY',
	'KK': 'Atlasjet',
	'OS': 'Austrian Airlines',
	'AV': 'Avianca',
	'J2': 'Azerbaijan Hava Yollary',
	'AD': 'AZUL LINHAS AEREAS BRASILEIRAS',
	'UP': 'Bahamas air',
	'PG': 'Bangkok Airways',
	'ID': 'BATIK AIR INDONESIA',
	'JV': 'Bearskin Airlines',
	'B2': 'Belavia',
	'BG': 'Biman Bangladesh',
	'0B': 'Blue Air',
	'BM': 'BMI Regional',
	'4B': 'Boutique Air',
	'BA': 'British Airways',
	'SN': 'Brussels Airlines',
	'FB': 'Bulgaria Air',
	'K6': 'Cambodia Angkor Air',
	'9K': 'Cape Air',
	'BW': 'Caribbean Airlines',
	'CX': 'Cathay Pacific',
	'9M': 'Central Mountain Air',
	'CI': 'China Airlines',
	'MU': 'China Eastern',
	'CZ': 'China Southern',
	'WX': 'CityJet',
	'DE': 'Condor Flugdienst',
	'CM': 'Copa Airlines',
	'OU': 'Croatia Airlines',
	'DX': 'Danish Air Transport',
	'DL': 'Delta Airlines',
	'B5': 'East African Safari Express',
	'U2': 'easyJet',
	'MS': 'EgyptAir',
	'LY': 'El Al Israel Airlines',
	'EK': 'Emirates',
	'ET': 'Ethiopian Airlines',
	'EY': 'Etihad Airways',
	'EW': 'Eurowings',
	'BR': 'Eva Airways',
	'AY': 'Finnair',
	'BE': 'Flybe',
	'FZ': 'flydubai',
	'XY': 'Flynas',
	'GA': 'Garuda Indonesia',
	'A9': 'Georgian Airways',
	'G8': 'Go Air',
	'GF': 'Gulf Air',
	'H1': 'Hahn Air',
	'HU': 'Hainan Airlines',
	'HA': 'Hawaiian Airlines',
	'HX': 'Hong Kong Airlines',
	'A5': 'HOP!',
	'IB': 'Iberia',
	'FI': 'Icelandair',
	'6E': 'IndiGo',
	'QZ': 'Indonesia AirAsia',
	'XT': 'Indonesia AirAsia X',
	'9B': 'Intourtrans',
	'IR': 'Iran Air',
	'JL': 'Japan Airlines',
	'J9': 'Jazeera Airways',
	'B6': 'Jetblue Airways',
	'JQ': 'Jetstar Airways',
	'3K': 'Jetstar Asia',
	'GK': 'Jetstar Japan',
	'BL': 'Jetstar Pacific Airlines',
	'HO': 'Juneyao Airlines',
	'RQ': 'Kam Air',
	'KQ': 'Kenya Airways',
	'KL': 'KLM Royal Dutch',
	'KE': 'Korean Air',
	'MN': 'Kulula Air',
	'KU': 'Kuwait Airways',
	'TM': 'Lam Linhas Aereas',
	'LA': 'Lan Airlines S.a.',
	'4M': 'Lan Argentina',
	'XL': 'Lan Ecuador Aerolane',
	'QV': 'Lao Aviation',
	'LQ': 'Lebanese Air Transport',
	'LI': 'Liat',
	'LM': 'Loganair Limited',
	'LO': 'Lot Polish Airlines',
	'LH': 'Lufthansa',
	'LG': 'Luxair',
	'MH': 'Malaysia Airlines',
	'OD': 'Malindo',
	'AE': 'Mandarin Airlines',
	'JE': 'Mango Airlines',
	'L6': 'Mauritian Airlines International',
	'IG': 'Meridiana',
	'OM': 'Miat Mongolian',
	'ME': 'Middle East Airlines',
	'MT': 'Multiple Airlines',
	'UB': 'Myanmar Airlines',
	'8M': 'Myanmar Airways',
	'DD': 'Nok Air',
	'XW': 'NokScoot',
	'WY': 'Oman Air',
	'GP': 'Palau Trans Pacific',
	'PC': 'Pegasus Airlines',
	'PR': 'Philippine Airlines',
	'Z2': 'Philippines AirAsia',
	'DV': 'Pll Scat Aircompany',
	'PW': 'Precision Air',
	'QF': 'Qantas Airways',
	'QR': 'Qatar Airways',
	'WZ': 'Red Wings Airlines',
	'8L': 'Redhill Aviation',
	'AT': 'Royal Air Maroc',
	'BI': 'Royal Brunei Airlines',
	'RJ': 'Royal Jordanian',
	'RA': 'Royal Nepal Airlines',
	'WB': 'Rwandair Express',
	'FR': 'Ryan Air',
	'SV': 'Saudia',
	'SK': 'Scandinavian Airlines',
	'TR': 'Scoot',
	'TZ': 'Scoot',
	'SC': 'Shandong Airlines',
	'FM': 'Shanghai Airlines',
	'ZH': 'Shenzhen Airlines',
	'S7': 'Siberia Airlines',
	'3U': 'Sichuan Airlines',
	'MI': 'Silkair',
	'3M': 'Silver Airways Corp',
	'SQ': 'Singapore Airlines',
	'GQ': 'Sky Express',
	'SA': 'South African Airways',
	'UL': 'Srilankan Airlines',
	'5H': 'Star Air',
	'SY': 'SUN COUNTRY',
	'LX': 'Swiss',
	'DT': 'Taag Angola Airlines',
	'JJ': 'Tam Linhas Aereas',
	'EQ': 'Tame',
	'TP': 'Tap Air Portugal',
	'RO': 'Tarom Romanian Air',
	'FD': 'Thai Air Asia',
	'XJ': 'Thai Airasia X',
	'TG': 'Thai Airways',
	'SL': 'THAI LION MENTARI',
	'WE': 'THAI SMILE AIRWAYS',
	'TT': 'tigerair Australia',
	'HV': 'Transavia Airlines',
	'TO': 'Transavia France',
	'TK': 'Turkish Airlines',
	'PS': 'Ukraine Intl Airlines',
	'B7': 'Uni Airways',
	'UA': 'United Airlines',
	'U6': 'Ural Airlines',
	'UT': 'Uta',
	'HY': 'Uzbekistan Airway',
	'VJ': 'VietJet Air',
	'VN': 'Vietnam Airlines',
	'VS': 'Virgin Atlantic',
	'VA': 'Virgin Australia',
	'UK': 'Vistara',
	'G3': 'VRG Linhas aereas',
	'VY': 'Vueling Air',
	'WS': 'Westjet',
	'W2': 'White Eagle Aviation',
	'WF': 'Wideroes Flyveselskap',
	'WM': 'Windward Island Airways',
	'MF': 'Xiamen Airlines',
	'SE': 'XL Airways France',
	'YT': 'Yeti Air'
};

export const POLICY_STORE_CONSTANTS = {
	POLICY_CONFIG_CALLING: 'POLICY_CONFIG_CALLING',
	POLICY_CONFIG_SUCCESS: 'POLICY_CONFIG_SUCCESS',
	POLICY_CONFIG_FAILURE: 'POLICY_CONFIG_FAILURE',
	POLICY_DATA_CALLING: 'POLICY_DATA_CALLING',
	POLICY_DATA_SUCCESS: 'POLICY_DATA_SUCCESS',
	POLICY_DATA_FAILURE: 'POLICY_DATA_FAILURE',
	FETCH_POLICY_DETAILS: 'FETCH_POLICY_DETAILS',
	POLICY_DETAIL_FAILURE: 'POLICY_DETAIL_FAILURE',
	SAVE_POLICY_CALLING: 'SAVE_POLICY_CALLING',
	SAVE_POLICY_SUCCESS: 'SAVE_POLICY_SUCCESS',
	SAVE_POLICY_FAILURE: 'SAVE_POLICY_FAILURE',
	UPDATE_POLICY_GROUP: 'UPDATE_POLICY_GROUP',
	UPDATE_DEFAULT_GROUP: 'UPDATE_DEFAULT_GROUP',
	TOGGLE_MODAL: 'TOGGLE_MODAL',
	UPDATE_FORMATTED_GRP_CONFIG: 'UPDATE_FORMATTED_GRP_CONFIG',
	UPDATE_FORMAT_CONFIG: 'UPDATE_FORMAT_CONFIG',
	UPDATE_GRP_ORG_CONFIG: 'UPDATE_GRP_ORG_CONFIG',
	TIER_MAP_AUTO_SUGGEST_VALUES: 'TIER_MAP_AUTO_SUGGEST_VALUES',
	TIER_CONFIG: 'TIER_CONFIG',
	LOB_CHANGE: 'LOB_CHANGE',
	RESET_POLICY_STATE: 'RESET_POLICY_STATE',
	DELETE_TIER_BUDGET: 'DELETE_TIER_BUDGET',
	POLICY_CHANGE_UPDATE: 'POLICY_CHANGE_UPDATE',
	AUTO_SUGGEST_FAILURE: 'AUTO_SUGGEST_FAILURE',
	MANAGER_MISSING_COUNT: 'MANAGER_MISSING_COUNT',
	MANAGER_MISSING_COUNT_SUCCESS: 'MANAGER_MISSING_COUNT_SUCCESS',
	MANAGER_MISSING_COUNT_FAILURE: 'MANAGER_MISSING_COUNT_FAILURE',
	UPDATE_POLICY_DEFAULT: 'UPDATE_POLICY_DEFAULT',
	UPDATE_POLICY_DEFAULT_KEYS: 'UPDATE_POLICY_DEFAULT_KEYS',
	UPDATE_POLICY_CONFIG_DATA: 'UPDATE_POLICY_CONFIG_DATA',
	HIDE_FIELD_UPDATE: 'HIDE_FIELD_UPDATE',
	UPDATE_POLICY_CHANGE: 'UPDATE_POLICY_CHANGE',
	UPDATE_HIDE_BLOCKS: 'UPDATE_HIDE_BLOCKS',
	SELECTED_GROUP_INDEX: 'SELECTED_GROUP_INDEX'
};

export const SHORTLIST_CONST = {
	API_LOADING: 'API_LOADING',
	API_ERROR: 'API_ERROR',
	API_SUCCESS: 'API_SUCCESS',
	RESET_API_STATE: 'RESET_API_STATE',
	SET_MODAL: 'SET_MODAL',
	SET_SHORT_LIST: 'SET_SHORT_LIST',
	TRAVEL_REQUEST: 'TRAVEL_REQUEST',
	FILTER_RESULTS: 'FILTER_RESULTS',
	TRAVEL_REQUEST_CALLED: 'TRAVEL_REQUEST_CALLED',
	SET_PAGE_TYPE: 'SET_PAGE_TYPE'
};

export const SHORTLIST_OPTIONS_CONST = {
	GET_OPTIONS_SUCCESS: 'GET_OPTIONS_SUCCESS',
	UPDATE_GRPIDS_DATA: 'UPDATE_GRPIDS_DATA',
	SHARE_SUCCESS: 'SHARE_SUCCESS',
	PAGE_CALLED: 'PAGE_CALLED'
};

export const TRAVEL_WISHLIST_CONST = {
	GET_WISHLIST: 'GET_WISHLIST',
	SET_WISHLIST_OPTION: 'SET_WISHLIST_OPTION',
	GET_FLIGHT_FARE: 'GET_FLIGHT_FARE',
	LOADING_FLIGHT_FARE: 'LOADING_FLIGHT_FARE',
	RESET_FLIGHT_FARE: 'RESET_FLIGHT_FARE',
	GET_HOTEL_FARE: 'GET_HOTEL_FARE',
	LOADING_HOTEL_FARE: 'LOADING_HOTEL_FARE',
	RESET_HOTEL_FARE: 'RESET_HOTEL_FARE'
};

export const TRAVEL_OPTIONS_CONST = {
	GET_APPROVAL_DETAILS: 'GET_APPROVAL_DETAILS',
	SET_TRAVEL_REQUESTS: 'SET_TRAVEL_REQUESTS',
	FILTER_REQUISITION_REQUESTS: 'FILTER_REQUISITION_REQUESTS'
};

export const EXPENSE_LANDING_CONST = {
	EXPENSE_SUMMARY: 'EXPENSE_SUMMARY',
	EXPENSE_DATA: 'EXPENSE_DATA',
	EXPENSE_ERROR: 'EXPENSE_ERROR',
	EXPENSE_LOADING: 'EXPENSE_LOADING',
	APPROVAL_EXPENSE_DATA: 'APPROVAL_EXPENSE_DATA',
	FEATURE_DATA: 'FEATURE_DATA',
	EXPENSE_MODAL: 'EXPENSE_MODAL',
	EXPENSE_FILTER: 'EXPENSE_FILTER',
	CLEAR_STATE: 'CLEAR_STATE'
};

export const EXPENSE_CONST = {
	API_LOADING: 'API_LOADING',
	API_ERROR: 'API_ERROR',
	API_SUCCESS: 'API_SUCCESS',
	RESET_API_STATE: 'RESET_API_STATE',
	EXPENSE_CAT: 'EXPENSE_CAT',
	BIZ_EXPNS_LIST: 'BIZ_EXPNS_LIST',
	EXPNS_MODAL_STEP: 'EXPNS_MODAL_STEP',
	EXPNS_RECEIPT_URL: 'EXPNS_RECEIPT_URL',
	EXPNS_DATA: 'EXPNS_DATA',
	EXPNS_MODAL: 'EXPNS_MODAL',
	CLEAR_EDIT_MODAL_DATA: 'CLEAR_EDIT_MODAL_DATA'
};

export const EXPENSE_DETAILS_CONST = {
	EXPNS_DETAIL: 'EXPNS_DETAIL',
	EXPNS_HISTORY: 'EXPNS_HISTORY',
	CLEAR_EXPNS: 'CLEAR_EXPNS'
};

export const EXPENSE_REIMBURSE_CONST = {
	GET_LIST: 'GET_LIST',
	UPDATE_SELECTED: 'UPDATE_SELECTED',
	UPDATE_FILTER_QUERY: 'UPDATE_FILTER_QUERY_EXP',
	UPDATE_FILTER_DATA: 'UPDATE_FILTER_DATA',
	GET_LIST_CALLING: 'GET_LIST_CALLING_EXP',
	GET_LIST_FAILED: 'GET_LIST_FAILED_EXP',
	GET_FILTER_COUNTS: 'GET_FILTER_COUNTS_EXP',
	DOWNLOAD_START: 'DOWNLOAD_START_EXP_RE',
	DOWNLOAD_FAILED: 'DOWNLOAD_FAILED_EXP_RE',
	DOWNLOAD_SUCCESS: 'DOWNLOAD_SUCCESS',
	SET_MODAL: 'SET_MODAL_EXP',
	UPLOAD_FAILED: 'UPLOAD_FAILED_EXP',
	UPLOAD_SUCCESS: 'UPLOAD_SUCCESS_EXP',
	CANCEL_UPLOAD: 'CANCEL_UPLOAD',
	SEARCH_LIST_CALLING: 'SEARCH_LIST_CALLING',
	SEARCH_LIST_FAILED: 'SEARCH_LIST_FAILED_EXP',
	SEARCH_LIST_SUCCESS: 'SEARCH_LIST_SUCCESS',
	SEARCH_LIST_RESET: 'SEARCH_LIST_RESET',
	DOWNLOAD_TnE: 'DOWNLOAD_TnE'
};
export const EXPENSE_STORE_ADMIN_CONST = {
	ADMIN_SCREEN: 'ADMIN_SCREEN',
	ADMIN_MODAL: 'ADMIN_MODAL',
	ADMIN_CATEGORIES: 'ADMIN_CATEGORIES',
	SCREEN_LEVEL_MODALS: 'SET_MODALS',
	UNSAVED_SCREEN_STATUS: 'UNSAVED_SCREEN_STATUS',
	CLEAR_STATE: 'CLEAR_STATE',
	T_N_E_DATA: 'T_N_E_DATA',
	EXPNS_FEATURE_DATA: 'EXPNS_FEATURE_DATA'
};

export const EXPENSE_POLICY_CONST = {
	APPROVAL_INPUTS: 'APPROVAL_INPUTS',
	MANAGER_LIST: 'MANAGER_LIST',
	EMPLOYEE_LIST: 'EMPLOYEE_LIST',
	CAT_LIST: 'CAT_LIST',
	POLICY_GROUPS: 'POLICY_GROUPS',
	POLICY_DATA: 'POLICY_DATA',
	UPDATE_DEFAULT_GROUP: 'UPDATE_DEFAULT_GROUP',
	API_LOADING: 'API_LOADING',
	CLEAR_STATE: 'CLEAR_STATE'
};

export const EMPLOYEE_STORE_CONST = {
	EMPLOYEE_LIST: 'EMPLOYEE_LIST',
	SET_BULK_UP_STATUS: 'SET_BULK_UP_STATUS',
	SET_MODAL: 'SET_MODAL_EMP',
	UPDATE_SELECTED: 'UPDATE_SELECTED',
	SET_INVITE_DATA: 'SET_INVITE_DATA',
	EMPLOYEE_INVITE_SUCCESS: 'EMPLOYEE_INVITE_SUCCESS',
	API_LOADING: 'EMP_API_LOADING',
	API_ERROR: 'EMP_API_ERROR',
	API_SUCCESS: 'EMP_API_SUCCESS',
	RESET_API_STATE: 'EMP_RESET_API_STATE',
	LOADING: 'EMP_LOADING',
	SUCCESS: 'EMP_SUCCESS',
	ERROR: 'ERROR',
	SET_FILTER_COUNT: 'SET_FILTER_COUNT',
	GET_MANAGERS_LIST: 'GET_MANAGERS_LIST',
	GET_GROUP_LIST: 'GET_GROUP_LIST',
	EMP_FILTER_QUERY: 'EMP_FILTER_QUERY',
	EMP_CLEAR_FILTER: 'EMP_CLEAR_FILTER',
	EMP_UPDATE_SORT: 'EMP_UPDATE_SORT',
	EMP_UPDATE_PAGE: 'EMP_UPDATE_PAGE',
	GET_DESIGNATION_LIST: 'GET_DESIGNATION_LIST',
	OPEN_CHANGE_EMP_PROP: 'OPEN_CHANGE_EMP_PROP',
	BULK_UPDATE_EMP: 'BULK_UPDATE_EMP',
	CREATE_NEW_GROUP: 'CREATE_NEW_GROUP',
	DELETE_EMPLOYEES: 'DELETE_EMPLOYEES',
	EMP_SEARCH_STR: 'EMP_SEARCH_STR',
	MODIFY_EMP_DET: 'MODIFY_EMP_DET',
	RESET_POPUPS_DATA: 'RESET_POPUPS_DATA',
	EMPLOYEE_MODIFY_SUCCESS: 'EMPLOYEE_MODIFY_SUCCESS',
	GET_REMINDER_SUCCESS: 'EMP_GET_REMINDER_SUCCESS',
	REMINDER_API_CALLED: 'EMP_REMINDER_API_CALLED',
	REMINDER_SUCCESS: 'EMP_REMINDER_SUCCESS',
	REMINDER_FAILED: 'EMP_REMINDER_FAILED',
	VIP_EXCEED: 'VIP_EXCEED',
	EMP_UPLOAD_STATUS: 'EMP_UPLOAD_STATUS',
	EMP_UPLOAD_CANCEL: 'EMP_UPLOAD_CANCEL',
	DOWNLOAD_CSV_FAIL: 'DOWNLOAD_CSV_FAIL',
	EMP_UPLOAD_RESET: 'EMP_UPLOAD_RESET',
	EMP_UPLOAD_TIME: 'EMP_UPLOAD_TIME',
	EMP_FEATURE_DATA: 'EMP_FEATURE_DATA',
	DATA_UPDATE_COUNT: 'DATA_UPDATE_COUNT',
	EMP_DWL_DATA: 'EMP_DWL_DATA',
	UPDATE_MODIFY_EMP_DET: 'UPDATE_MODIFY_EMP_DET',
	GET_UPDATE_STATUS: 'GET_UPDATE_STATUS',
	RESET_JOB_COUNT: 'RESET_JOB_COUNT',
	DOWNLOAD_BULK_URL: 'DOWNLOAD_BULK_URL',
	GET_AB_VALS: 'GET_AB_VALS',
	DOWNLOAD_CSV: 'DOWNLOAD_CSV',
	CREATE_GRP_EMP: 'CREATE_GRP_EMP',
	FETCH_INVITATION_CHNL: 'FETCH_INVITATION_CHNL',
	UPDATE_INVITATION_CHNL: 'UPDATE_INVITATION_CHNL',
	SET_MODAL_DATA: 'SET_MODAL_DATA',
	GET_HRMS_TOKEN: 'GET_HRMS_TOKEN',
	GET_HRMS_STATUS: 'GET_HRMS_STATUS'
};

export const GROUP_STORE_CONSTANTS = {
	GET_GROUP_DATA: 'GET_GROUP_DATA',
	SORT_GROUP_DATA: 'SORT_GROUP_DATA',
	SET_SELECTED_GROUPS: 'SET_SELECTED_GROUPS',
	FILTER_GROUPS: 'FILTER_GROUPS',
	GROUP_FEATURES: 'GROUP_FEATURES',
	GET_EMP_GRP_LIST: 'GET_EMP_GRP_LIST',
	VALIDATE_EMPLOYEE: 'VALIDATE_EMPLOYEE',
	VALIDATE_EMP_LOADING: 'VALIDATE_EMP_LOADING',
	RESET_VALIDATION_DATA: 'RESET_VALIDATION_DATA',
	REMOVE_GRP_EMP: 'REMOVE_GRP_EMP',
	ADD_EXIST_EMP: 'ADD_EXIST_EMP',
	CREATE_NEW_GRP: 'CREATE_NEW_GRP',
	CANCEL_GRP_REQUEST: 'CANCEL_GRP_REQUEST',
	SET_GRP_MODAL: 'SET_GRP_MODAL',
	GET_CONFLICTING_STATUS: 'GET_CONFLICTING_STATUS',
	REMOVE_CONFLICTS: 'REMOVE_CONFLICTS',
	REMOVE_CONFLICTS_LOADING: 'REMOVE_CONFLICTS_LOADING',
	API_LOADING: 'GRP_API_LOADING',
	API_SUCCESS: 'GRP_API_SUCCESS',
	API_ERROR: 'GRP_API_ERROR',
	GRP_CSV_DOWNLOAD: 'GRP_CSV_DOWNLOAD',
	GRP_CSV_UPLOAD: 'GRP_CSV_UPLOAD',
	GRP_UPLOAD_STATUS: 'GRP_UPLOAD_STATUS',
	GRP_UPLOAD_TIME: 'GRP_UPLOAD_TIME',
	GRP_UPLOAD_RESET: 'GRP_UPLOAD_RESET',
	RESET_GRP_API_STATE: 'RESET_GRP_API_STATE',
	GET_GROUP_DETAILS: 'GET_GROUP_DETAILS',
	DELETE_GROUPS: 'DELETE_GROUPS',
	RESET_CREATE_GROUP: 'RESET_CREATE_GROUP',
	GET_UPDATE_STATUS: 'GET_GRP_UPDATE_STATUS',
	RESET_JOB_COUNT: 'RESET_GRP_JOB_COUNT',
	DOWNLOAD_GROUP: 'DOWNLOAD_GROUP',
	RESET_INIT_STATE: 'RESET_INIT_STATE'
};

export const TRAIN_STORE_CONSTANT = {
	SET_PASSENGER_DETAILS_FORM: 'SET_PASSENGER_DETAILS_FORM',
	SET_TRAIN_DETAILS_FORM: 'SET_TRAIN_DETAILS_FORM',
	SAVE_TRAIN_DATA: 'SAVE_TRAIN_DATA',
	SHOW_POPUP: 'SHOW_POPUP'
};

export const RESOURCE_STORE_CONSTANT = {
	GET_RESOURCE_DATA_LOADING: 'GET_RESOURCE_DATA_LOADING',
	GET_RESOURCE_DATA: 'GET_RESOURCE_DATA',
	GET_RESOURCE_DATA_FAILURE: 'GET_RESOURCE_DATA_FAILURE'
};

export const REQUISITION_STORE_CONSTANT = {
	GET_POPULAR_FLIGHT: 'GET_POPULAR_FLIGHT',
	GET_FLIGHT_SEARCH: 'GET_FLIGHT_SEARCH',
	SET_FLIGHT_SEARCH: 'SET_FLIGHT_SEARCH',
	SET_FLIGHT_TRAVEL_DETAILS: 'SET_FLIGHT_TRAVEL_DETAILS',
	ADD_REMOVE_FLIGHT_TRAVEL: 'ADD_REMOVE_FLIGHT_TRAVEL',
	UPDATE_TRAVELLERS: 'UPDATE_TRAVELLERS',
	UPDATE_REQUISITION_MODAL: 'UPDATE_REQUISITION_MODAL',
	UPDATE_BOOK_FOR: 'UPDATE_BOOK_FOR',
	GET_HOTEL_SEARCH: 'GET_HOTEL_SEARCH',
	GET_PLACE_DETAILS: 'GET_PLACE_DETAILS',
	UPDATE_HOTEL_DATA: 'UPDATE_HOTEL_DATA',
	SET_REASONS_TRIP_TAGS: 'SET_REASONS_TRIP_TAGS',
	GET_REASONS_TRIP_TAGS: 'GET_REASONS_TRIP_TAGS',
	UPDATE_PRIMARY_PAX: 'UPDATE_PRIMARY_PAX',
	UPDATE_TRIP_TAGS: 'UPDATE_TRIP_TAGS',
	PROCEED_TO_SUBMIT: 'PROCEED_TO_SUBMIT',
	SET_TRAV_ERROR: 'SET_TRAV_ERROR',
	SET_TRAV_REASONS: 'SET_TRAV_REASONS',
	RESET_FLIGHT: 'RESET_FLIGHT',
	SET_CAB_PARAMS: 'SET_CAB_PARAMS',
	SET_SEARCH_DATA: 'SET_SEARCH_DATA',
	GET_BUS_SEARCH: 'GET_BUS_SEARCH',
	UPDATE_BUS_DATA: 'UPDATE_BUS_DATA',
	RESET_BUS_DATA: 'RESET_BUS_DATA',
	RESET_HOTEL_DATA: 'RESET_HOTEL_DATA',
	SET_MODAL: 'SET_MODAL',
	SET_APILOADING: 'SET_APILOADING'
};

export const TRAVEL_REQUEST_DETAILS_CONSTANT = {
	GET_TRF_DETAILS: 'GET_TRF_DETAILS',
	SHOW_MODAL: 'SHOW_MODAL',
	PUT_APPR_REJ_REQUEST: 'PUT_APPR_REJ_REQUEST',
	GET_TRF_DETAILS_FAILURE: 'GET_TRF_DETAILS_FAILURE',
	MARK_AS_BOOKED_LOADING: 'MARK_AS_BOOKED_LOADING',
	MARK_AS_BOOKED: 'MARK_AS_BOOKED',
	MARK_AS_BOOKED_FAILURE: 'MARK_AS_BOOKED_FAILURE',
	SHOW_MARKED_MODAL: 'SHOW_MARKED_MODAL',
	SHOW_NOTIFICATION: 'SHOW NOTIFICATION',
	CLOSE_NOTIFICATION: 'CLOSE NOTIFICATION'
};

export const GST_ACTION_CONSTANTS = {
	GET_GST_DATA: 'GET_GST_DATA',
	UPDATE_GST_DATA: 'UPDATE_GST_DATA',
	UPDATE_POP: 'UPDATE_POP',
	UPDATE_SELECTED_GST: 'UPDATE_SELECTED_GST'
};

export const POPUP_ACTION_CONSTANTS = {
	HIDE_POP: 'HIDE_POP',
	UPDATE_POP: 'UPDATE_POP'
};

export const TRIP_TAG_CONSTANTS = {
	FETCH_ALL_TRIPTAG_SUCCESS: 'FETCH_ALL_TRIPTAG_SUCCESS',
	FETCH_TRIPTAG_SUCCESS: 'FETCH_TRIPTAG_SUCCESS',
	FETCH_TRIPTAG_FAILURE: 'FETCH_TRIPTAG_FAILURE',
	SAVE_TRIPTAG_SUCCESS: 'SAVE_TRIPTAG_SUCCESS',
	SAVE_TRIPTAG_FAILURE: 'SAVE_TRIPTAG_FAILURE',
	UPDATE_TRIPTAG: 'UPDATE_TRIPTAG',
	UPDATE_TRIPTAG_WITH_ERROR_DATA: 'UPDATE_TRIPTAG_WITH_ERROR_DATA',
	UPDATE_MODAL_DATA: 'UPDATE_MODAL_DATA',
	UPDATE_APPROVAL_MATRIX: 'UPDATE_APPROVAL_MATRIX',
	UPDATE_REQUISITION: 'UPDATE_REQUISITION',
	SET_MATRIX: 'SET_MATRIX',
	SET_TRIP_VALUE_MANAGER_MAP: 'SET_TRIP_VALUE_MANAGER_MAP',
	SET_ENABLE_APPROVAL_FIELDS: 'SET_ENABLE_APPROVAL_FIELDS',
	UPDATE_ERROR_STATE: 'UPDATE_ERROR_STATE'
};

export const MANAGE_REQUEST_BOOKINGS = {
	SET_REQUEST_TYPE: 'SET_REQUEST_TYPE',
	GET_BOOKING_LIST: 'GET_BOOKING_LIST',
	GET_FILTER_COUNTS: 'GET_FILTER_COUNTS',
	FILTER_QUERY: 'FILTER_QUERY',
	FILTER_CLEAR: 'FILTER_CLEAR',
	GET_APPROVERS_LIST: 'GET_APPROVERS_LIST',
	UPDATE_PAGE_NO: 'UPDATE_PAGE_NO',
	CLEAR_SEARCH_VALUE: 'CLEAR_SEARCH_VALUE',
	SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
	MODIFY_BOOKING: 'MODIFY_BOOKING',
	UPDATE_TAB_COUNT: 'UPDATE_TAB_COUNT'
};

export const INTEGRATION_CONSTANTS = {
	API_LOADER: 'API_LOADER',
	API_SUCESS: 'API_SUCESS',
	API_FAILED: 'API_FAILED'
};

export const ORG_DETAILS_CONSTANT = {
	GET_ORGANIZATION_DETAILS: 'GET_ORGANIZATION_DETAILS',
	SET_MODAL: 'SET_MODAL',
	PREFILL_DATA: 'PREFILL_DATA',
	POST_FORM_DATA: 'POST_FORM_DATA',
	CONVERT_SVGS: 'CONVERT_SVGS',
	GET_CURRENCIES_API: 'GET_CURRENCIES_API',
	GET_API_KEYS: 'GET_API_KEYS',
	UPDATE_API_KEYS: 'UPDATE_API_KEYS',
	POST_IDENTIFIER_DATA: 'POST_IDENTIFIER_DATA',
	GET_ORGANIZATION_ADDRESS_DETAILS: 'GET_ORGANIZATION_ADDRESS_DETAILS',
	ADD_UPDATE_ADDRESS: 'ADD_UPDATE_ADDRESS',
	ADD_UPDATE_ORG_ID_ADDRESS: 'ADD_UPDATE_ORG_ID_ADDRESS'
};
