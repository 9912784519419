// External Libraries
import cloneDeep from 'lodash/cloneDeep';

// Action Constants
import { POLICY_STORE_CONSTANTS } from '../actionConstants';

// Utility Functions
import { getCookie, saveServerLogs } from '../../UtilityComponents/Util';
import { formatSaveResp } from '../../UtilityComponents/PolicyUtils';

// API Configurations
import { getccbAxiosConfig } from '../../apisSetup/ccbConfig';
import { getUserServiceAxiosConfig } from '../../apisSetup/userServiceConfig.js';

// Application Constants
import { APPLICATION_CONSTANTS, PATHS } from '../../AppConstants';

const ccbAxiosApiCaller = getccbAxiosConfig(true, {
	'deviceId': getCookie('dvid'),
	'mmt-auth': getCookie('mmt-auth')
}, false);

let userServiceAxiosInstance = getUserServiceAxiosConfig();

export const lobChange = (payloadData) => async (dispatch) => {
	dispatch(resetState());
	dispatch(fetchPolicyConfig(payloadData));
};

const resetState = () => async (dispatch) => {
	dispatch({
		type: POLICY_STORE_CONSTANTS.RESET_POLICY_STATE
	});
};

export const fetchPolicyConfig = (payloadData) => async (dispatch) => {
	let response = null;
	try {
		dispatch({
			type: POLICY_STORE_CONSTANTS.POLICY_CONFIG_CALLING,
			payload: payloadData
		});
		let {
			defaultLob,
			isAllConfig
		} = payloadData;
		let lobConfigKey = defaultLob.defaultLobApiKey;
		let url = `${PATHS.GET_POLICY_CONFIG}?lob=${lobConfigKey}&needCompleteConfig=${isAllConfig}`;
		response = await ccbAxiosApiCaller.get(url);

		const responseData = response && response.data;

		if (responseData) {
			dispatch({
				type: POLICY_STORE_CONSTANTS.POLICY_CONFIG_SUCCESS,
				payload: {
					responseData
				}
			});
		} else {
			dispatch({
				type: POLICY_STORE_CONSTANTS.POLICY_CONFIG_FAILURE
			});
		}
	} catch (e) {
		dispatch({
			type: POLICY_STORE_CONSTANTS.POLICY_CONFIG_FAILURE
		});
		saveServerLogs(e.stack, 'error', PATHS.GET_POLICY_CONFIG);
		return;
	}
};

export const autoSuggestApi = async (searchParams, apiFormat = {}, defaultOptions = [] ) => {
	let response = null;
	let opts = [];
	let stateOpts = defaultOptions.filter((state) => state.name.toLowerCase().includes(searchParams.q?.toLowerCase())) || [];
	try {
		let url = `${PATHS.AUTO_SUGGEST_CITY}`;
		response = await userServiceAxiosInstance.get(url, { params: searchParams });
		let responseData = response && response.data;
		if (responseData) {
			opts = [...responseData, ...stateOpts].map((location) => {
				let retVal = {
					value: location[apiFormat.value],
					label: location[apiFormat.label]
				};
				return retVal;
			});
		}
	} catch (e) {
		dispatch({
			type: POLICY_STORE_CONSTANTS.AUTO_SUGGEST_FAILURE
		});
		saveServerLogs(e.stack, 'error', PATHS.AUTO_SUGGEST_CITY);
	} finally {
		return opts;
	}
};

export const autoSuggestWithState = (searchKey, inputField, formattedConfigOrg, policyConfigMetaData, selectedGroupId) => async (dispatch) => {
	let response = null;
	let clonedFormattedConfigGroup = cloneDeep(formattedConfigOrg);
	try {
		let url = `${PATHS.AUTO_SUGGEST_CITY}?q=${searchKey}&t=state&c=IN`;
		response = await userServiceAxiosInstance.get(url);
		let responseData = response && response.data;
		let stateOpts = policyConfigMetaData?.stateCodeMap?.filter((state) => state.name.toLowerCase().includes(searchKey?.toLowerCase())) || [];
		if (responseData) {
			let opt = responseData;
			const finalOpts = [...opt, ...stateOpts];
			const updatedOpts = finalOpts.map((state) => {
				let retVal = {
					value: state.id,
					label: state.name
				};
				return retVal;
			});
			clonedFormattedConfigGroup['eligible_states'][inputField.key]['options'] = updatedOpts;
			dispatch({
				type: POLICY_STORE_CONSTANTS.UPDATE_FORMATTED_GRP_CONFIG,
				payload: {
					formattedConfigGroup: clonedFormattedConfigGroup,
					selectedGroupId
				}
			});
		}
	} catch (e) {
		dispatch({
			type: POLICY_STORE_CONSTANTS.AUTO_SUGGEST_FAILURE
		});
		saveServerLogs(e.stack, 'error', PATHS.AUTO_SUGGEST_CITY);
		return;
	}
};

export const fetchPolicyDetails = (selectedGroupId, saveOnly) => async (dispatch) => {
	let response = null;
	dispatch({
		type: POLICY_STORE_CONSTANTS.POLICY_DATA_CALLING
	});
	try {
		let requestObj = {
			'groupIdList': [selectedGroupId]
		};
		response = await ccbAxiosApiCaller.post(PATHS.FETCH_POLICY_DATA, requestObj);
		let resp = response && response.data;
		if (resp && resp.responseCode === '600') {
			dispatch({
				type: POLICY_STORE_CONSTANTS.POLICY_DATA_SUCCESS,
				payload: {
					policyData: resp.getPolicyV2List,
					selectedGroupId,
					saveOnly
				}
			});
		} else if (!saveOnly) {
			dispatch({
				type: POLICY_STORE_CONSTANTS.POLICY_DATA_FAILURE
			});
		}
	} catch (e) {
		dispatch({
			type: POLICY_STORE_CONSTANTS.POLICY_DATA_FAILURE
		});
		saveServerLogs(e.stack, 'error', PATHS.FETCH_POLICY_DATA);
		return;
	}
};

export const savePolicy = (policyData, newPolicyData, selectedGroupId, shouldRedirect) => async (dispatch) => {
	let response = null;
	let error = '';
	try {
		dispatch({
			type: POLICY_STORE_CONSTANTS.SAVE_POLICY_CALLING
		});
		response = await ccbAxiosApiCaller.put(PATHS.SAVE_POLICY_DATA, newPolicyData);
		let resp = response && response.data;
		if (resp && resp.responseCode === '600') {
			const policyDataUpdated = formatSaveResp(policyData, newPolicyData, resp);
			dispatch({
				type: POLICY_STORE_CONSTANTS.SAVE_POLICY_SUCCESS,
				payload: {
					policyDataUpdated,
					shouldRedirect
				}
			});
		} else if (resp) {
			error = APPLICATION_CONSTANTS.API_ERROR;
			if (resp.responseCode === 'VE') {
				error = resp.message;
			}
		}
	} catch (ex) {
		const expData = (ex && ex.response && ex.response.data);
		error = expData ? expData.message : APPLICATION_CONSTANTS.API_ERROR;
		saveServerLogs(`${ex.response ? JSON.stringify(expData) + 'req:' + JSON.stringify(ex.response.config.data) : ex.stack}`, 'error', PATHS.SAVE_POLICY_DATA);
	} finally {
		if (error) {
			dispatch({
				type: POLICY_STORE_CONSTANTS.SAVE_POLICY_FAILURE,
				payload: error
			});
		}
	}
};

export const updateDefaultSelectedGroup = (selectedGroupId, shouldUpdateGroup, selectedGroupIndex) => async (dispatch) => {
	dispatch({
		type: POLICY_STORE_CONSTANTS.UPDATE_DEFAULT_GROUP,
		payload: {
			selectedGroupId: selectedGroupId,
			shouldUpdateGroup,
			selectedGroupIndex
		}
	});
};

export const updateGroupAndFetchPolicy = (selectedGroupId, policyData, selectedGroupIndex, shouldUpdateGroup = true) => async (dispatch) => {
	let selId = selectedGroupId;
	try {
		selId = String(selectedGroupId);
	} catch (error) {

	}
	dispatch(updateDefaultSelectedGroup(selId, shouldUpdateGroup, selectedGroupIndex));
	if (!policyData[selId]) {
		dispatch(fetchPolicyDetails(selId, shouldUpdateGroup));
	}
};

export const togglePopUpData = (showModal, modalData) => async (dispatch) => {
	dispatch({
		type: POLICY_STORE_CONSTANTS.TOGGLE_MODAL,
		payload: {
			showModal,
			modalData
		}
	});
};

export const updateGroupAndOrgConfig = (formattedConfigGroup, formattedOrgConfig, selectedGroupId) => async (dispatch) => {
	dispatch({
		type: POLICY_STORE_CONSTANTS.UPDATE_GRP_ORG_CONFIG,
		payload: {
			formattedConfigGroup,
			formattedOrgConfig,
			selectedGroupId
		}
	});
	dispatch(policyChangeUpdate());
};

export const updateFormattedGrpConfig = (selectedGroupId, formattedConfigGroup) => async (dispatch) => {
	dispatch({
		type: POLICY_STORE_CONSTANTS.UPDATE_FORMATTED_GRP_CONFIG,
		payload: {
			selectedGroupId,
			formattedConfigGroup
		}
	});
	dispatch(policyChangeUpdate());
};

export const updateInputConfig = (formattedOrgConfig) => (dispatch) => {
	dispatch({
		type: POLICY_STORE_CONSTANTS.UPDATE_FORMAT_CONFIG,
		payload: {
			formattedOrgConfig
		}
	});
	dispatch(policyChangeUpdate());
};

export const policyChangeUpdate = (policyChange, selectedGroupId) => (dispatch) => {
	dispatch({
		type: POLICY_STORE_CONSTANTS.POLICY_CHANGE_UPDATE,
		payload: {
			policyChange,
			selectedGroupId
		}
	});
};

export const policyHideFieldsUpdate = (data) => ({
	type: POLICY_STORE_CONSTANTS.HIDE_FIELD_UPDATE,
	payload: data
});

export const getManagersMissingCount = (data) => async (dispatch) => {
	let response = null;
	const accbAxiosApiCaller = getccbAxiosConfig(true, {
		'mmt-auth': getCookie('mmt-auth')
	}, false);
	try {
		let url = PATHS.MANAGER_MISSING_COUNT;
		response = await accbAxiosApiCaller.post(url, data);
		if (response.data && response.data.responseCode === '600') {
			dispatch({
				type: POLICY_STORE_CONSTANTS.MANAGER_MISSING_COUNT_SUCCESS,
				payload: {
					data: response.data,
					id: data.groupId
				}
			});
		} else {
			dispatch({
				type: POLICY_STORE_CONSTANTS.MANAGER_MISSING_COUNT_FAILURE
			});
		}
	} catch (e) {
		dispatch({
			type: POLICY_STORE_CONSTANTS.MANAGER_MISSING_COUNT_FAILURE
		});
		saveServerLogs(e.stack, 'error', '');
		return;
	}
};

export const updateDefaultSectionKeys = (key, add) => ({
	type: POLICY_STORE_CONSTANTS.UPDATE_POLICY_DEFAULT_KEYS,
	payload: {
		key: key,
		add: add
	}
});

export const hideAllFieldsonBlock = (state) => ({
	type: POLICY_STORE_CONSTANTS.UPDATE_HIDE_BLOCKS,
	payload: state
});

export const setDisabledSubFields = (state) => ({
	type: POLICY_STORE_CONSTANTS.SET_DISABLED_FIELDS,
	payload: state
});
