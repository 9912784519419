import { ORGANIZATION_DETAILS_CONSTANT } from 'AppConstants';
import { ORG_DETAILS_CONSTANT } from '../actionConstants';
import { failedFor, successFor } from '../sagaUtil';
import { apiKeyFields } from '../../organizationDetails/configs';

const INITIAL_STATE = {
	orgsData: {},
	subsidiaryData: {},
	modalType: '',
	loading: false,
	clientApiFields: apiKeyFields
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}
	const { type, payload = {} } = action;
	switch (type) {
		case (ORG_DETAILS_CONSTANT.GET_ORGANIZATION_DETAILS): {
			return {
				...state,
				loading: true
			};
		}
		case successFor(ORG_DETAILS_CONSTANT.GET_ORGANIZATION_DETAILS): {
			return {
				...state,
				...payload,
				loading: false
			};
		}
		case failedFor(ORG_DETAILS_CONSTANT.GET_ORGANIZATION_DETAILS): {
			return {
				...state,
				loading: false,
				idError: true
			};
		}
		case ORG_DETAILS_CONSTANT.ADD_UPDATE_ORG_ID_ADDRESS:
		case ORG_DETAILS_CONSTANT.POST_FORM_DATA: {
			return {
				...state,
				modalState: {
					loading: true
				}
			};
		}
		case failedFor(ORG_DETAILS_CONSTANT.POST_IDENTIFIER_DATA):
		case failedFor(ORG_DETAILS_CONSTANT.POST_FORM_DATA): {
			return {
				...state,
				...payload
			};
		}
		case successFor(ORG_DETAILS_CONSTANT.GET_CURRENCIES_API): {
			return {
				...state,
				currencyOptions: payload
			};
		}
		case ORG_DETAILS_CONSTANT.SET_MODAL: {
			return {
				...state,
				...payload,
				modalState: {
					loading: false,
					errorMsg: ''
				}
			};
		}
		case successFor(ORG_DETAILS_CONSTANT.GET_API_KEYS):
		case failedFor(ORG_DETAILS_CONSTANT.GET_API_KEYS): {
			return {
				...state,
				...payload
			};
		}
		case ORG_DETAILS_CONSTANT.UPDATE_API_KEYS: {
			return {
				...state,
				apiKeys: {
					apiLoader: ORGANIZATION_DETAILS_CONSTANT.UPDATE_KEY,
					apiError: false,
					apiSuccess: false
				}
			};
		}
		case successFor(ORG_DETAILS_CONSTANT.UPDATE_API_KEYS):
		case failedFor(ORG_DETAILS_CONSTANT.UPDATE_API_KEYS): {
			return {
				...state,
				apiKeys: {
					apiLoader: false,
					...payload
				}
			};
		}
		case (ORG_DETAILS_CONSTANT.GET_ORGANIZATION_ADDRESS_DETAILS): {
			return {
				...state,
				addressloading: true,
				addressError: false
			};
		}
		case successFor(ORG_DETAILS_CONSTANT.GET_ORGANIZATION_ADDRESS_DETAILS): {
			return {
				...state,
				...payload,
				addressloading: false,
				addressError: false
			};
		}
		case failedFor(ORG_DETAILS_CONSTANT.GET_ORGANIZATION_ADDRESS_DETAILS): {
			return {
				...state,
				addressloading: false,
				addressError: true
			};
		}
		default: {
			return state;
		}
	}
};
