import { failedFor, successFor } from 'reduxStore/sagaUtil';
import cloneDeep from 'lodash/cloneDeep';

import {
	REPORT_DETAILS_STORE_CONSTANTS
} from '../../actionConstants';
import { REPORTS_CONSTANT, APPLICATION_CONSTANTS } from 'AppConstants';
import { filters } from '../../../reporting/pages/details/config';
import { getFilters, handleSelected, getSortProp, INITIAL_DATE_STATE } from 'UtilityComponents/reports/reportDetailsUtil';

const { dateFilters, dateQuery } = INITIAL_DATE_STATE;
const sortProp = getSortProp();

const initialDetailsList = { data: [], error: false, loading: false, hasMore: false };

const initApiState = {
	apiStatus: '',
	msgData: false,
	apiType: false,
	showNotify: false
};

export const INITIAL_STATE = {
	detailsList: { ...initialDetailsList, loading: true },
	selectedRecords: {},
	list: [],
	filters: [dateFilters.dateRange],
	query: { ...dateQuery, ...REPORTS_CONSTANT.DEAFULT_NO_ACTION },
	pageLimit: REPORTS_CONSTANT.DETAILS_PAGE_LIMIT * 6,
	sortQuery: {
		sort: { ...sortProp }
	},
	pageNo: 1,
	reportType: '',
	reportLevel: '',
	isAllSelected: false,
	reportSearchStr: '',
	searchCount: 0,
	...initApiState,
	abKeyReportValue: null
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}
	const { type, payload } = action;
	switch (type) {
		case REPORT_DETAILS_STORE_CONSTANTS.SET_DETAILS_LISTING: {
			return {
				...state,
				...payload
			};
		}
		case REPORT_DETAILS_STORE_CONSTANTS.UPDATE_DETAILS_SELECTED: {
			const { selectedList } = handleSelected(state.selectedRecords, payload);
			const { selectAll, checked } = payload || {};

			return {
				...state,
				selectedRecords: cloneDeep(selectedList),
				isAllSelected: selectAll && checked
			};
		}
		case REPORT_DETAILS_STORE_CONSTANTS.DETAILS_API_LOADING: {
			const { loading = true, apiType = '', data } = payload || {};
			return {
				...state,
				apiType,
				msgData: data,
				apiStatus: loading ? APPLICATION_CONSTANTS.LOADING : ''
			};
		}
		case failedFor(REPORT_DETAILS_STORE_CONSTANTS.DETAILS_API): {
			const { apiType = '', data, showNotify = false } = payload || {};
			return {
				...state,
				apiType,
				msgData: data,
				apiStatus: APPLICATION_CONSTANTS.FAILED,
				showNotify
			};
		}
		case successFor(REPORT_DETAILS_STORE_CONSTANTS.DETAILS_API): {
			const { data, apiType = '', showNotify = false } = payload;
			return {
				...state,
				msgData: data,
				apiType,
				apiStatus: APPLICATION_CONSTANTS.SUCCESS,
				showNotify
			};
		}
		case REPORT_DETAILS_STORE_CONSTANTS.RESET_REPORT_DETAIL_API: {
			return {
				...state,
				...initApiState
			};
		}
		case REPORT_DETAILS_STORE_CONSTANTS.UPDATE_DETAILS_SEARCH: {
			return {
				...state,
				searchCount: state.searchCount + 1,
				detailsList: { ...initialDetailsList },
				isAllSelected: false,
				selectedRecords: {},
				pageNo: 1
			};
		}
		case REPORT_DETAILS_STORE_CONSTANTS.UPDATE_REPORTS_LEVEL: {
			const { reportLevel } = payload;
			return {
				...state,
				searchCount: state.searchCount + 1,
				detailsList: { ...initialDetailsList },
				isAllSelected: false,
				selectedRecords: {},
				reportLevel: reportLevel,
				pageNo: 1
			};
		}
		case REPORT_DETAILS_STORE_CONSTANTS.SET_REPORT_TYPE: {
			const { reportType = '' } = payload || {};
			const filtersConfig = getFilters(filters, reportType);
			const sortProp = getSortProp();
			return {
				...state,
				reportType,
				filters: [dateFilters.dateRange, ...filtersConfig],
				sortQuery: {
					...state.sortQuery,
					sort: { ...sortProp }
				}
			};
		}
		case REPORT_DETAILS_STORE_CONSTANTS.DETAIL_FILTER_QUERY: {
			const { updatedQuery, updatedFilter = [] } = payload || {};
			return {
				...state,
				query: updatedQuery,
				filters: updatedFilter,
				detailsList: { ...initialDetailsList },
				pageNo: 1
			};
		}
		case REPORT_DETAILS_STORE_CONSTANTS.DETAIL_FILTER_CLEAR: {
			const { updatedQuery, updatedFilter } = payload || {};
			return {
				...state,
				query: updatedQuery,
				filters: updatedFilter,
				detailsList: { ...initialDetailsList },
				pageNo: 1
			};
		}
		case REPORT_DETAILS_STORE_CONSTANTS.DETAIL_UPDATE_SORT: {
			const { updatedSortQuery } = payload || {};
			return {
				...state,
				sortQuery: updatedSortQuery,
				detailsList: { ...initialDetailsList },
				pageNo: 1
			};
		}
		case REPORT_DETAILS_STORE_CONSTANTS.DETAIL_UPDATE_PAGE: {
			return {
				...state,
				pageNo: state.pageNo + 1
			};
		}
		case successFor(REPORT_DETAILS_STORE_CONSTANTS.RPRT_DETAILS_FILTER_COUNT): {
			const { filters } = payload;
			return {
				...state,
				filters
			};
		}
		case REPORT_DETAILS_STORE_CONSTANTS.REPORT_DETAILS_SEARCH: {
			const { reportSearchStr = '' } = payload;
			return {
				...state,
				reportSearchStr,
				searchCount: 0
			};
		}
		case successFor(REPORT_DETAILS_STORE_CONSTANTS.GET_AB_VALS_DETAILS): {
			return {
				...state,
				abKeyReportValue: payload
			};
		}
		case REPORT_DETAILS_STORE_CONSTANTS.SET_REPORT_LEVEL: {
			return {
				...state,
				reportLevel: payload
			};
		}
		default:
			return state;
	}
};
