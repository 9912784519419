import { TRAIN_STORE_CONSTANT } from '../actionConstants';

const INITIAL_STATE = {
	passengerDetails: [],
	trainDetails: {
		trainNo: '',
		trainName: '',
		fromCity: '',
		toCity: '',
		boardingStation: '',
		travelDate: new Date(),
		reservationType: '',
		classType: ''
	},
	isPopup: false
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}
	const { type, payload } = action;
	switch (type) {
		case TRAIN_STORE_CONSTANT.SET_PASSENGER_DETAILS_FORM:
			return {
				...state,
				passengerDetails: payload
			};
		case TRAIN_STORE_CONSTANT.SET_TRAIN_DETAILS_FORM:
			return {
				...state,
				trainDetails: payload
			};
		case TRAIN_STORE_CONSTANT.SHOW_POPUP:
			return {
				...state,
				...payload,
				isPopup: true
			};
	}
	return state;
};
