import {
	takeLatest,
	put,
	select,
	call
} from 'redux-saga/effects';

import { REQUISITION_STORE_CONSTANT } from '../actionConstants';
import { APPLICATION_CONSTANTS, PATHS, REQUISITION_CONSTS } from 'AppConstants';
import { getPopularFlight, fetchFlight, fetchHotel, fetchPlaceDetails, fetchBus } from '../../apis/requisitionAddServiceApis';
import { getReasonsTriptags, saveTripFormData } from '../../apis/requisitionApis';
import { getPopularFlightSuccess,
	searchHoteSuccess,
	setTripTagandReasons,
	setTravReqError,
	getPlaceDetailsSuccess,
	searchBusSuccess, setModal, setApiLoading } from 'reduxStore/actions/requisitionTravelActions';
import { saveServerLogs, getCookie } from 'UtilityComponents/Util';
import { formatPopularData, formatRequisitionData } from '../../travRequisition/utils';
import { getPrimaryPax, getTripTagsUserData, getSearchData, getTravellerDetails, getTravelReasons, getApprovalDetails } from '../selectors/requisitionSelectors';
import { SuccessPoptxt, FailedPoptxt } from '../../travRequisition/pages/ReqForm/config';

function* fetchPopularFlight({
	payload
}) {
	try {
		const response = yield call(getPopularFlight, payload);
		const {
			data
		} = response || {};
		if (data) {
			yield put(getPopularFlightSuccess({
				flightSearchList: formatPopularData(data),
				isPopular: true
			}));
		}
	} catch (err) {
		saveServerLogs(err.stack, 'error', PATHS.POPULAR_FLIGHT_SEARCH);
	}
}

function* searchFlight({
	payload
}) {
	try {
		const dvid = getCookie('dvid');
		const headers = {
			'device-id': dvid,
			'app-ver': '1.0.0',
			'mcid': dvid,
			'pfm': 'DESKTOP',
			'src': 'mmt',
			'os': 'mac'
		};
		const response = yield call(fetchFlight, payload, headers);
		const {
			data
		} = response || {};
		if (data && data.results && data.results.SUGGESTIONS) {
			const suggestionData = data.results.SUGGESTIONS.data;
			yield put(getPopularFlightSuccess({
				flightSearchList: suggestionData,
				isPopular: false
			}));
		}
	} catch (err) {
		saveServerLogs(err.stack, 'error', PATHS.SEARCH_FLIGHT);
	}
}

function* searchHotel({ payload }) {
	try {
		const response = yield call(fetchHotel, payload);
		const { data } = response || {};

		if (data) {
			yield put(searchHoteSuccess({ hotelSearchList: data }));
		}
	} catch (err) {
		saveServerLogs(err.stack, 'error', PATHS.SEARCH_HOTEL);
	}
}

function* getPlaceDetails({ payload }) {
	try {
		const response = yield call(fetchPlaceDetails, payload);
		const { data } = response || {};

		if (data && data.response) {
			const { details = {}, location, placeid } = data.response || {};
			const { search = {}, country = {} } = details;
			const { id, type, name } = search;
			const contextId = { region: id };
			yield put(getPlaceDetailsSuccess({ contextId,
				center: location,
				context: search,
				id,
				cityCode: id,
				cityName: name,
				type,
				countryName: country.name,
				countryCode: country.id,
				place_id: placeid }));
		}
	} catch (err) {
		saveServerLogs(err.stack, 'error', PATHS.GET_PLACE_DETAILS);
	}
}

function* searchBus({ payload }) {
	try {
		const response = yield call(fetchBus, payload);
		const { data } = response || {};
		if (data && data.data && data.data.documents) {
			yield put(searchBusSuccess({ busSearchList: data.data.documents }));
		}
	} catch (err) {
		saveServerLogs(err.stack, 'error', PATHS.SEARCH_HOTEL);
	}
}

function* getTriptagandReasons() {
	let errorMsg = false;
	try {
		yield put(setApiLoading(REQUISITION_CONSTS.API_CONSTS.REASONS_TRIP_TAGS));
		const primaryPax = yield select(getPrimaryPax);
		const response = yield call(getReasonsTriptags, { primaryPaxEmailId: primaryPax.email });
		const {
			data
		} = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			yield put(setTripTagandReasons({
				tripTags: data.tripTags?.attributeList,
				travelReasons: data.uiConfig?.travelReasons?.options,
				approvalDetails: data.approvalDetails
			}));
		} else {
			errorMsg = data && data.message ? data.message : false;
		}
	} catch (err) {
		saveServerLogs(err.stack, 'error', PATHS.GET_TRIPTAGS_REASONS);
	} finally {
		yield put(setApiLoading(false));
		if (errorMsg) {
			const modalData = {
				name: REQUISITION_CONSTS.API_MODALS,
				data: {
					apiStatus: 'ERROR',
					content: errorMsg
				}
			};
			yield put(setModal(modalData));
		}
	}
}

function* proceedToSubmit({
	payload
}) {
	let modalData = {};
	let isError = false;
	try {
		yield put(setTravReqError(false));
		yield put(setApiLoading(REQUISITION_CONSTS.API_CONSTS.PROCEED_SUBMIT));
		const tripTagData = yield select(getTripTagsUserData);
		const { flightData, cabsData, busData, hotelData } = yield select(getSearchData);
		const travllerData = yield select(getTravellerDetails);
		const travelReasons = yield select(getTravelReasons);
		const approverDetails = yield select(getApprovalDetails) || { approvingManagers: [] };
		const mgrCount = approverDetails.approvingManagers.length;
		const { email } = yield select(getPrimaryPax);
		const { retData, travReqError } = formatRequisitionData({ tripTagData, flightData, cabsData, travllerData, busData, hotelData, travelReasons, email });
		if (travReqError) {
			yield put(setTravReqError(travReqError));
		} else {
			const response = yield call(saveTripFormData, retData);
			if (response.data && response.data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE && response.data.requisitionId) {
				let successModalConfig = { ...SuccessPoptxt };

				const apprTxt = `${approverDetails.approvingManagers[0].manager?.name} ${mgrCount > 1 ? `, & ${(mgrCount - 1)} others` : ''}`;
				successModalConfig.content = successModalConfig.content.replace('approvername', apprTxt);
				modalData = {
					name: REQUISITION_CONSTS.API_MODALS,
					data: successModalConfig
				};
			} else {
				isError = true;
			}
		}
	} catch (err) {
		isError = true;
		saveServerLogs(err.stack, 'error', PATHS.SAVE_TRIP_FORM_DATA);
	} finally {
		if (isError) {
			modalData = {
				name: REQUISITION_CONSTS.API_MODALS,
				data: FailedPoptxt
			};
		}
		yield put(setModal(modalData));
	}
}

export default [
	takeLatest(REQUISITION_STORE_CONSTANT.GET_POPULAR_FLIGHT, fetchPopularFlight),
	takeLatest(REQUISITION_STORE_CONSTANT.GET_FLIGHT_SEARCH, searchFlight),
	takeLatest(REQUISITION_STORE_CONSTANT.GET_HOTEL_SEARCH, searchHotel),
	takeLatest(REQUISITION_STORE_CONSTANT.GET_BUS_SEARCH, searchBus),
	takeLatest(REQUISITION_STORE_CONSTANT.GET_PLACE_DETAILS, getPlaceDetails),
	takeLatest(REQUISITION_STORE_CONSTANT.GET_REASONS_TRIP_TAGS, getTriptagandReasons),
	takeLatest(REQUISITION_STORE_CONSTANT.PROCEED_TO_SUBMIT, proceedToSubmit)
];
