// report Details Actions comes here
import { REPORT_DETAILS_STORE_CONSTANTS } from '../../actionConstants';
import { actionCreator, successFor, failedFor } from '../../sagaUtil';

export const getDetails = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.GET_DETAILS_LISTING);
export const setDetails = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.SET_DETAILS_LISTING);
export const setApiLoading = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.DETAILS_API_LOADING);
export const setApiError = actionCreator(failedFor(REPORT_DETAILS_STORE_CONSTANTS.DETAILS_API));
export const setApiSuccess = actionCreator(successFor(REPORT_DETAILS_STORE_CONSTANTS.DETAILS_API));
export const resetApiState = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.RESET_REPORT_DETAIL_API);
export const setReportType = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.SET_REPORT_TYPE);
export const setSelected = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.UPDATE_DETAILS_SELECTED);
export const setFilterQuery = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.DETAIL_FILTER_QUERY);
export const updateQueryPage = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.DETAIL_UPDATE_PAGE);
export const updateSortQuery = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.DETAIL_UPDATE_SORT);
export const clearFilterUpdate = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.DETAIL_FILTER_CLEAR);
export const getFilterCount = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.RPRT_DETAILS_FILTER_COUNT);
export const getFilterCountSuccess = actionCreator(successFor(REPORT_DETAILS_STORE_CONSTANTS.RPRT_DETAILS_FILTER_COUNT));
export const setReportSearchStr = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.REPORT_DETAILS_SEARCH);
export const updateSearch = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.UPDATE_DETAILS_SEARCH);
export const updateReportLevel = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.UPDATE_REPORTS_LEVEL);
export const getAbValuesDetails = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.GET_AB_VALS_DETAILS);

export const getAbValsDetailsSuccess = actionCreator(successFor(REPORT_DETAILS_STORE_CONSTANTS.GET_AB_VALS_DETAILS));
export const setReportLevel = actionCreator(REPORT_DETAILS_STORE_CONSTANTS.SET_REPORT_LEVEL);
