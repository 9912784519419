import { RESOURCE_STORE_CONSTANT } from '../actionConstants';

const INITIAL_STATE = {};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}

	const { type, payload = {} } = action;

	switch (type) {
		case RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA_LOADING: {
			return {
				...state,
				...payload
			};
		}
		case RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA: {
			return {
				...state,
				...payload
			};
		}
		case RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA_FAILURE: {
			return {
				...state,
				...payload
			};
		}
		default:
			return state;
	}
};
