export const employeeSelectors = {
	isAllSelected: (state) => state.employee.isAllSelected,
	employeeList: (state) => state.employee.employeeList,
	selectedEmployees: (state) => state.employee.selectedEmployees,
	filters: (state) => state.employee.filters,
	query: (state) => state.employee.query,
	empSearchStr: (state) => state.employee.empSearchStr,
	selectedGrpId: (state) => state.employee.selectedGrpId,
	selectedVals: (state) => state.employee.selectedVals,
	isSingleSelect: (state) => state.employee.isSingleSelect
};
