import { takeLatest, put, call, take, select } from 'redux-saga/effects';
import { buffers, eventChannel, END } from 'redux-saga';

import { GROUP_STORE_CONSTANTS } from '../actionConstants';
import { getGroupsDataSuccess,
	getGroupsDataFailure,
	validateEmployeeError,
	validateEmployeeSuccess,
	createNewGroupSuccess,
	setGrpModal,
	getConflictingGrpsSuccess,
	removeConflictSuccess,
	removeConflictFailure,
	apiFailed,
	apiLoading,
	apiSuccess,
	getGroupsData,
	dwlGroupDataSuccess,
	getUpdateStatusSuccess,
	createNewGroupError } from 'reduxStore/actions/groupsAction';
import { getGrp,
	validateEmpApi,
	createGrpApi,
	getConflictingGrpsApi,
	resolveConflictsApi,
	uploadCsvApi,
	deleteGroup,
	downloadGrpData,
	cancelRequest } from '../../apis/groupapis';
import { getUpdateStatusApi } from '../../apis/empApis';
import { updateDataCount, fetchGroupsData, resetApiState as resetEmpApistate } from 'reduxStore/actions/employeeActions';
import { APPLICATION_CONSTANTS, PATHS, GROUP_CONSTANTS, EMPLOYEE_CONSTANTS } from 'AppConstants';
import { groupFilterData, getGroupDetails } from '../../UtilityComponents/groupsUtil';
import { selectedGroups } from '../selectors/groupSelector';
import { getUploadRemainingTime, saveServerLogs, urlParam } from 'UtilityComponents/Util';
import { MSG_DATA, ADMIN_GRP_DLT_MSG } from '../../Groups/config';

function* getGroups({ payload }) {
	try {
		const { isEmployeePage = false, key = 'groups' } = payload || {};
		const response = yield call(getGrp);
		const groupId = urlParam('groupId');
		const resp = response && response.data;
		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			yield put(fetchGroupsData({ key, groupList: resp.groupDetailsList }));
			if (!isEmployeePage) {
				const selectedGrpDet = getGroupDetails(groupId, resp.groupDetailsList);
				const { orgData = [], nonOrgData = [] } = groupFilterData(resp.groupDetailsList);
				yield put(getGroupsDataSuccess({ orgGrpData: orgData, nonOrgGrpData: nonOrgData, groupData: nonOrgData, selectedGrpDet }));
			}
		}
	} catch (err) {
		yield put(getGroupsDataFailure());
		saveServerLogs(err.stack, 'error', PATHS.GET_GROUP);
	}
}

function* validateEmployee({ payload = {} }) {
	let isError = false;
	let errMsg = '';
	const { requestData = {}, isCsv = false, fileIdentifier = '' } = payload;
	try {
		yield put({ type: GROUP_STORE_CONSTANTS.VALIDATE_EMP_LOADING, payload: { fileIdentifier } });
		const result = yield call(validateEmpApi, requestData);
		const { data } = result || {};
		if ( data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			yield put(validateEmployeeSuccess({ validationData: data, isCsv, fileIdentifier }));
		} else {
			isError = true;
			errMsg = data.message;
		}
	} catch (err) {
		isError = true;
		saveServerLogs(err.stack, 'error', PATHS.VALIDATE_EMP);
	} finally {
		if (isError) {
			yield put(validateEmployeeError({ fileIdentifier, errMsg }));
		}
	}
}

function* createNewGroup({ payload = {} }) {
	const {
		apiData,
		successMsg,
		isAddMember = false,
		isCreateModal = false,
		isEditModal = false
	} = payload;

	let isError = false;
	try {
		yield put({ type: GROUP_STORE_CONSTANTS.VALIDATE_EMP_LOADING });
		yield put(resetEmpApistate());
		const result = yield call(createGrpApi, apiData);
		const { data } = result || {};
		if ( data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const { groupDetails = {}, sendEmail = false, totalCount, jobId, jobStatus = APPLICATION_CONSTANTS.PENDING } = data;

			const showStatusModal = sendEmail && jobStatus !== APPLICATION_CONSTANTS.SUCCESS;
			if (showStatusModal) {
				yield put(getUpdateStatusSuccess({
					totalCount: totalCount,
					remainingCount: totalCount,
					jobId: jobId,
					jobStatus
				}));
				yield put(setGrpModal(GROUP_CONSTANTS.BULK_STATUS_POP));
			} else {
				yield put(createNewGroupSuccess({ group: groupDetails }));
			}

			switch (true) {
				case isCreateModal && !showStatusModal:
					yield put(setGrpModal(GROUP_CONSTANTS.CREATE_GRP_SUCCESS_MODAL));
					break;
				case isAddMember:
				case isEditModal:
					yield put(setGrpModal(''));
					break;
			}

			if (successMsg && !showStatusModal) {
				yield put(apiSuccess({
					apiType: GROUP_STORE_CONSTANTS.CREATE_NEW_GRP,
					showNotify: true,
					data: successMsg
				}));
			}

			if (isAddMember) {
				yield put(updateDataCount());
			}
			yield* getGroups({});
		} else {
			isError = true;
		}
	} catch (err) {
		isError = true;
		saveServerLogs(err.stack, 'error', PATHS.CREATE_GROUP);
	} finally {
		if (isError) {
			yield put(createNewGroupError());
		}
	}
}

function* getConflictingGroups() {
	try {
		const response = yield call(getConflictingGrpsApi);
		const { data } = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const { groupDetailsList = [] } = data;
			yield put(getConflictingGrpsSuccess({ conflictingGrps: groupDetailsList }));
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.GET_CONFLICTING_GRPS);
	}
}

function* resolveConflictingGrps({ payload }) {
	let isError = false;
	const { orgId } = payload;
	try {
		yield put(apiLoading({ loading: true, apiType: GROUP_STORE_CONSTANTS.REMOVE_CONFLICTS }));
		const result = yield call(resolveConflictsApi, payload);
		const { data } = result || {};
		if ( data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			yield put(removeConflictSuccess());
			yield put(apiSuccess({
				apiType: GROUP_STORE_CONSTANTS.REMOVE_CONFLICTS,
				showNotify: true,
				data: 'Successfully Updated Groups!'
			}));
			yield* getGroups({ payload: { orgId } });
		} else {
			isError = true;
		}
	} catch (err) {
		isError = true;
		saveServerLogs(err.stack, 'error', PATHS.RESOLVE_CONFLICTS);
	} finally {
		if (isError) {
			yield put(removeConflictFailure());
			yield put(apiFailed({ apiType: GROUP_STORE_CONSTANTS.REMOVE_CONFLICTS }));
		}
	}
}

function fileUploaderChannel(fileData, sendInvitation) {
	return eventChannel((emitter) => {
		let status;
		const completeStatus = {
			percentage: 0,
			status: APPLICATION_CONSTANTS.PENDING
		};
		emitter({
			type: GROUP_STORE_CONSTANTS.GRP_UPLOAD_STATUS,
			payload: { ...completeStatus }
		});

		const config = {
			uploadedBytes: 0,
			totalLength: 0
		};

		const timeController = getUploadRemainingTime(config, (remainingTime) => {
			emitter({
				type: GROUP_STORE_CONSTANTS.GRP_UPLOAD_TIME,
				payload: {
					remainingTime: remainingTime
				}
			});
		});

		const onUploadProgress = (progressEvent) => {
			const { total, loaded, target } = progressEvent;
			config.totalLength = progressEvent.lengthComputable ?
				total :
				target.getResponseHeader('content-length');
			config.uploadedBytes = loaded;
			const progressData = Math.round( (loaded * 100) / config.totalLength );
			const percentage = progressData < 80 ? progressData : 80;
			emitter({
				type: GROUP_STORE_CONSTANTS.GRP_UPLOAD_STATUS,
				payload: { ...completeStatus, percentage: percentage }
			});
		};

		emitter(apiLoading({ loading: true, apiType: GROUP_STORE_CONSTANTS.GRP_CSV_UPLOAD }));
		let error = false;
		let errMsg = false;

		uploadCsvApi({ apiData: fileData, onUploadProgress, sendInvitation }).then((response) => {
			const { data } = response || {};
			if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
				const { conflictingGrps } = data;
				emitter(getConflictingGrpsSuccess({ conflictingGrps }));
				status = APPLICATION_CONSTANTS.SUCCESS;
			} else {
				error = true;
				errMsg = response.data.message;
				status = APPLICATION_CONSTANTS.FAILED;
			}
		}).catch((e) => {
			status = APPLICATION_CONSTANTS.FAILED;
			error = e.message !== 'Operation canceled by the user.';
			saveServerLogs(e, 'error', PATHS.EMP_BULK_UPLOAD);
		}).finally(() => {
			if (error) {
				emitter(apiFailed({ apiType: GROUP_STORE_CONSTANTS.GRP_CSV_UPLOAD }));
			} else {
				emitter(apiLoading({ loading: false }));
			}
			emitter({
				type: GROUP_STORE_CONSTANTS.GRP_UPLOAD_STATUS,
				payload: {
					status,
					errMsg
				},
				status
			});
			clearInterval(timeController);
			emitter({ completed: true });
			emitter(END);
		});
		return () => {};
	}, buffers.sliding(3));
}

function* bulkCsvUpload({ payload = {} }) {
	const { fileData, sendInvitation } = payload;
	const channel = yield call(fileUploaderChannel, fileData, sendInvitation);
	while (true) {
		const event = yield take(channel);
		const { completed } = event;
		if (completed) {
			return;
		} else {
			yield put(event);
		}
	}
}

function* deleteGrps() {
	const { error: errMsg, success: successMsg } = MSG_DATA['deleteGrp'];
	try {
		yield put(apiLoading({ loading: true, apiType: GROUP_STORE_CONSTANTS.DELETE_GROUPS }));
		const groups = yield select(selectedGroups);
		const gid = Object.keys(groups);
		const ids = [];
		for (let id of gid) {
			ids.push(Number(id));
		}
		const response = yield call(deleteGroup, { ids });
		const resp = response && response.data;
		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			yield put(getGroupsData());
			yield put(apiSuccess({
				data: successMsg || '',
				apiType: GROUP_STORE_CONSTANTS.DELETE_GROUPS,
				showNotify: true
			}));
		}
	} catch (err) {
		let msg = '';
		if (err?.response?.data?.status === APPLICATION_CONSTANTS.FORBIDDEN_RESPONSE) {
			msg = ADMIN_GRP_DLT_MSG;
		}
		yield put(apiFailed({
			data: msg || errMsg || '',
			apiType: GROUP_STORE_CONSTANTS.DELETE_GROUPS,
			showNotify: true
		}));
		saveServerLogs(e.stack, 'error', PATHS.DELETE_GROUPS);
	}
}

function* getUploadStatus({ payload = {} }) {
	let response = null;
	const { jobId } = payload;
	let reqData = {
		jobId
	};
	if (!jobId) {
		reqData = {
			pageName: `${EMPLOYEE_CONSTANTS.EMP_UPDATE},${GROUP_CONSTANTS.GRP_UPDATE}`
		};
	}
	try {
		response = yield call(getUpdateStatusApi, reqData);
		const { data } = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			if (!jobId && data.jobId) {
				yield put(setGrpModal(GROUP_CONSTANTS.BULK_STATUS_POP));
			}
			yield put(getUpdateStatusSuccess(data));
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.BULK_UPDATE_EMP);
		yield put(getUpdateStatusSuccess({
			status: APPLICATION_CONSTANTS.FAILED
		}));
	}
}

function* cancellingRequest() {
	cancelRequest('Operation canceled by the user.');
}

function* dwlGrpDataCsv() {
	try {
		const selectedGrps = yield select(selectedGroups);
		const gid = Object.keys(selectedGrps);
		const groups = [];
		for (let id of gid) {
			groups.push(Number(id));
		}
		const reqData = {
			groups
		};

		const payload = { reqData, msgData: MSG_DATA['dwlGrp'] };
		yield downCSv(payload);
	} catch (e) {
	}
}

function* downCSv(payload) {
	const { reqData, msgData } = payload;
	let { error: errMsg, success: successMsg, zeroEmpMsg } = msgData;
	let error = false;
	try {
		yield put(apiLoading({ loading: true, apiType: GROUP_STORE_CONSTANTS.DOWNLOAD_GROUP }));

		const response = yield call(downloadGrpData, reqData);
		const { data } = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const { csvOnEmail, csvProcessId, isZeroEmployee } = data;
			if (csvOnEmail) {
				yield put(dwlGroupDataSuccess());
			} else if (csvProcessId) {
				window.open(`${PATHS.CCB_URL}${PATHS.DWL_EMP_JOB}?downloadCsvId=${csvProcessId}`);
				yield put(apiSuccess({
					data: successMsg || '',
					apiType: GROUP_STORE_CONSTANTS.DOWNLOAD_GROUP,
					showNotify: true
				}));
			} else {
				error = true;
				if (isZeroEmployee) {
					errMsg = zeroEmpMsg;
				}
			}
		}
	} catch (e) {
		error = true;
		saveServerLogs(e.stack, 'error', PATHS.EMP_DWL_DATA);
	} finally {
		if (error) {
			yield put(apiFailed({
				data: errMsg || '',
				apiType: GROUP_STORE_CONSTANTS.DOWNLOAD_GROUP,
				showNotify: true
			}));
		}
	}
}

export default [
	takeLatest(GROUP_STORE_CONSTANTS.GET_GROUP_DATA, getGroups),
	takeLatest(GROUP_STORE_CONSTANTS.VALIDATE_EMPLOYEE, validateEmployee),
	takeLatest(GROUP_STORE_CONSTANTS.CREATE_NEW_GRP, createNewGroup),
	takeLatest(GROUP_STORE_CONSTANTS.CANCEL_GRP_REQUEST, cancellingRequest),
	takeLatest(GROUP_STORE_CONSTANTS.GET_CONFLICTING_STATUS, getConflictingGroups),
	takeLatest(GROUP_STORE_CONSTANTS.REMOVE_CONFLICTS, resolveConflictingGrps),
	takeLatest(GROUP_STORE_CONSTANTS.GRP_CSV_UPLOAD, bulkCsvUpload),
	takeLatest(GROUP_STORE_CONSTANTS.DELETE_GROUPS, deleteGrps),
	takeLatest(GROUP_STORE_CONSTANTS.GET_UPDATE_STATUS, getUploadStatus),
	takeLatest(GROUP_STORE_CONSTANTS.DOWNLOAD_GROUP, dwlGrpDataCsv)
];
