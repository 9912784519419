import { SHORTLIST_OPTIONS_CONST } from '../actionConstants';
import { formatData, updateListing } from '../../UtilityComponents/sharedOptionUtils';

const INITIAL_STATE = {
    data: false,
    shared: false,
    pageLoaded: false
}

export default (state = INITIAL_STATE, action) => {
    if (!action) {
        return state;
    }

    const { type, payload = {} } = action;

    switch (type) {
        case SHORTLIST_OPTIONS_CONST.GET_OPTIONS_SUCCESS: {
            const optionsData = formatData(payload);
            return {
                ...state,
                ...optionsData,
                pageLoaded: true
            }
        }
        case SHORTLIST_OPTIONS_CONST.UPDATE_GRPIDS_DATA: {
            const { grpId } = payload
            let grpProductIds = [...state.grpProductIds[grpId]];
            for(let i = 0; i< payload.idData.length ; i++) {
                grpProductIds = removeItem(grpProductIds, payload.idData[i]);
            }
           
            const lobTypes = state.lobTypes;
            if(grpProductIds.length === 0) {
                const lob = state.groupsData[grpId].type;
                lobTypes[lob].count--;

            }
            updateListing(payload.idData, payload.lob, grpId, state.wishlistId)
            return {
                ...state,
                grpProductIds: {
                    ...state.grpProductIds,
                    [grpId] : [...grpProductIds],
                },
                lobTypes: {...lobTypes}
            }
        }
        case SHORTLIST_OPTIONS_CONST.SHARE_SUCCESS: {
            return {
                ...state,
                shared: true,
            }
        }
        case SHORTLIST_OPTIONS_CONST.PAGE_CALLED: {
            return {
                ...state,
                pageLoaded: true,
            }
        }
        default:
            return state;
    }
}


const removeItem = (data, id) => {
    for( let i = 0; i < data.length; i++){ 
        if (data[i] === id) { 
            data.splice(i, 1); 
        }
    }
    return data;
}