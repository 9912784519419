import { POPUP_ACTION_CONSTANTS } from '../actionConstants';

const INITIAL_STATE = {
	showPop: false,
	popupData: false
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}
	const { type, payload } = action;
	switch (type) {
		case POPUP_ACTION_CONSTANTS.HIDE_POP: {
			return {
				...state,
				showPop: false,
				popupData: false
			};
		}
		case POPUP_ACTION_CONSTANTS.UPDATE_POP: {
			return {
				...state,
				...payload
			};
		}
		default: {
			return state;
		}
	}
};
