import { DASHBOARD_CONST } from '../actionConstants';
import { getconfigData } from 'apis/commonApis';
import { getccbAxiosConfig } from '../../apisSetup/ccbConfig';
import { PATHS } from '../../AppConstants';
import { APPLICATION_CONSTANTS } from '../../AppConstants';
import { saveServerLogs } from '../../UtilityComponents/Util';

const axiosApiCaller = getccbAxiosConfig(true, {}, false);

function logoutUser() {
	if (window) {
		window.location = PATHS.LANDING_LOGIN_PAGE;
	}
}

function doSavingsCall(from, to, dispatch) {
	dispatch({
		type: DASHBOARD_CONST.SAVINGS_CALL_IN_PROGRESS,
		savings_payload: { callState: DASHBOARD_CONST.SAVINGS_CALL_IN_SUCCESS, data: null }
	});
	dispatch({
		type: DASHBOARD_CONST.WALLET_CALL_IN_PROGRESS,
		savings_payload: { callState: DASHBOARD_CONST.WALLET_CALL_IN_PROGRESS, data: null }
	});

	try {
		axiosApiCaller.get(PATHS.GET_WALLET_DATA).then((res) => {
			handleWalletData(res.data, dispatch);
		})
			.catch((err) => {
				dispatch({
					type: DASHBOARD_CONST.WALLET_CALL_FAILED,
					wallet_payload: {
						callState: DASHBOARD_CONST.WALLET_CALL_FAILED,
						errorType: err,
						errorMsg: 'Something went wrong, please try again later'
					}
				});
			});

		axiosApiCaller.get(PATHS.GET_SAVING_DATA + from + '&endDate=' + to).then((res) => {
			handleSavingsData(res.data, dispatch);
		})
			.catch((err) => {
				dispatch({
					type: DASHBOARD_CONST.SAVINGS_CALL_FAILED,
					savings_payload: {
						callState: DASHBOARD_CONST.SAVINGS_CALL_FAILED,
						errorType: err,
						errorMsg: 'Something went wrong, please try again later'
					}
				});
			});
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.GET_WALLET_DATA);
		if (typeof window !== 'undefined' && window && window.Raven) {
			window.Raven.captureException(e);
		}
	}
}

export const fetchDashboardData = (id, granularity, from, to, dateRangeText) => ((dispatch) => {
	dispatch({
		type: DASHBOARD_CONST.CALL_IN_PROGRESS,
		dashboard_payload: { callState: DASHBOARD_CONST.CALL_IN_PROGRESS }
	});
	const url = PATHS.DASHBOARD_DATA + granularity + '&from=' + from + '&to=' + to;
	try {
		axiosApiCaller.get(url).then((res) => {
			if (res.data && res.data.data) {
				handleDashboardData(id, res.data, dispatch, dateRangeText);
			} else {
				logoutUser();
			}
		})
			.catch((err) => {
				dispatch({
					type: DASHBOARD_CONST.CALL_FAILED,
					dashboard_payload: {
						callState: DASHBOARD_CONST.CALL_FAILED,
						errorType: err,
						errorMsg: 'Something went wrong, please try again later'
					}
				});
				saveServerLogs(err.stack, 'error', url);
			});
		doSavingsCall(from, to, dispatch);
	} catch (err) {
		saveServerLogs(err.stack, 'error', url);
	}
});

const handleSavingsData = (data, dispatch) => {
	dispatch({
		type: DASHBOARD_CONST.SAVINGS_CALL_IN_SUCCESS,
		savings_payload: { callState: DASHBOARD_CONST.SAVINGS_CALL_IN_SUCCESS, data: data }
	});
};
const handleWalletData = (data, dispatch) => {
	dispatch({
		type: DASHBOARD_CONST.WALLET_CALL_IN_SUCCESS,
		wallet_payload: { callState: DASHBOARD_CONST.WALLET_CALL_IN_SUCCESS, data: data }
	});
};

export const fetchDashboardConfig = () => (async (dispatch) => {
	dispatch({
		type: DASHBOARD_CONST.CONFIG_CALL_IN_PROGRESS,
		config_Payload: { callState: DASHBOARD_CONST.CALL_FAILED, data: getDefaultConfData }
	});
	try {
		const res = await getconfigData('DASHBOARD_CONFIG');
		handleDashboardConfData(res.data, dispatch);
	} catch (err) {
		dispatch({
			type: DASHBOARD_CONST.CONFIG_CALL_FAILED,
			config_Payload: { callState: DASHBOARD_CONST.CALL_FAILED, data: getDefaultConfData }
		});
		saveServerLogs(err.stack, 'error', 'dashboardconf');
	}
});

export const fetchBannerData = () => async (dispatch) => {
	try {
		const resp = await axiosApiCaller.get(PATHS.GET_BANNER_DATA);
		const { data } = resp || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const payload = {
				referralBanners: data?.referralCards,
				supportBanners: data?.supportBanners || []
			};
			dispatch({
				type: DASHBOARD_CONST.DASHBOARD_BANNER,
				payload: payload
			});
		}
	} catch (err) {
		saveServerLogs(err.stack, 'error', PATHS.GET_BANNER_DATA);
	}
};

const handleDashboardData = (id, data, dispatch, dateRangeText) => {
	dispatch({
		type: DASHBOARD_CONST.CALL_IN_SUCCESS,
		dashboard_payload: {
			callState: DASHBOARD_CONST.CALL_IN_SUCCESS,
			data: data.data,
			requestId: id,
			dateRangeText: dateRangeText
		}
	});
};
const handleDashboardConfData = (data, dispatch) => {
	dispatch({
		type: DASHBOARD_CONST.CONFIG_CALL_IN_SUCCESS,
		config_Payload: { callState: DASHBOARD_CONST.CALL_IN_SUCCESS, data: data }
	});
};
// /////////////////////////////Default conf///////////////////////////////
export const getDefaultConfData = () => ({
	'summary': ['total_bookings', 'total_spends', 'total_savings', 'wallet_bookings', 'frequent_traveler', 'group_name'],
	'detailed_stats': {
		'order': ['total_spends', 'total_bookings', 'groupwise_booking', 'ap_stats', 'wallet_lob_stats', 'policy_stats', 'top_hotels', 'top_airlines', 'top_travellers']
	},
	'detail_stat_config': {
		'total_spending': { 'type': 'bar' },
		'total_bookings': { 'type': 'bar' },
		'groupwise_booking': { 'type': 'bar' },
		'ap_stats': { 'type': 'bar' },
		'wallet_lob_stats': { 'type': 'bar' },
		'policy_stats': { 'type': 'pie' },
		'top_hotels': { 'type': 'columns' },
		'top_airlines': { 'type': 'columns' },
		'top_travellers': { 'type': 'columns' }
	}
});
// /////////////////////////////Default conf///////////////////////////////
