import cloneDeep from 'lodash/cloneDeep';

import { failedFor, successFor } from 'reduxStore/sagaUtil';
import {
	REPORT_LANDING_STORE_CONSTANTS
} from '../../actionConstants';

import {
	filterConfig
} from '../../../reporting/pages/summary/config';
import { APPLICATION_CONSTANTS, REPORTS_CONSTANT } from 'AppConstants';

import { presetFilters, formatGranuality, formatReportData, formatAddData, reportFieldFormatter } from 'UtilityComponents/reports/reportSummaryUtil';
const filtersObject = presetFilters(filterConfig, []);
const initialReport = formatReportData();

const initApiState = {
	apiStatus: '',
	msgData: false,
	apiType: false,
	showNotify: false
};

const initGstrDownload = {
	loading: false,
	success: false,
	error: false,
	errorMessage: ''
};

export const INITIAL_STATE = {
	apiSuccess: false,
	apiError: false,
	dwnloadReportData: {},
	summaryData: {
		data: [],
		apiLoading: true,
		apiSuccess: false
	},
	filters: filtersObject.filters,
	appliedFilters: filtersObject.appliedFilters,
	query: {
		...filtersObject.query, ...REPORTS_CONSTANT.DEAFULT_BOOKING_ACTION
	},
	gstrStatus: {
		gstrStatusSuccess: false,
		gstrLoading: false,
		gstrStatusfailed: false
	},
	customReports: {
		reports: [],
		apiLoading: true,
		apiSuccess: false
	},
	isReportDeleted: false,
	masterAdditionalData: {},
	...initialReport,
	...initApiState,
	gstrDownload: { ...initGstrDownload }
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}
	const { type, payload } = action;
	switch (type) {
		case REPORT_LANDING_STORE_CONSTANTS.GET_SUMMARY: {
			return {
				...state,
				summaryData: {
					data: [],
					apiLoading: true,
					apiSuccess: false
				}
			};
		}
		case failedFor(REPORT_LANDING_STORE_CONSTANTS.GET_SUMMARY):
		case successFor(REPORT_LANDING_STORE_CONSTANTS.GET_SUMMARY): {
			return {
				...state,
				...payload
			};
		}
		case REPORT_LANDING_STORE_CONSTANTS.UPDATE_FILTER_DATA: {
			const { filters = state.filters, appliedFilters = state.appliedFilters } = payload;
			return {
				...state,
				filters: cloneDeep(filters),
				listApiCalled: false,
				appliedFilters: cloneDeep(appliedFilters)
			};
		}
		case REPORT_LANDING_STORE_CONSTANTS.UPDATE_FILTER_QUERY: {
			return {
				...state,
				query: cloneDeep(payload)
			};
		}

		case REPORT_LANDING_STORE_CONSTANTS.UPDATE_FILTER_QUERY_EXP: {
			const filterData = payload === 1 ?
				{ ...state.query, ...REPORTS_CONSTANT.DEAFULT_BOOKING_ACTION } :
				{ ...state.query, ...REPORTS_CONSTANT.DEAFULT_NO_ACTION };
			return {
				...state,
				query: cloneDeep(filterData)
			};
		}

		case REPORT_LANDING_STORE_CONSTANTS.GET_GSTR_STATUS: {
			return {
				...state,
				gstrStatus: {
					gstrStatusSuccess: false,
					gstrLoading: true,
					gstrStatusfailed: false
				}
			};
		}
		case successFor(REPORT_LANDING_STORE_CONSTANTS.GET_GSTR_STATUS): {
			return {
				...state,
				gstrStatus: {
					gstrStatusSuccess: true,
					gstrLoading: false,
					...payload
				}
			};
		}
		case failedFor(REPORT_LANDING_STORE_CONSTANTS.GET_GSTR_STATUS): {
			return {
				...state,
				gstrStatus: {
					gstrStatusfailed: true,
					gstrLoading: false
				}
			};
		}
		case REPORT_LANDING_STORE_CONSTANTS.GET_CUSTOM_REPORTS: {
			return {
				...state,
				customReports: {
					apiLoading: true,
					apiSuccess: false,
					apiFailed: false
				}
			};
		}
		case successFor(REPORT_LANDING_STORE_CONSTANTS.GET_CUSTOM_REPORTS): {
			return {
				...state,
				customReports: {
					reports: payload,
					apiLoading: false,
					apiSuccess: true
				}
			};
		}
		case failedFor(REPORT_LANDING_STORE_CONSTANTS.GET_CUSTOM_REPORTS): {
			return {
				...state,
				customReports: {
					reports: payload,
					apiLoading: false,
					apiFailed: true
				}
			};
		}
		case successFor(REPORT_LANDING_STORE_CONSTANTS.GET_REPORT_DATA): {
			return {
				...state,
				...payload
			};
		}
		case REPORT_LANDING_STORE_CONSTANTS.SET_MODAL: {
			return {
				...state,
				...payload
			};
		}
		case REPORT_LANDING_STORE_CONSTANTS.UPDATE_FREQUENCY: {
			const frequencyField = formatGranuality(state.frequencyField, payload);
			return {
				...state,
				frequencyField
			};
		}
		case REPORT_LANDING_STORE_CONSTANTS.INIT_REPORT: {
			return {
				...state,
				...formatReportData()
			};
		}
		case successFor(REPORT_LANDING_STORE_CONSTANTS.GET_ADDITIONAL_DATA): {
			return {
				...state,
				masterAdditionalData: {
					...state.masterAdditionalData,
					[payload.reportType]: payload.additionalData
				}
			};
		}
		case REPORT_LANDING_STORE_CONSTANTS.UPDATE_ADD_DATA:
			const formattedAdditionalData = formatAddData(state.formattedAdditionalData, payload);
			return {
				...state,
				formattedAdditionalData
			};
		case REPORT_LANDING_STORE_CONSTANTS.HIGHLIGHT_FEATURE: {
			const { featureState } = payload;
			return {
				...state,
				featureState
			};
		}
		case REPORT_LANDING_STORE_CONSTANTS.DWNLD_REPORT_DATA: {
			const { dwnloadReportData = {} } = payload;
			return {
				...state,
				dwnloadReportData
			};
		}
		case REPORT_LANDING_STORE_CONSTANTS.REPORT_API_LOADING: {
			const { loading = true, apiType = '', data } = payload || {};
			return {
				...state,
				apiType,
				msgData: data,
				apiStatus: loading ? APPLICATION_CONSTANTS.LOADING : ''
			};
		}
		case failedFor(REPORT_LANDING_STORE_CONSTANTS.REPORT_API): {
			const { apiType = '', data, closeModal, showNotify = false } = payload || {};
			return {
				...state,
				apiType,
				msgData: data,
				apiStatus: APPLICATION_CONSTANTS.FAILED,
				modalType: closeModal ? '' : state.modalType,
				showNotify
			};
		}
		case successFor(REPORT_LANDING_STORE_CONSTANTS.REPORT_API): {
			const { data, apiType = '', closeModal, showNotify = false } = payload;
			return {
				...state,
				msgData: data,
				apiType,
				apiStatus: APPLICATION_CONSTANTS.SUCCESS,
				modalType: closeModal ? '' : state.modalType,
				showNotify
			};
		}
		case REPORT_LANDING_STORE_CONSTANTS.RESET_REPORT_LANDING_API: {
			return {
				...state,
				...initApiState
			};
		}
		case REPORT_LANDING_STORE_CONSTANTS.UPDATE_REPORT_FIELD: {
			const result = reportFieldFormatter(state.reportField, state.frequencyField, payload);
			return {
				...state,
				...result
			};
		}
		case REPORT_LANDING_STORE_CONSTANTS.DOWNLOAD_GSTR_REPORT: {
			return {
				...state,
				gstrDownload: {
					loading: true,
					success: false,
					error: false
				}
			};
		}
		case REPORT_LANDING_STORE_CONSTANTS.SET_INIT_GST_STATE: {
			return {
				...state,
				gstrDownload: { ...initGstrDownload }
			};
		}
		default:
			return state;
	}
};
