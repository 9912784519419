import { MANAGE_REQUEST_BOOKINGS } from 'reduxStore/actionConstants';
import { actionCreator, failedFor, successFor } from 'reduxStore/sagaUtil';

export const setRequestType = actionCreator(MANAGE_REQUEST_BOOKINGS.SET_REQUEST_TYPE);
export const updateTabCount = actionCreator(MANAGE_REQUEST_BOOKINGS.UPDATE_TAB_COUNT);

export const getBookingsDetails = actionCreator(MANAGE_REQUEST_BOOKINGS.GET_BOOKING_LIST);
export const getBookingsDetailsSuccess = actionCreator(successFor(MANAGE_REQUEST_BOOKINGS.GET_BOOKING_LIST));
export const getBookingsDetailsFailure = actionCreator(failedFor(MANAGE_REQUEST_BOOKINGS.GET_BOOKING_LIST));

export const getFilterCountsDetails = actionCreator(MANAGE_REQUEST_BOOKINGS.GET_FILTER_COUNTS);
export const getFilterCountSuccess = actionCreator(successFor(MANAGE_REQUEST_BOOKINGS.GET_FILTER_COUNTS));
export const getFilterCountFailure = actionCreator(failedFor(MANAGE_REQUEST_BOOKINGS.GET_FILTER_COUNTS));

export const setFilterQuery = actionCreator(MANAGE_REQUEST_BOOKINGS.FILTER_QUERY);
export const clearFilterUpdate = actionCreator(MANAGE_REQUEST_BOOKINGS.FILTER_CLEAR);
export const getApproversListSuccess = actionCreator(successFor(MANAGE_REQUEST_BOOKINGS.GET_APPROVERS_LIST));
export const clearSearchValue = actionCreator(MANAGE_REQUEST_BOOKINGS.CLEAR_SEARCH_VALUE);
export const updatePageNo = actionCreator(MANAGE_REQUEST_BOOKINGS.UPDATE_PAGE_NO);
export const setSearchQeury = actionCreator(MANAGE_REQUEST_BOOKINGS.SET_SEARCH_QUERY);

export const modifyBooking = actionCreator(MANAGE_REQUEST_BOOKINGS.MODIFY_BOOKING);
