import * as ActionConstants from "../actionConstants";

const initialState = {};
const hcsReducer = (state = initialState, action) => {
    if (!action) { return; }
    switch (action.type) {
        case ActionConstants.HCS_CONST.LOGIN_IN_PROGRESS:
        case ActionConstants.HCS_CONST.RESET:
        case ActionConstants.HCS_CONST.LOGIN_SUCCESS:
        case ActionConstants.HCS_CONST.LOGIN_FAILED:
        case ActionConstants.HCS_CONST.LOGOUT_IN_PROGRESS:
        case ActionConstants.HCS_CONST.LOGOUT_SUCCESS:
        case ActionConstants.HCS_CONST.LOGOUT_FAILED:
        case ActionConstants.HCS_CONST.SWITCH_IN_PROGRESS:
        case ActionConstants.HCS_CONST.SWITCH_SUCCESS:
        case ActionConstants.HCS_CONST.ASK_FOR_USER_CONFIRMATION:
        case ActionConstants.HCS_CONST.SWITCH_FAILED:
            return ({ ...state, "hcspayload": { ...action.payload } });
            break;
        case ActionConstants.HCS_CONST.USERINFO_SUCCESS: {
            let payload = action.payload;
            if (state.hcspayload) {
                payload = { ...state.hcspayload, ...action.payload };
            }
            return ({ ...state, "hcspayload": { ...payload } });
        }
        default:
            return state;
    }
};
export default hcsReducer;
