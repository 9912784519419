// Saga for report Details Comes here
import { takeLatest, put, select, call } from 'redux-saga/effects';

import { REPORT_DETAILS_STORE_CONSTANTS, REPORT_LANDING_STORE_CONSTANTS } from '../../actionConstants';
import { saveServerLogs } from 'UtilityComponents/Util';
import { getReportDetails, getReportFilterCount, searchReportDetails } from 'apis/reports/detailsApi';
import { APPLICATION_CONSTANTS, PATHS } from 'AppConstants';
import {
	getFilterCountSuccess,
	setDetails,
	setApiLoading,
	setApiError,
	getAbValsDetailsSuccess,
	updateReportLevel,
	setReportLevel
} from '../../actions/reports/reportDetailsAction';
import { reportDetailsSelectors } from '../../selectors/reports/reportDetailsSelector';
import { formatDetailsData, formatFilterCounts, isMoreRows } from 'UtilityComponents/reports/reportDetailsUtil';
import { REPORT_PAGE_TYPE } from '../../../reporting/pages/summary/config';
import { getAbExperiment } from 'apis/commonApis';
import { getReportLevel, addTimeStamp } from 'UtilityComponents/reports/reportSummaryUtil';
import { abKeyReportValue } from 'reduxStore/selectors/reportSelector';

/**
 * Get Reports Details List
 * @author - mmt8760
 * @typedef {Object} Payload
 * @property {Object} filterQuery - Applied Filters Query
 * @param {Payload} payload
 */
function* getDetails({ payload = {} }) {
	let error = false;
	const isAllSelect = yield select(reportDetailsSelectors.isAllSelected);
	const dtlsList = yield select(reportDetailsSelectors.detailsList);
	const selectedRecords = yield select(reportDetailsSelectors.selectedRecords);
	const abKeyVal = yield select(reportDetailsSelectors.abKeyReportValue);
	const { filterQuery = {}, isSearch = false } = payload;
	if (filterQuery.type === REPORT_PAGE_TYPE.SAVINGS_SUMMARY) {
		delete filterQuery['sort'];
	}
	const resetPage = filterQuery.pageNo === 1;

	const retData = {
		detailsList: {
			data: resetPage ? [] : dtlsList.data,
			error: false,
			loading: true,
			hasMore: selectedRecords.hasMore
		},
		selectedRecords: resetPage ? {} : selectedRecords
	};
	yield put(setDetails(retData));
	try {
		let reportLevel = '';
		if (typeof (abKeyVal) !== 'number' && !filterQuery.reportLevel) {
			const { value, isError } = yield* (getAbValues({}));
			if (isError) {
				error = isError;
			}
			reportLevel = getReportLevel(filterQuery.type, value);
			filterQuery.reportLevel = reportLevel;
			yield put(setReportLevel(reportLevel));
		}
		const listApi = isSearch ? searchReportDetails : getReportDetails;
		const resp = yield call(listApi, addTimeStamp(filterQuery));
		const { responseCode, bookingReportDetails = [], pageLimit = 10 } = resp?.data || {};
		const responseLength = bookingReportDetails.length;

		if (responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const { selectedList } = formatDetailsData(bookingReportDetails, isAllSelect);

			const hasMore = isMoreRows(responseLength, pageLimit, filterQuery.type);
			retData.detailsList = {
				data: resetPage ? bookingReportDetails : [...dtlsList.data, ...bookingReportDetails],
				error: false,
				loading: false,
				hasMore
			};
			retData.selectedRecords = resetPage ? selectedList : { ...selectedRecords, ...selectedList };
			yield put(setDetails(retData));
		} else {
			error = true;
		}
	} catch (e) {
		error = true;
		saveServerLogs(e.stack, 'error', PATHS.GET_REPORTS_DETAILS);
	} finally {
		if (error) {
			retData.detailsList = {
				...retData.detailsList,
				error: true,
				loading: false,
				hasMore: false
			};
			yield put(setDetails(retData));
		}
	}
}

/**
 * Get Reports Details Filters Count
 * @author - mmt8760
 * @typedef {Object} Payload
 * @property {Object} filterQuery - Applied Filters Query
 * @param {Payload} payload
 */
function* getFilterCount({ payload = {} }) {
	const { filterQuery = {} } = payload;
	const filters = yield select(reportDetailsSelectors.filters);
	try {
		const response = yield call(getReportFilterCount, filterQuery);
		const { data } = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const formattedFilters = formatFilterCounts(data.filterCounts, filters);
			yield put(getFilterCountSuccess({
				filters: formattedFilters
			}));
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.GET_REPORT_FILTER_COUNT);
	}
}

function* getAbValues({ payload = {} }) {
	let obj = {
		isError: false,
		value: null
	};
	try {
		const response = yield call(getAbExperiment, APPLICATION_CONSTANTS.MYBIZ_CORE);
		if (response) {
			const abKeyValue = response && response.reportdefaultview;
			yield put(getAbValsDetailsSuccess(abKeyValue));
			obj.value = abKeyValue;
		} else {
			obj.isError = true;
		}
		return obj;
	} catch (e) {
		obj.isError = true;
		yield put(getAbValsDetailsSuccess(null));
		saveServerLogs(e.stack, 'error', 'Abtest failed');
		return obj;
	}
};

export default [
	takeLatest(REPORT_DETAILS_STORE_CONSTANTS.GET_DETAILS_LISTING, getDetails),
	takeLatest(REPORT_DETAILS_STORE_CONSTANTS.RPRT_DETAILS_FILTER_COUNT, getFilterCount),
	takeLatest(REPORT_DETAILS_STORE_CONSTANTS.GET_AB_VALS_DETAILS, getAbValues)
];
