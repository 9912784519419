import { combineReducers } from 'redux';

import summaryReducer from './reportsSummaryReducer';
import detailsReducer from './reportsDetailsReducer';

const combinedReducer = combineReducers({
	summary: summaryReducer,
	details: detailsReducer
});

export default combinedReducer;
