import {
	WALLET_STORE_CONSTANTS
} from '../actionConstants';
import {
	getccbAxiosConfig
} from '../../apisSetup/ccbConfig';
import {
	PATHS, APPLICATION_CONSTANTS, WALLET_CONSTANTS, FILE_TYPE, REPORTS_CONSTANT
} from 'AppConstants';
import {
	saveServerLogs, getDefaultDate
} from 'UtilityComponents/Util';
import { trackWalletEvents, transformBonusBackData } from 'UtilityComponents/WalletUtils';

import {
	setApiSuccess,
	setModal
} from './reports/reportSummaryActions';

const ccbAxiosApiCaller = getccbAxiosConfig(true);
import axios from 'axios';

const CancelToken = axios.CancelToken;
const ccbDownloadApiCaller = getccbAxiosConfig(true, {
	'Accept': 'text/csv'
});

const START_TIME = '00:00:00';
const END_TIME = '23:59:59';

const addTimeStamp = (params) => {
	const tempParams = {
		...params
	};
	delete tempParams.fltTxt;
	if (tempParams.startDate) {
		tempParams.startDate = `${tempParams.startDate} ${START_TIME}`;
	}
	if (tempParams.endDate) {
		tempParams.endDate = `${tempParams.endDate} ${END_TIME}`;
	}
	return tempParams;
};

export const nextFeatureDesc = (payload, isClose) => (dispatch) => {
	if (isClose === true) {
		return dispatch(skipFeatureDesc());
	}
	dispatch({
		type: WALLET_STORE_CONSTANTS.NEXT_FEATURE,
		payload: payload
	});
};

export const skipFeatureDesc = () => (dispatch) => {
	dispatch({
		type: WALLET_STORE_CONSTANTS.SKIP_FEATURE
	});
};

export const getWalletList = (apiRequest, reset) => async (dispatch) => {
	let response = null;
	if (reset) {
		dispatch({
			type: WALLET_STORE_CONSTANTS.RESET_LIST,
			payload: apiRequest
		});
	} else {
		dispatch({
			type: WALLET_STORE_CONSTANTS.LIST_API_CALLING
		});
	}
	try {
		response = await ccbAxiosApiCaller.post(PATHS.GET_WALLET_LIST, addTimeStamp(apiRequest));
		dispatch({
			type: WALLET_STORE_CONSTANTS.SET_LIST,
			payload: response.data
		});
	} catch (e) {
		dispatch({
			type: WALLET_STORE_CONSTANTS.LIST_API_FAILED
		});
		saveServerLogs(e.stack, 'error', PATHS.GET_WALLET_LIST);
		return;
	}
};

export const getRewardsCashbackList = async (apiRequest, prevData) => {
	let response = null;

	response = await ccbAxiosApiCaller.post(PATHS.GET_WALLET_LIST, addTimeStamp(apiRequest));
	if (response.data && response.data.responseCode === '600') {
		const bonusList = transformBonusBackData(response.data.walletTxns, prevData);
		return { list: bonusList, lastIndex: response.data.walletTxns[response.data.walletTxns.length - 1]?.txnDate, hasMore: response.data?.walletTxns?.length > 1 };
	} else {
		throw { message: response.data?.message };
	}
};

export const getCounts = (apiRequest) => async (dispatch) => {
	let response = null;
	try {
		response = await ccbAxiosApiCaller.post(PATHS.GET_WALLET_COUNT, addTimeStamp(apiRequest));
		if (response.data && response.data.responseCode === '600') {
			dispatch({
				type: WALLET_STORE_CONSTANTS.SET_COUNT,
				payload: response.data
			});
		} else {
			dispatch({
				type: WALLET_STORE_CONSTANTS.COUNT_API_CALLED
			});
		}
	} catch (e) {
		dispatch({
			type: WALLET_STORE_CONSTANTS.COUNT_API_CALLED
		});
		saveServerLogs(e.stack, 'error', PATHS.GET_WALLET_COUNT);
		return;
	}
};

export const getAllData = (apiRequest, init) => (dispatch) => {
	if (init) {
		dispatch({
			type: WALLET_STORE_CONSTANTS.RESET_WALLET_DATA,
			payload: apiRequest
		});
	}
	dispatch(getWalletList(apiRequest));
	dispatch(getCounts(apiRequest));
};

export const setFilterObj = (filterObj) => (dispatch) => {
	dispatch({
		type: WALLET_STORE_CONSTANTS.SET_FILTER_OBJECT,
		payload: filterObj
	});
};

export const getNewQueryData = (filterQuery) => (dispatch) => {
	dispatch({
		type: WALLET_STORE_CONSTANTS.SET_QUERY,
		payload: filterQuery
	});
	dispatch(getAllData(filterQuery));
};

export const upateAppliedFilter = (payload) => (dispatch) => {
	dispatch({
		type: WALLET_STORE_CONSTANTS.UPDATE_APPLY_FILTER_OBJECT,
		payload: payload
	});
};

export const selectTransaction = (data) => (dispatch) => {
	dispatch({
		type: WALLET_STORE_CONSTANTS.UPDATE_SELECT_LIST,
		payload: data
	});
};

export const getAccountDetails = () => async (dispatch, _, axiosInstance) => {
	let response = null;
	dispatch({
		type: WALLET_STORE_CONSTANTS.WALLET_ACC_DTL_CALLED
	});
	try {
		response = await axiosInstance.get(PATHS.GET_WALLET_DETAILS);
		if (response.data && response.data.responseCode === '600') {
			dispatch({
				type: WALLET_STORE_CONSTANTS.SET_WALLET_ACC_DTL,
				payload: response.data
			});
		} else {
			dispatch({
				type: WALLET_STORE_CONSTANTS.WALLET_ACC_DTL_FAILED
			});
		}
	} catch (e) {
		dispatch({
			type: WALLET_STORE_CONSTANTS.WALLET_ACC_DTL_FAILED
		});
		saveServerLogs(e.stack, 'error', PATHS.GET_WALLET_DETAILS);
		return;
	}
};

export const rechargeWallet = (apiRequest) => async (dispatch) => {
	let response = null;
	dispatch({
		type: WALLET_STORE_CONSTANTS.RECHARGE_API_CALLED,
		payload: {
			rechargeAmount: apiRequest.amount
		}
	});

	try {
		response = await ccbAxiosApiCaller.post(PATHS.RECHARGE_WALLET, apiRequest);
		if (response.data && response.data.checkoutUrl) {
			window.location = response.data.checkoutUrl;
		} else {
			dispatch({
				type: WALLET_STORE_CONSTANTS.RECHARGE_FAILED,
				payload: {
					rechargeError: response.data && response.data.errorMessage ? response.data.errorMessage : APPLICATION_CONSTANTS.API_ERROR,
					rechargeUnSuccess: false
				}
			});
		}
	} catch (e) {
		dispatch({
			type: WALLET_STORE_CONSTANTS.RECHARGE_FAILED,
			payload: {
				rechargeError: APPLICATION_CONSTANTS.API_ERROR,
				rechargeUnSuccess: false
			}
		});
		saveServerLogs(e.stack, 'error', PATHS.RECHARGE_WALLET);
		return;
	}
};

export const setRechargeStatus = (payload) => async (dispatch) => {
	dispatch({
		type: WALLET_STORE_CONSTANTS.RECHARGE_STATUS,
		payload
	});
};

export const setReminder = (apiRequest) => async (dispatch) => {
	apiRequest.type = 'WALLET';
	dispatch({
		type: WALLET_STORE_CONSTANTS.REMINDER_API_CALLED
	});
	const url = apiRequest.is_enable ? PATHS.SET_REMINDER : PATHS.REMOVE_REMINDER;
	try {
		await ccbAxiosApiCaller.post(url, apiRequest);
		dispatch({
			type: WALLET_STORE_CONSTANTS.REMINDER_SUCCESS,
			payload: {
				apiRequest
			}
		});
		dispatch(getAccountDetails());
	} catch (e) {
		dispatch({
			type: WALLET_STORE_CONSTANTS.REMINDER_FAILED
		});
		saveServerLogs(e.stack, 'error', url);
		return;
	}
	const trackVal = apiRequest.is_enable ? 'enabled' : 'disabled';
	trackWalletEvents(`reminder_${trackVal}`);
};

export const fetchFilterOptions = (query) => async (dispatch) => {
	let response = null;
	let startDate = query.startDate;
	let endDate = query.endDate;
	try {
		startDate = new Date(`${query.startDate} ${START_TIME}`).getTime();
		endDate = new Date(`${query.endDate} ${END_TIME}`).getTime();
	} catch (error) {

	}
	let url = `${PATHS.GET_FILTER_OPTIONS}?startDate=${startDate}&endDate=${endDate}`;
	try {
		response = await ccbAxiosApiCaller.get(url);
		dispatch({
			type: WALLET_STORE_CONSTANTS.SET_FILTER_OPTIONS,
			payload: response.data
		});
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.GET_FILTER_OPTIONS);
		return;
	}
};

export const getAllDateCounts = (apiRequest) => async (dispatch) => {
	let response = null;
	const defDate = getDefaultDate(APPLICATION_CONSTANTS.TODAY, WALLET_CONSTANTS.API_DATE_FORMAT);
	dispatch({
		type: WALLET_STORE_CONSTANTS.SET_ALL_COUNT_API
	});
	try {
		response = await ccbAxiosApiCaller.post(PATHS.GET_WALLET_COUNT, addTimeStamp({ ...apiRequest, ...defDate }));
		dispatch({
			type: WALLET_STORE_CONSTANTS.SET_ALL_WALLET_COUNT,
			payload: response.data
		});
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.GET_INVOICE_COUNT_BUNDLE);
	}
};

const startDownlaod = (apiRequest, id) => async (dispatch) => {
	const qparamas = `downloadReportFilterId=${id}&startDate=${apiRequest.startDate}&endDate=${apiRequest.endDate}`;
	const url = `${PATHS.WALLET_START_DOWNLOAD}?${qparamas}`;
	dispatch({
		type: WALLET_STORE_CONSTANTS.DOWNLOAD_API_SUCCESS
	});
	window.location = PATHS.CCB_URL + url;
};

export const downloadTrxn = (apiRequest) => async (dispatch) => {
	let response = null;
	dispatch({
		type: WALLET_STORE_CONSTANTS.DOWNLOAD_API_CALLED
	});
	try {
		response = await ccbAxiosApiCaller.post(PATHS.WALLET_DOWNLOAD, addTimeStamp({ ...apiRequest, fileType: FILE_TYPE.CSV }));
		const { responseCode, mailDelivered, s3Url, downloadReportFilterId } = response?.data || {};
		if (responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			if (downloadReportFilterId) {
				dispatch(startDownlaod(apiRequest, downloadReportFilterId));
				dispatch(setApiSuccess({ apiType: PATHS.DOWNLOAD_REPORT, closeModal: true }));
			} else if (s3Url) {
				window.open(s3Url);
				dispatch(setApiSuccess({ apiType: PATHS.DOWNLOAD_REPORT, closeModal: true }));
				dispatch({
					type: WALLET_STORE_CONSTANTS.DOWNLOAD_API_SUCCESS
				});
			} else if (mailDelivered) {
				dispatch(setApiSuccess({ apiType: PATHS.DOWNLOAD_REPORT, closeModal: true }));
				dispatch(setModal({ modalType: REPORTS_CONSTANT.MAILER_MODAL }));
				dispatch({
					type: WALLET_STORE_CONSTANTS.DOWNLOAD_API_SUCCESS
				});
			} else {
				isError = true;
			}
		}
	} catch (e) {
		dispatch({
			type: WALLET_STORE_CONSTANTS.DOWNLOAD_API_FAILED
		});
		saveServerLogs(e.stack, 'error', PATHS.WALLET_DOWNLOAD);
		return;
	}
};

export const sendSelfMail = () => async (dispatch) => {
	let response = null;
	try {
		response = await ccbAxiosApiCaller.get(PATHS.SELF_MAIL);
		if (response && response.data && response.data.responseCode === '600') {
			dispatch({
				type: WALLET_STORE_CONSTANTS.SELF_MAIL_SUCCESS
			});
			return;
		}
		dispatch({
			type: WALLET_STORE_CONSTANTS.SELF_MAIL_FAILURE
		});
	} catch (e) {
		dispatch({
			type: WALLET_STORE_CONSTANTS.SELF_MAIL_FAILURE
		});
		saveServerLogs(e.stack, 'error', PATHS.SELF_MAIL);
		return;
	}
};

export const clearModalState = (payload) => async (dispatch) => {
	dispatch({
		type: WALLET_STORE_CONSTANTS.CLEAR_MODAL_STATE,
		payload
	});
};

export const setExpData = (payload) => async (dispatch) => {
	dispatch({
		type: WALLET_STORE_CONSTANTS.SET_EXP_DATA,
		payload
	});
};

export const setWalletOverlay = (overlay = '') => ({
	type: WALLET_STORE_CONSTANTS.WALLET_OVERLAY,
	payload: overlay
});
