import { POPUP_ACTION_CONSTANTS } from '../actionConstants';

export const updatePopup = (payload) => ({
	type: POPUP_ACTION_CONSTANTS.UPDATE_POP,
	payload
});

export const hidePop = () => ({
	type: POPUP_ACTION_CONSTANTS.HIDE_POP
});
