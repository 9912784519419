import { actionCreator, failedFor, successFor } from '../sagaUtil';
import { GST_ACTION_CONSTANTS } from 'reduxStore/actionConstants';

export const getGstDetails = actionCreator(GST_ACTION_CONSTANTS.GET_GST_DATA);
export const getGstDetailsSuccess = actionCreator(successFor(GST_ACTION_CONSTANTS.GET_GST_DATA));
export const getGstDetailsFailed = actionCreator(failedFor(GST_ACTION_CONSTANTS.GET_GST_DATA));

export const updateGst = actionCreator(GST_ACTION_CONSTANTS.UPDATE_GST_DATA);
export const updateGstSuccess = actionCreator(successFor(GST_ACTION_CONSTANTS.UPDATE_GST_DATA));
export const updateGstFailed = actionCreator(failedFor(GST_ACTION_CONSTANTS.UPDATE_GST_DATA));

export const updateSelectedGst = actionCreator(GST_ACTION_CONSTANTS.UPDATE_SELECTED_GST);
