/**
 * reducer functions for handling policy config and policy unit data
 * @author mybizFe
 * @param { Object } policyConfig {[id]: inputconfig} fields object for each grpID to rendered on UI created from config and masterpolicy which is mapped through BE config
 * @param { Object } fields fields object retreived from BE config used for creating formattedOrgConfig annd maintaining order on UI
 * @param { Object } policyData {[id]: policyVals} Policy Object with each group policy that has been rendered onn UI
 * @param { Object } formattedOrgConfig - policy config with inputs according to policy unit format global to be used for tier
 * @param { Object } policyChange - register policy value changes on UI
 * @param { Object } isDefPolicyState {id: Boolean} - keep track of which grp policy is completely set as default policy
 * @param { Object } sectionKeys -  to save default policy attr for a section when setdefault selelcted
 * @param { Object } sectionattrKeys - attr keys of the inputs located in section which has set default on
 * @param { Number|String } selectedGroupId - selected groupId onn UI
 * @param { Object } hideFields - attr keys of subfield that is to be hidden on UI
 * @param { Object } allDefaultSections -{sectionkeys, sectionAttrKeys} to set setdefault switcher sectionkeys and attribue keys to be saved in policy metadata
 * @param {Boolean } policyLoader - show loader when api in progress
 * @param { Object } hideAllfields - used to render or not rendor fields and save  policy
 * @returns state object for policy
 */

import {
	formatPolicyData,
	formatConfigData,
	updateManagerCounts,
	isCabsPolicy
} from '../../UtilityComponents/PolicyUtils';
import {
	POLICY_STORE_CONSTANTS
} from '../actionConstants';
import cloneDeep from 'lodash/cloneDeep';
import { LOB_CONSTANTS, lob_icon_map } from '../../policy/config';
import { urlParam } from '../../UtilityComponents/Util';
import { APPLICATION_CONSTANTS } from '../../AppConstants';
const groupId = urlParam('groupId') || (APPLICATION_CONSTANTS.ORGANIZATION_LEVEL_GROUP_ID);
const policyLob = urlParam('lob') || LOB_CONSTANTS.DOM_FLIGHTS;
const lobKey = lob_icon_map[policyLob].apiKey;

const INITIAL_STATE = {
	defaultLob: {
		lob: policyLob,
		defaultLobApiKey: lobKey
	},
	formattedOrgConfig: {},
	policyData: {},
	policyChange: {},
	isDefPolicyState: {},
	policyConfig: {},
	managerCountSuccess: false,
	managerCount: {},
	sectionKeys: {},
	sectionAttrKeys: {},
	selectedGroupId: groupId,
	hideFields: {},
	allDefaultSections: {},
	policyLoader: false,
	serverError: false,
	hideAllfields: {},
	policyCalling: false,
	selectedGroupIndex: -1,
	disabledSubFields: []
};

const defaultState = {
	formattedOrgConfig: {},
	policyChange: {},
	policyConfig: {},
	isDefPolicyState: {},
	hideFields: {},
	sectionKeys: {},
	sectionAttrKeys: {},
	policyDataSuccess: false,
	policyDataFailure: false,
	accordionLoader: true,
	tabLoader: true,
	policyLoader: false,
	serverError: false,
	hideAllfields: {},
	policyCalling: false
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}

	switch (action.type) {
		case POLICY_STORE_CONSTANTS.POLICY_CONFIG_CALLING: {
			return {
				...state,
				accordionLoader: true,
				tabLoader: true,
				policyConfigSuccess: false,
				policyConfigFailure: false,
				serverError: false,
				...action.payload
			};
		}
		case POLICY_STORE_CONSTANTS.POLICY_CONFIG_SUCCESS: {
			let formattedInptCnf = formatConfigData(action.payload.responseData, state.selectedGroupId, state.defaultLob.lob);
			let fConfigStatus = {};
			const slectedGrpId = state.selectedGroupId;
			const defaultPolicyGroupId = state.defaultPolicyGroupId;
			const defaultPolicy = state.policyData[defaultPolicyGroupId];
			const grpPolicy = state.policyData && state.policyData[state.selectedGroupId];
			let policyLobkey = lob_icon_map[state.defaultLob.lob].policyKey;
			const grpLobPolicy = grpPolicy && grpPolicy.policy && grpPolicy.policy.policy_units ? grpPolicy.policy.policy_units[policyLobkey] : false;
			const setDefaultVals = defaultPolicy && defaultPolicyGroupId != slectedGrpId && grpPolicy && !grpLobPolicy; // when policy for lob  ot present use setdefault state
			let sectionData = {};
			if (formattedInptCnf == null) {
				fConfigStatus = { policyConfigFailure: true };
			} else {
				fConfigStatus = { ...formattedInptCnf, policyConfigSuccess: true };
				sectionData = {
					sectionAttrKeys: formattedInptCnf?.allDefaultSections?.sectionAttrKeys
				};
			}
			if (!state.isAllConfig || typeof grpPolicy !== 'undefined' || setDefaultVals) {
				if (grpPolicy && !grpLobPolicy && !isCabsPolicy(state.defaultLob.lob)) {
					sectionData = setAsDefaultGrpPolicy(state, state.selectedGroupId, formattedInptCnf ? formattedInptCnf.allDefaultSections : false);
				}

				if (grpPolicy.policy || setDefaultVals) {
					const responseOb = formatPolicyData(
						fConfigStatus.policyConfigMetaData,
						formattedInptCnf ? formattedInptCnf.formattedOrgConfig : {},
						grpPolicy,
						state.defaultLob.lob,
						formattedInptCnf.policyConfig[state.selectedGroupId],
						defaultPolicyGroupId,
						state.selectedGroupId,
						defaultPolicy,
						setDefaultVals
					);
					const formattedPolicyData = handlePolicyState(state, responseOb, state.selectedGroupId, state.policyData[state.selectedGroupId], setDefaultVals);
					return { ...state, ...formattedPolicyData, ...fConfigStatus, tabLoader: false, accordionLoader: false, ...sectionData };
				}
			}
			return { ...state, ...fConfigStatus, ...sectionData, tabLoader: false, accordionLoader: false };
		}

		case POLICY_STORE_CONSTANTS.POLICY_CONFIG_FAILURE: {
			return {
				...state,
				policyConfigFailure: true
			};
		}
		case POLICY_STORE_CONSTANTS.RESET_POLICY_STATE: {
			return { ...state, ...defaultState };
		}

		case POLICY_STORE_CONSTANTS.POLICY_DATA_CALLING: {
			return {
				...state,
				policyDataSuccess: false,
				policyDataFailure: false,
				policyLoader: true,
				serverError: false
			};
		}

		case POLICY_STORE_CONSTANTS.POLICY_DATA_SUCCESS: {
			const policyObj = action.payload.policyData;
			const slectedGrpId = state.selectedGroupId;
			const saveOnly = action.payload.saveOnly;
			const policyInputFields = state.policyConfig ? state.policyConfig[slectedGrpId] : false;
			let sectionData = {};
			const grpPolicy = policyObj ? policyObj[0] : false;
			const currPolicy = state.policyData[slectedGrpId] ? state.policyData[slectedGrpId] : grpPolicy;
			const id = grpPolicy ? grpPolicy.groupId : '';
			const defaultPolicyGroupId = state.defaultPolicyGroupId;
			const defaultPolicy = grpPolicy.groupId == defaultPolicyGroupId ? grpPolicy : state.policyData[defaultPolicyGroupId];
			const policyLobkey = lob_icon_map[state.defaultLob.lob].policyKey;
			const currLobPolicy = currPolicy && currPolicy.policy && currPolicy.policy.policy_units ? currPolicy.policy.policy_units[policyLobkey] : false;
			const grpLobPolicy = grpPolicy && grpPolicy.policy && grpPolicy.policy.policy_units ? grpPolicy.policy.policy_units[policyLobkey] : false;
			const setDefaultVals = defaultPolicy && defaultPolicyGroupId != slectedGrpId && currPolicy && !currLobPolicy;
			let data = {};
			if (policyInputFields && grpPolicy && !grpLobPolicy && (grpPolicy.groupId == slectedGrpId) && !isCabsPolicy(state.defaultLob.lob)) {
				sectionData = setAsDefaultGrpPolicy(state, slectedGrpId, state.allDefaultSections);
			}
			if (grpPolicy) {
				data = {
					policyDataSuccess: grpPolicy.groupId == slectedGrpId,
					policyData: {
						...state.policyData,
						[id]: cloneDeep(grpPolicy)
					},
					isDefPolicyState: {
						...state.isDefPolicyState,
						[id]: false
					},
					updatedBy: currPolicy?.policy?.updated_by,
					policyLoader: false
				};
			}
			if (policyInputFields && (grpPolicy && grpPolicy.policy && grpPolicy.groupId == slectedGrpId) || setDefaultVals) {
				let grppolicyVals = !setDefaultVals ? grpPolicy : { groupId: slectedGrpId };
				const responseObj = formatPolicyData(state.policyConfigMetaData, state.formattedOrgConfig, grppolicyVals, state.defaultLob.lob, policyInputFields, state.defaultPolicyGroupId, slectedGrpId, defaultPolicy, setDefaultVals);
				const updatedBy = grppolicyVals?.policy?.updated_by;
				const resformatted = handlePolicyState(state, responseObj, slectedGrpId, grpPolicy, setDefaultVals);
				return { ...state, ...data, ...resformatted, ...sectionData, policyLoader: false, updatedBy };
			}
			return { ...state, ...sectionData, policyLoader: false, ...data };
		}
		case POLICY_STORE_CONSTANTS.POLICY_DATA_FAILURE: {
			return {
				...state,
				accordionLoader: false,
				tabLoader: false,
				policyDataFailure: true,
				policyLoader: false,
				serverError: false
			};
		}
		case POLICY_STORE_CONSTANTS.SAVE_POLICY_CALLING: {
			return {
				...state,
				policySaveLoader: true,
				policySaveSuccess: false,
				policySaveFailure: false,
				serverError: false,
				policyCalling: true,
				...action.payload
			};
		}
		case POLICY_STORE_CONSTANTS.SAVE_POLICY_SUCCESS: {
			return {
				...state,
				policySaveSuccess: true,
				policySaveLoader: false,
				policyChange: {},
				modalData: { modalType: 'save_success' },
				showModal: true,
				policyData: action.payload.policyDataUpdated,
				shouldRedirect: action.payload.shouldRedirect,
				serverError: false,
				policyCalling: false
			};
		}
		case POLICY_STORE_CONSTANTS.SAVE_POLICY_FAILURE: {
			return {
				...state,
				policySaveFailure: true,
				policySaveLoader: false,
				modalData: { modalType: 'save_failure' },
				showModal: !action.payload,
				serverError: action.payload,
				policyCalling: false
			};
		}

		case POLICY_STORE_CONSTANTS.UPDATE_DEFAULT_GROUP: {
			let { selectedGroupId } = action.payload;
			let grpPolicyConfig = state.policyConfig[selectedGroupId];
			let grpPolicyData = state.policyChange[selectedGroupId];
			const policyObj = state.policyData[selectedGroupId];
			const isDefPolicyState = state.isDefPolicyState[selectedGroupId];
			const shouldUpdateGrp = action.payload.shouldUpdateGroup;
			if (!isDefPolicyState && !grpPolicyData) {
				let policyConfig = grpPolicyConfig ? grpPolicyConfig : cloneDeep(state.formattedOrgConfig);
				policyConfig = formatPolicyData(state.policyConfigMetaData, state.formattedOrgConfig, policyObj, state.defaultLob.lob, policyConfig, state.defaultPolicyGroupId, selectedGroupId, state.policyData[state.defaultPolicyGroupId]);
				return { ...state,
					policyConfig: {
						...state.policyConfig,
						[selectedGroupId]: cloneDeep(policyConfig.inputFields)
					},
					hideFields: {
						...state.hideFields,
						[selectedGroupId]: policyConfig.hideKeys
					},
					policyChange: {
						...state.policyChange,
						[selectedGroupId]: 0
					},
					isDefPolicyState: {
						...state.isDefPolicyState,
						[selectedGroupId]: shouldUpdateGrp ? true : state.isDefPolicyState[selectedGroupId]
					},
					sectionKeys: {
						...state.sectionKeys,
						[selectedGroupId]: policyConfig.sectionKeys
					},
					hideAllfields: {
						...state.hideAllfields,
						[selectedGroupId]: policyConfig.hideAllfields
					},
					selectedGroupId: shouldUpdateGrp ? selectedGroupId : state.selectedGroupId,
					selectedGroupIndex: shouldUpdateGrp ? action.payload.selectedGroupIndex : state.selectedGroupIndex };
			}
			return { ...state,
				selectedGroupId: shouldUpdateGrp ? selectedGroupId : state.selectedGroupId,
				selectedGroupIndex: shouldUpdateGrp ? action.payload.selectedGroupIndex : state.selectedGroupIndex };
		}
		case POLICY_STORE_CONSTANTS.TIER_MAP_AUTO_SUGGEST_VALUES: {
			let { ggId, newfConfig } = action.payload;
			return { ...state,
				policyConfig: {
					...state.policyConfig,
					[ggId]: cloneDeep(newfConfig),
					...action.payload
				} };
		}
		case POLICY_STORE_CONSTANTS.TOGGLE_MODAL: {
			return { ...state, ...action.payload };
		}
		case POLICY_STORE_CONSTANTS.UPDATE_GRP_ORG_CONFIG: {
			return { ...state,
				policyConfig: {
					...state.policyConfig,
					[action.payload.selectedGroupId]: { ...action.payload.formattedConfigGroup }
				},
				formattedOrgConfig: {
					...action.payload.formattedOrgConfig
				} };
		}
		case POLICY_STORE_CONSTANTS.UPDATE_FORMAT_CONFIG: {
			return { ...state,
				formattedOrgConfig: { ...action.payload.formattedOrgConfig } };
		}
		case POLICY_STORE_CONSTANTS.UPDATE_FORMATTED_GRP_CONFIG: {
			return { ...state,
				policyConfig: {
					...state.policyConfig,
					[action.payload.selectedGroupId]: cloneDeep(action.payload.formattedConfigGroup)
				} };
		}
		case POLICY_STORE_CONSTANTS.MANAGER_MISSING_COUNT_SUCCESS: {
			const { id, data = {} } = action.payload;
			let policyConfig = state.policyConfig;
			const updatedManagers = updateManagerCounts(state.policyConfig[id], data.managerNotExistsCountList);
			if (updatedManagers) {
				policyConfig[state.selectedGroupId] = cloneDeep(updatedManagers);
			}
			return {
				...state,
				policyConfig: policyConfig,
				managerCount: {
					...state.managerCount,
					[id]: data.managerNotExistsCountList
				},
				managerCountSuccess: true
			};
		}

		case POLICY_STORE_CONSTANTS.UPDATE_POLICY_DEFAULT_KEYS: {
			const { key, add } = action.payload;
			const sectionKeys = cloneDeep(state.sectionKeys[state.selectedGroupId]) || [];
			if (!add && sectionKeys.includes(key)) {
				const ind = sectionKeys.indexOf(key);
				sectionKeys.splice(ind, 1);
			} else if ( add) {
				sectionKeys.push(key);
			}
			return {
				...state,
				sectionKeys: {
					...state.sectionKeys,
					[state.selectedGroupId]: sectionKeys
				}

			};
		}
		case POLICY_STORE_CONSTANTS.POLICY_CHANGE_UPDATE: {
			const change = typeof state.policyChange[state.selectedGroupId] === 'number' ? state.policyChange[state.selectedGroupId] : 0;
			return {
				...state,
				serverError: false,
				policyChange: {
					...state.policyChange,
					[state.selectedGroupId]: change + 1
				}
			};
		}
		case POLICY_STORE_CONSTANTS.HIDE_FIELD_UPDATE: {
			const hideFields = state.hideFields || {};
			const grphide = cloneDeep(hideFields[state.selectedGroupId]) || {};

			return {
				...state,
				hideFields: {
					...state.hideFields,
					[state.selectedGroupId]: { ...grphide, ...action.payload }
				}
			};
		}

		case POLICY_STORE_CONSTANTS.UPDATE_HIDE_BLOCKS: {
			return {
				...state,
				hideAllfields: {
					...state.hideAllfields,
					[state.selectedGroupId]: action.payload
				}
			};
		}

		case POLICY_STORE_CONSTANTS.SET_DISABLED_FIELDS: {
			return {
				...state,
				disabledSubFields: action.payload
			};
		}
	}
	return state;
};

const setAsDefaultGrpPolicy = (state, id, allDefaultSections) => {
	if (!allDefaultSections) {
		return { accordionLoader: false, tabLoader: false };
	} else {
		return {
			sectionKeys: {
				...state.sectionKeys,
				[id]: cloneDeep(allDefaultSections.sectionKeys)
			},
			sectionAttrKeys: allDefaultSections.sectionAttrKeys,
			isDefPolicyState: {
				...state.isDefPolicyState,
				[id]: true
			}

		};
	}
};

const handlePolicyState = (state, responseObj, id, policy, setDefault) => {
	let groupExists = state.policyChange[id];
	let policyChangeObj = {};
	if (!groupExists) {
		policyChangeObj = {
			policyChange: {
				...state.policyChange,
				[id]: 0
			}
		};
	}
	if (!responseObj.inputFields) {
		return {
			policyDataFailure: true,
			accordionLoader: true,
			tabLoader: true
		};
	}
	let policyData = !setDefault ? {
		policyData: {
			...state.policyData,
			[id]: cloneDeep(policy)
		},
		isDefPolicyState: {
			...state.isDefPolicyState,
			[id]: true
		},
		hideAllfields: {
			...state.hideAllfields,
			[id]: responseObj.hideAllfields
		},
		hideFields: {
			...state.hideFields,
			[id]: responseObj.hideKeys
		},
		sectionKeys: {
			...state.sectionKeys,
			[id]: responseObj.sectionKeys
		}
	} : {
		hideFields: {
			...state.hideFields,
			[id]: responseObj.hideKeys
		}
	};

	return {
		...policyChangeObj,
		policyDataSuccess: true,
		accordionLoader: false,
		tabLoader: false,
		policyConfig: {
			...state.policyConfig,
			[id]: responseObj.inputFields
		},
		...policyData
	};
};
