import { SHORTLIST_SHARE_CONSTANTS } from 'AppConstants';
import { SHORTLIST_CONST } from '../actionConstants';

const INITIAL_STATE = {
	apiLoading: false,
	apiSuccess: false,
	apiError: false,
	openModal: '',
	wishlists: [],
	isPopup: false,
	mobile: {
		page: SHORTLIST_SHARE_CONSTANTS.EMP_SHARED
	}
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}

	const { type, payload = {} } = action;
	switch (type) {
		case SHORTLIST_CONST.API_LOADING: {
			return {
				...state,
				apiLoading: payload.loading,
				apiSuccess: false,
				apiError: false,
				msgData: false,
				isPopup: false
			};
		}
		case SHORTLIST_CONST.API_SUCCESS: {
			return {
				...state,
				apiSuccess: payload.name,
				apiError: false,
				apiLoading: false,
				msgData: payload.data,
				openModal: payload.updateModal ? (payload.isPopup ? false : state.openModal ) : state.openModal,
				isPopup: payload.isPopup
			};
		}
		case SHORTLIST_CONST.API_ERROR: {
			return {
				...state,
				apiSuccess: false,
				apiLoading: false,
				apiError: payload.name,
				msgData: payload.data || false,
				openModal: payload.updateModal ? (payload.isPopup ? false : state.openModal ) : state.openModal,
				isPopup: typeof payload.isPopup === 'undefined' ? true : payload.isPopup
			};
		}
		case SHORTLIST_CONST.RESET_API_STATE: {
			return {
				...state,
				apiLoading: false,
				apiSuccess: false,
				apiError: false,
				msgData: false,
				isPopup: false
			};
		}
		case SHORTLIST_CONST.SET_MODAL: {
			return {
				...state,
				openModal: payload.modalType
			};
		}
		case SHORTLIST_CONST.SET_SHORT_LIST: {
			return {
				...state,
				apiLoading: false,
				wishlists: payload
			};
		}

		case SHORTLIST_CONST.SET_PAGE_TYPE: {
			return {
				...state,
				mobile: {
					...state.mobile,
					...payload
				}

			};
		}
		default:
			return state;
	}
};
