import { GROUP_STORE_CONSTANTS } from '../actionConstants';
import { actionCreator, successFor, failedFor } from '../sagaUtil';
import { doSorting } from '../../UtilityComponents/groupsUtil';

export const apiLoading = actionCreator(GROUP_STORE_CONSTANTS.API_LOADING);
export const apiSuccess = actionCreator(GROUP_STORE_CONSTANTS.API_SUCCESS);
export const apiFailed = actionCreator(GROUP_STORE_CONSTANTS.API_ERROR);
export const validateEmployee = actionCreator(GROUP_STORE_CONSTANTS.VALIDATE_EMPLOYEE);
export const validateEmployeeSuccess = actionCreator(successFor(GROUP_STORE_CONSTANTS.VALIDATE_EMPLOYEE));
export const validateEmployeeError = actionCreator(failedFor(GROUP_STORE_CONSTANTS.VALIDATE_EMPLOYEE));
export const resetValidateEmployee = actionCreator(GROUP_STORE_CONSTANTS.RESET_VALIDATION_DATA);
export const removeGrpEmp = actionCreator(GROUP_STORE_CONSTANTS.REMOVE_GRP_EMP);
export const addGrpEmp = actionCreator(GROUP_STORE_CONSTANTS.ADD_EXIST_EMP);
export const createNewGroup = actionCreator(GROUP_STORE_CONSTANTS.CREATE_NEW_GRP);
export const createNewGroupSuccess = actionCreator(successFor(GROUP_STORE_CONSTANTS.CREATE_NEW_GRP));
export const createNewGroupError = actionCreator(failedFor(GROUP_STORE_CONSTANTS.CREATE_NEW_GRP));
export const cancelRequest = actionCreator(GROUP_STORE_CONSTANTS.CANCEL_GRP_REQUEST);
export const setGrpModal = actionCreator(GROUP_STORE_CONSTANTS.SET_GRP_MODAL);
export const groupCreateReset = actionCreator(GROUP_STORE_CONSTANTS.RESET_CREATE_GROUP);

export const getGroupsData = actionCreator(GROUP_STORE_CONSTANTS.GET_GROUP_DATA);
export const getGroupsDataSuccess = actionCreator(successFor(GROUP_STORE_CONSTANTS.GET_GROUP_DATA));
export const getGroupsDataFailure = actionCreator(failedFor(GROUP_STORE_CONSTANTS.GET_GROUP_DATA));
export const setSelectedGroups = actionCreator(GROUP_STORE_CONSTANTS.SET_SELECTED_GROUPS);
export const getConflictingGrps = actionCreator(GROUP_STORE_CONSTANTS.GET_CONFLICTING_STATUS);
export const getConflictingGrpsSuccess = actionCreator(successFor(GROUP_STORE_CONSTANTS.GET_CONFLICTING_STATUS));
export const removeConflict = actionCreator(GROUP_STORE_CONSTANTS.REMOVE_CONFLICTS);
export const removeConflictSuccess = actionCreator(successFor(GROUP_STORE_CONSTANTS.REMOVE_CONFLICTS));
export const removeConflictFailure = actionCreator(failedFor(GROUP_STORE_CONSTANTS.REMOVE_CONFLICTS));
export const bulkCsvUpload = actionCreator(GROUP_STORE_CONSTANTS.GRP_CSV_UPLOAD);
export const resetUpload = actionCreator(GROUP_STORE_CONSTANTS.GRP_UPLOAD_RESET);
export const resetApiState = actionCreator(GROUP_STORE_CONSTANTS.RESET_GRP_API_STATE);
export const deleteGroup = actionCreator(GROUP_STORE_CONSTANTS.DELETE_GROUPS);
export const getUpdateStatus = actionCreator(GROUP_STORE_CONSTANTS.GET_UPDATE_STATUS);
export const getUpdateStatusSuccess = actionCreator(successFor(GROUP_STORE_CONSTANTS.GET_UPDATE_STATUS));
export const resetJobCounts = actionCreator(GROUP_STORE_CONSTANTS.RESET_JOB_COUNT);
export const dwlGroupData = actionCreator(GROUP_STORE_CONSTANTS.DOWNLOAD_GROUP);
export const dwlGroupDataSuccess = actionCreator(successFor(GROUP_STORE_CONSTANTS.DOWNLOAD_GROUP));

export const sortGroupData = (payload) => (dispatch) => {
	const data = doSorting(payload, payload.key === 'employeeSize');
	dispatch({
		type: GROUP_STORE_CONSTANTS.SORT_GROUP_DATA,
		payload: { nonOrgGrpData: data }
	});
};

export const filterGroupsByName = (filterString) => ({
	type: GROUP_STORE_CONSTANTS.FILTER_GROUPS,
	payload: filterString
});

export const groupFeatures = (feature, close) => ({
	type: GROUP_STORE_CONSTANTS.GROUP_FEATURES,
	payload: { feature: close ? 0 : feature }
});

export const resetInitState = () => ({
	type: GROUP_STORE_CONSTANTS.RESET_INIT_STATE
});
