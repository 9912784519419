import { takeLatest, call, put, select } from 'redux-saga/effects';

import { REPORT_LANDING_STORE_CONSTANTS } from '../../actionConstants';
import { APPLICATION_CONSTANTS, FILE_TYPE, PATHS, REPORTS_CONSTANT } from 'AppConstants';
import {
	getSummaryData,
	getGstrStatusData,
	getCustomReportsData,
	getReportsData,
	getReportAdditionalData,
	setSchedulerSettings,
	saveReportsDataApi,
	submitDwlReportParams,
	downloadGstrReportApi,
	downloadLedgerReportApi
} from 'apis/reports/summaryApis';
import {
	getSummarySuccess,
	getSummaryFailed,
	getGstrStatusSuccess,
	getCustomReportsSuccess,
	getReportDataSuccess,
	getGstrStatusFailed,
	getAdditionalSuccess,
	getCustomReportsFailed,
	setApiError,
	setApiLoading,
	setApiSuccess,
	setModal,
	getGstrStatus as getGstrStatusAction
} from '../../actions/reports/reportSummaryActions';
import {
	setApiLoading as setApiLoadingDetail,
	setApiError as setApiErrorDetail,
	setApiSuccess as setApiSuccessDetail
} from '../../actions/reports/reportDetailsAction';
import {
	formatSummaryData,
	formatCustomReportData,
	formatReportData,
	formatAddReport,
	formatReportRequest,
	addTimeStamp
} from 'UtilityComponents/reports/reportSummaryUtil';
import { saveServerLogs } from 'UtilityComponents/Util';
import { masterAddData, reportAdditionalData, selectedReport } from '../../selectors/reportSelector';
import { REPORT_DWNLD_FAIL, scheeduleGstRequest } from '../../../reporting/pages/summary/config';

function* getSummary({ payload }) {
	let error = false;
	const appData = {
		summaryData: {
			data: [],
			apiLoading: false,
			apiSuccess: true
		}
	};
	try {
		const { filter } = payload;
		if (filter.bookingAction) {
			filter.bookingAction = [];
		}
		if (filter && Object.keys(filter).length !== 0) {
			const resp = yield call(getSummaryData, addTimeStamp(payload));
			const { responseCode, bookingReportDetails = [] } = resp?.data || {};
			if (responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
				let summData = false;
				summData = formatSummaryData(bookingReportDetails, payload.type);

				appData.summaryData.data = summData;
				yield put(getSummarySuccess(appData));
			} else {
				error = true;
			}
		}
	} catch (e) {
		error = true;
		saveServerLogs(e.stack, 'error', PATHS.GET_REPORTS_SUMMARY);
	} finally {
		appData.summaryData.apiSuccess = !error;
		if (error) {
			yield put(getSummaryFailed(appData));
		}
	}
}

function* getGstrStatus({ payload }) {
	let error = false;
	try {
		const resp = yield call(getGstrStatusData, payload);
		const { responseCode } = resp?.data || {};
		if (responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			yield put(getGstrStatusSuccess(resp?.data));
		} else {
			error = true;
		}
	} catch (e) {
		error = true;
		saveServerLogs(e.stack, 'error', PATHS.GET_GSTR_STATUS);
	} finally {
		if (error) {
			yield put(getGstrStatusFailed());
		}
	}
}

function* saveReport(payload) {
	let isError = false;
	let errorMsg = APPLICATION_CONSTANTS.API_ERROR;
	try {
		if (payload) {
			const resp = yield call(saveReportsDataApi, payload);
			const { responseCode, message } = resp?.data || {};
			if (responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
				return resp.data;
			} else {
				isError = true;
				errorMsg = message;
			}
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.CUSTOM_REPORT_UPSERT);
		isError = true;
	} finally {
		if (isError) {
			yield put(setApiError({ apiType: PATHS.CUSTOM_REPORT_UPSERT, data: errorMsg, closeModal: true, showNotify: true }));
		}
	}
}

function* schedulerSettings({ payload }) {
	try {
		const requestPayoad = { reportData: { ...scheeduleGstRequest, ...payload } };
		const res = yield* saveReport(requestPayoad);
		const { responseCode, message } = res || {};
		if (responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			yield put(setModal({ modalType: REPORTS_CONSTANT.SUCCESS_MODAL, modalMsg: message }));
			yield put(getGstrStatusAction());
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.SCHEDULER_SETTINGS);
	}
}

function* getCustomReports({ payload }) {
	try {
		const resp = yield call(getCustomReportsData, payload);
		const { responseCode, customReports = [] } = resp?.data || {};
		if (responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const reportsData = formatCustomReportData(customReports);
			yield put(getCustomReportsSuccess(reportsData));
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.GET_CUSTOM_REPORTS);
		yield put(getCustomReportsFailed());
	}
}

function* saveReportData({ payload }) {
	let isError = false;
	let errorMsg = APPLICATION_CONSTANTS.API_ERROR;
	try {
		if (payload) {
			const { isGrtReport = false } = payload;
			const request = formatReportRequest(payload);
			const res = yield* saveReport(request);
			const { responseCode, message } = res || {};
			if (responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
				if (!isGrtReport) {
					yield call(getCustomReports, {});
				}
				const { reportData = {} } = request || {};
				const { disabled = false } = reportData;
				const selectedReportData = yield select(selectedReport);
				yield put(setModal({
					modalType: REPORTS_CONSTANT.SUCCESS_MODAL,
					modalMsg: message,
					isReportDeleted: disabled,
					isGrtReport,
					selectedReport: {
						...selectedReportData,
						...reportData
					}
				}));
			} else {
				isError = true;
				errorMsg = message;
			}
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.CUSTOM_REPORT_UPSERT);
		isError = true;
	} finally {
		if (isError) {
			yield put(setApiError({ apiType: PATHS.CUSTOM_REPORT_UPSERT, data: errorMsg, closeModal: true, showNotify: true }));
		}
	}
}

function* getReportData({ payload = {} }) {
	const { crId } = payload;
	try {
		if (crId) {
			const resp = yield call(getReportsData, { crId });
			const { responseCode, customReport = [] } = resp?.data || {};
			if (responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
				const reportData = formatReportData(customReport);
				const { reportAdditionalData } = reportData;
				if (reportAdditionalData) {
					const formattedAdditionalData = formatAddReport(reportData.reportAdditionalData);
					reportData.formattedAdditionalData = formattedAdditionalData;
				}
				yield put(getReportDataSuccess(reportData));
			}
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.GET_REPORT_DATA);
	}
}

function* getAdditionalData({ payload }) {
	try {
		if (payload) {
			const masterAdditionalData = yield select(masterAddData);
			let report = masterAdditionalData[payload];
			if (!report) {
				const resp = yield call(getReportAdditionalData, { reportType: payload });
				// const { responseCode, additionalData = [] } = resp?.data || {} ;
				const additionalData = resp?.data || [];
				if (additionalData) {
					report = additionalData;
					yield put(getAdditionalSuccess({ additionalData: report, reportType: payload }));
				}
			}
			const reportAdditional = yield select(reportAdditionalData);
			if (report) {
				const formattedAdditionalData = formatAddReport(report, reportAdditional);
				yield put(getReportDataSuccess({ formattedAdditionalData }));
			}
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.GET_ADDITIONAL_DATA);
	}
}

function* downloadTypeReport({ payload = {} }) {
	let { requestPayload, reportPageName, downloadReportType = REPORTS_CONSTANT.OTHER_DOWNLOAD_REPORT_TYPE } = payload;
	const apiType = PATHS.DOWNLOAD_REPORT;
	const isReportPage = reportPageName === REPORTS_CONSTANT.REPORT_SUMMARY;
	if (isReportPage) {
		downloadReportType = REPORTS_CONSTANT.OTHER_DOWNLOAD_REPORT_TYPE;
	}
	const apiLoading = isReportPage ? setApiLoading : setApiLoadingDetail;
	const apiError = isReportPage ? setApiError : setApiErrorDetail;
	const apiSuccess = isReportPage ? setApiSuccess : setApiSuccessDetail;
	let bodyRequest = {
		...requestPayload,
		reportPageName,
		downloadReportType,
		fileType: FILE_TYPE.CSV
	};
	yield put(apiLoading({ apiType, loading: true }));
	let isError = false;
	try {
		const resp = yield call(submitDwlReportParams, addTimeStamp(bodyRequest));
		const { responseCode, jobId, mailDelivered, s3Url } = resp?.data || {};
		if (responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			if (jobId) {
				window.open(`${PATHS.CCB_URL}${PATHS.DOWNLOAD_REPORT}?jobId=${jobId}`);
				yield put(apiSuccess({ apiType, closeModal: true }));
			} else if (s3Url) {
				window.open(s3Url);
				yield put(apiSuccess({ apiType, closeModal: true }));
			} else if (mailDelivered) {
				yield put(apiSuccess({ apiType, closeModal: true }));
				yield put(setModal({ modalType: REPORTS_CONSTANT.MAILER_MODAL }));
			} else {
				isError = true;
			}
		} else {
			isError = true;
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.DOWNLOAD_REPORT);
		isError = true;
	} finally {
		if (isError) {
			yield put(apiError({ apiType, data: REPORT_DWNLD_FAIL, closeModal: true, showNotify: true }));
		}
	}
}

function* downloadGstrReport({ payload }) {
	let isError = false;
	let errorMessage = '';
	try {
		const resp = yield call(downloadGstrReportApi, payload);
		const { responseCode, url, message } = resp?.data || {};
		if (responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE && url) {
			window.open(url);
			yield put(setModal({ modalType: '' }));
		} else {
			isError = true;
			errorMessage = message;
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.DOWNLOAD_GSTR_REPORT);
		isError = true;
	} finally {
		if (isError) {
			yield put(setApiError({ apiType: PATHS.DOWNLOAD_GSTR_REPORT, data: errorMessage, closeModal: true, showNotify: true }));
		}
	}
}

function* downloadLedgerReport({ payload }) {
	let isError = false;
	let errorMessage = '';
	const { filter } = addTimeStamp(payload);
	try {
		const resp = yield call(downloadLedgerReportApi, filter);
		const { responseCode, message } = resp?.data || {};
		if (responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			yield put(setModal({ modalType: REPORTS_CONSTANT.LEDGER_MODAL, modalMsg: message }));
		} else {
			isError = true;
			errorMessage = message || APPLICATION_CONSTANTS.GENERIC_API_ERROR;
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.DOWNLOAD_GSTR_REPORT);
		isError = true;
		errorMessage = APPLICATION_CONSTANTS.GENERIC_API_ERROR;
	} finally {
		errorMessage = APPLICATION_CONSTANTS.GENERIC_API_ERROR;

		if (isError) {
			yield put(setApiError({ apiType: PATHS.DOWNLOAD_GSTR_REPORT, data: errorMessage, closeModal: true, showNotify: true }));
		}
	}
}

export default [
	takeLatest(REPORT_LANDING_STORE_CONSTANTS.GET_SUMMARY, getSummary),
	takeLatest(REPORT_LANDING_STORE_CONSTANTS.GET_GSTR_STATUS, getGstrStatus),
	takeLatest(REPORT_LANDING_STORE_CONSTANTS.GET_CUSTOM_REPORTS, getCustomReports),
	takeLatest(REPORT_LANDING_STORE_CONSTANTS.SAVE_REPORT_DATA, saveReportData),
	takeLatest(REPORT_LANDING_STORE_CONSTANTS.GET_REPORT_DATA, getReportData),
	takeLatest(REPORT_LANDING_STORE_CONSTANTS.GET_ADDITIONAL_DATA, getAdditionalData),
	takeLatest(REPORT_LANDING_STORE_CONSTANTS.SCHEDULE_SETTINGS, schedulerSettings),
	takeLatest(REPORT_LANDING_STORE_CONSTANTS.DOWNLOAD_TYPE_REPORT, downloadTypeReport),
	takeLatest(REPORT_LANDING_STORE_CONSTANTS.DOWNLOAD_GSTR_REPORT, downloadGstrReport),
	takeLatest(REPORT_LANDING_STORE_CONSTANTS.DOWNLOAD_LEDGER_REPORT, downloadLedgerReport)
];
