import { call, put, select, takeLatest } from 'redux-saga/effects';

import { APPLICATION_CONSTANTS, ORGANIZATION_DETAILS_CONSTANT, PATHS } from 'AppConstants';
import { postOrgDetails, getClientKeyApi, getCurrenciesApi, getIdentifyDetails, getProfileDetailsApi,
	postIdentifierDetailsApi, updateClientKeyApi, getOrgDetailsApi, updateAddOrgAddressApi } from 'apis/organizationDetailsApis';
import { BIZ_HEADER_STORE_CONSTANTS, ORG_DETAILS_CONSTANT } from 'reduxStore/actionConstants';
import { getApiKeysFailed, getApiKeysSuccess, getCurrenciesFailed, getCurrenciesSuccess, getOrgsDataSuccess,
	postFormDataFailed, setModal, updateApiKeysFailed, getOrgsAddresDataSuccess, getOrgsAddresDataFailure, getOrgsDataFailure } from 'reduxStore/actions/organizationActions';
import { updatePopup } from '../actions/popupActions';
import { formatCurrencyData, formatKeyDataFields } from 'UtilityComponents/organizationUtil';
import { getApiFields } from 'reduxStore/selectors/organizationSelectors';
import { getOrgId, saveServerLogs } from 'UtilityComponents/Util';

function* fetchOrgDetails() {
	let isError = false;
	try {
		const response = yield call(getIdentifyDetails);
		const { data } = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const { companyIdentifiers = [] } = data;
			yield put(getOrgsDataSuccess({
				subsidiaryData: companyIdentifiers
			}));
		} else {
			isError = true;
		}
	} catch (err) {
		isError = true;
		saveServerLogs(err.stack, 'error', PATHS.GET_IDENTIFY_DETAILS);
	} finally {
		if (isError) {
			yield put(getOrgsDataFailure());
		}
	}
}

function* fetchOrgAddressDetails() {
	let isError = false;
	try {
		const response = yield call(getOrgDetailsApi);
		const { data } = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const { companyIdentifiers = [] } = data;
			yield put(getOrgsAddresDataSuccess({
				companyAddresses: companyIdentifiers
			}));
		} else {
			isError = true;
		}
	} catch (err) {
		isError = true;
		saveServerLogs(err.stack, 'error', 'fetchOrgAddressDetails');
	} finally {
		if (isError) {
			yield put(getOrgsAddresDataFailure());
		}
	}
}

function* addUpdateIdOrAddress({ payload }) {
	// call postformdata or submitAddressData based on payload.updatetype with requestpayload excluding updayetype
	const { updateType, ...reqPayload } = payload;
	if (updateType === ORGANIZATION_DETAILS_CONSTANT.UPDATE_TYPE.ADDRESS) {
		yield submitAddressData(reqPayload);
	} else {
		yield postFormData({ payload: reqPayload });
	}
}

function* postFormData({ payload }) {
	const { type, ...reqPaylaod } = payload;
	const reqUrl = type === ORGANIZATION_DETAILS_CONSTANT.ORG_DETAILS_MODAL ? postOrgDetails : postIdentifierDetailsApi;
	let errorMsg = false;
	const orgId = getOrgId();
	try {
		const response = yield call(reqUrl, reqPaylaod, { orgId });
		const { data } = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			if (type === ORGANIZATION_DETAILS_CONSTANT.ORG_DETAILS_MODAL) {
				yield put({
					type: BIZ_HEADER_STORE_CONSTANTS.GET_PROFILE_CALLING
				});
			} else {
				yield fetchOrgDetails();
			}

			yield put(setModal({ modalType: '' }));
		} else {
			errorMsg = data && data.message;
		}
	} catch (err) {
		const errData = err?.response?.data;
		errorMsg = errData && errData.message || APPLICATION_CONSTANTS.GENERIC_API_ERROR;
		saveServerLogs(err.stack, 'error', reqUrl);
	} finally {
		if (errorMsg && type === ORGANIZATION_DETAILS_CONSTANT.DELETE_MODAL) {
			yield put(setModal({ modalType: '' }));
			yield put(updatePopup(
				{ showPop: true,
					popData: {
						isFooter: false,
						content: errorMsg
					} }
			));
		} else if (errorMsg) {
			yield put(postFormDataFailed({
				modalState: { loading: false,
					errorMsg }
			}));
		}
	}
}

function* submitAddressData(payload) {
	const { type, ...reqPayload } = payload;
	let errorMsg = false;
	try {
		const response = yield call(updateAddOrgAddressApi, reqPayload);
		const { data } = response || {};
		if (data && data.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			yield fetchOrgAddressDetails();
			yield put(setModal({ modalType: '' }));
		} else {
			errorMsg = data && data.message;
		}
	} catch (err) {
		const errData = err?.response?.data;
		errorMsg = errData && errData.message || APPLICATION_CONSTANTS.GENERIC_API_ERROR;
		saveServerLogs(err.stack, 'error', reqUrl);
	} finally {
		if (errorMsg && type === ORGANIZATION_DETAILS_CONSTANT.DELETE_MODAL) {
			yield put(setModal({ modalType: '' }));
			yield put(updatePopup(
				{ showPop: true,
					popData: {
						isFooter: false,
						content: errorMsg
					} }
			));
		} else if (errorMsg) {
			yield put(postFormDataFailed({
				modalState: { loading: false,
					errorMsg }
			}));
		}
	}
}

function* getCurrencies() {
	let error = false;
	try {
		const response = yield call(getCurrenciesApi);
		const respData = response && response.data;
		if (respData) {
			const cur = formatCurrencyData(respData?.currencies);
			yield put(getCurrenciesSuccess(cur));
		} else {
			error = true;
		}
	} catch (error) {
		saveServerLogs(error.stack, 'error', PATHS.SAVE_ORGANIZATION_DETAILS);
		error = true;
	} finally {
		if (error) {
			yield put(getCurrenciesFailed());
		}
	}
}

function* getApiKeysData() {
	let error = false;
	try {
		const response = yield call(getClientKeyApi);
		const respData = response && response.data;
		if (respData && respData.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			const apiFields = yield select(getApiFields);
			const data = formatKeyDataFields(respData, apiFields);
			yield put(getApiKeysSuccess(data));
		} else {
			error = true;
		}
	} catch (err) {
		saveServerLogs(err.stack, 'error', PATHS.GET_API_KEYS);
		error = true;
	} finally {
		if (error) {
			yield put(getApiKeysFailed());
		}
	}
}

function* updateKeysData({ payload }) {
	let error = false;
	try {
		const response = yield call(updateClientKeyApi, payload);
		const respData = response && response.data;

		if (respData.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			yield put(setModal({ modalType: '' }));
			yield getApiKeysData();
		} else {
			error = respData.message;
		}
	} catch (err) {
		saveServerLogs(err.stack, 'error', PATHS.UPDATE_API_KEYS);
		error = true;
	} finally {
		if (error) {
			yield put(updatePopup({
				showPop: true,
				popData: {

					heading: APPLICATION_CONSTANTS.GENERIC_ERROR_HEADING,
					content: error || APPLICATION_CONSTANTS.GENERIC_API_ERROR,
					apiStatus: 'ERR_DEFAULT',
					isFooter: false
				}
			}));
			yield put(updateApiKeysFailed({
				apiError: ORG_DETAILS_CONSTANT.UPDATE_API_KEYS,
				apiSuccess: false
			}));
		}
	}
}

export default [
	takeLatest(ORG_DETAILS_CONSTANT.GET_ORGANIZATION_DETAILS, fetchOrgDetails),
	takeLatest(ORG_DETAILS_CONSTANT.POST_FORM_DATA, postFormData),
	takeLatest(ORG_DETAILS_CONSTANT.GET_CURRENCIES_API, getCurrencies),
	takeLatest(ORG_DETAILS_CONSTANT.GET_API_KEYS, getApiKeysData),
	takeLatest(ORG_DETAILS_CONSTANT.UPDATE_API_KEYS, updateKeysData),
	takeLatest(ORG_DETAILS_CONSTANT.GET_ORGANIZATION_ADDRESS_DETAILS, fetchOrgAddressDetails),
	takeLatest(ORG_DETAILS_CONSTANT.ADD_UPDATE_ADDRESS, submitAddressData),
	takeLatest(ORG_DETAILS_CONSTANT.ADD_UPDATE_ORG_ID_ADDRESS, addUpdateIdOrAddress)
];
