import { REQUISITION_STORE_CONSTANT } from '../actionConstants';

import { actionCreator, failedFor, successFor } from '../sagaUtil';

export const getPopularFlight = actionCreator(REQUISITION_STORE_CONSTANT.GET_POPULAR_FLIGHT);
export const getPopularFlightSuccess = actionCreator(successFor(REQUISITION_STORE_CONSTANT.GET_POPULAR_FLIGHT));
export const searchFlight = actionCreator(REQUISITION_STORE_CONSTANT.GET_FLIGHT_SEARCH);
export const searchFlightSuccess = actionCreator(successFor(REQUISITION_STORE_CONSTANT.GET_FLIGHT_SEARCH));
export const setFlightSearchParams = actionCreator(REQUISITION_STORE_CONSTANT.SET_FLIGHT_SEARCH);
export const setFlightTravelDetails = actionCreator(REQUISITION_STORE_CONSTANT.SET_FLIGHT_TRAVEL_DETAILS);
export const addRemoveFlightTravel = actionCreator(REQUISITION_STORE_CONSTANT.ADD_REMOVE_FLIGHT_TRAVEL);
export const updateTravellerDtls = actionCreator(REQUISITION_STORE_CONSTANT.UPDATE_TRAVELLERS);
export const updateReqModal = actionCreator(REQUISITION_STORE_CONSTANT.UPDATE_REQUISITION_MODAL);
export const setPrimaryPax = actionCreator(REQUISITION_STORE_CONSTANT.UPDATE_PRIMARY_PAX);
export const updateBookFor = actionCreator(REQUISITION_STORE_CONSTANT.UPDATE_BOOK_FOR);

export const searchHotel = actionCreator(REQUISITION_STORE_CONSTANT.GET_HOTEL_SEARCH);
export const searchHoteSuccess = actionCreator(successFor(REQUISITION_STORE_CONSTANT.GET_HOTEL_SEARCH));
export const getPlaceDetails = actionCreator(REQUISITION_STORE_CONSTANT.GET_PLACE_DETAILS);
export const getPlaceDetailsSuccess = actionCreator(successFor(REQUISITION_STORE_CONSTANT.GET_PLACE_DETAILS));
export const setHotelDetails = actionCreator(REQUISITION_STORE_CONSTANT.UPDATE_HOTEL_DATA);
export const resetHotelData = actionCreator(REQUISITION_STORE_CONSTANT.RESET_HOTEL_DATA);

export const searchBus = actionCreator(REQUISITION_STORE_CONSTANT.GET_BUS_SEARCH);
export const searchBusSuccess = actionCreator(successFor(REQUISITION_STORE_CONSTANT.GET_BUS_SEARCH));
export const setBusDetails = actionCreator(REQUISITION_STORE_CONSTANT.UPDATE_BUS_DATA);

export const getTripTagandReasons = actionCreator(REQUISITION_STORE_CONSTANT.GET_REASONS_TRIP_TAGS);
export const setTripTagandReasons = actionCreator(REQUISITION_STORE_CONSTANT.SET_REASONS_TRIP_TAGS);
export const updateTripTagFormData = actionCreator(REQUISITION_STORE_CONSTANT.UPDATE_TRIP_TAGS);
export const proceedToSubmit = actionCreator(REQUISITION_STORE_CONSTANT.PROCEED_TO_SUBMIT);
export const setModal = actionCreator(REQUISITION_STORE_CONSTANT.SET_MODAL);
export const setTravReqError = actionCreator(REQUISITION_STORE_CONSTANT.SET_TRAV_ERROR);
export const setTravReqReasons = actionCreator(REQUISITION_STORE_CONSTANT.SET_TRAV_REASONS);
export const resetFlights = actionCreator(REQUISITION_STORE_CONSTANT.RESET_FLIGHT);
export const setCabParams = actionCreator(REQUISITION_STORE_CONSTANT.SET_CAB_PARAMS);
export const setSearchData = actionCreator(REQUISITION_STORE_CONSTANT.SET_SEARCH_DATA);
export const setApiLoading = actionCreator(REQUISITION_STORE_CONSTANT.SET_APILOADING);
export const resetBusData = actionCreator(REQUISITION_STORE_CONSTANT.RESET_BUS_DATA);
