import {
	WALLET_STORE_CONSTANTS
} from '../actionConstants';
import { WALLET_CONSTANTS, APPLICATION_CONSTANTS } from 'AppConstants';
import {
	filters
} from '../../wallet/config';
import { getCookie, getLocalStorage } from 'UtilityComponents/Util';
import { presetFilters, trackWalletEvents, formatListData, formatCount, fromatAccDetails } from 'UtilityComponents/WalletUtils';
import cloneDeep from 'lodash/cloneDeep';
const filtersObject = presetFilters(filters, []);
const INITIAL_STATE = {
	filters: filtersObject.filters,
	list: [],
	appliedFilters: filtersObject.appliedFilters,
	selectedList: {},
	isAllCount: true,
	MTD: true,
	mmtCount: 0,
	mainLastIndex: 0,
	feature: 0,
	walletDtls: {},
	experiments: {},
	walletOverlay: ''
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}
	switch (action.type) {
		case WALLET_STORE_CONSTANTS.NEXT_FEATURE: {
			const status = action.payload === 1 ? 'next' : 'previous';
			trackWalletEvents(`Wallet_onboarding_${state.feature}_${WALLET_CONSTANTS.TOTAL_FEATURES}_${status}`);
			let feature = parseInt(state.feature) + (action.payload);
			if (!action.payload) {
				feature = 1;
			}
			return {
				...state,
				feature
			};
		}
		case WALLET_STORE_CONSTANTS.SKIP_FEATURE: {
			trackWalletEvents(`Wallet_onboarding_${WALLET_CONSTANTS.TOTAL_FEATURES}_${WALLET_CONSTANTS.TOTAL_FEATURES}_next`);
			return {
				...state,
				feature: 0

			};
		}
		case WALLET_STORE_CONSTANTS.SET_LIST: {
			const listData = formatListData(action.payload, state.isAllSelected, state.mmtCount);
			return {
				...state,
				list: [...state.list, ...listData.list],
				listApiCalled: true,
				lastIndex: listData.lastIndex,
				pageDataApi: listData.list.length === 0,
				selectedList: { ...state.selectedList, ...listData.selectedList },
				mmtCount: listData.mmtCount,
				listApiFailed: false

			};
		}
		case WALLET_STORE_CONSTANTS.SET_COUNT: {
			const isNofilter = state.appliedFilters.length === 0;
			const formattedData = formatCount(action.payload, cloneDeep(state.filters), state.filterQuery, isNofilter);
			return {
				...state,
				filters: formattedData.filters,
				countApiCalled: true,
				isAllCount: formattedData.isAllCount,
				MTD: formattedData.isMTD

			};
		}
		case WALLET_STORE_CONSTANTS.SET_FILTER_OBJECT: {
			return {
				...state,
				filters: action.payload

			};
		}
		case WALLET_STORE_CONSTANTS.UPDATE_APPLY_FILTER_OBJECT: {
			return {
				...state,
				...action.payload

			};
		}
		case WALLET_STORE_CONSTANTS.SET_QUERY: {
			return {
				...state,
				filterQuery: cloneDeep(action.payload),
				list: [],
				bundleData: false,
				listApiCalled: false,
				countApiCalled: false

			};
		}
		case WALLET_STORE_CONSTANTS.RESET_LIST: {
			return {
				...state,
				filterQuery: cloneDeep(action.payload),
				list: [],
				listApiCalled: false,
				listApiFailed: false

			};
		}

		case WALLET_STORE_CONSTANTS.LIST_API_CALLING: {
			return {
				...state,
				listApiCalled: false,
				listApiFailed: false

			};
		}

		case WALLET_STORE_CONSTANTS.UPDATE_SELECT_LIST: {
			return {
				...state,
				...action.payload

			};
		}
		case WALLET_STORE_CONSTANTS.RESET_WALLET_DATA: {
			return {
				...state,
				...INITIAL_STATE,
				initQuery: action.payload,
				walletDtls: state.walletDtls,
				experiments: state.experiments,
				filterQuery: cloneDeep(action.payload)

			};
		}
		case WALLET_STORE_CONSTANTS.LIST_API_CALLED: {
			return {
				...state,
				listApiCalled: true,
				listApiFailed: false

			};
		}
		case WALLET_STORE_CONSTANTS.LIST_API_FAILED: {
			return {
				...state,
				listApiCalled: true,
				listApiFailed: true

			};
		}

		case WALLET_STORE_CONSTANTS.COUNT_API_CALLED: {
			return {
				...state,
				countApiCalled: true

			};
		}
		case WALLET_STORE_CONSTANTS.SET_ALL_COUNT_API: {
			return {
				...state,
				isAllCount: true
			};
		}
		case WALLET_STORE_CONSTANTS.SET_ALL_WALLET_COUNT: {
			const isNofilter = state.appliedFilters.length === 0;
			const formattedData = formatCount(action.payload, cloneDeep(state.filters), state.filterQuery, isNofilter, true);
			return {
				...state,
				filters: formattedData.filters,
				countApiCalled: true,
				isAllCount: true,
				MTD: formattedData.isMTD
			};
		}
		case WALLET_STORE_CONSTANTS.WALLET_ACC_DTL_CALLED: {
			return {
				...state,
				walletAccLoader: true,
				walletDtls: {}
			};
		}
		case WALLET_STORE_CONSTANTS.SET_WALLET_ACC_DTL: {
			const details = fromatAccDetails(action.payload);
			return {
				...state,
				...details,
				walletAccLoader: false
			};
		}
		case WALLET_STORE_CONSTANTS.WALLET_ACC_DTL_FAILED: {
			return {
				...state,
				walletDtls: false,
				walletAccLoader: false
			};
		}
		case WALLET_STORE_CONSTANTS.RECHARGE_API_CALLED: {
			return {
				...state,
				rechargeLoader: true,
				rechargeError: false,
				rechargeUnSuccess: false,
				rechargeSuccess: false,
				...action.payload
			};
		}
		case WALLET_STORE_CONSTANTS.RECHARGE_SUCCESS: {
			return {
				...state,
				rechargeLoader: false,
				rechargeError: false,
				rechargeUnSuccess: false,
				rechargeSuccess: true
			};
		}
		case WALLET_STORE_CONSTANTS.RECHARGE_FAILED: {
			return {
				...state,
				rechargeLoader: false,
				rechargeSuccess: false,
				...action.payload
			};
		}
		case WALLET_STORE_CONSTANTS.RECHARGE_STATUS: {
			return {
				...state,
				...action.payload,
				rechargeLoader: false
			};
		}
		case WALLET_STORE_CONSTANTS.REMINDER_API_CALLED: {
			return {
				...state,
				reminderLoader: true,
				reminderError: false,
				reminderSucces: false
			};
		}
		case WALLET_STORE_CONSTANTS.REMINDER_SUCCESS: {
			return {
				...state,
				reminderSettings: {
					...state.reminderSettings,
					enable: action.payload && action.payload.apiRequest && action.payload.apiRequest.is_enable
				},
				reminderLoader: false,
				reminderError: false,
				reminderSucces: true
			};
		}
		case WALLET_STORE_CONSTANTS.REMINDER_FAILED: {
			return {
				...state,
				reminderLoader: false,
				reminderError: APPLICATION_CONSTANTS.API_ERROR,
				reminderSucces: false
			};
		}

		case WALLET_STORE_CONSTANTS.DOWNLOAD_API_CALLED: {
			return {
				...state,
				downloadLoader: true,
				downloadError: false,
				downloadSuccess: false
			};
		}
		case WALLET_STORE_CONSTANTS.DOWNLOAD_API_SUCCESS: {
			return {
				...state,
				downloadLoader: false,
				downloadError: false,
				downloadSuccess: true,
				selectedList: {},
				isAllSelected: false
			};
		}
		case WALLET_STORE_CONSTANTS.DOWNLOAD_API_FAILED: {
			return {
				...state,
				downloadLoader: false,
				downloadError: true,
				downloadSuccess: false
			};
		}

		case WALLET_STORE_CONSTANTS.MODAL_TYPE: {
			return {
				...state,
				modalType: action.payload

			};
		}
		case WALLET_STORE_CONSTANTS.SELF_MAIL_SUCCESS: {
			return {
				...state,
				mailSendSuccess: true
			};
		}
		case WALLET_STORE_CONSTANTS.SELF_MAIL_FAILURE: {
			return {
				...state,
				mailSendFailure: true
			};
		}
		case WALLET_STORE_CONSTANTS.CLEAR_MODAL_STATE: {
			return {
				...state,
				mailSendSuccess: false,
				mailSendFailure: false
			};
		}
		case WALLET_STORE_CONSTANTS.SET_EXP_DATA: {
			const { payload = {} } = action;
			const { walletReminderExp, walletNewFlow, newWalletUI = getLocalStorage(WALLET_CONSTANTS.WALLET_NEW_FLOW) === 'true', ssrAb = false } = payload;
			return {
				...state,
				experiments: {
					walletReminderExp,
					walletNewFlow,
					newWalletUI,
					ssrAb
				}
			};
		}
		case WALLET_STORE_CONSTANTS.WALLET_OVERLAY: {
			return {
				...state,
				walletOverlay: action.payload
			};
		}
		default:
			return state;
	}
};
