import {CONSTANTS} from "../../CompanyAdminReferral/utils";

export default (state ={} ,action) => {
    if(!action) return state;

    switch (action.type) {
        case CONSTANTS.POST_ADMIN_REFER_SUCCESS :
            return {...state, ...action.payload}
        case CONSTANTS.SELF_ADMIN_REFER_DETAILS_FETCH_SUCCESS :
            return {...state, ...action.payload}
        case CONSTANTS.SELF_ADMIN_REFER_DETAILS_POST_SUCCESS :
            return {...state, ...action.payload}
        case CONSTANTS.ADMIN_REFER_FAILURE :
            return {...state, ...action.payload}
        case CONSTANTS.CLEAR_ADMIN_STATE :
            return {...state, ...action.payload}
        case CONSTANTS.EMPLOYEE_DATA_API_DATA_SUCCESS:
            return {...state, ...action.payload}
    }
    return state;
}
