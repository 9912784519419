import { SHORTLIST_CONST } from '../actionConstants';
import {
	saveServerLogs, getCookie
} from '../../UtilityComponents/Util';
import {
	PATHS
} from '../../AppConstants';
import { getAxiosConfig } from '../../apisSetup/apiConfig';

const axiosApiCaller = getAxiosConfig(PATHS.WISHLIST_FQDN, true,
	{
		'Content-Type': 'application/json',
		'mmt-auth': getCookie('mmt-auth')
	}, false);

export const setApiLoading = (state) => ({
	type: SHORTLIST_CONST.API_LOADING,
	payload: {
		loading: state
	}
});

export const setApiError = (data) => ({
	type: SHORTLIST_CONST.API_ERROR,
	payload: data
});

export const setApiSuccess = (data) => ({
	type: SHORTLIST_CONST.API_SUCCESS,
	payload: data
});

export const resetApiState = () => ({
	type: SHORTLIST_CONST.RESET_API_STATE
});

export const setModal = (modalType) => ({
	type: SHORTLIST_CONST.SET_MODAL,
	payload: {
		modalType
	}
});

export const setMobilePage = (payload) => ({
	type: SHORTLIST_CONST.SET_PAGE_TYPE,
	payload
});

export const shortlist = () => async (dispatch) => {
	try {
		dispatch(setApiLoading(true));
		const response = await axiosApiCaller.get(PATHS.WISH_LIST);
		if (response && response.data && response.data.status === 'SUCCESS') {
			dispatch({
				type: SHORTLIST_CONST.SET_SHORT_LIST,
				payload: response.data.wishlists
			});
		} else {
			dispatch(setApiError());
		}
	} catch (e) {
		dispatch(setApiError());
	} finally {
		dispatch(setApiLoading(false));
	}
};
