import * as ActionConstants from '../actionConstants';

const initialState = {};
const rootReducer = (state = initialState, action) => {
	let screenFgtPwd = ActionConstants.SCREEN_FGT_PWD;
	let screenVerifyEmail = ActionConstants.SCREEN_VERIFY_EMAIL;
	if (!action) {
		return;
	}
	switch (action.type) {
		case ActionConstants.RESET_LOGGING:
			return {};
		case ActionConstants.LOGGED_IN:
		case ActionConstants.LOGIN_PROGRESS:
		case ActionConstants.LOGIN_FAILED:
		case ActionConstants.INVALID_EMAIL_FORMAT:
		case ActionConstants.LOGIN_B2C:
		case ActionConstants.LOGIN_PROCEED_TO_DASHBOARD:
		case ActionConstants.PRE_VALIDATION_SUCCESS:
		case ActionConstants.PRE_VALIDATION_FAILED:
		case ActionConstants.LOGIN_PROCEED_TO_URL:
		case ActionConstants.PASSWORD_LENGTH_IS_INSUFFICIENT:
		case ActionConstants.LOGIN_SSO:
		case ActionConstants.LOGIN_PASS_EXPIRE:
		case ActionConstants.PROCEED_TO_PROVISIONAL_DASHBOARD:
			return { ...state, loginPayload: { ...action.payload } };
		case screenFgtPwd.CALL_SUCCESS:
		case screenFgtPwd.CALL_IN_PROGRESS:
		case screenFgtPwd.CALL_DONE:
		case screenFgtPwd.CALL_FAILED:
		case screenFgtPwd.NW_ERROR:
		case screenFgtPwd.FORGOT_PWD_INVALID_EMAIL:
		case screenFgtPwd.CLEAR_STATE:
		case screenFgtPwd.FORGOT_PWD_INVALID_EMAIL_FORMAT:
		case screenFgtPwd.SSO_USER_FORGOT_PASSWORD:
			return { ...state, fgtPwdPayload: { ...action.payload } };
		case screenVerifyEmail.ADMIN_LOGGED_IN:
		case screenVerifyEmail.NEXT_CLICKED:
		case screenVerifyEmail.NON_ADMIN_LOGGED_IN:
		case screenVerifyEmail.CALL_FAILED:
		case screenVerifyEmail.CALL_DONE:
		case screenVerifyEmail.CALL_IN_PROGRESS:
		case screenVerifyEmail.NW_ERROR:
		case screenVerifyEmail.CLEAR_STATE:
		case screenVerifyEmail.CALL_SUCCESS:
		case screenVerifyEmail.SERVER_ERROR:
			return { ...state, emlVerifyPayload: { ...action.payload } };
		default:
			return state;
	}
};
export default rootReducer;
