import { GROUP_STORE_CONSTANTS } from '../actionConstants';
import { successFor, failedFor } from '../sagaUtil';
import { setSelectedGroups, filterGroupByName } from '../../UtilityComponents/groupsUtil';
import { removeItem, formatValidationData, addItemsToValid } from '../../Groups/utils';
import { APPLICATION_CONSTANTS, GROUP_CONSTANTS } from 'AppConstants';

const initUploadState = {
	totalTobeUpdated: 0,
	remainingCount: 0,
	jobId: false,
	updateStatus: {}
};

const INITIAL_STATE = {
	groupData: {
		grpId: null
	},
	validationData: {
		alreadyExist: [],
		invalidUsers: [],
		notFoundUsers: [],
		existInSameGroup: [],
		validUsers: [],
		validationKey: ''
	},
	validationFail: 0,
	validationFailMsg: '',
	validationFileLoading: {},
	newGroupData: {},
	grpLoading: false,
	modal: false,
	selectedGroups: {},
	isAllGrpSelected: false,
	conflictingGrps: [],
	apiStatus: '',
	apiType: '',
	msgData: '',
	showNotify: false,
	downloadCsvStatus: {
		status: '',
		msg: ''
	},
	uploadRemainingTime: { min: 0, hr: 0, sec: 0 },
	uploadStatus: {
		status: '',
		percentage: 0
	},
	selectedGrpDet: {},
	...initUploadState
};

const initState = {
	apiLoading: false,
	msgData: false,
	apiType: false,
	apiStatus: '',
	modal: '',
	showNotify: false
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}

	const { type, payload = {} } = action;

	switch (type) {
		case GROUP_STORE_CONSTANTS.GET_GROUP_DATA: {
			return {
				...state,
				grploading: true
			};
		}
		case successFor(GROUP_STORE_CONSTANTS.GET_GROUP_DATA): {
			return {
				...state,
				grploading: false,
				selectedGroups: {},
				isAllGrpSelected: false,
				...payload
			};
		}
		case failedFor(GROUP_STORE_CONSTANTS.GET_GROUP_DATA): {
			return {
				...state,
				grploading: false,
				grpFailure: true
			};
		}
		case successFor(GROUP_STORE_CONSTANTS.VALIDATE_EMPLOYEE): {
			const { validationData = {}, isCsv, fileIdentifier } = payload;
			const { alreadyExist,
				invalidUsers,
				notFoundUsers,
				validUsers,
				existInSameGroup,
				key } = formatValidationData(validationData, isCsv);

			return {
				...state,
				validationData: {
					...state.validationData,
					alreadyExist: [...state.validationData.alreadyExist, ...alreadyExist],
					invalidUsers: [...state.validationData.invalidUsers, ...invalidUsers],
					notFoundUsers: [...state.validationData.notFoundUsers, ...notFoundUsers],
					validUsers: [...state.validationData.validUsers, ...validUsers],
					existInSameGroup: [...state.validationData.existInSameGroup, ...existInSameGroup],
					validationKey: isCsv ? key : state.validationData.validationKey
				},
				validationFail: 0,
				validationFailMsg: '',
				validationFileLoading: { [fileIdentifier]: false },
				grpLoading: false
			};
		}
		case failedFor(GROUP_STORE_CONSTANTS.VALIDATE_EMPLOYEE): {
			const { errMsg } = payload;
			return {
				...state,
				validationFail: state.validationFail + 1,
				validationFailMsg: errMsg,
				validationFileLoading: {},
				grpLoading: false
			};
		}
		case GROUP_STORE_CONSTANTS.VALIDATE_EMP_LOADING: {
			return {
				...state,
				validationFileLoading: {},
				grpLoading: true
			};
		}
		case GROUP_STORE_CONSTANTS.RESET_VALIDATION_DATA: {
			return {
				...state,
				validationData: {
					alreadyExist: [],
					invalidUsers: [],
					notFoundUsers: [],
					existInSameGroup: [],
					validUsers: [],
					validationKey: ''
				},
				validationFail: 0,
				validationFailMsg: '',
				validationFileLoading: {},
				grpLoading: false
			};
		}
		case GROUP_STORE_CONSTANTS.REMOVE_GRP_EMP: {
			const { config = {}, item = {} } = payload;
			const validationData = removeItem(config, item, state.validationData);
			const { validUsers = [] } = validationData;
			return {
				...state,
				validationData,
				validationFileLoading: validUsers.length > 0 ? state.validationFileLoading : {}
			};
		}
		case GROUP_STORE_CONSTANTS.ADD_EXIST_EMP: {
			const { config = {}, items = [] } = payload;
			const validationData = addItemsToValid(config, items, state.validationData);
			return {
				...state,
				validationData
			};
		}
		case successFor(GROUP_STORE_CONSTANTS.CREATE_NEW_GRP): {
			const { group } = payload;
			return {
				...state,
				...initUploadState,
				newGroupData: group,
				grpLoading: false
			};
		}
		case failedFor(GROUP_STORE_CONSTANTS.CREATE_NEW_GRP): {
			return {
				...state,
				grpLoading: false,
				validationFailMsg: '',
				validationFail: state.validationFail + 1
			};
		}
		case GROUP_STORE_CONSTANTS.RESET_CREATE_GROUP: {
			return {
				...state,
				grpLoading: false,
				newGroupData: {}
			};
		}
		case GROUP_STORE_CONSTANTS.SET_GRP_MODAL: {
			return {
				...state,
				modal: payload
			};
		}

		case GROUP_STORE_CONSTANTS.SORT_GROUP_DATA: {
			return {
				...state,
				...payload
			};
		}
		case GROUP_STORE_CONSTANTS.SET_SELECTED_GROUPS: {
			const selectedGrpData = setSelectedGroups(payload, state.selectedGroups);
			return {
				...state,
				...selectedGrpData
			};
		}
		case GROUP_STORE_CONSTANTS.FILTER_GROUPS: {
			const filterResult = filterGroupByName(payload, state.groupData);
			return {
				...state,
				nonOrgGrpData: filterResult
			};
		}
		case GROUP_STORE_CONSTANTS.GROUP_FEATURES: {
			const { feature } = payload;
			return {
				...state,
				feature
			};
		}
		case successFor(GROUP_STORE_CONSTANTS.GET_GROUP_DETAILS): {
			return {
				...state,
				...payload
			};
		}
		case successFor(GROUP_STORE_CONSTANTS.GET_CONFLICTING_STATUS): {
			const { conflictingGrps = [] } = payload;
			return {
				...state,
				conflictingGrps
			};
		}
		case GROUP_STORE_CONSTANTS.REMOVE_CONFLICTS_LOADING: {
			return {
				...state,
				conflictLoading: true
			};
		}
		case successFor(GROUP_STORE_CONSTANTS.REMOVE_CONFLICTS): {
			return {
				...state,
				conflictingGrps: []
			};
		}
		case GROUP_STORE_CONSTANTS.API_LOADING: {
			const { loading, data, apiType = false } = payload;
			return {
				...state,
				msgData: data || false,
				apiType: apiType,
				apiStatus: loading ? APPLICATION_CONSTANTS.LOADING : ''
			};
		}
		case GROUP_STORE_CONSTANTS.API_ERROR: {
			const { data = false, openModal, apiType = false, showNotify } = payload;
			return {
				...state,
				apiLoading: false,
				msgData: data,
				apiType: apiType,
				apiStatus: APPLICATION_CONSTANTS.FAILED,
				modal: typeof openModal === 'undefined' ? openModal : false,
				showNotify
			};
		}
		case GROUP_STORE_CONSTANTS.API_SUCCESS: {
			const { data = false, openModal, apiType = false, showNotify = false } = payload;
			return {
				...state,
				apiLoading: false,
				msgData: data || false,
				apiType: apiType,
				apiStatus: APPLICATION_CONSTANTS.SUCCESS,
				modal: openModal || false,
				showNotify
			};
		}
		case GROUP_STORE_CONSTANTS.RESET_GRP_API_STATE: {
			return {
				...state,
				apiLoading: false,
				msgData: false,
				apiStatus: '',
				modal: '',
				apiType: false,
				showNotify: false
			};
		}
		case GROUP_STORE_CONSTANTS.GRP_UPLOAD_STATUS: {
			const { status, percentage, errMsg = false } = payload;
			const isPending = status === APPLICATION_CONSTANTS.PENDING;
			return {
				...state,
				uploadRemainingTime: isPending ? state.uploadRemainingTime : {
					min: 0,
					hr: 0,
					sec: 0
				},
				uploadStatus: {
					...state.uploadStatus,
					status: status,
					percentage: isPending ? percentage : 100,
					errMsg
				}
			};
		}
		case GROUP_STORE_CONSTANTS.GRP_UPLOAD_TIME: {
			const { remainingTime } = payload;
			return {
				...state,
				uploadRemainingTime: remainingTime
			};
		}
		case GROUP_STORE_CONSTANTS.GRP_UPLOAD_RESET: {
			return {
				...state,
				uploadRemainingTime: {
					min: 0,
					hr: 0,
					sec: 0
				},
				uploadStatus: {
					status: '',
					percentage: 0,
					errMsg: false
				}
			};
		}
		case GROUP_STORE_CONSTANTS.DELETE_GROUPS: {
			return {
				...state,
				...payload
			};
		}
		case successFor(GROUP_STORE_CONSTANTS.DOWNLOAD_GROUP): {
			return {
				...state,
				modal: GROUP_CONSTANTS.SUCCESS_MODAL
			};
		}
		case GROUP_STORE_CONSTANTS.RESET_INIT_STATE: {
			return {
				...state,
				...initState
			};
		}
		case successFor(GROUP_STORE_CONSTANTS.GET_UPDATE_STATUS): {
			const { totalCount = state.totalTobeUpdated, remainingCount = 0, jobId = state.jobId, jobStatus, csvLink, message } = payload;
			return {
				...state,
				totalTobeUpdated: totalCount,
				remainingCount: remainingCount,
				jobId,
				grpLoading: false,
				updateStatus: {
					csvLink,
					message,
					status: jobStatus
				}
			};
		}
		case GROUP_STORE_CONSTANTS.RESET_JOB_COUNT: {
			const { resetAll = false } = payload;
			let retData = {
				totalTobeUpdated: 0,
				remainingCount: 0
			};
			if (resetAll) {
				retData = initUploadState;
			}
			return {
				...state,
				...retData
			};
		}
		default:
			return state;
	}
};
