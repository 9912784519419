import { all } from 'redux-saga/effects';

import groupSaga from '../sagas/groupSaga';
import empSaga from '../sagas/employeeSaga';
import requisitionTravelSaga from '../sagas/requisitionTravelSaga';
import trfDtlSaga from '../sagas/TRFSaga';
import reportSummary from '../sagas/reports/reportSummarySaga';
import reportDetails from '../sagas/reports/reportDetailsSaga';
import gstSaga from '../sagas/gstSaga';
import mrbSaga from '../sagas/manageRequestBookingsSaga';
import orgSaga from '../sagas/organizationSaga';

export default function* rootSaga() {
	yield all([
		...groupSaga,
		...empSaga,
		...requisitionTravelSaga,
		...trfDtlSaga,
		...reportSummary,
		...reportDetails,
		...gstSaga,
		...mrbSaga,
		...orgSaga
	]);
}
