import { REPORT_LANDING_STORE_CONSTANTS } from '../../actionConstants';
import { actionCreator, successFor, failedFor } from '../../sagaUtil';

export const getSummary = actionCreator(REPORT_LANDING_STORE_CONSTANTS.GET_SUMMARY);
export const getSummarySuccess = actionCreator(successFor(REPORT_LANDING_STORE_CONSTANTS.GET_SUMMARY));
export const getSummaryFailed = actionCreator(failedFor(REPORT_LANDING_STORE_CONSTANTS.GET_SUMMARY));

export const updateFilters = actionCreator(REPORT_LANDING_STORE_CONSTANTS.UPDATE_FILTER_DATA);
export const updateFilterQuery = actionCreator(REPORT_LANDING_STORE_CONSTANTS.UPDATE_FILTER_QUERY);
export const updateFilterQueryExp = actionCreator(REPORT_LANDING_STORE_CONSTANTS.UPDATE_FILTER_QUERY_EXP);

export const getGstrStatus = actionCreator(REPORT_LANDING_STORE_CONSTANTS.GET_GSTR_STATUS);
export const getGstrStatusSuccess = actionCreator(successFor(REPORT_LANDING_STORE_CONSTANTS.GET_GSTR_STATUS));
export const getGstrStatusFailed = actionCreator(failedFor(REPORT_LANDING_STORE_CONSTANTS.GET_GSTR_STATUS));

export const scheduleSetting = actionCreator(REPORT_LANDING_STORE_CONSTANTS.SCHEDULE_SETTINGS);
export const scheduleSettingFailed = actionCreator(failedFor(REPORT_LANDING_STORE_CONSTANTS.SCHEDULE_SETTINGS));

export const getCustomReports = actionCreator(REPORT_LANDING_STORE_CONSTANTS.GET_CUSTOM_REPORTS);
export const getCustomReportsSuccess = actionCreator(successFor(REPORT_LANDING_STORE_CONSTANTS.GET_CUSTOM_REPORTS));
export const getCustomReportsFailed = actionCreator(failedFor(REPORT_LANDING_STORE_CONSTANTS.GET_CUSTOM_REPORTS));

export const getReportData = actionCreator(REPORT_LANDING_STORE_CONSTANTS.GET_REPORT_DATA);
export const getReportDataSuccess = actionCreator(successFor(REPORT_LANDING_STORE_CONSTANTS.GET_REPORT_DATA));
export const getReportDataFailed = actionCreator(failedFor(REPORT_LANDING_STORE_CONSTANTS.GET_REPORT_DATA));

export const saveReportData = actionCreator(REPORT_LANDING_STORE_CONSTANTS.SAVE_REPORT_DATA);
export const saveReportDataSuccess = actionCreator(successFor(REPORT_LANDING_STORE_CONSTANTS.SAVE_REPORT_DATA));
export const saveReportDataFailed = actionCreator(failedFor(REPORT_LANDING_STORE_CONSTANTS.SAVE_REPORT_DATA));

export const getAdditionalData = actionCreator(REPORT_LANDING_STORE_CONSTANTS.GET_ADDITIONAL_DATA);
export const getAdditionalSuccess = actionCreator(successFor(REPORT_LANDING_STORE_CONSTANTS.GET_ADDITIONAL_DATA));
export const getAdditionalFailed = actionCreator(failedFor(REPORT_LANDING_STORE_CONSTANTS.GET_ADDITIONAL_DATA));

export const deleteReport = actionCreator(REPORT_LANDING_STORE_CONSTANTS.DELETE_REPORT);

export const fetchReport = (payload) => (dispatch) => dispatch(getReportData(payload));

export const setModal = actionCreator(REPORT_LANDING_STORE_CONSTANTS.SET_MODAL);

export const updateFrequency = actionCreator(REPORT_LANDING_STORE_CONSTANTS.UPDATE_FREQUENCY);

export const updateAddData = actionCreator(REPORT_LANDING_STORE_CONSTANTS.UPDATE_ADD_DATA);

export const initReport = actionCreator(REPORT_LANDING_STORE_CONSTANTS.INIT_REPORT);
export const highlightFeature = (featureState, close) => ({
	type: REPORT_LANDING_STORE_CONSTANTS.HIGHLIGHT_FEATURE,
	payload: { featureState: close ? 0 : featureState }
});

export const setApiLoading = actionCreator(REPORT_LANDING_STORE_CONSTANTS.REPORT_API_LOADING);
export const setApiError = actionCreator(failedFor(REPORT_LANDING_STORE_CONSTANTS.REPORT_API));
export const setApiSuccess = actionCreator(successFor(REPORT_LANDING_STORE_CONSTANTS.REPORT_API));
export const resetApiState = actionCreator(REPORT_LANDING_STORE_CONSTANTS.RESET_REPORT_LANDING_API);

export const setDwnloadReportData = actionCreator(REPORT_LANDING_STORE_CONSTANTS.DWNLD_REPORT_DATA);
export const downloadTypeReport = actionCreator(REPORT_LANDING_STORE_CONSTANTS.DOWNLOAD_TYPE_REPORT);
export const downloadTypeReportSucccess = actionCreator(successFor(REPORT_LANDING_STORE_CONSTANTS.DOWNLOAD_TYPE_REPORT));
export const downloadTypeReportFailed = actionCreator(failedFor(REPORT_LANDING_STORE_CONSTANTS.DOWNLOAD_TYPE_REPORT));

export const updateReportField = actionCreator(REPORT_LANDING_STORE_CONSTANTS.UPDATE_REPORT_FIELD);

export const setInitGstState = actionCreator(REPORT_LANDING_STORE_CONSTANTS.SET_INIT_GST_STATE);
export const downloadGSTReport = actionCreator(REPORT_LANDING_STORE_CONSTANTS.DOWNLOAD_GSTR_REPORT);
export const downloadLedgerReport = actionCreator(REPORT_LANDING_STORE_CONSTANTS.DOWNLOAD_LEDGER_REPORT)
