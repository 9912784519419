import { TRAIN_STORE_CONSTANT } from '../actionConstants';
import { saveServerLogs } from '../../UtilityComponents/Util';
import { getccbAxiosConfig } from '../../apisSetup/ccbConfig';
import { PATHS, APPLICATION_CONSTANTS } from '../../AppConstants';

const ccbAxiosApiCaller = getccbAxiosConfig(true, {}, false);

export const setPassesngerData = (data) =>({
	type: TRAIN_STORE_CONSTANT.SET_PASSENGER_DETAILS_FORM,
	payload: data
});

export const setTrainData = (data) =>({
	type: TRAIN_STORE_CONSTANT.SET_TRAIN_DETAILS_FORM,
	payload: data
});

export const saveTrainData = (apiRequest) => async (dispatch) => {
	let error = false;
	let modalData = {};
	try {
		const response = await ccbAxiosApiCaller.post(PATHS.SAVE_TRAIN_DATA, apiRequest);
		let res = response && response.data;
		if (res && res.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			modalData = {
				heading: res.displayTitle,
				displayMessage: res.displayMessage
			};
		} else {
			modalData = {
				displayMessage: res.message
			};
			error = true;
		}
	} catch (ex) {
		const expData = ex && ex.response && ex.response.data;
		const displayMessage = (expData && expData.message) || APPLICATION_CONSTANTS.API_ERROR;
		modalData = { displayMessage };
		error = true;
		saveServerLogs(e.stack, 'error', PATHS.SAVE_TRAIN_DATA);
	} finally {
		dispatch({
			type: TRAIN_STORE_CONSTANT.SHOW_POPUP,
			payload: { popupData: {
				heading: error ? 'ERROR' : modalData.heading,
				content: modalData.displayMessage,
				apiSuccess: error ? 'ERROR' : 'REQ_CANCELLED'
			} }
		});
	}
};
