import {
	INVOICE_STORE_CONSTANTS
} from '../actionConstants';
import {
	getccbAxiosConfig
} from '../../apisSetup/ccbConfig';
import {
	PATHS, INVOICE_CONSTANTS
} from '../../AppConstants';
import {
	saveServerLogs
} from '../../UtilityComponents/Util';
import InvoiceUtils from '../../UtilityComponents/InvoiceUtils';
import { pollJobStatus } from 'apis/commonApis';

const ccbAxiosApiCaller = getccbAxiosConfig(true);

export const nextFeatureDesc = (payload, isClose) => {
	if (isClose === true) {
		return skipFeatureDesc();
	}
	return {
		type: INVOICE_STORE_CONSTANTS.NEXT_FEATURE,
		payload: payload
	};
};

export const skipFeatureDesc = () => ({
	type: INVOICE_STORE_CONSTANTS.SKIP_FEATURE
});

export const showModalPopup = (modal) => ({
	type: INVOICE_STORE_CONSTANTS.MODAL_TYPE,
	payload: modal
});

export const getInvoiceList = (apiRequest, reset, isScroll) => async (dispatch, getState) => {
	let response = null;
	if (reset) {
		dispatch({
			type: INVOICE_STORE_CONSTANTS.RESET_LIST,
			payload: apiRequest
		});
	}
	if (isScroll) {
		dispatch({
			type: INVOICE_STORE_CONSTANTS.LIST_SCROLL
		});
	}
	try {
		const storeData = getState();
		response = await ccbAxiosApiCaller.post(PATHS.GET_INVOICE_LIST, InvoiceUtils.formatRequestPayload(apiRequest, storeData.invoice.appliedFilters));
		dispatch({
			type: INVOICE_STORE_CONSTANTS.SET_LIST,
			payload: response.data
		});
	} catch (e) {
		dispatch({
			type: INVOICE_STORE_CONSTANTS.LIST_API_CALLED
		});
		saveServerLogs(e.stack, 'error', PATHS.GET_INVOICE_LIST);
		return;
	}
};

export const getCountBundle = (apiRequest) => async (dispatch, getState) => {
	let response = null;
	try {
		const storeData = getState();
		response = await ccbAxiosApiCaller.post(PATHS.GET_INVOICE_COUNT_BUNDLE, InvoiceUtils.formatRequestPayload(apiRequest, storeData.invoice.appliedFilters));
		dispatch({
			type: INVOICE_STORE_CONSTANTS.SET_COUNT_BUNDLE,
			payload: response.data
		});
	} catch (e) {
		dispatch({
			type: INVOICE_STORE_CONSTANTS.BUNDLE_API_CALLED
		});
		saveServerLogs(e.stack, 'error', PATHS.GET_INVOICE_COUNT_BUNDLE);
		return;
	}
};

export const getAllData = (apiRequest, init) => (dispatch) => {
	if (init) {
		dispatch({
			type: INVOICE_STORE_CONSTANTS.SET_INIT_QUERY,
			payload: apiRequest
		});
	}
	dispatch(getInvoiceList(apiRequest));
	dispatch(getCountBundle(apiRequest));
};

export const getNewPageData = (apiRequest) => (dispatch) => {
	dispatch({
		type: INVOICE_STORE_CONSTANTS.SET_INIT_QUERY,
		payload: apiRequest
	});
	dispatch(getInvoiceList(apiRequest));
};

export const getNewQueryData = (filterQuery) => (dispatch) => {
	dispatch({
		type: INVOICE_STORE_CONSTANTS.SET_QUERY,
		payload: filterQuery
	});
	dispatch(getAllData(filterQuery));
};

export const setFilterObj = (filterObj) => (dispatch) => {
	dispatch({
		type: INVOICE_STORE_CONSTANTS.SET_FILTER_OBJECT,
		payload: filterObj
	});
};

export const upateAppliedFilter = (payload) => ({
	type: INVOICE_STORE_CONSTANTS.UPDATE_APPLY_FILTER_OBJECT,
	payload: payload
});

export const selectInvoice = (data) => ({
	type: INVOICE_STORE_CONSTANTS.UPDATE_SELECT_LIST,
	payload: data
});

export const startBulkDownload = (id, isMail) => async (dispatch) => {
	const successCb = (data = {}) => {
		dispatch({
			type: INVOICE_STORE_CONSTANTS.DOWNLOAD_INVOICE_SUCCESS
		});
		if (data.s3Url) {
			window.location = data.s3Url;
		} else {
			failureCb();
		}
	};

	const failureCb = () => {
		dispatch({
			type: INVOICE_STORE_CONSTANTS.DOWNLOAD_INVOICE_FAILED
		});
	};
	setTimeout(() => {
		pollJobStatus({ 'invoiceJobId': id }, successCb, failureCb);
	}, INVOICE_CONSTANTS.POLL_INTERVAL * 1000);
};

export const downloadInvoices = (apiRequest) => async (dispatch, getState) => {
	let response = null;
	dispatch({
		type: INVOICE_STORE_CONSTANTS.DOWNLOAD_INVOICE_START
	});
	try {
		const storeData = getState();
		response = await ccbAxiosApiCaller.post(PATHS.DOWNLOAD_INVOICE, InvoiceUtils.formatRequestPayload(apiRequest, storeData.invoice.appliedFilters));
		if (response.data.responseCode === '600' && response.data.jobId) {
			startBulkDownload(response.data.jobId)(dispatch);
		} else {
			dispatch({
				type: INVOICE_STORE_CONSTANTS.DOWNLOAD_INVOICE_FAILED
			});
		}
	} catch (e) {
		dispatch({
			type: INVOICE_STORE_CONSTANTS.DOWNLOAD_INVOICE_FAILED
		});
		saveServerLogs(e.stack, 'error', PATHS.DOWNLOAD_INVOICE);
		return;
	}
};

export const fetchFilterOptions = (query) => async (dispatch) => {
	let response = null;
	let startDate = query.startDate;
	let endDate = query.endDate;
	try {
		startDate = new Date(`${query.startDate} ${INVOICE_CONSTANTS.START_TIME}`).getTime();
		endDate = new Date(`${query.endDate} ${INVOICE_CONSTANTS.END_TIME}`).getTime();
	} catch (error) {

	}
	let url = `${PATHS.GET_FILTER_OPTIONS}?startDate=${startDate}&endDate=${endDate}`;
	try {
		response = await ccbAxiosApiCaller.get(url);
		dispatch({
			type: INVOICE_STORE_CONSTANTS.SET_FILTER_OPTIONS,
			payload: response.data
		});
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.GET_FILTER_OPTIONS);
		return;
	}
};

export const getInvoiceById = (id) => async (dispatch) => {
	let response = null;
	dispatch({
		type: INVOICE_STORE_CONSTANTS.SEARCH_RESULTS_START,
		payload: id
	});
	const url = PATHS.INVOICE_ID_SEARCH.replace('{id}', id);
	try {
		response = await ccbAxiosApiCaller.get(url);
		dispatch({
			type: INVOICE_STORE_CONSTANTS.SET_SEARCH_RESULTS,
			payload: response.data
		});
	} catch (e) {
		dispatch({
			type: INVOICE_STORE_CONSTANTS.LIST_API_CALLED,
			payload: true
		});
		saveServerLogs(e.stack, 'error', PATHS.INVOICE_ID_SEARCH);
		return;
	}
};

export const cancelSearch = () => ({
	type: INVOICE_STORE_CONSTANTS.SEARCH_RESULTS_CANCEL
});

export const resetDownload = () => ({
	type: INVOICE_STORE_CONSTANTS.DOWNLOAD_RESET
});

export const emailInvoice = (apiRequest) => async (dispatch, getState) => {
	let response = null;
	try {
		const storeData = getState();
		response = await ccbAxiosApiCaller.post(PATHS.EMAIL_DOWNLOAD_LINK, InvoiceUtils.formatRequestPayload(apiRequest, storeData.invoice.appliedFilters));
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.EMAIL_DOWNLOAD_LINK);
		return;
	}
};

export const getAllDateCounts = (apiRequest) => async (dispatch) => {
	let response = null;
	dispatch({
		type: INVOICE_STORE_CONSTANTS.SET_ALL_COUNT_API
	});
	try {
		response = await ccbAxiosApiCaller.post(PATHS.GET_INVOICE_COUNT_BUNDLE, InvoiceUtils.formatRequestPayload(apiRequest));
		dispatch({
			type: INVOICE_STORE_CONSTANTS.SET_ALL_COUNT_BUNDLE,
			payload: response.data
		});
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.GET_INVOICE_COUNT_BUNDLE);
	}
};

export const setReminder = (apiRequest) => async (dispatch) => {
	const apiParams = {
		'is_enable': apiRequest.is_enable,
		'type': 'INVOICES',
		'frequency': apiRequest.frequency,
		'is_granular': true,
		'granular_data': {
			'every_period': apiRequest.every_period,
			'on_day': apiRequest.on_day
		}
	};
	dispatch({
		type: INVOICE_STORE_CONSTANTS.REMINDER_API_CALLED
	});
	const url = apiRequest.is_enable ? PATHS.SET_REMINDER : PATHS.REMOVE_REMINDER;
	try {
		await ccbAxiosApiCaller.post(url, apiParams);
		dispatch({
			type: INVOICE_STORE_CONSTANTS.REMINDER_SUCCESS,
			payload: {
				apiParams
			}
		});
		getReminderSettings()(dispatch);
	} catch (e) {
		dispatch({
			type: INVOICE_STORE_CONSTANTS.REMINDER_FAILED
		});
		saveServerLogs(e.stack, 'error', url);
		return;
	}
	const trackVal = apiRequest.is_enable ? 'enabled' : 'disabled';
	InvoiceUtils.trackInvoiceEvents(`reminder_${trackVal}`);
};

export const getReminderSettings = (apiRequest) => async (dispatch) => {
	let response = null;
	dispatch({
		type: INVOICE_STORE_CONSTANTS.GET_REMINDER_API_CALLED
	});
	try {
		response = await ccbAxiosApiCaller.get(PATHS.GET_REMINDER_SETTING + 'INVOICES');
		if (response.data.responseCode === '600' && response.data.response) {
			dispatch({
				type: INVOICE_STORE_CONSTANTS.GET_REMINDER_SUCCESS,
				payload: response.data.response
			});
		}
	} catch (e) {
		saveServerLogs(e.stack, 'error', PATHS.GET_REMINDER_SETTING);
	}
};

export const getInvoiceSummary = (apiRequest) => async (dispatch) => {
	let response = null;
	dispatch({
		type: INVOICE_STORE_CONSTANTS.INVOICE_SUMMARY_CALLED
	});
	try {
		response = await ccbAxiosApiCaller.post(PATHS.INVOICE_SUMMARY, apiRequest);
		const responseData = response && response.data;
		if (responseData) {
			const formattedSummary = InvoiceUtils.formatSummaryData(responseData.invoiceSummary);
			dispatch({
				type: INVOICE_STORE_CONSTANTS.INVOICE_SUMMARY_SUCCESS,
				payload: formattedSummary
			});
		}
	} catch (e) {
		dispatch({
			type: INVOICE_STORE_CONSTANTS.INVOICE_SUMMARY_FAILED
		});
		saveServerLogs(e.stack, 'error', PATHS.INVOICE_SUMMARY);
		return;
	}
};
