import { GST_ACTION_CONSTANTS } from '../actionConstants';
import { failedFor, successFor } from '../sagaUtil';

const INITIAL_STATE = {
	gstData: {
		apiLoader: true,
		apiSuccess: false,
		apiError: false,
		gstList: false
	},
	selectedGst: {},
	updateGst: {
		apiLoader: false,
		apiSuccess: false,
		apiError: false
	},
	showModal: false
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}
	const { type, payload = {} } = action;
	switch (type) {
		case (GST_ACTION_CONSTANTS.GET_GST_DATA): {
			return {
				...state,
				gstData: {
					gstList: false,
					apiLoader: true,
					apiSuccess: false,
					apiFailed: false
				}
			};
		}
		case successFor(GST_ACTION_CONSTANTS.GET_GST_DATA): {
			return {
				...state,
				gstData: {
					gstList: payload,
					apiLoader: false,
					apiSuccess: true,
					apiFailed: false
				}
			};
		}
		case failedFor(GST_ACTION_CONSTANTS.GET_GST_DATA): {
			return {
				...state,
				gstData: {
					gstList: false,
					apiLoader: false,
					apiSuccess: false,
					apiFailed: true,
					message: payload
				}
			};
		}
		case (GST_ACTION_CONSTANTS.UPDATE_GST_DATA): {
			return {
				...state,
				updateGst: {
					apiLoader: true,
					apiSuccess: false,
					apiFailed: false,
					type: payload.action
				}
			};
		}
		case successFor(GST_ACTION_CONSTANTS.UPDATE_GST_DATA): {
			return {
				...state,
				selectedGst: {},
				updateGst: {
					apiLoader: false,
					apiSuccess: true,
					apiFailed: false
				}
			};
		}
		case failedFor(GST_ACTION_CONSTANTS.UPDATE_GST_DATA): {
			return {
				...state,
				updateGst: {
					apiLoader: false,
					apiSuccess: false,
					apiFailed: true
				}
			};
		}
		case GST_ACTION_CONSTANTS.UPDATE_POP: {
			return {
				...state,
				showPop: payload
			};
		}
		case GST_ACTION_CONSTANTS.UPDATE_SELECTED_GST: {
			return {
				...state,
				selectedGst: { ...payload }
			};
		}
		default: {
			return state;
		}
	}
};
