import { getccbAxiosConfig } from '../../apisSetup/ccbConfig';
import { API_CALL_CONSTANTS, CONSTANTS, SUCCESS_STATUS_CODE, logOmniture } from '../../CompanyAdminReferral/utils';
import { getCookie } from '../../UtilityComponents/Util';
import { PATHS } from '../../AppConstants';
let ccbAxiosApiCaller = getccbAxiosConfig(true);

export const saveAdminRefer = (adminReferObj) => async (dispatch) => {
	try {
		let error = false;
		let dispatchObj = {};
		let mmtauth = getCookie('mmt-auth');
		if (!mmtauth) {
			window.location.href = PATHS.LANDING_LOGIN_PAGE;
		}
		let response = await ccbAxiosApiCaller.post(API_CALL_CONSTANTS.POST_ADMIN_REFER_API, adminReferObj);
		let responseData = response && response.data;
		if (responseData && responseData.responseCode === SUCCESS_STATUS_CODE) {
			dispatchObj = {
				type: CONSTANTS.POST_ADMIN_REFER_SUCCESS,
				payload: { admin_refer_success: true, loader: false, referredAdminObj: adminReferObj }
			};
		} else if (responseData && responseData.responseCode === 'DCF') {
			error = true;
			dispatchObj = {
				type: CONSTANTS.ADMIN_REFER_FAILURE,
				payload: { adminError: CONSTANTS.USER_REFFERED_ERROR, loader: false }
			};
		} else if (responseData && responseData.responseCode === 'RASDE') {
			error = true;
			dispatchObj = {
				type: CONSTANTS.ADMIN_REFER_FAILURE,
				payload: { adminError: CONSTANTS.DOMAIN_MISMATCH_ERROR, loader: false }
			};
		} else if (responseData && responseData.responseCode && responseData.message) {
			error = true;
			dispatchObj = {
				type: CONSTANTS.ADMIN_REFER_FAILURE,
				payload: { adminError: responseData.message, loader: false }
			};
		}
		if (error) {
			logOmniture('error', 'mybiz:refer_admin_page');
		} else {
			logOmniture('refer_admin_details_submitted', 'mybiz:refer_admin_page');
		}
		dispatch(dispatchObj);
	} catch (e) {
		logOmniture('error', 'mybiz:refer_admin_page');
		dispatch({
			type: CONSTANTS.ADMIN_REFER_FAILURE,
			payload: { adminError: CONSTANTS.GENERIC_ERROR, loader: false }
		});
	}
};

export const saveSelfAdminDetails = (adminReferDetailObj) => async (dispatch) => {
	try {
		let error = false;
		let mmtauth = getCookie('mmt-auth');
		if (!mmtauth) {
			window.location.href = PATHS.LANDING_LOGIN_PAGE;
		}
		let response = await ccbAxiosApiCaller.post(API_CALL_CONSTANTS.SELF_ADMIN_REFER_DETAILS, adminReferDetailObj);
		let responseData = response && response.data;
		if (responseData && responseData.responseCode === SUCCESS_STATUS_CODE) {
			logOmniture('admin_claim_details_submitted', 'mybiz:become_admin_page');
			dispatch({
				type: CONSTANTS.SELF_ADMIN_REFER_DETAILS_POST_SUCCESS,
				payload: { admin_save_detail_success: true, loader: false }
			});
			return;
		} else if (responseData && responseData.responseCode === 'DCF') {
			error = true;
			dispatch({
				type: CONSTANTS.ADMIN_REFER_FAILURE,
				payload: { adminError: CONSTANTS.USER_REFFERED_ERROR, loader: false }
			});
		} else if (responseData && responseData.responseCode && responseData.message) {
			error = true;
			dispatch({
				type: CONSTANTS.ADMIN_REFER_FAILURE,
				payload: { adminError: responseData.message, loader: false }
			});
		}
		if (error) {
			logOmniture('error', 'mybiz:become_admin_page');
		}
	} catch (e) {
		logOmniture('error', 'mybiz:become_admin_page');
		dispatch({
			type: CONSTANTS.ADMIN_REFER_FAILURE,
			payload: { adminError: CONSTANTS.GENERIC_ERROR, loader: false }
		});
	}
};

export const fetchSelfAdminDetails = () => async (dispatch) => {
	try {
		let response = await ccbAxiosApiCaller.get(API_CALL_CONSTANTS.SELF_ADMIN_REFER_DETAILS);
		let responseData = response && response.data;
		if (responseData && responseData.responseCode === SUCCESS_STATUS_CODE) {
			let adminObj = {
				name: responseData.name,
				phoneNo: responseData.phoneNo,
				emailId: responseData.emailId,
				referrerEmailId: responseData.referrerEmailId,
				referrerName: responseData.referrerName,
				referrerPhoneNo: responseData.referrerPhoneNo
			};
			dispatch({
				type: CONSTANTS.SELF_ADMIN_REFER_DETAILS_FETCH_SUCCESS,
				payload: { admin_fetch_detail_success: true, adminObj, loader: false }
			});
		} else if (responseData && responseData.responseCode) {
			dispatch({
				type: CONSTANTS.ADMIN_REFER_FAILURE,
				payload: { adminError: responseData.message, loader: false }
			});
		}
	} catch (e) {
		dispatch({
			type: CONSTANTS.ADMIN_REFER_FAILURE,
			payload: { adminError: CONSTANTS.GENERIC_ERROR, loader: false }
		});
	}
};

export const fetchEmployeeData = () => async (dispatch) => {
	try {
		dispatch(clearAdminState());
		let response = await ccbAxiosApiCaller.get(PATHS.GET_EMP_DETAILS);
		let responseData = response && response.data;
		if (responseData && responseData.responseCode === SUCCESS_STATUS_CODE && responseData.employee) {
			const { businessEmailId, name } = responseData.employee;
			dispatch({
				type: CONSTANTS.EMPLOYEE_DATA_API_DATA_SUCCESS,
				payload: { businessEmailId: businessEmailId, referrerName: name }
			});
		}
	} catch (e) {
	}
};

export const clearAdminState = () => async (dispatch) => {
	dispatch({
		type: CONSTANTS.CLEAR_ADMIN_STATE,
		payload: {
			admin_refer_success: false,
			admin_fetch_detail_success: false,
			admin_save_detail_success: false,
			adminError: ''
		}
	});
};

export const setLoader = (loader) => async (dispatch) => {
	dispatch({
		type: CONSTANTS.CLEAR_ADMIN_STATE,
		payload: { loader }
	});
};
