import { RESOURCE_STORE_CONSTANT } from '../actionConstants';
import { PATHS, APPLICATION_CONSTANTS, RESOURCE_CONSTANT } from '../../AppConstants';
import { saveServerLogs } from 'UtilityComponents/Util';

export const getBlogsData = (apidata = {}) => async (dispatch, _, axiosInstance) => {
	let error = false;
	try {
		const { pageName = RESOURCE_CONSTANT.PAGE_NAME, limit = '' } = apidata;
		let url = `${PATHS.BLOGS_DATA_REQUEST}?pageName=${pageName}`;
		if (limit) {
			url = `${url}&limit=${limit}`;
		}

		dispatch({
			type: RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA_LOADING,
			payload: { blogsLoading: true }
		});
		const response = await axiosInstance.get(url);
		const resp = response && response.data;
		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			dispatch({
				type: RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA,
				payload: { blogsData: resp.data, blogsLoading: false }
			});
			return;
		}
		error = true;
	} catch (e) {
		error = true;
		saveServerLogs(e.stack, 'error', PATHS.GET_EXPENSE_LIST);
	} finally {
		if (error) {
			dispatch({
				type: RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA_FAILURE,
				payload: { errorMesg: APPLICATION_CONSTANTS.API_ERROR, blogsLoading: false, blogsFailure: true }
			});
		}
	}
};

export const getProductVideos = (apidata = {}) => async (dispatch, _, axiosInstance) => {
	let error = false;
	try {
		const { pageName = RESOURCE_CONSTANT.PAGE_NAME, limit = '' } = apidata;
		let url = `${PATHS.PRODUCT_VIDEO_REQUEST}?pageName=${pageName}`;
		if (limit) {
			url = `${url}&limit=${limit}`;
		}
		dispatch({
			type: RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA_LOADING,
			payload: { videoLoading: true }
		});
		const response = await axiosInstance.get(url);
		const resp = response && response.data;
		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			dispatch({
				type: RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA,
				payload: { productVideos: resp.data, videoLoading: false }
			});
			return;
		}
		error = true;
	} catch (e) {
		error = true;
	} finally {
		if (error) {
			dispatch({
				type: RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA_FAILURE,
				payload: { errorMesg: APPLICATION_CONSTANTS.API_ERROR, videoLoading: false, videoFailure: true }
			});
		}
	}
};

export const getBusinessDesign = () => async (dispatch, _, axiosInstance) => {
	let error = false;
	try {
		dispatch({
			type: RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA_LOADING,
			payload: { designLoading: true }
		});
		const response = await axiosInstance.get(PATHS.BUSINESS_DATA_REQUEST);
		const resp = response && response.data;
		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			dispatch({
				type: RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA,
				payload: { businessDesignData: resp.data, designLoading: false }
			});
			return;
		}
		error = true;
	} catch (e) {
		error = true;
	} finally {
		if (error) {
			dispatch({
				type: RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA_FAILURE,
				payload: { errorMesg: APPLICATION_CONSTANTS.API_ERROR, designLoading: false, designFailure: true }
			});
		}
	}
};

export const getBenefits = () => async (dispatch, _, axiosInstance) => {
	let error = false;
	try {
		dispatch({
			type: RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA_LOADING,
			payload: { benefitsLoading: true }
		});
		const response = await axiosInstance.get(PATHS.MYBIZ_BENEFIT_REQUEST);
		const resp = response && response.data;
		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			dispatch({
				type: RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA,
				payload: { myBizBenefitsData: resp.data, benefitsLoading: false }
			});
			return;
		}
		error = true;
	} catch (e) {
		error = true;
	} finally {
		if (error) {
			dispatch({
				type: RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA_FAILURE,
				payload: { errorMesg: APPLICATION_CONSTANTS.API_ERROR, benefitsLoading: false, benefitsFailure: true }
			});
		}
	}
};

export const getCustomerStories = (apidata = {}) => async (dispatch, _, axiosInstance) => {
	let error = false;
	try {
		const { pageName = RESOURCE_CONSTANT.PAGE_NAME, limit = '' } = apidata;
		let url = `${PATHS.TESTIMONIES_REQUEST}?pageName=${pageName}`;
		if (limit) {
			url = `${url}limit=${limit}`;
		}
		dispatch({
			type: RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA_LOADING,
			payload: { custStoriesLoading: true }
		});
		const response = await axiosInstance.get(url);
		const resp = response && response.data;
		if (resp && resp.responseCode === APPLICATION_CONSTANTS.SUCCESS_RESPONSE_CODE) {
			dispatch({
				type: RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA,
				payload: { custStoriesData: resp.data, custStoriesLoading: false }
			});
			return;
		}
		error = true;
	} catch (e) {
		error = true;
	} finally {
		if (error) {
			dispatch({
				type: RESOURCE_STORE_CONSTANT.GET_RESOURCE_DATA_FAILURE,
				payload: { errorMesg: APPLICATION_CONSTANTS.API_ERROR, custStoriesLoading: false, custStoriesFailure: true }
			});
		}
	}
};
