import { TRAVEL_WISHLIST_CONST } from '../actionConstants';
import { LOB } from '../../shortListNShare/config';

const INITIAL_STATE = {
	wishlistData: null,
	travellerName: '',
	travellerCount: 1,
	cabinClass: {},
	statusInfo: {},
	selectedProducts: {},
	moreOptions: [],
	goingTo: '',
	ownerName: '',
	createdAt: '',
	cancelRequestText: [],
	selectedLob: { [LOB.FLIGHT]: 0, [LOB.HOTEL]: 0 },
	flightFare: {},
	hotelFare: {}
};

export default (state = INITIAL_STATE, action) => {
	if (!action) {
		return state;
	}

	const { type, payload = {} } = action;

	switch (type) {
		case TRAVEL_WISHLIST_CONST.GET_WISHLIST: {
			const {
				wishList,
				travellerCount,
				cc,
				statusInfo,
				moreOptions,
				goingTo,
				ownerName,
				createdAt,
				cancelRequestText,
				lobCount = { [LOB.FLIGHT]: 0, [LOB.HOTEL]: 0 },
				recipientDetails,
				bookingFor
			} = payload;

			return {
				...state,
				wishlistData: wishList,
				travellerCount,
				cabinClass: cc,
				statusInfo,
				selectedProducts: {},
				moreOptions,
				goingTo,
				ownerName,
				createdAt,
				cancelRequestText,
				lobCount,
				selectedLob: { FLIGHT: 0, HOTEL: 0 },
				flightFare: {},
				hotelFare: {},
				recipientDetails,
				bookingFor
			};
		}
		case TRAVEL_WISHLIST_CONST.SET_WISHLIST_OPTION: {
			const { option, checked, group, lob } = payload;
			let newProductsList;
			let newSelectedLob;
			if (checked) {
				newProductsList = { ...state.selectedProducts, [group]: option };
				const lobCount = state.selectedLob[lob] + 1;
				newSelectedLob = { ...state.selectedLob, [lob]: lobCount };
			} else {
				newProductsList = { ...state.selectedProducts };
				const lobCount = state.selectedLob[lob] - 1;
				newSelectedLob = { ...state.selectedLob, [lob]: lobCount };
				delete newProductsList[group];
			}

			return {
				...state,
				selectedProducts: newProductsList,
				selectedLob: newSelectedLob
			};
		}
		case TRAVEL_WISHLIST_CONST.LOADING_FLIGHT_FARE: {
			const { grpId, loadingMap } = payload;
			const updatedFlightFare = { ...state.flightFare, [grpId]: loadingMap };
			return {
				...state,
				flightFare: updatedFlightFare
			};
		}
		case TRAVEL_WISHLIST_CONST.GET_FLIGHT_FARE: {
			const { grpId, fareProductMap } = payload;
			const updatedFlightFare = { ...state.flightFare, [grpId]: fareProductMap };
			return {
				...state,
				flightFare: updatedFlightFare,
				isFlightFareSet: true
			};
		}
		case TRAVEL_WISHLIST_CONST.RESET_FLIGHT_FARE: {
			return {
				...state,
				flightFare: {},
				isFlightFareSet: false
			};
		}
		case TRAVEL_WISHLIST_CONST.LOADING_HOTEL_FARE: {
			const { grpId, loadingMap } = payload;
			const updatedHotelFare = { ...state.hotelFare, [grpId]: loadingMap };
			return {
				...state,
				hotelFare: updatedHotelFare
			};
		}
		case TRAVEL_WISHLIST_CONST.GET_HOTEL_FARE: {
			const { grpId, fareProductMap } = payload;
			const updatedHotelFare = { ...state.hotelFare, [grpId]: fareProductMap };
			return {
				...state,
				hotelFare: updatedHotelFare,
				isHotelFareSet: true
			};
		}
		case TRAVEL_WISHLIST_CONST.RESET_HOTEL_FARE: {
			return {
				...state,
				hotelFare: {},
				isHotelFareSet: false
			};
		}
		default:
			return state;
	}
};
