// Constants and Config
import { APPLICATION_CONSTANTS } from 'AppConstants';
import { CLAIMS_STATE_CONFIG, DEFAULT_OPTS, MSR_CONFIG, MY_CLAIMS_CONFIG } from '../../bizHeader/config';
import { BIZ_HEADER_STORE_CONSTANTS, CALLBACK_ACTION_TYPE } from '../actionConstants';

// Utils
import { formatOptions, formatProfileData } from 'UtilityComponents/bizHeaderUtils';
import { serializeObject } from 'UtilityComponents/Util';

const initialState = {
	headerOptions: formatOptions({ headers: DEFAULT_OPTS }),
	headerOptionsData: { headers: DEFAULT_OPTS },
	profileData: false,
	feature: false,
	requistionEnabled: false,
	centralBooker: false,
	bizHeader: {},
	loader: false,
	profileApiCalling: true,
	headerApiCall: true,
	msrRedirectionUrl: {
		isLoading: false,
		error: false
	}
};

export default (state = initialState, action) => {
	if (!action) return state;

	switch (action.type) {
		case BIZ_HEADER_STORE_CONSTANTS.BIZ_HEADER_NEXT_FEATURE: {
			let feature = state.feature ? parseInt(state.feature) + (action.payload) : 1;
			if (!action.payload) {
				feature = 1;
			}
			return {
				...state,
				feature
			};
		}
		case BIZ_HEADER_STORE_CONSTANTS.SET_SERVER_DATA: {
			const { headerOptionsData = { headers: DEFAULT_OPTS }, locationData = {} } = action.payload;
			const headerOptions = formatOptions(headerOptionsData, false, { pathname: locationData.pageUrl, searchQry: serializeObject(locationData.params) });
			return {
				...state,
				headerApiCall: false,
				headerApiFailed: false,
				headerOptions: headerOptions,
				headerOptionsData: headerOptionsData
			};
		}
		case BIZ_HEADER_STORE_CONSTANTS.BIZ_HEADER_SKIP_FEATURE: {
			return {
				...state,
				feature: false

			};
		}
		case BIZ_HEADER_STORE_CONSTANTS.GET_HEADER_OPTS_CALLING:
			return {
				...state,
				headerApiCall: true
			};
		case BIZ_HEADER_STORE_CONSTANTS.GET_HEADER_OPTS_SUCCESS:
			const headerOptionsData = action.payload || state.headerOptionsData;
			if (Array.isArray(headerOptionsData.headers)) {
				headerOptionsData.headers = [...headerOptionsData.headers, MY_CLAIMS_CONFIG, MSR_CONFIG];
			}
			// to be removed
			const isDiceOptin = state.orgDetails?.tneStatus ? state.orgDetails.tneStatus !== CLAIMS_STATE_CONFIG.NOT_DONE : false;
			const diceEnabled = typeof state.features?.diceEnabled === 'undefined' ? undefined : (state.features?.diceEnabled || isDiceOptin);
			const headerOptions = formatOptions(headerOptionsData, diceEnabled);
			return {
				...state,
				headerApiCall: false,
				headerApiFailed: false,
				headerOptions: headerOptions,
				headerOptionsData: headerOptionsData
			};
		case BIZ_HEADER_STORE_CONSTANTS.UPDATE_HEADER_OPTS: {
			const headerOptionsData = state.headerOptionsData || {};
			// to be removed
			const isDiceOptin = state.orgDetails?.tneStatus ? state.orgDetails.tneStatus !== CLAIMS_STATE_CONFIG.NOT_DONE : false;
			const diceEnabled = typeof action.payload === 'undefined' ? undefined : (action.payload.diceEnabled || isDiceOptin);
			const headerOptions = formatOptions(headerOptionsData, diceEnabled);
			return {
				...state,
				isExperimentData: true,
				headerOptions: headerOptions,
				features: {
					...state.features,
					...action.payload
				}
			};
		}
		case BIZ_HEADER_STORE_CONSTANTS.GET_HEADER_OPTS_FAILURE:
			return {
				...state,
				headerApiCall: false,
				headerApiFailed: true
				// headerOptions: {
				//     headers: []
				// }
			};
		case BIZ_HEADER_STORE_CONSTANTS.GET_PROFILE_CALLING:
			return {
				...state,
				profileApiCalling: true
			};
		case BIZ_HEADER_STORE_CONSTANTS.GET_PROFILE_SUCCESS:
			const { profileData, features = {}, orgDetails } = formatProfileData(action.payload);
			return {
				...state,
				profileData: profileData,
				features,
				orgDetails,
				profileApiCalling: false,
				profileApiSucces: true,
				profileApiFailed: false
			};
		case BIZ_HEADER_STORE_CONSTANTS.GET_PROFILE_FAILURE:
			return {
				...state,
				profileApiCalling: false,
				profileData: false,
				profileApiSucces: false,
				profileApiFailed: true
			};
		case BIZ_HEADER_STORE_CONSTANTS.ASK_FOR_USER_CONFIRMATION:
			return {
				...state,
				profileApiFailed: true
			};
		case BIZ_HEADER_STORE_CONSTANTS.BIZ_LOGOUT_SUCESS:
			return {
				...state,
				profileApiFailed: true
			};
		case CALLBACK_ACTION_TYPE.GET_CALLBACK_SUPPORT_CALLING:
			return {
				...state,
				callBackSupportCompleted: false
			};
		case CALLBACK_ACTION_TYPE.GET_CALLBACK_SUPPORT_SUCCESS:
			const { response, isHeader } = action.payload;
			return {
				...state,
				...response,
				showCallbackTip: isHeader,
				callBackSupportCompleted: true
			};
		case CALLBACK_ACTION_TYPE.GET_CALLBACK_SUPPORT_FAILURE:
			return {
				...state,
				callBackSupportCompleted: true
			};

		case CALLBACK_ACTION_TYPE.REQUEST_CALLBACK:
			return {
				...state,
				requestCallBackApiCall: true
			};
		case CALLBACK_ACTION_TYPE.REQUEST_CALLBACK_SUCCESS:
			return {
				...state,
				...action.payload,
				requestFormPopUp: false,
				showPopUp: false,
				confirmPopUp: true,
				requestCallBackApiCall: false
			};
		case CALLBACK_ACTION_TYPE.REQUEST_CALLBACK_FAILURE:
			return {
				...state,
				requestCallBackApiCall: false
			};
		case CALLBACK_ACTION_TYPE.CALLBACK_MODAL_DATA:
			return {
				...state,
				...action.payload
			};
		case BIZ_HEADER_STORE_CONSTANTS.SET_LOADING:
			return {
				...state,
				...action.payload
			};
		case BIZ_HEADER_STORE_CONSTANTS.UPDATE_ORG_SUCCESS:
			return {
				...state,
				orgDetails: action.payload
			};
		case BIZ_HEADER_STORE_CONSTANTS.FETCH_MSR_DETAILS_REQUEST:
			return {
				...state,
				msrRedirectionUrl: {
					loading: true
				}
			};
		case BIZ_HEADER_STORE_CONSTANTS.FETCH_MSR_DETAILS_UPDATE:
			const { scenario = '', error } = action.payload;

			/* Success case */
			const updatedState = {
				...state,
				msrRedirectionUrl: {
					loading: false
				}
			};

			/* Error case */
			if (scenario === APPLICATION_CONSTANTS.FAILED) {
				updatedState.msrRedirectionUrl.error = error;
			}

			return updatedState;
		case BIZ_HEADER_STORE_CONSTANTS.FETCH_MSR_DETAILS_RESET:
			return {
				...state,
				msrRedirectionUrl: {
					isLoading: false,
					error: false
				}
			};
	}
	return state;
};
